import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { downloadAndArchiveDocuments } from "@documents/store/thunks/downloadAndArchiveDocuments";
import { getUnreadDocumentIds } from "@documents/utils/getUnreadDocumentIds";
import TermsNotAcceptedError from "@terms/utils/TermsNotAcceptedError";
import { EventDocumentRow } from "@myCalendar/models";
import { updateEventDocumentsReadFlag } from "@myCalendar/store/thunks/updateEventDocumentsReadFlag";

export const downloadSelectedEventDocuments = createAsyncThunk<void, EventDocumentRow[], ThunkApiConfig>(
  "myCalendar/downloadSelectedEventDocuments",
  async (rows, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(downloadAndArchiveDocuments(rows));

      const documentIds = rows.map((row) => row.id);
      const unreadDocumentsIds = getUnreadDocumentIds(rows, documentIds);

      dispatch(
        updateEventDocumentsReadFlag({
          documentIds: unreadDocumentsIds,
        })
      );

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: "Document download successful.",
        })
      );
    } catch (error) {
      if (error instanceof TermsNotAcceptedError) {
        return;
      }

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "The document download failed",
        })
      );

      return rejectWithValue("Failed to download document");
    }
  }
);
