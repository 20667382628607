import React, { CSSProperties, memo, useCallback, useEffect, useMemo } from "react";
import { useFlexLayout, useRowSelect, useTable } from "react-table";
import { FixedSizeList } from "react-window";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { Box, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { BaseRow, DataTableProps } from "@shared/components/DataGrid/models";
import { HeaderCell, Row } from "@shared/components/DataGrid/components";
import {
  useDataGridHeaderHeight,
  useDataGridInfiniteScroll,
  useDataGridScrollDimensions,
  useVirtualizedDataGridHeight,
} from "@shared/components/DataGrid/hooks";
import { dataGridRowHeight } from "@shared/components/DataGrid/constants";

const useStyles = makeStyles<Theme, { height: number; headerVisible: boolean; verticalScrollbarWidth: number }>(
  (theme) => ({
    table: {
      height: ({ height }) => height ?? "auto",
      minWidth: "100% !important",
    },
    portionLoadingIndicator: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: ({ headerVisible }) => (headerVisible ? "flex" : "none"),
      alignItems: "flex-start",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingRight: ({ verticalScrollbarWidth }) => verticalScrollbarWidth,
      overflow: "hidden",
      backgroundColor: theme.palette.common.white,
    },
  })
);

const DataGrid = <D extends BaseRow>({
  columns,
  data,
  maxHeight,
  menu,
  headerVisible = true,
  infiniteScrollConfig,
  onSortChange,
}: DataTableProps<D>) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow, toggleAllRowsSelected, selectedFlatRows } =
    useTable<D>(
      {
        columns,
        data: data ?? [],
        autoResetSelectedRows: false,
      },
      useFlexLayout,
      useRowSelect
    );

  const selectedRowIds = useMemo(() => selectedFlatRows.map((row) => row.original.id || row.id), [selectedFlatRows]);

  const { headerHeight, headerRef, setHeaderRef } = useDataGridHeaderHeight();

  const {
    verticalScrollbarWidth,
    horizontalScrollbarHeight,
    scrollerRef: listRef,
    setScrollerRef,
  } = useDataGridScrollDimensions();

  const { height, bodyHeight } = useVirtualizedDataGridHeight({
    totalRows: rows.length,
    maxHeight,
    headerHeight,
    horizontalScrollbarHeight,
  });

  const { isItemLoaded, handleLoadMore, itemCount } = useDataGridInfiniteScroll(rows.length, infiniteScrollConfig);

  const classes = useStyles({
    height,
    verticalScrollbarWidth,
    headerVisible,
  });

  const handleListScroll = useCallback(
    (event) => {
      if (headerRef && headerRef.scrollLeft !== event.target.scrollLeft) {
        headerRef.scrollLeft = event.target.scrollLeft;
      }
    },
    [headerRef]
  );

  useEffect(() => {
    if (listRef) {
      listRef.addEventListener("scroll", handleListScroll);
    }
  }, [listRef, handleListScroll]);

  /**
   * Function to render a table row.
   * ---
   * To display InfiniteScroll loading indicator as the last item of the list, we do a little hack here:
   * 1) if loading is in progress, increase itemCount property of the FixedSizeList by 1;
   * 2) therefore we still have the same amount of rows(e.g. 50), but renderRow will be invoked 51 times
   * hence the last row won't be found in the rows array and this is the time for loading indicator to get to the scene.
   * ---
   * Even though Row component doesn't need selected property, it's crucial to have it here to re-render row if it's
   * been selected.
   */
  const renderRow = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const row = rows[index];

      if (!row) {
        return (
          <div style={style} className={classes.portionLoadingIndicator}>
            <CircularProgress size={24} />
          </div>
        );
      }

      const selected = Boolean(selectedRowIds[Number(row.id)]);

      prepareRow(row);
      const { key } = row.getRowProps();

      return <Row {...row} style={style} key={key} selected={selected} />;
    },
    [classes.portionLoadingIndicator, prepareRow, rows, selectedRowIds]
  );

  return (
    <div {...getTableProps()} className={classes.table} data-testid="dataGrid">
      <div className={classes.header} ref={setHeaderRef}>
        {headers.map((c) => (
          <HeaderCell
            {...c}
            key={c.id}
            toggleAllRowsSelected={toggleAllRowsSelected}
            isAnyRowSelected={Boolean(selectedRowIds.length)}
            menu={menu}
            selectedRowIds={selectedRowIds}
            onSortChange={onSortChange}
          />
        ))}
      </div>
      <Box {...getTableBodyProps()} height={bodyHeight}>
        <AutoSizer>
          {({ width, height }: Size) => (
            <InfiniteLoader
              itemCount={infiniteScrollConfig?.total || rows.length}
              isItemLoaded={isItemLoaded}
              loadMoreItems={handleLoadMore}
            >
              {({ ref, onItemsRendered }) => (
                <FixedSizeList
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                  outerRef={setScrollerRef}
                  itemSize={dataGridRowHeight}
                  height={height || 0}
                  itemCount={itemCount}
                  width={width || 0}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
    </div>
  );
};

export default memo(DataGrid) as typeof DataGrid;
