import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Page } from "@shared/components";
import { AppRoute } from "@app/models/AppRoute";
import { ReactComponent as ArrowBack } from "@assets/svg/arrow_back.svg";

const useStyles = makeStyles((theme) => ({
  tile: {
    display: "block",
    background: theme.palette.background.paper,
    paddingBlock: theme.spacing(20),
    textAlign: "center",
    textTransform: "uppercase",
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontSize: 30,
    fontWeight: theme.typography.fontWeightMedium,
    transition: "all ease-in .15s",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
      "&> svg": {
        color: theme.palette.common.white,
      },
    },
  },
  arrow: {
    transform: "rotate(180deg)",
    marginLeft: theme.spacing(8),
    color: theme.palette.info.main,
  },
}));

const AdministrationHome = () => {
  const classes = useStyles();

  return (
    <Page title="Administration">
      <Grid container direction="row" spacing={5}>
        <Grid item xs={6}>
          <Link className={classes.tile} to={AppRoute.AdministrationReports} data-testid="reports-link">
            Reports
            <ArrowBack className={classes.arrow} data-testid="reports-link-arrow" />
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link className={classes.tile} to={AppRoute.AdministrationImpersonate} data-testid="impersonation-link">
            Impersonate user
            <ArrowBack className={classes.arrow} data-testid="impersonation-link-arrow" />
          </Link>
        </Grid>
      </Grid>
    </Page>
  );
};

export default memo(AdministrationHome);
