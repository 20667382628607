import React, { FunctionComponent, memo, useCallback, useMemo, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import { Input, ListOptionBase } from "@shared/components";
import { MobileDrawerSubmenuSection } from "@app/models/Header";
import { ReactComponent as BackArrow } from "@assets/svg/arrow_dropdown.svg";

interface Props {
  sections: MobileDrawerSubmenuSection[];
  onOptionClick(option: ListOptionBase): void;
  onBack(): void;
}

const useStyles = makeStyles((theme) => ({
  back: {
    marginBottom: theme.spacing(8),
  },
  backArrow: {
    transform: "rotate(90deg)",
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 25,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: "1.75px",
  },
  option: {
    fontSize: 18,
  },
}));

const SubmenuSection: FunctionComponent<{
  section: MobileDrawerSubmenuSection;
  onOptionClick(option: ListOptionBase): void;
}> = ({ section, onOptionClick }) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const [displayOptions, setDisplayOptions] = useState(section.options);

  const handleFilterOptions = useMemo(
    () =>
      debounce(
        (search: string) =>
          setDisplayOptions(
            section.options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
          ),
        300
      ),
    [section.options]
  );

  const handleInputChange = useCallback(
    (search: string) => {
      setSearchText(search);

      handleFilterOptions(search);
    },
    [handleFilterOptions]
  );

  return (
    <>
      <Typography className={classes.title}>{section.label}</Typography>
      {section.withSearch && (
        <Input
          search
          autoFocus
          dense={false}
          value={searchText}
          onChange={handleInputChange}
          placeholder="Search by Keyword"
        />
      )}
      <Stack
        spacing={5}
        divider={<Divider sx={{ borderColor: "common.white" }} />}
        sx={{ maxHeight: "calc(100vh - 440px)", overflowY: "auto" }}
      >
        {displayOptions.length ? (
          displayOptions.map((option) =>
            option.isActive ? (
              <Box key={option.id} className={classes.option} onClick={() => onOptionClick(option)}>
                {option.label}
              </Box>
            ) : null
          )
        ) : (
          <Box className={classes.option}>No options</Box>
        )}
      </Stack>
    </>
  );
};

const AppMobileDrawerSubmenu: FunctionComponent<Props> = ({ sections, onBack, onOptionClick }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box onClick={onBack} className={classes.back}>
        <BackArrow className={classes.backArrow} />
        Back
      </Box>
      <Stack spacing={5} sx={{ maxHeight: "calc(100vh - 300px)" }}>
        {sections.map((section) => (
          <SubmenuSection key={section.id} section={section} onOptionClick={onOptionClick} />
        ))}
      </Stack>
    </Box>
  );
};

export default memo(AppMobileDrawerSubmenu);
