import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";

export const insightsHubSelector = (state: RootState) => state.insightsHub;

export const insightsHubArticleByIdSelector = createSelector(
  [insightsHubSelector, (state, articleId): string => articleId],
  (insightsHubSlice, articleId) => ({
    loading: insightsHubSlice.loading,
    article: (insightsHubSlice.data ?? []).find((article) => String(article.id) === articleId)!,
    error: insightsHubSlice.error,
  })
);

export const insightsHubArticlesSelector = createSelector([insightsHubSelector], (insightsHubSlice) => {
  return (insightsHubSlice.data ?? []).map((article) => ({
    id: article.id,
    title: article.title,
    url: article.homepageImage,
    description: article.shortDescription,
    publishDate: article.publishDate,
    publised_date: article.publised_date,
  }));
});

export const insightsHubArticlesAuthorsSelector = createSelector([insightsHubSelector], (insightsHubSlice) =>
  Array.from(new Set((insightsHubSlice.data ?? []).map((article) => article.authorName)))
);
