import { createSlice } from "@reduxjs/toolkit";
import { HomePageVideoDetails, HomeState } from "@home/models";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { fetchHomePageVideoDetails } from "./thunks/fetchHomePageVideoDetails";

const initialState: HomeState = {
  homePageVideo: ReduxUtils.getAsyncSlice<HomePageVideoDetails>(),
  isShownActionRequiredDocumentsNotice: true,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    resetState: () => initialState,
    setShownActionRequiredDocumentsNoticeFlag: (state, action) => {
      state.isShownActionRequiredDocumentsNotice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHomePageVideoDetails.pending, (state) => {
      ReduxUtils.defaultPendingActionHandler(state.homePageVideo);
    });

    builder.addCase(fetchHomePageVideoDetails.fulfilled, (state, action) => {
      ReduxUtils.defaultFulfilledActionHandler(state.homePageVideo, action.payload);
    });

    builder.addCase(fetchHomePageVideoDetails.rejected, (state, action) => {
      ReduxUtils.defaultRejectedActionHandler(state.homePageVideo, action.error.message);
    });
  },
});

export const { resetState, setShownActionRequiredDocumentsNoticeFlag } = homeSlice.actions;

export default homeSlice.reducer;
