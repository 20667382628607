import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { callActionRequiredItemsApi } from "@documents/store/thunks/callActionRequiredItemsApi";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ActionRequiredItemsApiResponse } from "@documents/models";

export const fetchNumberOfActionRequiredItems = createAsyncThunk<ActionRequiredItemsApiResponse, void, ThunkApiConfig>(
  "documents/fetchNumberOfActionRequiredItems",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callActionRequiredItemsApi());
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "failed to get number of action items",
        })
      );

      return rejectWithValue("Failed to fetch number of action items");
    }
  }
);
