import React, { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Drawer, DrawerProps, Grid, Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cn from "classnames";
import { IMPERSONATION_BAR_HEIGHT_MOBILE, MOBILE_HEADER_HEIGHT } from "@app/constants/header";
import IAGroupDropdown from "@app/components/IAGroupDropdown";
import AppToolbarIcons from "@app/components/AppToolbarIcons";
import AppMobileDrawerSubmenu from "@app/components/AppMobileDrawerSubmenu";
import { HeaderTabLabel, ProfileMenuOptionLabel } from "@app/models/Header";
import { AppRoute } from "@app/models/AppRoute";
import { useGetUserPermissions } from "@user/hooks";
import { getReportingNavigationLinks } from "@reporting/utils";
import { useAppProfile, useAppSelector } from "@app/hooks";
import { appNavigationFundsSelector } from "@app/store/selectors";
import { fundsSelector } from "@store/Entities/selectors";
import { ReactComponent as OptionArrow } from "@assets/svg/arrow_dropdown.svg";
import { ReactComponent as ProfileIcon } from "@assets/svg/profile_icon.svg";
import { Button, ListOptionBase } from "@shared/components";
import { getProfileMenuOptions } from "@app/utils/getProfileMenuOptions";

const useDrawerStyles = makeStyles<Theme, { isImpersonating: boolean }>((theme) => ({
  root: {
    top: ({ isImpersonating }) =>
      isImpersonating ? IMPERSONATION_BAR_HEIGHT_MOBILE + MOBILE_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT,
  },
  paperAnchorRight: {
    width: "100%",

    top: ({ isImpersonating }) =>
      isImpersonating ? IMPERSONATION_BAR_HEIGHT_MOBILE + MOBILE_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: "none",
    padding: theme.spacing(9),
    height: ({ isImpersonating }) =>
      isImpersonating
        ? `calc(100% - ${IMPERSONATION_BAR_HEIGHT_MOBILE + MOBILE_HEADER_HEIGHT}px)`
        : `calc(100% - ${MOBILE_HEADER_HEIGHT}px)`,
  },
  modal: {
    "& > .MuiModal-backdrop": {
      top: ({ isImpersonating }) =>
        isImpersonating ? IMPERSONATION_BAR_HEIGHT_MOBILE + MOBILE_HEADER_HEIGHT : MOBILE_HEADER_HEIGHT,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  option: {
    textTransform: "uppercase",
    fontSize: 25,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 1.75,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  submenuArrow: {
    transform: "rotate(-90deg)",
    width: 20,
    height: 20,
  },
  iconButton: {
    color: theme.palette.grey[500],
    cursor: "pointer",
    transition: "color .15s",
    "&:focus": {
      color: theme.palette.grey[500],
    },
  },
  iconButtonActive: {
    color: theme.palette.info.main,
  },
}));

const navigationItems: ListOptionBase[] = [
  {
    id: AppRoute.InsightsHub,
    label: HeaderTabLabel.InsightsHub,
  },
  {
    id: AppRoute.DataRooms,
    label: HeaderTabLabel.DataRooms,
  },
  {
    id: AppRoute.FundProfile,
    label: HeaderTabLabel.Funds,
  },
  {
    id: AppRoute.ReportingBase,
    label: HeaderTabLabel.Reporting,
  },
  {
    id: AppRoute.Documents,
    label: HeaderTabLabel.Documents,
  },
  {
    id: AppRoute.MyCalendar,
    label: HeaderTabLabel.MyCalendar,
  },
];

const AppMobileDrawer: FunctionComponent<{ onClose(): void } & DrawerProps> = ({ onClose, ...props }) => {
  const [view, setView] = useState<"main" | "reporting" | "funds" | "profile">("main");

  const history = useHistory();

  const funds = useAppSelector(appNavigationFundsSelector);
  const { loading: fundsLoading } = useAppSelector(fundsSelector);

  const fundSections = useMemo(
    () => [
      {
        id: "funds",
        label: "FUNDS",
        withSearch: true,
        options: funds.map((fund) => ({ ...fund, isActive: true })),
      },
    ],
    [funds]
  );

  const permissions = useGetUserPermissions();
  const reportingDropdownSections = useMemo(() => getReportingNavigationLinks(permissions), [permissions]);

  useEffect(() => {
    setView("main");
  }, [props.open]);

  const handleNavigationChange = useCallback(
    (item: ListOptionBase) => {
      if (item.id === AppRoute.ReportingBase) {
        setView("reporting");

        return;
      }

      if (item.id === AppRoute.FundProfile) {
        if (fundsLoading) return;

        setView("funds");

        return;
      }

      history.push(item.id);
      onClose();
    },
    [history, onClose, fundsLoading]
  );

  const { userName, canImpersonate, isImpersonating, handleProfileMenuClick, handleSignOut } = useAppProfile();

  const profileMenuSections = useMemo(
    () => [
      {
        id: "profile",
        label: userName.toUpperCase(),
        options: getProfileMenuOptions(canImpersonate, isImpersonating).map((option) => ({
          ...option,
          isActive: true,
        })),
      },
    ],
    [canImpersonate, isImpersonating, userName]
  );

  const handleProfileMenuOptionClick = useCallback(
    (option) => {
      handleProfileMenuClick(option);
      onClose();
    },
    [handleProfileMenuClick, onClose]
  );

  const handleProfileIconClick = useCallback(() => setView("profile"), []);

  const handleBack = useCallback(() => setView("main"), []);
  const classes = useStyles();
  const drawerClasses = useDrawerStyles({ isImpersonating });

  return (
    <Drawer classes={drawerClasses} anchor="right" {...props}>
      <Grid container flexDirection="column" justifyContent="space-between" height="100%">
        <Grid item width="100%">
          {view === "main" && (
            <>
              <IAGroupDropdown fullWidth />
              <Stack spacing={5} marginTop={10}>
                {navigationItems.map((item) => (
                  <Box key={item.id} className={classes.option} onClick={() => handleNavigationChange(item)}>
                    {item.label}
                    {(item.id === AppRoute.FundProfile || item.id === AppRoute.ReportingBase) && (
                      <OptionArrow className={classes.submenuArrow} />
                    )}
                  </Box>
                ))}
              </Stack>
            </>
          )}
          {view === "reporting" && (
            <AppMobileDrawerSubmenu
              sections={reportingDropdownSections}
              onOptionClick={handleNavigationChange}
              onBack={handleBack}
            />
          )}
          {view === "funds" && (
            <AppMobileDrawerSubmenu
              sections={fundSections}
              onOptionClick={handleNavigationChange}
              onBack={handleBack}
            />
          )}
          {view === "profile" && (
            <div>
              <AppMobileDrawerSubmenu
                sections={profileMenuSections}
                onOptionClick={handleProfileMenuOptionClick}
                onBack={handleBack}
              />
              <Box marginTop={12} display="flex" justifyContent="center">
                <Button size="large" variant="outlined" shape="rounded" onClick={handleSignOut}>
                  {ProfileMenuOptionLabel.SignOut}
                </Button>
              </Box>
            </div>
          )}
        </Grid>
        <Grid item container spacing={6} justifyContent="center">
          <AppToolbarIcons mobile />
          <Grid item>
            <ProfileIcon
              className={cn(classes.iconButton, view === "profile" && classes.iconButtonActive)}
              onClick={handleProfileIconClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default memo(AppMobileDrawer);
