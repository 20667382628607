import { createAsyncThunk } from "@reduxjs/toolkit";
import { EntityDocumentType } from "@dataRooms/models";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchEntityDocumentTypes = createAsyncThunk<EntityDocumentType[], number, ThunkApiConfig>(
  "dataRooms/fetchEntityDocumentTypes",
  async (entityId, { rejectWithValue, extra, dispatch }) => {
    try {
      const response = await extra<EntityDocumentType[]>(
        `documents/v1/Documents/entitledEntity/${entityId}/documentTypes`
      );

      return response.data;
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load data",
        })
      );

      return rejectWithValue("Failed to load entity document types");
    }
  }
);
