import { dataGridRowHeight } from "@shared/components/DataGrid/constants";

interface UseVirtualizedDataGridHeightArgs {
  totalRows: number;
  headerHeight: number;
  horizontalScrollbarHeight: number;
  maxHeight?: number;
}

interface UseVirtualizedDataGridHeightResult {
  bodyHeight: string | number;
  height: number;
}

const useVirtualizedDataGridHeight = ({
  totalRows,
  headerHeight,
  maxHeight,
  horizontalScrollbarHeight,
}: UseVirtualizedDataGridHeightArgs): UseVirtualizedDataGridHeightResult => {
  const rowsHeight = totalRows * dataGridRowHeight + horizontalScrollbarHeight;
  const tableHeight = rowsHeight + headerHeight;

  const rowsFitMaxHeight = Number(maxHeight) > tableHeight;

  const bodyHeight = rowsFitMaxHeight ? rowsHeight : `calc(100% - ${headerHeight}px)`;

  return {
    bodyHeight,
    height: rowsFitMaxHeight ? tableHeight : Number(maxHeight),
  };
};

export default useVirtualizedDataGridHeight;
