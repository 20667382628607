import { createAsyncThunk } from "@reduxjs/toolkit";
import { omit } from "lodash";
import { FetchDocumentResponse } from "@documents/models/api.models";
import { ThunkApiConfig } from "@shared/models/redux";
import { buildDocumentsRequestBody, convertEntityGlobalIdsToIds } from "@documents/utils";
import { openNotificationThunk } from "@store/Notification/thunks";
import {
  fundDocumentsSelector,
  preparedInvestorAccountsFunDocumentSelector,
  selectedFundDocumentsFilters,
} from "@funds/store/selectors";
import { callDocumentsAPI } from "@documents/store/thunks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { selectedIAGroupIdSelector } from "@app/store/selectors";
import { LOAD_DOCUMENTS_ERROR_MESSAGE } from "@documents/constants";
import { entitledAccountsSelector, entitledFundsSelector } from "@store/Entities/selectors";

interface FetchMoreDocumentsArgs {
  fundId: string;
  start: number;
}

export const fetchMoreFundDocuments = createAsyncThunk<FetchDocumentResponse, FetchMoreDocumentsArgs, ThunkApiConfig>(
  "funds/fetchMoreFundDocuments",
  async ({ fundId }, { getState, dispatch, rejectWithValue }) => {
    const rootState = getState();
    const entitledAccounts = entitledAccountsSelector(rootState);
    const entitledFunds = entitledFundsSelector(rootState);
    const { canImpersonate } = impersonationStateSelector(rootState);
    const filters = selectedFundDocumentsFilters(rootState);
    const investorAccountsOptions = preparedInvestorAccountsFunDocumentSelector(rootState);
    const { pagination } = fundDocumentsSelector(rootState);
    const selectedAccountGroup = selectedIAGroupIdSelector(rootState);
    const investorAccountIds = investorAccountsOptions.map((account) => account.id.toString());

    const investorAccounts =
      selectedAccountGroup && !filters.investorAccounts ? investorAccountIds : filters.investorAccounts;

    const body = buildDocumentsRequestBody({
      filters: {
        ...omit(filters, ["investorAccounts"]),
        fundIds: convertEntityGlobalIdsToIds(entitledFunds.data!, [fundId]),
        investorAccountIds: investorAccounts && convertEntityGlobalIdsToIds(entitledAccounts.data!, investorAccounts),
      },
      pagination,
      isAdmin: canImpersonate,
      sort: {
        key: filters.sortKey,
        direction: filters.sortDirection,
      },
    });

    try {
      return await dispatch(callDocumentsAPI(body));
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: LOAD_DOCUMENTS_ERROR_MESSAGE,
        })
      );

      return rejectWithValue("Failed to fetch fund documents");
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        funds: {
          fundDocuments: { pagination },
        },
      } = getState();

      return pagination.total === pagination.count;
    },
  }
);
