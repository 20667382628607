import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { fundDocumentsDataGridRowsSelector } from "@funds/store/selectors";
import { callDocumentReadStatusAPI } from "@documents/store/thunks/callDocumentReadStatusAPI";
import { openNotificationThunk } from "@store/Notification/thunks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

export const updateFundDocumentReadFlag = createAsyncThunk<void, string[], ThunkApiConfig>(
  "funds/updateFundDocumentReadFlag",
  async (documentIds, { dispatch, getState, rejectWithValue }) => {
    try {
      const dataRows = fundDocumentsDataGridRowsSelector(getState());
      const { isImpersonating } = impersonationStateSelector(getState());

      if (!dataRows || isImpersonating) {
        return;
      }

      await dispatch(callDocumentReadStatusAPI(documentIds));
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "There was a problem updating the document status. Please try again.",
        })
      );

      return rejectWithValue("Failed to update read status");
    }
  }
);
