import { intersection } from "lodash";
import { OutstandingTerm, OutstandingTermStatus, Term, TermAssociation, TermsState } from "@terms/models";
import { termsOrderMap } from "@terms/constants";
import { Nullable } from "@shared/models/general";

const prepareOutstandingTerms = (
  termsData: TermsState["data"],
  associationTypesToPick: Partial<Record<TermAssociation, Nullable<Term["associations"]>>>
): Array<OutstandingTerm> => {
  return Object.entries(associationTypesToPick)
    .map(([associationType, ids]) =>
      termsData.filter((term) => {
        const shouldIncludeByType = term.associationType === associationType;

        const shouldIncludeById = ids ? intersection(term.associations, ids).length : ids === null;

        return shouldIncludeByType && shouldIncludeById && !term.accepted;
      })
    )
    .flat()
    .sort((termLeft, termRight) => termsOrderMap[termLeft.associationType] - termsOrderMap[termRight.associationType])
    .map((term, idx) => ({
      id: term.id,
      status: OutstandingTermStatus.Pending,
      active: idx === 0,
    }));
};

export default prepareOutstandingTerms;
