import { createAsyncThunk } from "@reduxjs/toolkit";
import { InvestorRequestBody } from "@investorRequest/models";
import { validateFields } from "@investorRequest/store/ducks";
import { investorRequestSelector } from "@investorRequest/store/selectors";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ThunkApiConfig } from "@shared/models/redux";

export const sendInvestorRequestThunk = createAsyncThunk<boolean, InvestorRequestBody, ThunkApiConfig>(
  "investorRequest/sendInvestorRequest",
  async (body, { rejectWithValue, dispatch, extra }) => {
    try {
      const response = await extra<boolean, InvestorRequestBody>("/entitlements/v1/user/InvestorRequest", {
        method: "post",
        body,
      });

      if (response.data) {
        dispatch(
          openNotificationThunk({
            variant: "info",
            text: "Your request has been sent.",
          })
        );
      }

      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.[0] || "Some error occurred. Please try again.";

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: errorMessage,
        })
      );

      return rejectWithValue(errorMessage);
    }
  }
);

export const submitFormThunk = createAsyncThunk<void, void, ThunkApiConfig>(
  "investorRequest/submitFormThunk",
  (_, { dispatch, getState }) => {
    dispatch(validateFields());

    const { requestType, commonFields, customFields, validationWarningShown } = investorRequestSelector(getState());

    if (validationWarningShown) return;

    const body: InvestorRequestBody = {
      customFields,
      requestType: requestType!,
      request: commonFields.message,
      entitledAccounts: commonFields.selectedAccounts,
      entitledEntities: commonFields.selectedFunds,
    };

    if (commonFields.attachment) {
      body.attachmentName = commonFields.attachment.name;
      body.attachment = commonFields.attachment.base64;
    }

    dispatch(sendInvestorRequestThunk(body));
  }
);
