import { createAsyncThunk } from "@reduxjs/toolkit";
import { PreferenceDefaultInvestorGroupResponse, PreferenceShortenObject, UserPreferenceName } from "@user/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { callGetUserPreference } from "@user/store/thunks/callGetUserPreferenceAPI";
import { callCreateUserPreferenceAPI } from "@user/store/thunks/callCreateUserPreferenceAPI";
import { callUpdateUserPreferenceAPI } from "@user/store/thunks/callUpdateUserPreferenceAPI";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

export const updateUserPreference = createAsyncThunk<
  PreferenceShortenObject<UserPreferenceName>,
  PreferenceShortenObject<UserPreferenceName> | PreferenceDefaultInvestorGroupResponse,
  ThunkApiConfig
>(
  "user/updateUserPreference",
  async (preference, { dispatch, getState, rejectWithValue }) => {
    const {
      user: { data },
    } = getState();

    try {
      const { id: preferenceId } = await dispatch(
        callGetUserPreference({
          userId: data!.id,
          preferenceName: preference.setting,
        })
      );

      let updatedPreference: PreferenceShortenObject<UserPreferenceName>;

      // 0 means that there's no such a preference and we need to create it
      if (preferenceId === 0) {
        updatedPreference = await dispatch(
          callCreateUserPreferenceAPI({
            userId: data!.id,
            preference,
          })
        );
      } else {
        updatedPreference = await dispatch(
          callUpdateUserPreferenceAPI({
            ...preference,
            userId: Number(data!.id),
            id: preferenceId,
          })
        );
      }

      return updatedPreference;
    } catch (error) {
      console.error(error);

      return rejectWithValue("Failed to update user preference");
    }
  },
  {
    condition: (_, { getState }) => {
      const { isImpersonating } = impersonationStateSelector(getState());

      return !isImpersonating;
    },
  }
);
