import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { FileResponse } from "@shared/models/request";
import { convertEntityGlobalIdsToIds, timeRangeConverter } from "@documents/utils";

export const generateWhoAccessedDocumentsReportThunk = createAsyncThunk<void, void, ThunkApiConfig>(
  "administrationReports/generateWhoAccessedDocumentsReportThunk",
  async (_, { getState, dispatch, rejectWithValue, extra }) => {
    const {
      administrationReports: { filters },
      entities: { entitledFunds },
    } = getState();

    const { start, end } = timeRangeConverter(filters.timeRange);

    const entitledEntityIds =
      filters.selectedFunds && convertEntityGlobalIdsToIds(entitledFunds.data!, filters.selectedFunds);

    const body = {
      entitledEntityIds,
      startDate: start,
      endDate: end,
      reportFormat: "excel documentreport",
      user: filters.userEmail.length ? filters.userEmail : undefined,
      batchId: filters.batchNumber.length ? filters.batchNumber : undefined,
      includeDeletedDocuments: filters.includeDeletedDocuments,
      commonNameGlobalIds: filters.commonNames,
    };

    try {
      const response = await extra<FileResponse>("/documents/v1/useractivitytracking", {
        method: "post",
        responseType: "blob",
        body,
      });

      if (response.status == 204) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: "Report has no data for the parameters given",
          })
        );

        return;
      }

      const today = new Date();
      const fileName = `WhoAccessedDocumentsReport_${
        today.getMonth() + 1
      }-${today.getDate()}-${today.getFullYear()}.xlsx`;

      if (response.data.blob?.size) {
        saveAs(response.data.blob, fileName);

        dispatch(
          openNotificationThunk({
            variant: "info",
            text: "Report generation successful.",
          })
        );
      }
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Report download failed.",
        })
      );

      return rejectWithValue("The document download failed");
    }
  }
);
