import { useCallback } from "react";
import { Action } from "redux";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "@app/hooks";
import { Nullable } from "@shared/models/general";
import { MultiselectOption } from "../components/List";

interface Props<T> {
  entityName: string;
  nullIfEmpty?: boolean;
  selectedValues: Nullable<T[]>;
  action(payload: Record<string, Nullable<T[]>>): Action | AsyncThunkAction<any, any, any>;
  extra?(): void;
}

const useMultiselectHandlers = <T extends string | number = string>({
  extra,
  action,
  entityName,
  selectedValues,
  nullIfEmpty = true,
}: Props<T>): [(option: MultiselectOption) => void, () => void] => {
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (option: MultiselectOption) => {
      let newValues: Nullable<T[]>;

      if (option.selected) {
        newValues = [...(selectedValues ?? []), option.id as T];
      } else {
        newValues = selectedValues!.filter((a) => a !== option.id);

        if (nullIfEmpty && !newValues.length) {
          newValues = null;
        }
      }

      dispatch(action({ [entityName]: newValues }));

      if (extra) {
        extra();
      }
    },
    [action, dispatch, entityName, nullIfEmpty, selectedValues, extra]
  );

  const handleClearAll = useCallback(() => {
    dispatch(action({ [entityName]: nullIfEmpty ? null : [] }));

    if (extra) {
      extra();
    }
  }, [dispatch, action, entityName, nullIfEmpty, extra]);

  return [handleChange, handleClearAll];
};

export default useMultiselectHandlers;
