import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { normalizeReportingPeriodsResponse } from "@reporting/utils";
import { changeCasFilterValues } from "@capitalAccountStatement/store/thunks/index";
import { CapitalAccountStatementFilterOptions } from "@capitalAccountStatement/models";
import {
  capitalAccountStatementFilterOptionsSelector,
  capitalAccountStatementFiltersSelector,
} from "@capitalAccountStatement/store/selectors";
import { callFundInvestorQuarterOptionsAPI } from "@capitalAccountStatement/store/thunks/callFundInvestorQuarterOptionsAPI";
import { ReportingPeriodsResponse } from "@reporting/models";
import RequestUtils from "@shared/utils/RequestUtils";

export const fetchFundInvestorQuarterOptions = createAsyncThunk<
  CapitalAccountStatementFilterOptions,
  void,
  ThunkApiConfig
>(
  "capitalAccountStatement/fetchFundInvestorQuarterOptions",
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const callFundInvestorQuarterOptionsAPICancelable = RequestUtils.getAbortThunk<void, ReportingPeriodsResponse[]>(
        callFundInvestorQuarterOptionsAPI,
        dispatch
      );

      const response = await callFundInvestorQuarterOptionsAPICancelable();

      if (!response.payload) {
        return rejectWithValue("Failed to fetch filters data");
      }

      const periods = normalizeReportingPeriodsResponse(response.payload as ReportingPeriodsResponse[]);

      if (periods.length) {
        const filterSelection = capitalAccountStatementFiltersSelector(getState());

        const latestPeriod = periods[periods.length - 1];

        dispatch(
          changeCasFilterValues({
            ...filterSelection,
            funds: latestPeriod.associatedFunds[0],
            asOfDate: {
              year: latestPeriod.year,
              quarter: latestPeriod.quarter,
            },
          })
        );
      }

      return { periods };
    } catch (error) {
      console.dir(error);

      return rejectWithValue("Failed to fetch filters data");
    }
  },
  {
    condition: (_, { getState }) => {
      const options = capitalAccountStatementFilterOptionsSelector(getState());

      return !options.loading && !options.data;
    },
  }
);
