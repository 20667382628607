import { createAsyncThunk } from "@reduxjs/toolkit";
import { FundHead, FundHeads } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchFundHeads = createAsyncThunk<FundHeads, { id: string }, ThunkApiConfig>(
  "funds/fetchFundHeads",
  async ({ id }, { rejectWithValue, extra, signal }) => {
    try {
      const response = await extra<FundHead[]>(`reporting/v1/Funds/${id}/persons`, { signal });

      return {
        [id]: response.data,
      };
    } catch (e) {
      return rejectWithValue("Failed to fetch Fund Heads");
    }
  }
);
