import { ThunkAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { AxiosResponse } from "axios";
import { RootState } from "@store/index";
import { ThunkExtra } from "@shared/models/request";

type CallDocumentReadStatusAPI = (
  documentIds: string[]
) => ThunkAction<Promise<AxiosResponse<string | null>[]>, RootState, ThunkExtra, Action>;

export const callDocumentReadStatusAPI: CallDocumentReadStatusAPI =
  (documentIds) => async (_dispatch, _state, extra) => {
    const requestPromises = documentIds.map((id) =>
      extra<string>(`documents/v1/users/${id}/read/true`, {
        method: "post",
      })
    );

    return Promise.all(requestPromises);
  };
