import React, { FunctionComponent, useEffect } from "react";
import AppRouter from "@app/containers/AppRouter";
import DocumentHead from "@app/components/DocumentHead";
import InvestorRequestDialog from "@investorRequest/containers/InvestorRequestDialog";
import TermsDialog from "@terms/containers/TermsDialog";
import VideoDialog from "@videos/containers/VideoDialog";
import NotificationSnackbar from "@app/containers/NotificationSnackbar";
import ImpersonateSnackbar from "@impersonateUser/components/ImpersonateSnackbar";
import AppLoadingIndicator from "@app/components/AppLoadingIndicator";
import { userSliceSelector } from "@user/store/selectors";
import { termsSelector } from "@terms/store/selectors";
import { initializeApp } from "@app/store/thunks";
import {
  useAppDispatch,
  useAppSelector,
  useGTagRegister,
  useLandingPageRedirect,
  useScrollToTop,
  useStartAppListeners,
} from "@app/hooks";
import Layout from "@app/containers/Layout";

const App: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const appLoading = useAppSelector((state) => {
    const { loading: userLoading } = userSliceSelector(state);
    const { loading: termsLoading } = termsSelector(state);

    return userLoading || termsLoading;
  });

  useEffect(() => {
    dispatch(initializeApp());
  }, [dispatch]);

  useStartAppListeners();
  useGTagRegister();
  useLandingPageRedirect();
  useScrollToTop();

  if (appLoading) {
    return <AppLoadingIndicator />;
  }

  return (
    <>
      <DocumentHead />

      <NotificationSnackbar />
      <ImpersonateSnackbar />

      <Layout>
        <AppRouter />
      </Layout>

      <InvestorRequestDialog />
      <TermsDialog />
      <VideoDialog />
    </>
  );
};

export default App;
