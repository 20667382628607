import { AppThunkAPI } from "@shared/models/redux";
import { UserPreferencesListResponse } from "@user/models";

type CallGetUserPreferencesListAPI = (userId: number) => AppThunkAPI<Promise<UserPreferencesListResponse>>;

export const callGetUserPreferencesListAPI: CallGetUserPreferencesListAPI =
  (userId) => async (_dispatch, _state, extra) => {
    const response = await extra<UserPreferencesListResponse>(`/entitlements/v1/user/UserPreferences?userId=${userId}`);

    return response.data;
  };
