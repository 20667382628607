import { AppThunkAPI } from "@shared/models/redux";
import {
  PreferenceDefaultInvestorGroupResponse,
  PreferenceFullObject,
  PreferenceShortenObject,
  UserPreferenceName,
} from "@user/models";

type CallUpdateUserPreferenceAPI<S extends UserPreferenceName> = (
  preference: PreferenceFullObject<S> | (PreferenceDefaultInvestorGroupResponse & { id: number; userId: number })
) => AppThunkAPI<Promise<PreferenceShortenObject<S>>>;

export const callUpdateUserPreferenceAPI: CallUpdateUserPreferenceAPI<UserPreferenceName> =
  (preference) => async (_dispatch, _state, extra) => {
    const response = await extra<PreferenceShortenObject<UserPreferenceName>>(
      `/entitlements/v1/user/UserPreferences/${preference.id}?userId=${preference.userId}`,
      {
        method: "PUT",
        body: {
          Setting: preference.setting,
          Value: preference.value,
          id: preference.id,
        },
      }
    );

    return response.data;
  };
