import { createAsyncThunk } from "@reduxjs/toolkit";
import { SortDirection } from "@shared/models/general";
import { callDocumentsAPI } from "@documents/store/thunks/callDocumentsAPI";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { setHighlightedVideo } from "@dataRooms/store/ducks";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { normalizeDocumentResponse } from "@documents/utils";

export const fetchEntityDocuments = createAsyncThunk<
  Record<string, DocumentRow[]>,
  {
    documentTypeIds: number[];
    entitledEntityIds: number[];
    sortDirection: SortDirection;
    sortKey: string;
  },
  ThunkApiConfig
>(
  "dataRooms/fetchEntityDocuments",
  async ({ documentTypeIds, entitledEntityIds, sortDirection, sortKey }, { rejectWithValue, dispatch }) => {
    try {
      const documents = await dispatch(
        callDocumentsAPI({
          documentLocation: "Opportunity",
          documentStatuses: ["P", "A", "W", "V"],
          documentTypes: documentTypeIds,
          entitledEntityIds: entitledEntityIds,
          includeVideos: true,
          sortDirection,
          sortKey,
          userId: null,
        })
      );
      const highlightedVideo = documents.results.find((document) => document.videoIsHighlighted);

      if (highlightedVideo) {
        dispatch(
          setHighlightedVideo({
            videoToken: highlightedVideo.videoToken,
            videoDescription: highlightedVideo.videoDescription,
            videoId: highlightedVideo.videoId,
            videoTokenExpiration: highlightedVideo.videoTokenExpiration,
            title: highlightedVideo.title,
          })
        );
      }

      return documents.results.reduce(
        (result: Record<number, DocumentRow[]>, document) => ({
          ...result,
          [document.documentTypeId]: result[document.documentTypeId]
            ? [...result[document.documentTypeId], normalizeDocumentResponse(document)]
            : [normalizeDocumentResponse(document)],
        }),
        {}
      );
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load data",
        })
      );

      return rejectWithValue("Failed to load entity documents");
    }
  }
);
