import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFSISArgs, FundFPSISAppreciation } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchFSISAppreciation = createAsyncThunk<FundFPSISAppreciation, fetchFSISArgs, ThunkApiConfig>(
  "funds/fetchFSISPeriod",
  async ({ fundId, date }, { rejectWithValue, extra, signal }) => {
    // strip time part from date, leave date itself
    const formattedDate = date.split("T")[0];

    try {
      const response = await extra<FundFPSISAppreciation>(
        `/reporting/v1/FPSISPeriod?fundId=${fundId}&reportingDate=${formattedDate}`,
        { signal }
      );

      return response.data;
    } catch (e) {
      return rejectWithValue("Failed to fetch FPSIS period data");
    }
  }
);
