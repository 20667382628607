import React, { lazy, Suspense, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AppRoute } from "@app/models/AppRoute";
import { useGetUserPermissions } from "@user/hooks";
import { Permission } from "@user/models";
import ReportingDrawer from "@reporting/components/ReportingDrawer";
import AppLoadingIndicator from "@app/components/AppLoadingIndicator";
import { getReportingNavigationLinks } from "@reporting/utils/getReportingNavigationLinks";

const AccountSummaryAlpInvestPage = lazy(
  () => import("@accountSummaryAlpInvest/containers/AccountSummaryAlpInvestPage")
);
const AccountSummaryCarlylePage = lazy(() => import("@accountSummaryCarlyle/containers/AccountSummaryCarlylePage"));
const CapitalAccountStatementPage = lazy(
  () => import("@capitalAccountStatement/containers/CapitalAccountStatementPage")
);
const ScheduleOfInvestmentsPage = lazy(() => import("@scheduleOfInvestments/containers/ScheduleOfInvestmentsPage"));
const CashFlowActivityPage = lazy(() => import("@cashFlowActivity/containers/CashFlowActivityPage"));
const PortfolioPerformancePage = lazy(() => import("@portfolioPerformance/containers/PortfolioPerformancePage"));
const DiversificationAnalysisPage = lazy(
  () => import("@diversificationAnalysis/containers/DiversificationAnalysisPage")
);

const ReportingRouter = () => {
  const location = useLocation();
  const permissions = useGetUserPermissions();

  const routeToRedirect = useMemo(() => {
    switch (true) {
      case permissions[Permission.ViewReportsCarlyle]:
        return AppRoute.CapitalAccountStatement;
      case permissions[Permission.ViewPortfolioPerformanceReport]:
        return AppRoute.PortfolioPerformance;
      case permissions[Permission.ViewDiversificationAnalysisReport]:
        return AppRoute.DiversificationAnalysis;
      case permissions[Permission.ViewAccountSummaryCarlyle]:
        return AppRoute.AccountSummaryCarlyle;
      case permissions[Permission.ViewAccountSummaryAlpInvest]:
        return AppRoute.AccountSummaryAlpInvest;
      default:
        return "";
    }
  }, [permissions]);

  const sideBarRoutes = useMemo(() => getReportingNavigationLinks(permissions), [permissions]);

  return (
    <ReportingDrawer activeRoute={location.pathname as AppRoute} routes={sideBarRoutes}>
      <Suspense fallback={<AppLoadingIndicator />}>
        <Switch>
          <Route exact path={AppRoute.ReportingBase}>
            <Redirect to={routeToRedirect} />
          </Route>
          {permissions[Permission.ViewReportsCarlyle] && (
            <Route path={AppRoute.AccountSummaryCarlyle} component={AccountSummaryCarlylePage} />
          )}
          {permissions[Permission.ViewReportsCarlyle] && [
            <Route
              key={AppRoute.CapitalAccountStatement}
              path={AppRoute.CapitalAccountStatement}
              component={CapitalAccountStatementPage}
            />,
            <Route
              key={AppRoute.ScheduleOfInvestments}
              path={AppRoute.ScheduleOfInvestments}
              component={ScheduleOfInvestmentsPage}
            />,
            <Route key={AppRoute.CashFlowActivity} path={AppRoute.CashFlowActivity} component={CashFlowActivityPage} />,
          ]}
          {permissions[Permission.ViewAccountSummaryAlpInvest] && (
            <Route path={AppRoute.AccountSummaryAlpInvest} component={AccountSummaryAlpInvestPage} />
          )}
          {permissions[Permission.ViewPortfolioPerformanceReport] && (
            <Route path={AppRoute.PortfolioPerformance} component={PortfolioPerformancePage} />
          )}
          {permissions[Permission.ViewDiversificationAnalysisReport] && (
            <Route path={AppRoute.DiversificationAnalysis} component={DiversificationAnalysisPage} />
          )}
          <Route path="**">
            <Redirect to={routeToRedirect} />
          </Route>
        </Switch>
      </Suspense>
    </ReportingDrawer>
  );
};

export default ReportingRouter;
