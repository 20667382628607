import { DocumentStatusId, TimeRangeIDs } from "@documents/models/filter.models";

export const statusFilterOptions = [
  { id: DocumentStatusId.All, label: "All" },
  { id: DocumentStatusId.Read, label: "Read" },
  { id: DocumentStatusId.Unread, label: "Unread" },
  { id: DocumentStatusId.ActionItems, label: "Action Items" },
  { id: DocumentStatusId.UnreadActionItems, label: "Unread Action Items" },
];
export const documentStatusesAdmin = ["P", "A", "W", "V"];
export const documentStatusesNonAdmin = ["A"];

export const timeRangeOptions: {
  label: string;
  id: TimeRangeIDs;
}[] = [
  { id: TimeRangeIDs.ALL, label: "All dates" },
  { id: TimeRangeIDs.LAST30, label: "Last 30 days" },
  {
    id: TimeRangeIDs.LAST90,
    label: "Last 90 days",
  },
  {
    id: TimeRangeIDs.YEARBEGINNING,
    label: "Since beginning of year",
  },
  {
    id: TimeRangeIDs.EXACTRANGE,
    label: "Exact date range",
  },
];
