import { DocumentRow } from "@documents/models/dataGrid.models";

export const getUnreadDocumentIds = <T extends { id?: string; markedRead: boolean }>(
  documentList: T[],
  documentIds: string[]
) => {
  const documentsRecord: Record<string, Partial<DocumentRow>> = documentList.reduce((result, current) => {
    return { ...result, [current!.id!]: current };
  }, {});

  const unreadIds = documentIds.reduce((result: string[], id) => {
    return documentsRecord[id]?.markedRead === false ? [...result, id] : result;
  }, []);

  return unreadIds;
};
