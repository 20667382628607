import { createAsyncThunk } from "@reduxjs/toolkit";
import { FundDates, FundPeriods } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchFundDates = createAsyncThunk<FundDates, string, ThunkApiConfig>(
  "funds/fetchFundDates",
  async (entityID, { rejectWithValue, extra, signal }) => {
    try {
      const response = await extra<FundPeriods>(`/reporting/v1/FPSIS/${entityID}/periods`, { signal });

      return response.data;
    } catch (e) {
      return rejectWithValue("Failed to fetch Fund Periods");
    }
  }
);
