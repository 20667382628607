import { sub } from "date-fns";
import { TimeRangeFilterOptions, TimeRangeIDs } from "@documents/models/filter.models";
import DateUtils from "@shared/utils/DateUtils";

export const timeRangeConverter = (timeRange: TimeRangeFilterOptions) => {
  switch (timeRange.timePeriod.id) {
    case TimeRangeIDs.LAST30: {
      const endDate = new Date();

      const year = endDate.getFullYear();
      const month = endDate.getMonth();
      const day = endDate.getDate();
      const endDateUTC = new Date(Date.UTC(year, month, day + 1));
      const startDate = sub(endDateUTC, { days: 32 });

      return { start: startDate, end: endDateUTC };
    }

    case TimeRangeIDs.LAST90: {
      const endDate = new Date();

      const year = endDate.getFullYear();
      const month = endDate.getMonth();
      const day = endDate.getDate();
      const endDateUTC = new Date(Date.UTC(year, month, day + 1));
      const startDate = sub(endDateUTC, { days: 92 });

      return { start: startDate, end: endDateUTC };
    }

    case TimeRangeIDs.YEARBEGINNING: {
      const currentYear = new Date().getFullYear();
      const startOfTheYear = new Date(Date.UTC(currentYear, 0, 1));
      const currentDayDate = new Date();
      const currentMonth = currentDayDate.getMonth();
      const currentDay = currentDayDate.getDate();

      const endDate = new Date(Date.UTC(currentYear, currentMonth, currentDay + 1));

      return { start: startOfTheYear, end: endDate };
    }

    case TimeRangeIDs.EXACTRANGE: {
      if (!timeRange.start || !timeRange.end) {
        return { start: null, end: null };
      }

      return {
        start: DateUtils.setUtcTimeZone(DateUtils.setStartOfDay(timeRange.start)),
        end: DateUtils.setUtcTimeZone(DateUtils.setEndOfDay(timeRange.end)),
      };
    }

    default:
      return { start: null, end: null };
  }
};
