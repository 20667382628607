import { createAsyncThunk } from "@reduxjs/toolkit";
import { accountGroupsSelector } from "@store/Entities/selectors";
import { ThunkApiConfig } from "@shared/models/redux";
import { AccountGroupResponse } from "../models";

export const fetchAccountGroups = createAsyncThunk<AccountGroupResponse[], boolean | undefined, ThunkApiConfig>(
  "entities/fetchAccountGroups",
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra<AccountGroupResponse[]>("/entitlements/v1/user/InvestorAccountGroups", {
        queryParams: { includeGroups: true, _: Date.now() },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to load account groups");
    }
  },
  {
    condition: (refresh, { getState }) => {
      if (refresh) return true;

      const accountGroups = accountGroupsSelector(getState());

      return !accountGroups.loading && !accountGroups.data;
    },
  }
);
