import { Quarters, ReportingPeriodTypes } from "@shared/models/reporting";
import { AsyncSlice } from "@shared/models/redux";
import {
  AccountSummaryAccountsFilter,
  AccountSummaryDataGridColumn,
  AccountSummaryDataGridColumnNames,
  AccountSummaryReport,
  AccountSummaryReportData,
  AccountSummaryReportTotal,
  FundData,
} from "@accountSummaryCarlyle/models";
import { accountSummaryDataGridColumns } from "@accountSummaryCarlyle/constants";
import { CurrencyAbbreviation, CurrencyName } from "@shared/models/currency";
import { Funds, Investors } from "@store/Entities/models";
import { asyncArray } from "@utils/array";
import { Nullable } from "@shared/models/general";
import { DonutValue } from "@shared/components";

export const getAccountSummaryDataGridColumns = (
  onClick: (id: string) => void,
  isMobile: boolean
): ReadonlyArray<AccountSummaryDataGridColumn> => [
  {
    accessor: "fundName",
    Header: AccountSummaryDataGridColumnNames.FundName,
    width: 220,
    align: "left",
    headerAlign: "left",
    headerContentAlign: "flex-start",
    letterSpacing: 0,
    pinned: !isMobile,
    withTooltip: true,
    minWidth: 220,
    onClick: (row) => row.original.fundId && onClick(row.original.fundId),
  },
  ...accountSummaryDataGridColumns,
];

export const getReportData = (
  report: AsyncSlice<AccountSummaryReport[]>,
  funds: AsyncSlice<Funds>,
  investorAccounts: string[]
): AccountSummaryReportData[] | [] => {
  if (!funds.data || !report.data || !investorAccounts) {
    return [];
  }
  const reportEntries = report.data.reduce(
    (reportData: Record<string, AccountSummaryReportData>, item: AccountSummaryReport) => {
      const reportDataId = `${item.fundId}|${item.reportingCurrency}`;

      if (item.period === ReportingPeriodTypes.ITD && investorAccounts.includes(item.investorId)) {
        if (Object.getOwnPropertyDescriptor(reportData, reportDataId)) {
          reportData[reportDataId].commitment += item.commitmentBeginningRemainingCommitment;
          reportData[reportDataId].remainingCommitments += item.remainingCommitments;
          reportData[reportDataId].totalContributedCapital += item.totalContributedCapital;
          reportData[reportDataId].totalDistributions += item.totalDistributions;
          reportData[reportDataId].capitalAccountAtFairValue += item.capitalAccountAtFairValue;
        } else {
          const fundRef = funds.data ? funds?.data[item.fundId as keyof AccountSummaryReport] : undefined;

          const reportingDate = new Date(item.reportingDate);
          const month = reportingDate.getMonth();
          const year = reportingDate.getFullYear().toString();
          const reportingQuarter =
            (month === 2 && Quarters.Q1) ||
            (month === 5 && Quarters.Q2) ||
            (month === 8 && Quarters.Q3) ||
            (month === 11 && Quarters.Q4);
          const reportingDateText = `${reportingQuarter} ${year}`;

          if (fundRef) {
            reportData[reportDataId] = {
              fundId: item.fundId,
              fundName: fundRef.name,
              fundType: fundRef.strategy,
              currency: CurrencyName[item.reportingCurrency],
              reportingDate: reportingDateText,
              commitment: item.commitmentBeginningRemainingCommitment,
              remainingCommitments: item.remainingCommitments,
              totalContributedCapital: item.totalContributedCapital,
              totalDistributions: item.totalDistributions,
              capitalAccountAtFairValue: item.capitalAccountAtFairValue,
            };
          }
        }
      }

      return reportData;
    },
    {}
  );

  return Object.values(reportEntries).sort((a, b) => {
    return a.fundName.toUpperCase() > b.fundName.toUpperCase() ? 1 : -1;
  });
};

export const getConsolidateFundValues = (
  report: AccountSummaryReport[],
  funds: Funds,
  investorAccounts: string[]
): Record<string, AccountSummaryReportData> => {
  return report.reduce((fundValues: Record<string, AccountSummaryReportData>, item) => {
    const reportDataId = `${item.fundId}|${item.reportingCurrency}`;

    if (item.period === ReportingPeriodTypes.ITD && investorAccounts.includes(item.investorId)) {
      if (Object.getOwnPropertyDescriptor(fundValues, reportDataId)) {
        fundValues[reportDataId].commitment += item.commitmentBeginningRemainingCommitment * item.exchangeRateToUSD;
        fundValues[reportDataId].remainingCommitments += item.remainingCommitments * item.exchangeRateToUSD;
        fundValues[reportDataId].totalContributedCapital += item.totalContributedCapital * item.exchangeRateToUSD;
        fundValues[reportDataId].totalDistributions += item.totalDistributions * item.exchangeRateToUSD;
        fundValues[reportDataId].capitalAccountAtFairValue += item.capitalAccountAtFairValue * item.exchangeRateToUSD;
      } else {
        const fundRef = funds ? funds[item.fundId as keyof AccountSummaryReport] : undefined;

        if (fundRef) {
          fundValues[reportDataId] = {
            fundId: item.fundId,
            fundName: fundRef.name,
            fundType: fundRef.strategy,
            commitment: item.commitmentBeginningRemainingCommitment * item.exchangeRateToUSD,
            remainingCommitments: item.remainingCommitments * item.exchangeRateToUSD,
            totalContributedCapital: item.totalContributedCapital * item.exchangeRateToUSD,
            totalDistributions: item.totalDistributions * item.exchangeRateToUSD,
            capitalAccountAtFairValue: item.capitalAccountAtFairValue * item.exchangeRateToUSD,
          };
        }
      }
    }

    return fundValues;
  }, {});
};

export const getTotalReportData = (
  report: AsyncSlice<AccountSummaryReport[]>,
  funds: AsyncSlice<Funds>,
  investorAccounts: string[]
): AccountSummaryReportTotal => {
  const initialTotalValues = {
    commitment: 0,
    remainingCommitments: 0,
    totalContributedCapital: 0,
    totalDistributions: 0,
    capitalAccountAtFairValue: 0,
  };

  if (!funds.data || !report.data || !investorAccounts) {
    return initialTotalValues;
  }

  const consolidatedFundValues = getConsolidateFundValues(report.data, funds.data, investorAccounts);

  const reportTotals = Object.entries(consolidatedFundValues).reduce(
    (reportTotal: AccountSummaryReportTotal, [, datum]): AccountSummaryReportTotal => {
      return {
        commitment: (reportTotal.commitment += datum.commitment),
        remainingCommitments: reportTotal.remainingCommitments
          ? (reportTotal.remainingCommitments += datum.remainingCommitments)
          : datum.remainingCommitments,
        totalContributedCapital: reportTotal.totalContributedCapital
          ? (reportTotal.totalContributedCapital += datum.totalContributedCapital)
          : datum.totalContributedCapital,
        totalDistributions: reportTotal.totalDistributions
          ? (reportTotal.totalDistributions += datum.totalDistributions)
          : datum.totalDistributions,
        capitalAccountAtFairValue: reportTotal.capitalAccountAtFairValue
          ? (reportTotal.capitalAccountAtFairValue += datum.capitalAccountAtFairValue)
          : datum.capitalAccountAtFairValue,
      };
    },
    initialTotalValues
  );

  return reportTotals;
};

export const getCurrencyAbbreviationFromCurrencyName = (
  currency: string | undefined = CurrencyName.USD
): CurrencyAbbreviation => {
  switch (currency) {
    case CurrencyName.EUR:
      return CurrencyAbbreviation.EUR;
    case CurrencyName.USD:
      return CurrencyAbbreviation.USD;
    case CurrencyName.JPY:
      return CurrencyAbbreviation.JPY;
    case CurrencyName.SEK:
      return CurrencyAbbreviation.SEK;
    case CurrencyName.BRL:
      return CurrencyAbbreviation.BRL;
    case CurrencyName.GBP:
      return CurrencyAbbreviation.GBP;

    default:
      return CurrencyAbbreviation.USD;
  }
};

export const makeAccountFilter = async (
  fundData: FundData[],
  year: number,
  quarter: number,
  funds: string[],
  investors: Investors,
  accounts: Nullable<AccountSummaryAccountsFilter>,
  isCurrent: boolean
): Promise<AccountSummaryAccountsFilter> => {
  let priorYear = year;
  let priorQuarter = quarter - 1;

  if (quarter === 1) {
    priorYear = year - 1;
    priorQuarter = 4;
  }
  let twoPriorYear = priorYear;
  let twoPriorQuarter = priorQuarter - 1;

  if (priorQuarter === 1) {
    twoPriorYear = priorYear - 1;
    twoPriorQuarter = 4;
  }

  return asyncArray(fundData).reduce<AccountSummaryAccountsFilter>((filters, fund) => {
    const currentYear = Number(fund.year);
    const currentQuarter = Number(fund.quarter);
    const isEqualDate = year === currentYear && quarter === currentQuarter;
    const hasMatchedDates =
      isEqualDate ||
      (priorYear === currentYear && priorQuarter === currentQuarter) ||
      (twoPriorYear === currentYear && twoPriorQuarter === currentQuarter);

    if (
      (isEqualDate || (isCurrent && hasMatchedDates)) &&
      (!funds || funds.includes(fund.fundId)) &&
      !filters[fund.investorId] &&
      investors[fund.investorId]
    ) {
      filters[fund.investorId] = Boolean(accounts && accounts[fund.investorId]);
    }

    return filters;
  }, {});
};

export const getAccountSummaryPieChartData = (
  fundValues: Record<string, AccountSummaryReportData>,
  fundTypeName: "remainingCommitments" | "capitalAccountAtFairValue"
): DonutValue[] => {
  let totalValue = 0;

  const pieChartData = Object.entries(fundValues).reduce((data: Record<string, number>, [, item]) => {
    totalValue += item[fundTypeName];

    if (data[item.fundType]) {
      data[item.fundType] += item[fundTypeName];
    } else {
      data[item.fundType] = item[fundTypeName];
    }

    return data;
  }, {});

  return pieChartData
    ? Object.entries(pieChartData)
        .map(([name, value]) => ({
          name,
          value: Number(((100 * value) / totalValue).toFixed(1)),
        }))
        .filter((datum) => datum.value)
    : [];
};
