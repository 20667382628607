import React, { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";
import { ControlProps } from "@shared/components/Control/models";
import { ReactComponent as InfoIcon } from "@assets/svg/info_circle.svg";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(5),
    textTransform: "uppercase",
  },
  infoIcon: {
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.info.main,
    },
    marginLeft: theme.spacing(1),
  },
}));

const Control: FunctionComponent<ControlProps> = ({ children, testId, label, hint }) => {
  const classes = useStyles();

  return (
    <Box data-testid={testId ? `control-${testId}` : "control"}>
      {label && (
        <Box display="flex" flexDirection="row">
          <Typography className={classes.label} data-testid={`${testId}-label`}>
            {label}
          </Typography>
          {hint && (
            <Tooltip title={hint} placement="right">
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default Control;
