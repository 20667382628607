import React, { memo, useCallback, useMemo, useRef } from "react";
import { Grid, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cn from "classnames";
import { ListItemProps, ListOptionBase } from "@shared/components/List/models";
import useIsOverflowing from "@shared/hooks/useIsOverflowing";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    lineHeight: 1,
    transition: "all ease-in .15s",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "rgba(208, 232, 248, 0.3)",
    },
  },
  label: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  small: {
    padding: theme.spacing(2.5, 5),
    fontSize: 14,
  },
  medium: {
    padding: theme.spacing(3.5, 5),
    fontSize: 16,
  },
  large: {
    padding: theme.spacing(4.5, 5),
    fontSize: 18,
  },
  selected: {
    backgroundColor: theme.palette.info.light,
  },
  disabled: {
    color: theme.palette.grey[500],
    cursor: "default",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const ListItem = <ListOption extends ListOptionBase>({
  option,
  size,
  style,
  selected,
  onOptionClick,
}: ListItemProps<ListOption>) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const isTextOverflowing = useIsOverflowing(ref);
  const showTooltip = !!option.tooltip || isTextOverflowing;

  const className = useMemo(
    () =>
      cn({
        [classes.root]: true,
        [classes.small]: size === "small",
        [classes.medium]: size === "medium",
        [classes.large]: size === "large",
        [classes.selected]: selected,
        [classes.disabled]: option.disabled,
      }),
    [classes, selected, size, option.disabled]
  );

  const handleClick = useCallback(() => {
    if (option.disabled) return;

    onOptionClick(option);
  }, [option, onOptionClick]);

  const testIdFromLabel = option.label.toLowerCase().replaceAll(" ", "-");

  return (
    <Tooltip disableInteractive disableHoverListener={!showTooltip} title={option.tooltip ?? option.label}>
      <Grid
        container
        style={style}
        className={className}
        onClick={handleClick}
        data-testid={`list-option-${testIdFromLabel}`}
      >
        {option.startAdornment && (
          <Grid item xs={1} marginRight={2}>
            {option.startAdornment}
          </Grid>
        )}
        <Grid item xs className={classes.label} ref={ref} data-testid={`list-option-text-${testIdFromLabel}`}>
          {option.label}
        </Grid>
        {option.endAdornment && (
          <Grid item xs={1} marginLeft={2}>
            {option.endAdornment}
          </Grid>
        )}
      </Grid>
    </Tooltip>
  );
};

export default memo(ListItem) as typeof ListItem;
