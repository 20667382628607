import React, { memo, useMemo, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers";
import ToggleButton from "@shared/components/ToggleButton/ToggleButton";
import { ToggleButtonVariant } from "@shared/components/ToggleButton/models";
import { SingleDatePickerPlaceholders } from "./constants";
import { SingleDatePickerProps } from "./models";

const useStyles = makeStyles(() => ({
  root: {
    height: 45,
    fontSize: 18,
    display: "flex",
    alignItems: "center",
  },
}));

const TextField = ({ inputProps, value, label, id, setOpen, isOpen, testId }: any) => {
  const classes = useStyles();

  return (
    <Box
      id={id}
      className={classes.root}
      data-testid={`${testId}-text-field`}
      ref={inputProps!.ref}
      onClick={() => setOpen((open: boolean) => !open)}
    >
      <ToggleButton open={isOpen} variant={ToggleButtonVariant.Underlined} testId={`${testId}-toggle-button`}>
        {value || label}
      </ToggleButton>
    </Box>
  );
};

const SingleDatePicker = ({ selectedDate, testId, handleDateChange }: SingleDatePickerProps) => {
  const dateRef = useRef<HTMLInputElement>(null);
  const currentDate = useMemo(() => new Date(), []);
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);

  // useEffect(() => {
  //   if (!selectedDate) {
  //     handleDateChange(currentDate);
  //   }
  // }, [selectedDate, currentDate, handleDateChange]);

  return (
    <Grid container spacing={7}>
      <Grid item xs={12} data-testid="date-picker">
        <DatePicker
          open={isDateDropdownOpen}
          value={selectedDate || currentDate}
          onChange={handleDateChange}
          orientation="landscape"
          minDate={new Date(1980, 1, 1)}
          maxDate={currentDate}
          format="MM/dd/yyyy"
          label={SingleDatePickerPlaceholders.dateLabel}
          inputRef={dateRef}
          slots={{ textField: TextField }}
          slotProps={{
            textField: {
              setOpen: setIsDateDropdownOpen,
              isOpen: isDateDropdownOpen,
              testId: testId && `${testId}-date`,
            } as any,
            popper: { anchorEl: dateRef.current, placement: "bottom" },
          }}
          onOpen={() => setIsDateDropdownOpen(true)}
          onClose={() => setIsDateDropdownOpen(false)}
        />
      </Grid>
    </Grid>
  );
};

export default memo<SingleDatePickerProps>(SingleDatePicker);
