import React, { useCallback, useMemo } from "react";
import { Box, CircularProgress, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { VIDEO_PROVIDER_URL } from "@app/constants/globalUrls";
import { NoDataMessage, NoDataTile } from "@shared/components/NoDataTile";
import { HomePageVideoDetails } from "@home/models/index";
import { Nullable } from "@shared/models/general";

const useStyles = makeStyles<Theme, Pick<Props, "textColorWhite" | "fontSizeSmall">>((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  superScript: {
    marginBottom: theme.spacing(12.5),
    fontSize: ({ fontSizeSmall }) => (fontSizeSmall ? 14 : 18),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 3.6,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
  },
  title: {
    lineHeight: 1,
    letterSpacing: ({ fontSizeSmall }) => (fontSizeSmall ? 1 : 1.2),
    color: ({ textColorWhite }) => (textColorWhite ? theme.palette.common.white : theme.palette.primary.main),
  },
  player: {
    width: "100%",
    aspectRatio: "16 / 9",
    background: theme.palette.grey[300],
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: 40,
    lineHeight: 1.5,
    backgroundColor: theme.palette.common.white,
    fontSize: 20,
  },
  contactUs: {
    textDecoration: "underline",
    color: theme.palette.text.secondary,

    "&:hover": {
      color: theme.palette.info.main,
    },
  },
  description: {
    paddingTop: theme.spacing(6),
    wordBreak: "break-word",
  },
}));

interface Props {
  isVideoLoading?: boolean;
  videoError?: Nullable<String>;
  video: Nullable<HomePageVideoDetails>;
  onContactUsClick?: () => void;
  textColorWhite?: boolean;
  fontSizeSmall?: boolean;
}

const VideoSection = ({
  isVideoLoading,
  videoError,
  video,
  onContactUsClick,
  textColorWhite,
  fontSizeSmall,
}: Props) => {
  const classes = useStyles({ textColorWhite, fontSizeSmall });

  const handleContactUsClick = useCallback(() => {
    if (onContactUsClick) {
      onContactUsClick();
    }
  }, [onContactUsClick]);

  const theme = useTheme();

  const isXLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const textVariant = useMemo(() => {
    if (fontSizeSmall) {
      return "h3";
    }
    if (isXLarge) {
      return "h1";
    }

    return "h2";
  }, [fontSizeSmall, isXLarge]);

  if (isVideoLoading) {
    return (
      <Box className={classes.root}>
        <Box className={classes.progress}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (!video || videoError) {
    return <NoDataTile onContactUsClick={handleContactUsClick} noDataMessage={NoDataMessage.General} />;
  }
  const videoNameTestId = video.title.toLowerCase().replaceAll(" ", "-");

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} lg={4}>
        <Box className={classes.header}>
          <span className={classes.superScript}>{video.type}</span>
          <Typography variant={textVariant} className={classes.title} data-testid={`video-title-${videoNameTestId}`}>
            {video.title}
          </Typography>
          {video.description ? (
            <Typography variant="body2" className={classes.description}>
              {video.description}
            </Typography>
          ) : null}
        </Box>
      </Grid>
      <Grid item xs={12} lg={8} data-testid={`video-frame-${videoNameTestId}`}>
        <iframe
          data-testid="videoIFrame"
          className={classes.player}
          title={video.title}
          frameBorder="0"
          allowFullScreen
          src={`${VIDEO_PROVIDER_URL}/${video.id}/player?uss_token=${video.token}`}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(VideoSection);
