import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileState } from "@profile/models";
import { createAccountGroupThunk, deleteAccountGroupThunk, updateAccountGroupThunk } from "@profile/store/thunks";

const initialState: ProfileState = {
  myGroups: {
    loading: false,
    selectedAccountGroupId: null,
    selectedAccountIds: [],
    groupNameInput: "",
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    changeInputValue: (state, action: PayloadAction<{ value: string }>) => {
      state.myGroups.groupNameInput = action.payload.value;
    },
    addNewGroup: (state) => {
      state.myGroups.selectedAccountGroupId = "NEW";
      state.myGroups.groupNameInput = "My New Group";
      state.myGroups.selectedAccountIds = [];
    },
    selectGroup: (
      state,
      action: PayloadAction<{
        id: number;
        selectedAccountIds: number[];
        groupName: string;
      }>
    ) => {
      state.myGroups.selectedAccountGroupId = action.payload.id;
      state.myGroups.selectedAccountIds = action.payload.selectedAccountIds;
      state.myGroups.groupNameInput = action.payload.groupName;
    },
    selectAccount: (state, action: PayloadAction<{ id: number; selected: boolean }>) => {
      if (action.payload.selected) {
        state.myGroups.selectedAccountIds.push(action.payload.id);
      } else {
        state.myGroups.selectedAccountIds = state.myGroups.selectedAccountIds.filter(
          (sid) => sid !== action.payload.id
        );
      }
    },
    clearAllSelectedAccounts: (state) => {
      state.myGroups.selectedAccountIds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteAccountGroupThunk.pending, (state) => {
        state.myGroups.loading = true;
      })
      .addCase(deleteAccountGroupThunk.fulfilled, (state) => {
        state.myGroups = initialState.myGroups;
      })
      .addCase(deleteAccountGroupThunk.rejected, (state) => {
        state.myGroups.loading = false;
      })
      .addCase(updateAccountGroupThunk.pending, (state) => {
        state.myGroups.loading = true;
      })
      .addCase(updateAccountGroupThunk.fulfilled, (state) => {
        state.myGroups.loading = false;
      })
      .addCase(updateAccountGroupThunk.rejected, (state) => {
        state.myGroups.loading = false;
      })
      .addCase(createAccountGroupThunk.pending, (state) => {
        state.myGroups.loading = true;
      })
      .addCase(createAccountGroupThunk.fulfilled, (state, action) => {
        state.myGroups.loading = false;
        state.myGroups.selectedAccountGroupId = action.payload;
      })
      .addCase(createAccountGroupThunk.rejected, (state) => {
        state.myGroups.loading = false;
      });
  },
});

export const { changeInputValue, addNewGroup, selectGroup, selectAccount, clearAllSelectedAccounts } =
  profileSlice.actions;
export default profileSlice.reducer;
