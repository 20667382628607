import React, { FunctionComponent } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  footnote: {
    padding: "1rem 0",
    fontFamily: theme.typography.fontFamily,
    fontSize: "11px",
    lineHeight: "1.2rem",
    fontStyle: "italic",
    fontWeight: "normal",
    letterSpacing: "0.5px",
  },
}));

export const Footnote: FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <div data-testid="footnote" className={classes.footnote}>
      {children}
    </div>
  );
};
