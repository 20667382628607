import { RefObject } from "react";

export enum ToggleButtonSize {
  Small = "Small",
  Big = "Big",
}

export enum ToggleButtonVariant {
  Transparent = "Transparent",
  Contained = "Contained",
  Underlined = "Underlined",
}

export interface ToggleButtonProps {
  open: boolean;
  loading?: boolean;
  ref?: RefObject<any>;
  fullWidth?: boolean;
  size?: ToggleButtonSize;
  variant?: ToggleButtonVariant;
  disabled?: boolean;
  testId?: string;
  onClick?(): void;
}
