import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { FileResponse } from "@shared/models/request";
import { timeRangeConverter, timeRangeConverter2 } from "@documents/utils";

export const generateBounceBackReportThunk = createAsyncThunk<void, void, ThunkApiConfig>(
  "administrationReports/generateBounceBackReportThunk",
  async (_, { dispatch, rejectWithValue, getState, extra }) => {
    const {
      administrationReports: { filters },
    } = getState();
    const { start, end } = timeRangeConverter(filters.timeRange);
    const { start2, end2 } = timeRangeConverter2(filters.timeRange2);
    const body = {
      emailDate: {
        startDate: start,
        endDate: end,
      },
      bounceBackDate: {
        startDate: start2,
        endDate: end2,
      },
      emailAddress: filters.userEmail.length ? filters.userEmail : null,
      emailSource: filters.emailSource.length ? filters.emailSource : null,
      reportFormat: "excel",
    };

    try {
      const response = await extra<FileResponse>("/documents/v1/BounceBack/bounceBack", {
        method: "post",
        responseType: "blob",
        body,
      });

      if (response.status == 204) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: "Report has no data for the parameters given",
          })
        );

        return;
      }

      const today = new Date();
      const fileName = `DigestBounceBackReport${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}.xlsx`;

      if (response.data.blob?.size) {
        saveAs(response.data.blob, fileName);

        dispatch(
          openNotificationThunk({
            variant: "info",
            text: "Report generation successful.",
          })
        );
      }
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Report download failed.",
        })
      );

      return rejectWithValue("The document download failed");
    }
  }
);
