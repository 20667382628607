import { createAsyncThunk } from "@reduxjs/toolkit";
import { Report } from "powerbi-client";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { callExportAPI } from "@reporting/store/thunks/callExportAPI";
import { ReportingExport } from "@reporting/models";
import { getExportParams } from "@accountSummaryAlpInvest/utils/getExportParams";

export const postAccountSummaryExportThunk = createAsyncThunk<
  ReportingExport,
  { type: string; report?: Report },
  ThunkApiConfig
>("accountSummaryAlpInvest/postAccountSummaryExportThunk", async ({ type, report }, { dispatch, rejectWithValue }) => {
  const exportParams = await getExportParams(report);

  try {
    return await dispatch(callExportAPI("AccountSummary", type, JSON.stringify(exportParams)));
  } catch (error) {
    dispatch(
      openNotificationThunk({
        variant: "warning",
        text: "Failed to export report",
      })
    );

    return rejectWithValue("Failed to fetch export details");
  }
});
