import { AppThunkAPI } from "@shared/models/redux";
import { ActionRequiredItemsApiResponse } from "@documents/models";

type CallActionRequiredItemsApi = () => AppThunkAPI<Promise<ActionRequiredItemsApiResponse>>;
export const callActionRequiredItemsApi: CallActionRequiredItemsApi = () => async (_dispatch, _state, extra) => {
  const response = await extra<ActionRequiredItemsApiResponse>("/documents/v1/Users/documents/actionRequired", {
    method: "POST",
  });

  return response.data;
};
