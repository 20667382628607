interface Options {
  loopTime: number;
  sleepTime: number;
}

type ForEachCallback<ArrayElement> = (element: ArrayElement, index: number, array: ArrayElement[]) => void;

type MapCallback<ArrayElement, ResultElement> = (
  element: ArrayElement,
  index: number,
  array: ArrayElement[]
) => ResultElement;

type ReduceCallback<ArrayElement, Result> = (
  result: Result,
  element: ArrayElement,
  index: number,
  array: ArrayElement[]
) => Result;

const sleep = <Returned>(callback: Function, milliseconds: number): Promise<Returned> => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(callback());
    }, milliseconds)
  );
};

const forEach = async <ArrayElement>(
  options: Options,
  array: ArrayElement[],
  index: number,
  callback: ForEachCallback<ArrayElement>
): Promise<void> => {
  const startLoopTime = performance.now();

  for (let i = index; i < array.length; i++) {
    if (performance.now() - startLoopTime > options.loopTime) {
      return sleep<void>(() => forEach(options, array, i, callback), options.sleepTime);
    }

    callback(array[i], i, array);
  }
};

const map = async <ArrayElement, ResultElement>(
  options: Options,
  array: ArrayElement[],
  index: number,
  callback: MapCallback<ArrayElement, ResultElement>,
  accumulator: ResultElement[] = []
): Promise<ResultElement[]> => {
  const startLoopTime = performance.now();

  for (let i = index; i < array.length; i++) {
    if (performance.now() - startLoopTime > options.loopTime) {
      return sleep<ResultElement[]>(() => map(options, array, i, callback, accumulator), options.sleepTime);
    }

    accumulator.push(callback(array[i], i, array));
  }

  return accumulator;
};

const reduce = async <ArrayElement, Result>(
  options: Options,
  array: ArrayElement[],
  index: number,
  callback: ReduceCallback<ArrayElement, Result>,
  initialValue: Result
): Promise<Result> => {
  const startLoopTime = performance.now();
  let result = initialValue;

  for (let i = index; i < array.length; i++) {
    if (performance.now() - startLoopTime > options.loopTime) {
      return sleep<Result>(() => reduce(options, array, i, callback, result), options.sleepTime);
    }

    result = callback(result, array[i], i, array);
  }

  return result;
};

export const asyncArray = <ArrayElement>(
  array: ArrayElement[],
  options: Options = { loopTime: 45, sleepTime: 90 }
) => ({
  forEach: (callback: ForEachCallback<ArrayElement>) => forEach<ArrayElement>(options, array, 0, callback),

  map: <ResultElement>(callback: MapCallback<ArrayElement, ResultElement>) =>
    map<ArrayElement, ResultElement>(options, array, 0, callback),

  reduce: <Result>(callback: ReduceCallback<ArrayElement, Result>, initialValue: Result) =>
    reduce<ArrayElement, Result>(options, array, 0, callback, initialValue),
});
