export enum Permission {
  ViewAccountSummaryCarlyle = "ViewAccountSummaryCarlyle",
  ViewReportsCarlyle = "ViewReportsCarlyle",
  ViewAccountSummaryAlpInvest = "ViewAccountSummaryAlpInvest",
  ExportAccountSummaryAlpInvest = "ExportAccountSummaryAlpInvest",
  ViewPortfolioPerformanceReport = "ViewPortfolioPerformanceReport",
  ExportPortfolioPerformanceReport = "ExportPortfolioPerformanceReport",
  ViewDiversificationAnalysisReport = "ViewDiversificationAnalysisReport",
  ExportDiversificationAnalysisReport = "ExportDiversificationAnalysisReport",
  ViewFunds = "ViewFunds",
  ViewDocumentsRestricted = "ViewDocumentsRestricted",
  ViewDataRooms = "ViewDataRooms",
}
