import { createSlice } from "@reduxjs/toolkit";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { PortfolioPerformanceState } from "@portfolioPerformance/models";
import { fetchPortfolioPerformanceEmbedUrlThunk } from "@portfolioPerformance/store/thunks/fetchPortfolioPerformanceEmbedUrlThunk";
import { postPortfolioPerformanceExportThunk } from "@portfolioPerformance/store/thunks/postPortfolioPerformanceExportThunk";
import { fetchPortfolioPerformanceExportStatusThunk } from "@portfolioPerformance/store/thunks/fetchPortfolioPerformanceExportStatusThunk";
import { downloadPortfolioPerformanceThunk } from "@portfolioPerformance/store/thunks/downloadPortfolioPerformanceThunk";

const initialState: PortfolioPerformanceState = {
  embedUrlConfig: ReduxUtils.getAsyncSlice(),
  export: ReduxUtils.getAsyncSlice(),
  isReportDownloading: false,
};

const portfolioPerformanceSlice = createSlice({
  name: "portfolioPerformance",
  initialState,
  reducers: {
    resetState: (state) => {
      state.embedUrlConfig = initialState.embedUrlConfig;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortfolioPerformanceEmbedUrlThunk.pending, (state) =>
        ReduxUtils.defaultPendingActionHandler(state.embedUrlConfig)
      )
      .addCase(fetchPortfolioPerformanceEmbedUrlThunk.fulfilled, (state, action) =>
        ReduxUtils.defaultFulfilledActionHandler(state.embedUrlConfig, {
          embedUrl: action.payload.embedUrl,
          reportingDate: action.payload.reportingDate,
        })
      )
      .addCase(fetchPortfolioPerformanceEmbedUrlThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.embedUrlConfig, action.payload as string)
      )
      .addCase(postPortfolioPerformanceExportThunk.pending, (state) => {
        state.isReportDownloading = true;
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          status: "Initialized",
        });
      })
      .addCase(postPortfolioPerformanceExportThunk.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          exportId: action.payload.exportId,
          status: "Initialized",
          percentComplete: action.payload.percentComplete,
          resourceFileExtension: action.payload.resourceFileExtension,
        });
      })
      .addCase(postPortfolioPerformanceExportThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.export, action.payload as string)
      )
      .addCase(fetchPortfolioPerformanceExportStatusThunk.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.export);
      })
      .addCase(fetchPortfolioPerformanceExportStatusThunk.fulfilled, (state, action) => {
        if (action.payload.status === "Failed") {
          state.isReportDownloading = false;
        }
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          exportId: action.payload.exportId,
          status: action.payload.status,
          percentComplete: action.payload.percentComplete,
          resourceFileExtension: action.payload.resourceFileExtension,
        });
      })
      .addCase(fetchPortfolioPerformanceExportStatusThunk.rejected, (state, action) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
        ReduxUtils.defaultRejectedActionHandler(state.export, action.payload as string);
      })
      .addCase(downloadPortfolioPerformanceThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(downloadPortfolioPerformanceThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
      })
      .addCase(downloadPortfolioPerformanceThunk.rejected, (state) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
      });
  },
});

export const { resetState } = portfolioPerformanceSlice.actions;
export default portfolioPerformanceSlice.reducer;
