import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortDirection } from "@shared/models/general";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { ListOptionBase } from "@shared/components";
import { DocumentStatusId } from "@documents/models/filter.models";
import { statusFilterOptions, timeRangeOptions } from "@documents/constants";
import { SortKeys } from "@documents/models/sort.models";
import { ToggleDocumentActionItemFlagPayload } from "@documents/models/api.models";
import CommonUtils from "@shared/utils/CommonUtils";
import { fetchMoreFundDocuments } from "@funds/store/thunks/fetchMoreFundDocuments";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { normalizeDocumentResponse } from "@documents/utils";
import { downloadFundDocument } from "@funds/store/thunks/downloadFundDocument";
import {
  FSISDate,
  FsisFlocReport,
  FSISReport,
  FundDates,
  FundFPSIS,
  FundFPSISAppreciation,
  FundHeads,
  FundsState,
  HistoricMoic,
} from "../models";
import { changeFundDocumentsFilterValue, resetFundDocumentsFilters } from "./actions";
import {
  downloadFSISReport,
  downloadSelectedFundDocuments,
  fetchFPSISData,
  fetchFSISAppreciation,
  fetchFSISDates,
  fetchFsisFlocReports,
  fetchFSISReports,
  fetchFundDates,
  fetchFundDocuments,
  fetchFundHeads,
  fetchFundInvestorAccounts,
  fetchHistoricMoic,
  initFundDocuments,
  updateFundDocumentReadFlag,
} from "./thunks";

const initialState: FundsState = {
  fundHeads: ReduxUtils.getAsyncSlice<FundHeads>(),
  fundDates: ReduxUtils.getAsyncSlice<FundDates>(),
  fundFPSIS: ReduxUtils.getAsyncSlice<FundFPSIS[]>(),
  fundFSISAppreciation: ReduxUtils.getAsyncSlice<FundFPSISAppreciation>(),
  fsisDates: ReduxUtils.getAsyncSlice<FSISDate[]>(),
  fsisReports: ReduxUtils.getAsyncSlice<
    Record<string, Record<string, FSISReport[]>>,
    Record<string, Record<string, string | null>>
  >(),
  fsisFlocReports: ReduxUtils.getAsyncSlice<
    Record<string, Record<string, FsisFlocReport[]>>,
    Record<string, Record<string, string | null>>
  >(),
  historicMoic: ReduxUtils.getAsyncSlice<
    Record<string, Record<string, HistoricMoic | null>>,
    Record<string, Record<string, string | null>>
  >(),
  summaryInvestmentScheduleFilters: {
    asOfDate: null,
  },
  fundDocumentsFilters: {
    status: { id: DocumentStatusId.All, label: "All" },
    timeRange: {
      timePeriod: timeRangeOptions[0],
      start: null,
      end: null,
    },
    documentTypes: null,
    investorAccounts: null,
    sortKey: SortKeys.PUBLISH_DATE,
    sortDirection: SortDirection.DESC,
    fileName: "",
  },
  fundDocumentsFilterOptions: {
    statuses: statusFilterOptions,
    timeOptions: timeRangeOptions,
    investorAccounts: ReduxUtils.getAsyncSlice<ListOptionBase[]>(),
  },
  fundDocuments: ReduxUtils.getPaginatedAsyncSlice<DocumentRow[]>(),
  isExportFsisReportLoading: false,
};

export const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    resetState: () => initialState,
    changeAsOfDate: (state, { payload }) => {
      state.summaryInvestmentScheduleFilters.asOfDate = payload;
    },
    updateActionItemFlagState: (state, { payload }: PayloadAction<ToggleDocumentActionItemFlagPayload>) => {
      state.fundDocuments.data = state.fundDocuments.data!.map((row) => {
        if (row.id === payload.id) {
          row.actionItem = payload.actionItem;
        }

        return row;
      });
    },
  },
  extraReducers: (builder) => {
    // Handle fetching fundHeads
    builder.addCase(fetchFundHeads.pending, (state) => {
      state.fundHeads.loading = true;
      state.fundHeads.error = null;
    });
    builder.addCase(fetchFundHeads.fulfilled, (state, action) => {
      state.fundHeads.loading = false;
      state.fundHeads.error = null;
      state.fundHeads.data = action.payload;
    });
    builder.addCase(fetchFundHeads.rejected, (state, action) => {
      state.fundHeads.loading = false;
      state.fundHeads.error = action.error.message!;
      state.fundHeads.data = null;
    });

    // Handle fetching fundDates
    builder.addCase(fetchFundDates.pending, (state) => {
      state.fundDates.loading = true;
      state.fundDates.error = null;
    });
    builder.addCase(fetchFundDates.fulfilled, (state, action) => {
      state.fundDates.loading = false;
      state.fundDates.error = null;
      state.fundDates.data = action.payload;
    });
    builder.addCase(fetchFundDates.rejected, (state, action) => {
      state.fundDates.loading = false;
      state.fundDates.error = action.error.message!;
      state.fundDates.data = null;
    });

    // Handle fetching fundFPSIS
    builder.addCase(fetchFPSISData.pending, (state) => {
      state.fundFPSIS.loading = true;
      state.fundFPSIS.error = null;
    });
    builder.addCase(fetchFPSISData.fulfilled, (state, action) => {
      state.fundFPSIS.loading = false;
      state.fundFPSIS.error = null;
      state.fundFPSIS.data = action.payload;
    });
    builder.addCase(fetchFPSISData.rejected, (state, action) => {
      state.fundFPSIS.loading = false;
      state.fundFPSIS.error = action.error.message!;
      state.fundFPSIS.data = null;
    });

    // Handle fetching fundFSISAppreciation
    builder.addCase(fetchFSISAppreciation.pending, (state) => {
      state.fundFSISAppreciation.loading = true;
      state.fundFSISAppreciation.error = null;
    });
    builder.addCase(fetchFSISAppreciation.fulfilled, (state, action) => {
      state.fundFSISAppreciation.loading = false;
      state.fundFSISAppreciation.error = null;
      state.fundFSISAppreciation.data = action.payload;
    });
    builder.addCase(fetchFSISAppreciation.rejected, (state, action) => {
      state.fundFSISAppreciation.loading = false;
      state.fundFSISAppreciation.error = action.error.message!;
      state.fundFSISAppreciation.data = null;
    });

    // Handle fetching fsisDates
    builder.addCase(fetchFSISDates.pending, (state) => {
      state.fsisDates.loading = true;
      state.fsisDates.error = null;
    });
    builder.addCase(fetchFSISDates.fulfilled, (state, action) => {
      state.fsisDates.loading = false;
      state.fsisDates.error = null;
      state.fsisDates.data = action.payload;
    });
    builder.addCase(fetchFSISDates.rejected, (state, action) => {
      state.fsisDates.loading = false;
      state.fsisDates.error = action.error.message!;
      state.fsisDates.data = null;
    });

    // Handle fetching fsisReports
    builder.addCase(fetchFSISReports.pending, (state) => {
      state.fsisReports.loading = true;
    });

    builder.addCase(fetchFSISReports.fulfilled, (state, { payload, meta }) => {
      state.fsisReports.loading = false;

      state.fsisReports.error = {
        ...state.fsisReports?.error,
        [meta.arg.fundId]: {
          ...state.fsisReports?.error?.[meta.arg.fundId],
          [meta.arg.date]: null,
        },
      };

      state.fsisReports.data = {
        ...state.fsisReports?.data,
        [meta.arg.fundId]: {
          ...state.fsisReports?.data?.[meta.arg.fundId],
          [meta.arg.date]: payload,
        },
      };
    });

    builder.addCase(fetchFSISReports.rejected, (state, { error: { message }, meta }) => {
      state.fsisReports.loading = false;

      state.fsisReports.error = {
        ...state.fsisReports?.error,
        [meta.arg.fundId]: {
          ...state.fsisReports?.error?.[meta.arg.fundId],
          [meta.arg.date]: message ?? "Something went wrong",
        },
      };

      state.fsisReports.data = {
        ...state.fsisReports?.data,
        [meta.arg.fundId]: {
          ...state.fsisReports?.data?.[meta.arg.fundId],
          [meta.arg.date]: [],
        },
      };
    });

    // Handle fetching fsisFlocReports
    builder.addCase(fetchFsisFlocReports.pending, (state) => {
      state.fsisFlocReports.loading = true;
    });

    builder.addCase(fetchFsisFlocReports.fulfilled, (state, { payload, meta }) => {
      state.fsisFlocReports.loading = false;

      state.fsisFlocReports.error = {
        ...state.fsisFlocReports?.error,
        [meta.arg.fundId]: {
          ...state.fsisFlocReports?.error?.[meta.arg.fundId],
          [meta.arg.date]: null,
        },
      };

      state.fsisFlocReports.data = {
        ...state.fsisFlocReports?.data,
        [meta.arg.fundId]: {
          ...state.fsisFlocReports?.data?.[meta.arg.fundId],
          [meta.arg.date]: payload,
        },
      };
    });

    builder.addCase(fetchFsisFlocReports.rejected, (state, { error: { message }, meta }) => {
      state.fsisFlocReports.loading = false;

      state.fsisFlocReports.error = {
        ...state.fsisFlocReports?.error,
        [meta.arg.fundId]: {
          ...state.fsisFlocReports?.error?.[meta.arg.fundId],
          [meta.arg.date]: message ?? "Something went wrong",
        },
      };

      state.fsisFlocReports.data = {
        ...state.fsisFlocReports?.data,
        [meta.arg.fundId]: {
          ...state.fsisFlocReports?.data?.[meta.arg.fundId],
          [meta.arg.date]: [],
        },
      };
    });

    // Handle fetching historicMoic
    builder.addCase(fetchHistoricMoic.pending, (state) => {
      state.historicMoic.loading = true;
    });

    builder.addCase(fetchHistoricMoic.fulfilled, (state, { payload, meta }) => {
      state.historicMoic.loading = false;

      state.historicMoic.error = {
        ...state.historicMoic?.error,
        [meta.arg.fundId]: {
          ...state.historicMoic?.error?.[meta.arg.fundId],
          [meta.arg.date]: null,
        },
      };

      state.historicMoic.data = {
        ...state.historicMoic?.data,
        [meta.arg.fundId]: {
          ...state.historicMoic?.data?.[meta.arg.fundId],
          [meta.arg.date]: payload,
        },
      };
    });

    builder.addCase(fetchHistoricMoic.rejected, (state, { error: { message }, meta }) => {
      state.historicMoic.loading = false;

      state.historicMoic.error = {
        ...state.historicMoic?.error,
        [meta.arg.fundId]: {
          ...state.historicMoic?.error?.[meta.arg.fundId],
          [meta.arg.date]: message ?? "Something went wrong",
        },
      };

      state.historicMoic.data = {
        ...state.historicMoic?.data,
        [meta.arg.fundId]: {
          ...state.historicMoic?.data?.[meta.arg.fundId],
          [meta.arg.date]: null,
        },
      };
    });

    builder.addCase(fetchFundInvestorAccounts.pending, (state) => {
      ReduxUtils.defaultPendingActionHandler(state.fundDocumentsFilterOptions.investorAccounts);
    });

    builder.addCase(fetchFundInvestorAccounts.fulfilled, (state, action) => {
      ReduxUtils.defaultFulfilledActionHandler(state.fundDocumentsFilterOptions.investorAccounts, action.payload);
    });

    builder.addCase(fetchFundInvestorAccounts.rejected, (state, action) => {
      ReduxUtils.defaultRejectedActionHandler(
        state.fundDocumentsFilterOptions.investorAccounts,
        action.error.message as string,
        [] as ListOptionBase[]
      );
    });

    builder.addCase(initFundDocuments.fulfilled, (state) => {
      const {
        fundDocumentsFilterOptions: { investorAccounts: investorAccountOptions },
        fundDocumentsFilters: { investorAccounts: selectedInvestorAccounts },
      } = state;

      // if IA Group was updated, at this point of time IA options has already been updated respectively,
      // but also we need to get rid of selected IAs that are not relevant anymore
      if (selectedInvestorAccounts) {
        const optionsMap = investorAccountOptions.data!.reduce<Record<string, boolean>>((acc, curr) => {
          acc[curr.id] = true;

          return acc;
        }, {});

        state.fundDocumentsFilters.investorAccounts = selectedInvestorAccounts.filter(
          (accountId) => optionsMap[accountId]
        );
      }
    });

    builder.addCase(resetFundDocumentsFilters, (state) => {
      state.fundDocumentsFilters = initialState.fundDocumentsFilters;
    });

    builder.addCase(changeFundDocumentsFilterValue, (state, action) => {
      state.fundDocumentsFilters = {
        ...state.fundDocumentsFilters,
        ...action.payload,
      };
    });

    builder.addCase(fetchFundDocuments.pending, (state) => {
      state.fundDocuments.pagination = initialState.fundDocuments.pagination;
      ReduxUtils.defaultPendingActionHandler(state.fundDocuments);
    });

    builder.addCase(fetchFundDocuments.fulfilled, (state, action) => {
      if (action.payload) {
        ReduxUtils.defaultFulfilledActionHandler(
          state.fundDocuments,
          action.payload.results.map(normalizeDocumentResponse)
        );
        state.fundDocuments.pagination.total = action.payload!.total;
      } else {
        state.fundDocuments.data = null;
        state.fundDocuments.pagination.total = 0;
        state.fundDocuments.loading = false;
      }
    });

    builder.addCase(fetchFundDocuments.rejected, (state, action) => {
      if (action.meta.aborted) return;

      ReduxUtils.defaultRejectedActionHandler(state.fundDocuments, action.payload as string);
    });

    builder.addCase(fetchMoreFundDocuments.pending, (state, action) => {
      state.fundDocuments.pagination.loading = true;
      state.fundDocuments.pagination.start = action.meta.arg.start;
    });

    builder.addCase(fetchMoreFundDocuments.fulfilled, (state, action) => {
      ReduxUtils.defaultFulfilledPaginatedActionHandler(state.fundDocuments, action.payload.total);

      state.fundDocuments.data = state.fundDocuments.data!.concat(
        action.payload.results.map(normalizeDocumentResponse)
      );
    });

    builder.addCase(fetchMoreFundDocuments.rejected, (state, action) => {
      ReduxUtils.defaultRejectedActionHandler(state.fundDocuments, action.error.message!, state.fundDocuments.data);
    });

    builder.addCase(updateFundDocumentReadFlag.pending, (state, action) => {
      const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg);

      state.fundDocuments.data =
        state.fundDocuments.data?.map((record) => ({
          ...record,
          markedRead: idsToMarkReadMap[record.id!] ?? record.markedRead,
        })) ?? [];
    });
    builder
      .addCase(updateFundDocumentReadFlag.rejected, (state, action) => {
        const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg);

        state.fundDocuments.data = state.fundDocuments.data =
          state.fundDocuments.data?.map((record) => ({
            ...record,
            markedRead: idsToMarkReadMap[record.id!] ? false : record.markedRead,
          })) ?? [];
      })
      .addCase(downloadFundDocument.pending, (state, action) => {
        const row = state.fundDocuments.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = true;
        }
      })
      .addCase(downloadFundDocument.fulfilled, (state, action) => {
        const row = state.fundDocuments.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = false;
        }
      })
      .addCase(downloadFundDocument.rejected, (state, action) => {
        const row = state.fundDocuments.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = false;
        }
      })

      .addCase(downloadSelectedFundDocuments.pending, (state, action) => {
        const selectedRows = state.fundDocuments.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = true));
        }
      })
      .addCase(downloadSelectedFundDocuments.fulfilled, (state, action) => {
        const selectedRows = state.fundDocuments.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      })
      .addCase(downloadSelectedFundDocuments.rejected, (state, action) => {
        const selectedRows = state.fundDocuments.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      })
      .addCase(downloadFSISReport.pending, (state) => {
        state.isExportFsisReportLoading = true;
      })
      .addCase(downloadFSISReport.fulfilled, (state) => {
        state.isExportFsisReportLoading = false;
      })
      .addCase(downloadFSISReport.rejected, (state) => {
        state.isExportFsisReportLoading = false;
      });
  },
});

export const { resetState, changeAsOfDate, updateActionItemFlagState } = fundsSlice.actions;
export default fundsSlice.reducer;
