import { useEffect, useState } from "react";
import { Nullable } from "@shared/models/general";

const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: false, subtree: false },
};

function useMutationObservable(targetEl: Nullable<HTMLElement>, callback: MutationCallback, options = DEFAULT_OPTIONS) {
  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    const obs = new MutationObserver(callback);

    setObserver(obs);
  }, [callback, options, setObserver]);

  useEffect(() => {
    if (!observer || !targetEl) return;

    observer.observe(targetEl, options.config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, options]);
}

export default useMutationObservable;
