import React, { forwardRef, memo, PropsWithChildren, useCallback } from "react";
import cn from "classnames";
import { Button, CircularProgress, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as ArrowDropdown } from "@assets/svg/arrow_dropdown.svg";
import { ToggleButtonProps, ToggleButtonSize, ToggleButtonVariant } from "./models";

const useStyles = makeStyles<Theme, Pick<ToggleButtonProps, "fullWidth" | "open" | "variant" | "loading">>((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightMedium,
    width: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
    cursor: ({ loading }) => (loading ? "default" : "pointer"),
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  small: {
    fontSize: 16,
    height: 45,
    padding: theme.spacing(3, 6),
  },
  big: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    height: 55,
    padding: theme.spacing(5),
  },
  contained: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  transparent: {
    backgroundColor: "transparent",
    padding: theme.spacing(0, 5),
    "& span": {
      fontSize: theme.typography.fontSize,
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
    },
    "& span::before": {
      display: "block",
      content: "attr(data-title)",
      fontWeight: "bold",
      height: "0",
      overflow: "hidden",
      visibility: "hidden",
    },

    "&:active": {
      "& span": {
        position: "relative",
        top: "0px",
        left: "0px",
      },
    },
    "&:focus": {
      "& span": {
        position: "relative",
        top: "0px",
        left: "0px",
      },
    },

    "&:hover": {
      cursor: ({ loading }) => (loading ? "default" : "pointer"),
      boxShadow: "unset",
      backgroundColor: "transparent",
      opacity: 1,
      "& span": {
        color: theme.palette.common.white,
      },
    },
  },
  underlined: {
    padding: theme.spacing(4, 0),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  loader: {
    marginLeft: theme.spacing(2.5),
    color: "inherit",
  },
  arrow: {
    transition: "transform ease-in .25s",
    transform: ({ open }) => (open ? "rotate(180deg)" : "none"),
    marginLeft: theme.spacing(2.5),
    "& line": {
      stroke: ({ variant }) =>
        variant === ToggleButtonVariant.Transparent ? theme.palette.common.white : theme.palette.text.primary,
    },
  },
}));

const ToggleButton = forwardRef<any, PropsWithChildren<ToggleButtonProps>>(
  ({ children, size, variant, fullWidth, open, onClick, disabled, loading, testId }, ref) => {
    const classes = useStyles({ open, fullWidth, variant, loading });

    const handleClick = useCallback(() => {
      if (!disabled && !loading && onClick) {
        onClick();
      }
    }, [loading, disabled, onClick]);

    const classNames = cn({
      [classes.root]: true,
      [classes.small]: size === ToggleButtonSize.Small,
      [classes.big]: size === ToggleButtonSize.Big,
      [classes.contained]: variant === ToggleButtonVariant.Contained,
      [classes.transparent]: variant === ToggleButtonVariant.Transparent,
      [classes.underlined]: variant === ToggleButtonVariant.Underlined,
    });

    return (
      <Button className={classNames} onClick={handleClick} ref={ref} disabled={disabled} data-testid="toggle-button">
        <span
          data-title={children as string}
          className={classes.text}
          data-testid={testId && `${testId}-toggle-button`}
        >
          {children}
        </span>
        {loading ? (
          <CircularProgress className={classes.loader} size={15} />
        ) : (
          <ArrowDropdown className={classes.arrow} data-testid="arrow-dropdown" />
        )}
      </Button>
    );
  }
);

ToggleButton.defaultProps = {
  size: ToggleButtonSize.Small,
  variant: ToggleButtonVariant.Contained,
  fullWidth: true,
  disabled: false,
};

ToggleButton.displayName = "ToggleButton";

export default memo(ToggleButton);
