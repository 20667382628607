class FileUtils {
  static toBase64String = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64String = (reader.result as string).split("base64,")[1];

        return resolve(base64String);
      };

      reader.onerror = (error) => reject(error);
    });
}

export default FileUtils;
