import { createAsyncThunk } from "@reduxjs/toolkit";
import { intersectionWith } from "lodash";
import { ThunkApiConfig } from "@shared/models/redux";
import { OutstandingTermStatus, Term, TermAcceptPeriod } from "@terms/models";
import { termsSelector } from "@terms/store/selectors";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

export const postAcceptedTerms = createAsyncThunk<void, void, ThunkApiConfig>(
  "terms/postAcceptedTerms",
  async (_, { extra, getState }) => {
    const { data: termsData, outstandingTerms } = termsSelector(getState());

    const acceptedTermsIds = outstandingTerms
      .filter((term) => term.status === OutstandingTermStatus.Accepted)
      .map((term) => term.id);

    const termsIdsToSend: Array<Term["id"]> = intersectionWith(
      termsData,
      acceptedTermsIds,
      (termData, termId) => termData.id === termId
    )
      .filter((term) => term.whenAccepted === TermAcceptPeriod.Once)
      .map((term) => term.id);

    if (!termsIdsToSend.length) return;

    try {
      await extra("/entitlements/v1/user/Terms", {
        method: "POST",
        body: termsIdsToSend,
      });
    } catch (error) {
      console.error(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const { isImpersonating } = impersonationStateSelector(getState());

      return !isImpersonating;
    },
  }
);
