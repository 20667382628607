import React, { FunctionComponent, memo, useCallback } from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/styles";
import { OutstandingTermStatus, Term, TermToDisplay } from "@terms/models";
import { Accordion, Button, RoundedIcon } from "@shared/components";
import { ReactComponent as CheckIcon } from "@assets/svg/check.svg";
import { ReactComponent as ExclamationIcon } from "@assets/svg/exclamation.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { useMobileLayout } from "@app/hooks";

interface Props {
  terms: Array<TermToDisplay>;
  onButtonClick(status: OutstandingTermStatus, termId: Term["id"]): void;
}

const useStyles = makeStyles((theme) => ({
  termTile: {
    background: theme.palette.common.white,
    padding: theme.spacing(4, 0),
  },
  buttonWrapper: {
    "& > button": {
      fontSize: 14,
      letterSpacing: 1.4,
      padding: theme.spacing(0, 7.5),
    },
  },
}));

const MultipleTermsContent: FunctionComponent<Props> = ({ terms, onButtonClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMobileLayout();

  const renderIcon = useCallback((status: OutstandingTermStatus) => {
    switch (status) {
      case OutstandingTermStatus.Accepted:
        return (
          <Tooltip title={OutstandingTermStatus.Accepted} placement="top-end">
            <RoundedIcon Icon={CheckIcon} variant={"info"} interactive={false} />
          </Tooltip>
        );
      case OutstandingTermStatus.Declined:
        return (
          <Tooltip title={OutstandingTermStatus.Declined} placement="top-end">
            <RoundedIcon Icon={CloseIcon} variant={"secondary"} interactive={false} />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title={OutstandingTermStatus.Pending} placement="top-end">
            <RoundedIcon Icon={ExclamationIcon} variant={"warning"} interactive={false} />
          </Tooltip>
        );
    }
  }, []);

  return (
    <Grid container spacing={isMobile ? 2 : 3}>
      {terms.map((term) => (
        <Grid item xs={12} key={term.id}>
          <div className={classes.termTile}>
            <Accordion
              title={
                <Box display="flex">
                  {renderIcon(term.status)}
                  <Typography variant="bodySubhead3" fontWeight={term.active ? 700 : 400} ml={3}>
                    {term.name}
                  </Typography>
                </Box>
              }
              content={
                <Box
                  display="flex"
                  flexDirection={"column"}
                  sx={{
                    [theme.breakpoints.up("lg")]: {
                      padding: theme.spacing(0, 9),
                    },
                  }}
                >
                  <Typography
                    variant="alert"
                    dangerouslySetInnerHTML={{
                      __html: term.text,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      [theme.breakpoints.down("sm")]: {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        pt: theme.spacing(5),
                        display: "inline-flex",
                        flexDirection: "row",
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column",
                          width: "auto",
                        },
                      }}
                    >
                      <Box data-testid="do-not-agree-button" className={classes.buttonWrapper}>
                        <Button
                          fullWidth={isMobile}
                          size="small"
                          variant="outlined"
                          shape="rounded"
                          onClick={() => onButtonClick(OutstandingTermStatus.Declined, term.id)}
                        >
                          I DISAGREE
                        </Button>
                      </Box>
                      <Box
                        data-testid="agree-button"
                        sx={{
                          pl: theme.spacing(7),
                          [theme.breakpoints.down("sm")]: {
                            pt: 5,
                            pl: 0,
                          },
                        }}
                        className={classes.buttonWrapper}
                      >
                        <Button
                          autoFocus
                          fullWidth={isMobile}
                          size="small"
                          variant="contained"
                          shape="rounded"
                          onClick={() => onButtonClick(OutstandingTermStatus.Accepted, term.id)}
                        >
                          I AGREE
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              }
              active={term.status === OutstandingTermStatus.Pending ? undefined : false}
            />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default memo<Props>(MultipleTermsContent);
