import { createAsyncThunk } from "@reduxjs/toolkit";
import { documentTypesSelector } from "@store/Entities/selectors";
import { ThunkApiConfig } from "@shared/models/redux";
import { DocumentType } from "../models";

export const fetchDocumentTypes = createAsyncThunk<DocumentType[], void, ThunkApiConfig>(
  "entities/fetchDocumentTypes",
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra<DocumentType[]>("/documents/v1/DocumentTypes", {
        queryParams: { sortColumn: "name" },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to load document types");
    }
  },
  {
    condition: (_, { getState }) => {
      const documentTypes = documentTypesSelector(getState());

      return !documentTypes.loading && !documentTypes.data;
    },
  }
);
