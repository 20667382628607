import { createSlice } from "@reduxjs/toolkit";
import { NotificationState } from "@store/Notification/models";
import { closeNotification } from "@store/Notification/actions";
import { openNotificationThunk } from "@store/Notification/thunks";

const initialState: NotificationState = {
  open: false,
  text: "",
  variant: "info",
  exclamation: "",
};

const exclamationMap: { [key in NotificationState["variant"]]: string } = {
  info: "Success!",
  warning: "Error!",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(openNotificationThunk.fulfilled, (state, action) => {
        state.open = true;
        state.text = action.payload.text;
        state.variant = action.payload.variant;
        state.exclamation = action.payload.exclamation || exclamationMap[action.payload.variant];
      })
      .addCase(closeNotification, (state) => {
        state.open = false;
      });
  },
});

export default notificationSlice.reducer;
