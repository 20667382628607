import { Nullable } from "@shared/models/general";
import { AppRoute } from "@app/models/AppRoute";
import { Currency } from "@shared/models/currency";

export interface PreferenceShortenObject<S extends UserPreferenceName> {
  setting: S;
  value: UserPreferencesState[S];
}

export interface PreferenceFullObject<S extends UserPreferenceName> extends PreferenceShortenObject<S> {
  id: number;
  userId: number;
}

// Such a variety in values is because someone sometime ago made a mistake
// and there are different values in Prod DB now =/
// Preferred approach: if ALL IAG selected => it should be null
export interface PreferenceDefaultInvestorGroupResponse {
  setting: UserPreferenceName.UserDefaultInvestorsGroup;
  value: UserPreferencesState[UserPreferenceName.UserDefaultInvestorsGroup] | "all" | "ALL";
}

export enum UserPreferenceName {
  DateFormat = "DateFormat",
  NumberFormat = "NumberFormat",
  UserDefaultInvestorsGroup = "UserDefaultInvestorsGroup",
  UserLandingPage = "UserLandingPage",
  AlpInvestDefaultCurrency = "AlpInvestDefaultCurrency",
}

export interface UserPreferencesState {
  [UserPreferenceName.DateFormat]: DateFormat;
  [UserPreferenceName.NumberFormat]: NumberFormat;
  [UserPreferenceName.UserDefaultInvestorsGroup]: Nullable<number>;
  [UserPreferenceName.UserLandingPage]: LandingPages;
  [UserPreferenceName.AlpInvestDefaultCurrency]: Nullable<Currency>;
}

export type UserPreferencesListResponse = Array<
  | PreferenceShortenObject<UserPreferenceName.DateFormat>
  | PreferenceShortenObject<UserPreferenceName.NumberFormat>
  | PreferenceDefaultInvestorGroupResponse
  | PreferenceShortenObject<UserPreferenceName.UserLandingPage>
>;

export enum DateFormat {
  MonthDayYearDash = "MMM-DD-YYYY",
  MonthDayYearSpace = "MMMM DD, YYYY",
  MonthDayYearSlash = "M/D/YYYY",
  DayMonthYearDot = "DD.M.YYYY",
  YearMonthDayDot = "YYYY.M.DD",
}

export enum NumberFormat {
  CommaDot = "1,234.56",
  DotComma = "1.234,56",
}

export type LandingPages =
  | AppRoute.Home
  | AppRoute.Documents
  | AppRoute.AccountSummaryCarlyle
  | AppRoute.CapitalAccountStatement
  | AppRoute.ScheduleOfInvestments
  | AppRoute.CashFlowActivity
  | AppRoute.DataRooms;
