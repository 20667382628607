import { createTheme, responsiveFontSizes, Theme } from "@mui/material";
import ValueSansRegularBinary from "@assets/fonts/ValueSans-Regular-Pro.otf";
import ValueSansMediumBinary from "@assets/fonts/ValueSans-Medium-Pro.otf";
import ValueSansBoldBinary from "@assets/fonts/ValueSans-Bold-Pro.otf";
import FortescueRegularBinary from "@assets/fonts/FortescueDisplay-Regular-Pro.otf";
import FortescueMediumBinary from "@assets/fonts/FortescueDisplay-SemiBold-Pro.otf";
import FortescueBoldBinary from "@assets/fonts/FortescueDisplay-Bold-Pro.otf";
import SegoeRegularBinary from "@assets/fonts/segoeui.ttf";
import SegoeMediumBinary from "@assets/fonts/SEGUISB.TTF";
import SegoeBoldBinary from "@assets/fonts/segoeuib.ttf";

let theme: Theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#093549",
    },
    secondary: {
      main: "#6C849C",
    },
    info: {
      main: "#65A3E0",
      light: "#D0E8F8",
    },
    warning: {
      main: "#AB8433",
    },
    text: {
      primary: "#093549",
      secondary: "#60A0DF",
    },
    accent: {
      main: "#F6E7C4",
      light: "#FCF6E7",
    },
    grey: {
      "50": "#FAFAFA",
      "100": "#FBFBFB",
      "200": "#F5F5F5",
      "300": "#F5F5F5",
      "400": "#DCDCDC",
      "500": "#CBD0D3",
      "600": "#B2B2B2",
      "800": "#9F9F9F",
      "900": "#767676",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: "Value Sans, sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 16,
    htmlFontSize: 16,
    body1: {
      fontSize: 20,
    },
    body2: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 20,
    },
    h1: {
      fontFamily: "Fortescue, serif",
      fontSize: "5rem",
      letterSpacing: 1.2,
    },
    h2: {
      fontFamily: "Fortescue, serif",
      fontSize: "3.75rem",
      letterSpacing: 0.9,
    },
    h3: {
      fontFamily: "Fortescue, serif",
      fontSize: "3.125rem",
      letterSpacing: 0.75,
    },
    h4: {
      fontFamily: "Fortescue, serif",
      fontSize: "2.19rem",
      letterSpacing: 0.53,
    },
    h5: {
      fontFamily: "Fortescue, serif",
      fontSize: "1.75rem",
      letterSpacing: 0.42,
    },
    h6: {
      fontFamily: "Fortescue, serif",
      fontSize: "1.5rem",
      letterSpacing: 0.36,
    },
    subhead1: {
      letterSpacing: 0.39,
      fontWeight: 700,
      fontSize: "1.625rem",
    },
    subhead2: {
      letterSpacing: 0.36,
      fontWeight: 700,
      fontSize: "1.5rem",
    },
    subhead3: {
      letterSpacing: 0.27,
      fontWeight: 700,
      fontSize: "1.125rem",
    },
    subhead4: {
      letterSpacing: 0.24,
      fontWeight: 700,
      fontSize: "1rem",
    },
    subhead5: {
      letterSpacing: 0.21,
      fontWeight: 700,
      fontSize: "0.875rem",
    },
    subhead6: {
      letterSpacing: 0.15,
      fontWeight: 700,
      fontSize: "0.625rem",
    },
    bodySubhead1: {
      fontSize: "1.5rem",
      letterSpacing: 0.48,
      fontWeight: 500,
    },
    bodySubhead2: {
      fontSize: "1.375rem",
      letterSpacing: 0.44,
      fontWeight: 500,
    },
    bodySubhead3: {
      fontSize: "1rem",
      letterSpacing: 0.32,
      fontWeight: 500,
    },
    alert: {
      fontSize: "0.9375rem",
      letterSpacing: 0.3,
    },
    footnote: {
      fontSize: "0.6875rem",
      letterSpacing: 0.22,
    },
  },
  transitions: {
    duration: {
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: Value Sans;
          font-display: swap;
          font-style: normal;
          font-weight: 400;
          src: local('Value Sans'), local('ValueSans-Regular'), url(${ValueSansRegularBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Value Sans;
          font-display: swap;
          font-style: normal;
          font-weight: 500;
          src: local('Value Sans Medium'), local('ValueSans-Medium'), url(${ValueSansMediumBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Value Sans;
          font-display: swap;
          font-style: normal;
          font-weight: 700;
          src: local('Value Sans Bold'), local('ValueSans-Bold'), url(${ValueSansBoldBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Fortescue;
          font-display: swap;
          font-style: normal;
          font-weight: 400;
          src: local('Fortescue'), local('Fortescue-Regular'), url(${FortescueRegularBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Fortescue;
          font-display: swap;
          font-style: normal;
          font-weight: 500;
          src: local('Fortescue Medium'), local('Fortescue-Medium'), url(${FortescueMediumBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Fortescue;
          font-display: swap;
          font-style: normal;
          font-weight: 700;
          src: local('Fortescue Bold'), local('Fortescue-Bold'), url(${FortescueBoldBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Segoe;
          font-display: swap;
          font-style: normal;
          font-weight: 400;
          src: local('Segoe'), local('Segoe-Regular'), url(${SegoeRegularBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Segoe;
          font-display: swap;
          font-style: normal;
          font-weight: 500;
          src: local('Segoe Medium'), local('Segoe-Medium'), url(${SegoeMediumBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        @font-face {
          font-family: Segoe;
          font-display: swap;
          font-style: normal;
          font-weight: 700;
          src: local('Segoe Bold'), local('Segoe-Bold'), url(${SegoeBoldBinary}) format('opentype');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        
        html {
          overflow: hidden scroll;
        }
        
        body {
          font-family: Value Sans, sans-serif;
          background-color: #F5F5F5;
          font-weight: 400;
        }
        
        h1, h2, h3, h4, h5, h6, p {
          padding: 0;
          margin: 0;
        }
        
        h1, h2, h3, h4, h5, h6 {
          font-family: Fortescue, serif;
        }
      `,
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          },
          "&:focus:not(.Mui-disabled):before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          },
          "&:after": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollableX: {
          overflow: "unset",
          overflowX: "unset",
        },
        root: {
          overflow: "unset",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#707070",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        docked: {
          height: "100%",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthMd: {
          maxWidth: 785,
        },
        paperWidthLg: {
          maxWidth: 1005,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(4px)",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:hover": {
            color: theme.palette.info.main,
          },
        }),
      },
    },
  },
});

theme = responsiveFontSizes(theme, { factor: 1.2, breakpoints: ["xs", "sm", "md", "lg", "xl"] });
export default theme;
