import { Nullable } from "@shared/models/general";
import { ListOptionBase } from "@shared/components";

export enum TimeRangeIDs {
  ALL = "ALL",
  LAST30 = "LAST30",
  LAST90 = "LAST90",
  YEARBEGINNING = "YEARBEGINNING",
  EXACTRANGE = "EXACTRANGE",
}

export enum DocumentStatusId {
  All = "All",
  Read = "Read",
  Unread = "Unread",
  ActionItems = "ActionItems",
  UnreadActionItems = "UnreadActionItems",
}

export enum DocumentStatus {
  Pending = "Pending", // P
  Approved = "Approved", // V
  Active = "Active", // A
  Suspended = "Suspended", // S
  Deleted = "Deleted", // D
  PendingApproval = "Pending Approval", // W
}

export interface TimeRangeFilterOptions {
  start: Nullable<Date>;
  end: Nullable<Date>;
  timePeriod: ListOptionBase;
}
export interface TimeRangeFilterOptions2 {
  start2: Nullable<Date>;
  end2: Nullable<Date>;
  timePeriod2: ListOptionBase;
}
