/**
 * Helper function to display a negative number with parentheses.
 *
 * @param {string} str
 * @return {string}
 */
export function parensIfNeg(str) {
  return str.includes("-") ? `(${str.replace("-", "")})` : str;
}

/**
 * Helper function to format numbers.
 *
 * @param {number} number
 * @param {string} format
 * @param {int} decimalPlaces
 * @return {string}
 */
export function formatNumber(number = null, format = "", decimalPlaces = null) {
  const locale = format === "1.234,56" ? "de-DE" : "en-US";
  const options = {};
  if (Number.isInteger(decimalPlaces)) {
    options.minimumFractionDigits = decimalPlaces;
    options.maximumFractionDigits = decimalPlaces;
  }
  let formattedNumber = new Intl.NumberFormat(locale, options).format(number);
  if (number < 0) {
    formattedNumber = parensIfNeg(formattedNumber);
  }

  if (formattedNumber === "-0") {
    return "0";
  }

  return formattedNumber;
}

/**
 * Helper function to get currency symbol.
 *
 * @param {string} currencyISO3
 * @return {string}
 */
export function getCurrencySymbol(currencyISO3) {
  return (
    currencyISO3 &&
    Number()
      .toLocaleString(undefined, { style: "currency", currency: currencyISO3 })
      .slice(0, 1)
  );
}
