import React, { FunctionComponent, memo } from "react";
import { Box, darken } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { ChipProps } from "@shared/components/Chip/models";
import { ReactComponent as TimesIcon } from "@assets/svg/times.svg";

const useStyles = makeStyles<Theme, Pick<ChipProps, "fullWidth">>((theme) => ({
  chip: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : "auto"),
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: 0,
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(2, 1.5),
  },
  chipText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
  },
  deleteIcon: {
    cursor: "pointer",
    color: theme.palette.warning.main,
    transition: "all ease-in .2s",
    "&:hover": {
      color: darken(theme.palette.warning.main, 0.25),
    },
  },
  icon: {
    width: "10px",
    height: "10px",
    marginRight: theme.spacing(1.5),
  },
}));

const Chip: FunctionComponent<ChipProps> = ({ Icon, label, fullWidth, onDelete }) => {
  const classes = useStyles({ fullWidth });
  const testIdFromLabel = label.toLowerCase().replaceAll(" ", "-");

  return (
    <Box className={classes.chip}>
      {Icon && <Icon className={classes.icon} data-testid={`chip-icon-${testIdFromLabel}`} />}
      <Box className={classes.chipText} title={label} data-testid={`chip-label-${testIdFromLabel}`}>
        {label}
      </Box>
      {onDelete && (
        <Box width="10px" ml={1.5}>
          <TimesIcon className={classes.deleteIcon} onClick={onDelete} data-testid={`chip-delete-${testIdFromLabel}`} />
        </Box>
      )}
    </Box>
  );
};

export default memo<ChipProps>(Chip);
