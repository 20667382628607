import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { InvestorRequestState } from "@investorRequest/models";
import { MultiselectOption } from "@shared/components";
import CommonUtils from "@shared/utils/CommonUtils";
import { entitledAccountsSelector, entitledFundsSelector } from "@store/Entities/selectors";

export const investorRequestRootSelector = (state: RootState): InvestorRequestState => state.investorRequest;

const selectedAccountsSelector = (state: RootState): InvestorRequestState["commonFields"]["selectedAccounts"] =>
  state.investorRequest.commonFields.selectedAccounts;

const selectedFundsSelector = (state: RootState): InvestorRequestState["commonFields"]["selectedFunds"] =>
  state.investorRequest.commonFields.selectedFunds;

const accountsSelector = createSelector(
  entitledAccountsSelector,
  selectedAccountsSelector,
  (accountsSlice, selectedAccounts) => {
    const selectedAccountsMap = CommonUtils.convertArrayToMap(selectedAccounts);

    return CommonUtils.convertMapToArray(
      accountsSlice.data,
      (account): MultiselectOption => ({
        id: account.globalId,
        label: account.name,
        selected: selectedAccountsMap[account.globalId],
      })
    );
  }
);

const fundsSelector = createSelector(entitledFundsSelector, selectedFundsSelector, (fundsSlice, selectedFunds) => {
  const selectedFundsMap = CommonUtils.convertArrayToMap(selectedFunds);

  return CommonUtils.convertMapToArray(fundsSlice.data, (fund) => ({
    id: fund.globalId,
    label: fund.entityName,
    selected: selectedFundsMap[fund.globalId],
  }));
});

export const investorRequestSelector = createSelector(
  investorRequestRootSelector,
  entitledAccountsSelector,
  entitledFundsSelector,
  (investorRequest, accountsSlice, fundsSlice) => ({
    ...investorRequest,
    loading: investorRequest.loading || accountsSlice.loading || fundsSlice.loading,
  })
);

export const investorRequestPreparedDataSelector = createSelector(
  investorRequestRootSelector,
  accountsSelector,
  fundsSelector,
  ({ commonFields, customFields }, accounts, funds) => ({
    customFields,
    accounts,
    funds,
    message: commonFields.message,
  })
);
