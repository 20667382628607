import { createAsyncThunk } from "@reduxjs/toolkit";
import { callDocumentReadStatusAPI } from "@documents/store/thunks/callDocumentReadStatusAPI";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

export const updateEventDocumentsReadFlag = createAsyncThunk<void, { documentIds: string[] }, ThunkApiConfig>(
  "myCalendar/updateEventDocumentsReadFlag",
  async ({ documentIds }, { dispatch, getState }) => {
    try {
      const { isImpersonating } = impersonationStateSelector(getState());

      if (!isImpersonating) {
        await dispatch(callDocumentReadStatusAPI(documentIds));
      }
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "There was a problem updating the document status. Please try again.",
        })
      );
    }
  }
);
