export enum HelpAnchor {
  Home = "#home",
  Accounts = "#accounts",
  DataRooms = "#datarooms",
  Reports = "#reports",
  Documents = "#documents",
  Funds = "#funds",
  Login = "#login",
  MFA = "#mfa",
  GlobalInsights = "#globalinsights",
  Other = "#other",
}
