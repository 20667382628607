import { ListItemSize, ListOptionBase } from "@shared/components/List/models";

export const listOptionHeightMap: Record<ListItemSize, number> = {
  small: 36,
  medium: 46,
  large: 56,
};

export const noOptions: ListOptionBase = {
  id: "NO_OPTIONS",
  label: "No options",
  disabled: true,
};
