import React, { FunctionComponent, memo, useRef, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Nullable } from "@shared/models/general";

interface DatepickerProps {
  value: string;
  onChange(value: Nullable<Date>): void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 55,
    padding: theme.spacing(0, 5),
    backgroundColor: theme.palette.common.white,
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    display: "flex",
    alignItems: "center",
  },
}));

const TextField = ({ inputProps, value, label, id, setOpen }: any) => {
  const classes = useStyles();

  return (
    <Box
      id={id}
      className={classes.root}
      data-testid="datepicker-toggle-button"
      ref={inputProps!.ref}
      onClick={() => setOpen((open: boolean) => !open)}
    >
      {value || label}
    </Box>
  );
};

const InvestorRequestDatepicker: FunctionComponent<DatepickerProps> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      open={open}
      inputRef={inputRef}
      value={value ? new Date(value) : null}
      orientation="landscape"
      format="MMMM d, yyyy"
      label="Date Instructions Become Effective"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onChange={onChange}
      slots={{ textField: TextField }}
      slotProps={{
        textField: { setOpen } as any,
        popper: { anchorEl: inputRef.current, placement: "bottom" },
      }}
    />
  );
};

export default memo<DatepickerProps>(InvestorRequestDatepicker);
