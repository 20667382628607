import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { FileResponse } from "@shared/models/request";
import { timeRangeConverter } from "@documents/utils";

export const generateTermsAcceptanceReportThunk = createAsyncThunk<void, void, ThunkApiConfig>(
  "administrationReports/generateTermsAcceptanceReportThunk",
  async (_, { dispatch, rejectWithValue, getState, extra }) => {
    const {
      administrationReports: { filters },
    } = getState();

    const { start, end } = timeRangeConverter(filters.timeRange);

    const body = {
      productIds: filters.selectedFunds,
      startDate: start,
      endDate: end,
      reportFormat: "excel",
      documentTypeIds: filters.documentTypes,
      userEmail: filters.userEmail.length ? filters.userEmail : undefined,
    };

    try {
      const response = await extra<FileResponse>("/entitlements/v1/user/Terms/TermAcceptanceReport", {
        method: "post",
        responseType: "blob",
        body,
      });

      if (response.status == 204) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: "Report has no data for the parameters given",
          })
        );

        return;
      }

      const today = new Date();
      const fileName = `TermAcceptanceReport${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}.xlsx`;

      if (response.data.blob?.size) {
        saveAs(response.data.blob, fileName);

        dispatch(
          openNotificationThunk({
            variant: "info",
            text: "Report generation successful.",
          })
        );
      }
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Report download failed.",
        })
      );

      return rejectWithValue("The document download failed");
    }
  }
);
