import React, { FunctionComponent, memo, useMemo } from "react";
import { Box, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link, matchPath, useHistory } from "react-router-dom";
import { HEADER_BAR_HEIGHT } from "@app/constants/header";
import { Container } from "@shared/components";
import logo from "@assets/images/logo_white.png";
import IAGroupDropdown from "@app/components/IAGroupDropdown";
import { AppRoute } from "@app/models/AppRoute";
import AppToolbarIcons from "@app/components/AppToolbarIcons";

interface Props {
  isTransparent?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    position: "relative",
    zIndex: theme.zIndex.appBar,
    height: HEADER_BAR_HEIGHT,
    background: ({ isTransparent }) => (isTransparent ? "transparent" : theme.palette.primary.main),
    padding: theme.spacing(15, 0, 5),
  },
}));

const AppHeaderBar: FunctionComponent<Props> = ({ isTransparent }) => {
  const classes = useStyles({ isTransparent });
  const history = useHistory();

  const isAdministrationPage = useMemo(
    () =>
      !!matchPath(history.location.pathname, {
        path: AppRoute.AdministrationBase,
      }),
    [history.location.pathname]
  );

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
          <Grid item>
            <Link to={AppRoute.Home}>
              <img src={logo} alt="Carlyle" width={216} />
            </Link>
          </Grid>
          <Grid item container spacing={6} alignItems="center" justifyContent="flex-end">
            <Grid item>{!isAdministrationPage && <IAGroupDropdown />}</Grid>
            <AppToolbarIcons />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default memo<Props>(AppHeaderBar);
