import { createSelector } from "@reduxjs/toolkit";
import { memoize, orderBy, isEmpty } from "lodash";
import { RootState } from "@store/index";
import { QuarterLabel, QuarterValue } from "@shared/models/date";
import { Currency, CurrencySymbol } from "@shared/models/currency";
import { SelectedOption, Year, MultiselectOption } from "@shared/components";
import NumberUtils from "@shared/utils/NumberUtils";
import { ReportSections, ReportSubSections } from "@capitalAccountStatement/constants/reportSections";
import {
  FieldKeys,
  templateMain,
  templateRemainingCommitment,
  templateTotalContributionsOutsideOfCommitments,
} from "@capitalAccountStatement/constants/dataGridDataTemplates";
import { Nullable } from "@shared/models/general";
import { AsyncSlice } from "@shared/models/redux";
import { Investors } from "@store/Entities/models";
import { ReportingPeriodTypes, SpecialAccountKeys } from "@shared/models/reporting";
import { NumberFormat } from "@user/models";
import { selectedIAGroupIdSelector } from "@app/store/selectors";
import { fundsSelector } from "@store/Entities/selectors";
import {
  CapitalAccountStatement,
  CapitalAccountStatementDataRow,
  CapitalAccountStatementDataSet,
  CapitalAccountStatementDataTemplate,
  CapitalAccountStatementFilters,
  CapitalAccountStatementReports,
  CASFundOption,
} from "../models";

const createGridDataForSubSection = (
  template: CapitalAccountStatementDataTemplate[],
  reportData: CapitalAccountStatementDataSet,
  investorAccounts: Nullable<string[]>,
  section: ReportSections,
  fundCurrency: Currency
): CapitalAccountStatementDataRow[] => {
  const rows: CapitalAccountStatementDataRow[] = [];

  template.forEach((reportField: CapitalAccountStatementDataTemplate) => {
    //Create the grid rows here
    let amtYTD = 0;
    let amtQTD = 0;
    let amtITD = 0;

    if (reportData[reportField.fieldId]) {
      Object.keys(reportData[reportField.fieldId].investorAccounts).forEach((account: string) => {
        if (
          section === ReportSections.YourInterest &&
          (account === SpecialAccountKeys.CarlyleGroupAccount ||
            account === SpecialAccountKeys.TotalFundAccount ||
            (investorAccounts !== null && !investorAccounts.includes(account)))
        )
          return;

        if (section === ReportSections.CarlyleGroup && account !== SpecialAccountKeys.CarlyleGroupAccount) return;

        if (section === ReportSections.TotalFund && account !== SpecialAccountKeys.TotalFundAccount) return;

        amtYTD += reportData[reportField.fieldId].investorAccounts[account][ReportingPeriodTypes.YTD];
        amtQTD += reportData[reportField.fieldId].investorAccounts[account][ReportingPeriodTypes.QTD];
        amtITD += reportData[reportField.fieldId].investorAccounts[account][ReportingPeriodTypes.ITD];
      });
    }
    const row: CapitalAccountStatementDataRow = {
      rowMeta: reportField.type ? { currency: fundCurrency, type: reportField.type } : { currency: fundCurrency },
      fieldId: reportField.fieldId,
      fieldDescriptor:
        reportField.fieldDescriptor +
        (reportField.fieldId === FieldKeys.ChangeInAccruedCarriedInterest && section === ReportSections.YourInterest
          ? "*"
          : ""), // Add footnote designation for Your Interest Tab only for the Change In Accrued Carried interest field
      amountQTD: amtQTD,
      amountYTD: amtYTD,
      amountITD: amtITD,
      amtQTDValue: amtQTD,
      amtYTDValue: amtYTD,
      amtITDValue: amtITD,
    };

    rows.push(row);
  });

  return rows;
};

const calculateCurrency = (section: ReportSections, currentReports: CapitalAccountStatementReports): Currency => {
  switch (section) {
    case ReportSections.YourInterest:
      return currentReports.fundCurrency;
    case ReportSections.CarlyleGroup:
      return currentReports.carlyleGroupCurrency;
    case ReportSections.TotalFund:
      return currentReports.totalFundCurrency;
  }
};

const calculateCurrencySymbol = (section: ReportSections, currentReports: CapitalAccountStatementReports): string => {
  switch (section) {
    case ReportSections.YourInterest:
      return CurrencySymbol[currentReports.fundCurrency];
    case ReportSections.CarlyleGroup:
      return CurrencySymbol[currentReports.carlyleGroupCurrency];
    case ReportSections.TotalFund:
      return CurrencySymbol[currentReports.totalFundCurrency];
    default:
      return "";
  }
};

const calculatePercentageOfFund = (
  section: ReportSections,
  currentReports: CapitalAccountStatementReports,
  commitmentsValue: number,
  commitmentForTotalFundValue: number
): number => {
  const exchangeRateDivisor =
    section === ReportSections.YourInterest && currentReports.fundCurrency !== currentReports.totalFundCurrency
      ? currentReports.exchangeRateToUSD
      : 1;

  return commitmentForTotalFundValue > 0
    ? ((commitmentsValue / commitmentForTotalFundValue) * 100) / exchangeRateDivisor
    : 0;
};

export const capitalAccountStatementReportSelector = (state: RootState): CapitalAccountStatement["report"] =>
  state.capitalAccountStatement.report;

export const capitalAccountStatementFilterOptionsSelector = (
  state: RootState
): CapitalAccountStatement["filterOptions"] => state.capitalAccountStatement.filterOptions;

export const capitalAccountStatementFiltersSelector = (state: RootState): CapitalAccountStatement["filters"] =>
  state.capitalAccountStatement.filters;

export const capitalAccountStatementAccountFiltersSelector = (
  state: RootState
): CapitalAccountStatement["filters"]["accounts"] => state.capitalAccountStatement.filters.accounts;

const reportDataSelector = (state: RootState): CapitalAccountStatement["report"]["data"] =>
  state.capitalAccountStatement.report.data;

export const capitalAccountStatementReportExportLoadingSelector = (
  state: RootState
): CapitalAccountStatement["isReportDownloading"] => state.capitalAccountStatement.isReportDownloading;

export const filteredAccountsSelector = createSelector(capitalAccountStatementAccountFiltersSelector, (accounts) => {
  return accounts === null ? [] : Object.keys(accounts).filter((account) => accounts[account]);
});

const getTemplate = (subSection: ReportSubSections): CapitalAccountStatementDataTemplate[] | never => {
  switch (subSection) {
    case ReportSubSections.Main:
      return templateMain;
    case ReportSubSections.RemainingCommitment:
      return templateRemainingCommitment;
    case ReportSubSections.TotalContributionsOutsideOfCommitment:
      return templateTotalContributionsOutsideOfCommitments;
    default:
      throw Error(`There is no template "${subSection}"`);
  }
};

const buildInvestorAccountOptions = (
  investors: AsyncSlice<Investors>,
  filters: CapitalAccountStatementFilters
): MultiselectOption[] => {
  if (investors.data === null) {
    return [];
  }

  const options = Object.values(investors.data).reduce((result: MultiselectOption[], investor) => {
    if (filters?.accounts?.[investor.investorId] === undefined) {
      return result;
    }

    return [
      ...result,
      {
        id: investor.investorId,
        label: investor.investorName,
        selected: filters.accounts[investor.investorId],
      },
    ];
  }, []);

  return orderBy(options, [({ label }) => label.toLowerCase()]);
};

export const investorAccountsOptionsSelector = createSelector(capitalAccountStatementFiltersSelector, (filters) => {
  return memoize((investors: AsyncSlice<Investors>): MultiselectOption[] =>
    buildInvestorAccountOptions(investors, filters)
  );
});

export const gridSelector = createSelector(
  reportDataSelector,
  capitalAccountStatementFiltersSelector,
  (report, filters) => {
    return memoize(
      (
        section: ReportSections,
        subSection: ReportSubSections,
        investors: AsyncSlice<Investors>
      ): CapitalAccountStatementDataRow[] => {
        if (!report || !report.originalData) {
          return [];
        }

        const accountOptions = buildInvestorAccountOptions(investors, filters);
        const selectedOptions = accountOptions.filter(({ selected }) => selected).map(({ id }) => id.toString());

        const effectiveAccounts = selectedOptions.length
          ? selectedOptions
          : accountOptions.map(({ id }) => id.toString());

        return createGridDataForSubSection(
          getTemplate(subSection),
          report.originalData,
          effectiveAccounts,
          section,
          calculateCurrency(section, report)
        );
      },
      (...args) => JSON.stringify(args)
    );
  }
);

export const statisticSelector = createSelector(reportDataSelector, (report) => {
  return memoize(
    (section: ReportSections, gridRows: CapitalAccountStatementDataRow[], numberFormat: NumberFormat) => {
      if (report === null) {
        return {
          commitments: "",
          totalFundSize: "",
          percentageOfFund: "",
        };
      }

      const currencySymbol = calculateCurrencySymbol(section, report);
      const totalFundCurrencySymbol = CurrencySymbol[report.totalFundCurrency];

      const [{ amtITDValue: commitment = 0 } = {}] = gridRows.filter(
        (row: CapitalAccountStatementDataRow) => row.fieldId === FieldKeys.Commitment
      );

      const totalFundCommitment = report.totalFundAmount;
      const fundPercentage = calculatePercentageOfFund(section, report, commitment, totalFundCommitment);

      const commitments = `${currencySymbol}${NumberUtils.formatNumber(commitment, numberFormat, 0)}`;

      const totalFundSize = `${totalFundCurrencySymbol}${NumberUtils.formatNumber(
        totalFundCommitment,
        numberFormat,
        0
      )}`;

      const percentageOfFund = `${fundPercentage.toFixed(2)}%`;

      return {
        commitments,
        totalFundSize,
        percentageOfFund,
      };
    },
    (...args) => JSON.stringify(args)
  );
});

export const capitalAccountStatementDateRangeSelector = createSelector(
  capitalAccountStatementFilterOptionsSelector,
  (filterOptions): Year[] | undefined => {
    if (!filterOptions?.data?.periods) {
      return;
    }

    const years = Array.from(new Set(filterOptions.data.periods.map(({ year }) => year)));

    return years
      .sort()
      .reverse()
      .map((year, index) => {
        const periods =
          filterOptions?.data?.periods.filter((period) => {
            return period.year === year && period.associatedFunds !== null;
          }) ?? [];

        const quarters = periods
          .map(({ quarter }) => {
            const id = Number(quarter);
            const label = QuarterLabel[QuarterValue[id] as keyof typeof QuarterLabel];

            return { id, label };
          })
          .reverse();

        return {
          id: index + 1,
          label: year,
          quarters,
        };
      });
  }
);

export const capitalAccountStatementFundsFilter = createSelector(
  capitalAccountStatementFilterOptionsSelector,
  capitalAccountStatementFiltersSelector,
  (filterOptions, filters): CASFundOption[] | undefined => {
    const { year, quarter } = filters?.asOfDate ?? {};

    if (!year || !quarter) {
      return;
    }

    const fundListOptions: CASFundOption[] | undefined = filterOptions?.data?.periods
      ?.find((option) => {
        return option.year === year && option.quarter === quarter;
      })
      ?.associatedFunds.map((fund) => {
        return {
          id: fund.value,
          label: fund.label,
          key: `${fund.value}-${fund.currency}`,
        };
      });

    return fundListOptions ? orderBy(fundListOptions, [({ label }) => label.toLowerCase()]) : undefined;
  }
);

export const reportingCriteriaAsOfDateSelector = createSelector(
  capitalAccountStatementDateRangeSelector,
  capitalAccountStatementFiltersSelector,
  (dateRange, filters): Date | undefined => {
    if (!dateRange) {
      return;
    }

    const option = dateRange.find(({ label }) => label === filters?.asOfDate?.year);

    if (!option) {
      return;
    }

    const year = Number(option.label);
    const quarter = Number(filters?.asOfDate?.quarter);

    if (Number.isNaN(year) || Number.isNaN(quarter)) {
      return;
    }

    const quarterLabel = QuarterLabel[QuarterValue[quarter] as keyof typeof QuarterLabel];

    return new Date(`${year} ${quarterLabel}`);
  }
);

export const asOfDateFilterSelectedOption = createSelector(
  capitalAccountStatementDateRangeSelector,
  capitalAccountStatementFiltersSelector,
  (dateRange, filters): SelectedOption | undefined => {
    if (!dateRange) {
      return;
    }

    const option = dateRange.find(({ label }) => label === filters?.asOfDate?.year);

    if (!option) {
      return;
    }

    const quarter = filters?.asOfDate?.quarter;

    if (!quarter) {
      return;
    }

    return {
      year: Number(option.id),
      quarter: Number(quarter),
    };
  }
);

export const reportingCriteriaSelectedAccounts = createSelector(capitalAccountStatementFiltersSelector, (filters) => {
  return memoize((investors: AsyncSlice<Investors>): string[] => {
    if (filters.accounts === null) {
      return [];
    }

    const investorAccountOptions = buildInvestorAccountOptions(investors, filters);

    const selectedAccountIds = Object.keys(filters.accounts).filter((accountId) => filters.accounts?.[accountId]);

    const hasSelectedAccounts = selectedAccountIds.length && investors.data;
    const investorNames = hasSelectedAccounts
      ? selectedAccountIds.map((id) => investors.data?.[id]?.investorName ?? "")
      : investorAccountOptions.map((account) => account.label);

    return orderBy(investorNames);
  });
});

export const casDataLoadingSelector = createSelector(
  fundsSelector,
  capitalAccountStatementReportSelector,
  capitalAccountStatementFilterOptionsSelector,
  (funds, report, filterOptions) => {
    return memoize(
      (investors: AsyncSlice<Investors>): boolean =>
        funds.loading || report.loading || filterOptions.loading || investors.loading
    );
  }
);

export const casSelectors = createSelector(
  asOfDateFilterSelectedOption,
  capitalAccountStatementDateRangeSelector,
  capitalAccountStatementFilterOptionsSelector,
  capitalAccountStatementFiltersSelector,
  capitalAccountStatementFundsFilter,
  reportingCriteriaAsOfDateSelector,
  selectedIAGroupIdSelector,
  reportingCriteriaSelectedAccounts,
  investorAccountsOptionsSelector,
  casDataLoadingSelector,
  gridSelector,
  statisticSelector,
  (
    selectedRange,
    dateRange,
    reportFilters,
    filterValues,
    fundList,
    reportingCriteriaAsOfDate,
    accountGroup,
    selectReportingCriteriaAccounts,
    selectInvestorAccounts,
    selectLoadingFlag,
    selectGrid,
    selectStatistics
  ) => ({
    selectedRange,
    dateRange,
    reportFilters,
    filterValues,
    fundList,
    reportingCriteriaAsOfDate,
    accountGroup,
    selectReportingCriteriaAccounts,
    selectInvestorAccounts,
    selectLoadingFlag,
    selectGrid,
    selectStatistics,
  })
);

export const isCASReportDownloadableSelector = createSelector(
  capitalAccountStatementReportSelector,
  capitalAccountStatementFilterOptionsSelector,
  (report, filterOptions) => {
    const loading = report.loading || filterOptions.loading;
    const error = report.error || filterOptions.error;
    const hasData = !isEmpty(report.data);

    return !loading && !error && hasData;
  }
);
