import { getYearQuarterFromDate } from "@reporting/utils/index";
import { ReportingPeriod, ReportingPeriodFund, ReportingPeriodsResponse } from "@reporting/models";

export const normalizeReportingPeriodsResponse = (periodsResponse: ReportingPeriodsResponse[]): ReportingPeriod[] => {
  return periodsResponse
    .map((period) => {
      const { year, quarter } = getYearQuarterFromDate(period.date);

      const associatedFunds: ReportingPeriodFund[] = period.funds
        .map((fund) => ({
          label: fund.name,
          value: fund.id,
          currency: fund.currency,
        }))
        .sort((left, right) => (left.label.toUpperCase() > right.label.toUpperCase() ? 1 : -1));

      return { year, quarter, associatedFunds };
    })
    .sort((left, right) => (Number(left.year + left.quarter) > Number(right.year + right.quarter) ? 1 : -1));
};
