import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFSISArgs, HistoricMoic } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchHistoricMoic = createAsyncThunk<HistoricMoic, fetchFSISArgs, ThunkApiConfig>(
  "funds/fetchHistoricMoic",
  async ({ fundId, date }, { rejectWithValue, extra, signal }) => {
    try {
      const { status, data } = await extra<HistoricMoic>(
        `/reporting/v1/FPSIS/${encodeURIComponent(fundId)}/${encodeURIComponent(date.split("T")[0])}/moictotals`,
        { signal }
      );

      if (status === 404) {
        throw Error("MOIC not found");
      }

      return data;
    } catch (e) {
      return rejectWithValue("Failed to fetch historic MOIC");
    }
  }
);
