import { createAsyncThunk } from "@reduxjs/toolkit";
import { CanceledError } from "axios";
import { omit } from "lodash";
import { FetchDocumentResponse } from "@documents/models/api.models";
import { buildDocumentsRequestBody, getEntitiesListForDocumentsRequest } from "@documents/utils";
import { callDocumentsAPI } from "@documents/store/thunks/callDocumentsAPI";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ThunkApiConfig } from "@shared/models/redux";
import { LOAD_DOCUMENTS_ERROR_MESSAGE } from "@documents/constants";
import { selectedIAGroupIdSelector } from "@app/store/selectors";
import { documentsSliceSelector } from "@documents/store/selectors";

export const fetchDocuments = createAsyncThunk<FetchDocumentResponse, boolean | undefined, ThunkApiConfig>(
  "documents/fetchDocuments",
  async (_, { getState, dispatch, rejectWithValue, signal }) => {
    const rootState = getState();

    const {
      entities: { entitledAccounts, entitledFunds },
      impersonation: { canImpersonate: isAdmin },
      documents: {
        report: { pagination },
        filters,
        sort,
      },
    } = rootState;

    const selectedAccountGroup = selectedIAGroupIdSelector(rootState);

    const { investorAccountIds, fundIds } = getEntitiesListForDocumentsRequest({
      entitledAccounts,
      entitledFunds,
      isAnyIAGSelected: Boolean(selectedAccountGroup),
      selectedAccounts: filters.investorAccounts,
      selectedFunds: filters.funds,
    });

    const body = buildDocumentsRequestBody({
      isAdmin,
      pagination,
      sort,
      filters: {
        ...omit(filters, ["funds", "investorAccounts"]),
        investorAccountIds,
        fundIds,
      },
    });

    try {
      return await dispatch(callDocumentsAPI(body, { signal }));
    } catch (error) {
      if (!(error instanceof CanceledError)) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: LOAD_DOCUMENTS_ERROR_MESSAGE,
          })
        );
      }

      return rejectWithValue("Failed to fetch Documents");
    }
  },
  {
    condition: (refresh, { getState }) => {
      if (refresh) return true;

      const documents = documentsSliceSelector(getState());

      return !documents.loading && !documents.data;
    },
  }
);
