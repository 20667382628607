import { AppThunkAPI } from "@shared/models/redux";
import { ReportingEmbedUrlConfig } from "@reporting/models";

type CallEmbedUrlAPI = (report: string) => AppThunkAPI<Promise<ReportingEmbedUrlConfig>>;

export const callEmbedUrlAPI: CallEmbedUrlAPI = (report) => async (_dispatch, _getState, extra) => {
  const response = await extra<ReportingEmbedUrlConfig>(`/entitlements/v1/reports/${report}/EmbedUrl`);

  return response.data;
};
