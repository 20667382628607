import { RootState } from "@store/index";
import { AccountSummaryAlpInvestState } from "@accountSummaryAlpInvest/models";

export const accountSummaryAlpInvestEmbedUrlSelector = (state: RootState) =>
  state.accountSummaryAlpInvest.embedUrlConfig;

export const accountSummaryAlpInvestExportSelector = (state: RootState) => state.accountSummaryAlpInvest.export;

export const accountSummaryReportExportLoadingSelector = (
  state: RootState
): AccountSummaryAlpInvestState["isReportDownloading"] => state.accountSummaryAlpInvest.isReportDownloading;
