import { AppRoute } from "@app/models/AppRoute";
import { Permission } from "@user/models";
import { AccountSummaryReportName, ReportingNavigationSection, ReportName } from "@reporting/models";

export const getReportingNavigationLinks = (
  permissions: Record<Permission, boolean>
): Array<ReportingNavigationSection> => {
  const result: Array<ReportingNavigationSection> = [];

  const carlyleReportingOptions = [
    {
      id: AppRoute.AccountSummaryCarlyle,
      label: AccountSummaryReportName.Single,
      isActive: permissions.ViewAccountSummaryCarlyle,
    },
    {
      id: AppRoute.CapitalAccountStatement,
      label: ReportName.CapitalAccountStatement,
      isActive: permissions.ViewReportsCarlyle,
    },
    {
      id: AppRoute.ScheduleOfInvestments,
      label: ReportName.ScheduleOfInvestments,
      isActive: permissions.ViewReportsCarlyle,
    },
    {
      id: AppRoute.CashFlowActivity,
      label: ReportName.CashFlowActivity,
      isActive: permissions.ViewReportsCarlyle,
    },
  ];

  const alpInvestReportingOptions = [
    {
      id: AppRoute.AccountSummaryAlpInvest,
      label: AccountSummaryReportName.Single,
      isActive: permissions.ViewAccountSummaryAlpInvest,
    },
    {
      id: AppRoute.PortfolioPerformance,
      label: ReportName.PortfolioPerformance,
      isActive: permissions.ViewPortfolioPerformanceReport,
    },
    {
      id: AppRoute.DiversificationAnalysis,
      label: ReportName.DiversificationAnalysis,
      isActive: permissions.ViewDiversificationAnalysisReport,
    },
  ];

  if (carlyleReportingOptions.some((option) => option.isActive)) {
    result.push({
      id: "carlyle",
      label: "CARLYLE",
      options: carlyleReportingOptions,
    });
  }

  if (alpInvestReportingOptions.some((option) => option.isActive)) {
    result.push({
      id: "alpinvest",
      label: "ALPINVEST",
      options: alpInvestReportingOptions,
    });
  }

  return result;
};
