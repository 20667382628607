import React from "react";
import { Container as MuiContainer } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
  testId?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    maxWidth: `calc(1700px + ${theme.spacing(15)})`,
    padding: theme.spacing(0, 7.5),
    [theme.breakpoints.up("lg")]: {
      maxWidth: `calc(1700px + ${theme.spacing(50)})`,
      padding: theme.spacing(0, 25),
    },
  },
}));

const Container = ({ children, className, testId }: Props) => {
  const classes = useStyles();

  return (
    <MuiContainer className={classes.root} data-testid="container">
      {className ? (
        <div className={className} data-testid={testId ?? "container-with-classname"}>
          {children}
        </div>
      ) : (
        children
      )}
    </MuiContainer>
  );
};

export default Container;
