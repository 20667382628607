import { createSelector } from "@reduxjs/toolkit";
import { sortBy } from "lodash";
import { RootState } from "@store/index";
import {
  documentTypesSelector,
  entitledAccountsByFundLoadingSelector,
  entitledAccountsSelector,
  entitledFundsSelector,
} from "@store/Entities/selectors";
import { MultiselectOption, RowType } from "@shared/components";
import CommonUtils from "@shared/utils/CommonUtils";
import DateUtils from "@shared/utils/DateUtils";
import { DocumentsState } from "@documents/models";
import { DocumentStatus } from "@documents/models/filter.models";
import { preferencesSelector } from "@app/store/selectors";

const selectedDocumentTypesSelector = (state: RootState): DocumentsState["filters"]["documentTypes"] =>
  state.documents.filters.documentTypes;

export const selectedAccountsSelector = (state: RootState): DocumentsState["filters"]["investorAccounts"] =>
  state.documents.filters.investorAccounts;

const selectedFundsSelector = (state: RootState): DocumentsState["filters"]["funds"] => state.documents.filters.funds;

const selectedTimeRangeSelector = (state: RootState): DocumentsState["filters"]["timeRange"] =>
  state.documents.filters.timeRange;

const selectedStatusSelector = (state: RootState): DocumentsState["filters"]["status"] =>
  state.documents.filters.status;

const searchInputSelector = (state: RootState): DocumentsState["filters"]["fileName"] =>
  state.documents.filters.fileName;

export const selectDocumentsFilterValues = (state: RootState): DocumentsState["filters"] => state.documents.filters;

export const documentsSliceSelector = (state: RootState): DocumentsState["report"] => state.documents.report;

export const documentsSortSelector = (state: RootState): DocumentsState["sort"] => state.documents.sort;

export const actionRequiredItemsSelector = (state: RootState): DocumentsState["numberOfActionRequiredItems"] =>
  state.documents.numberOfActionRequiredItems;

export const documentsExportLoadingSelector = (state: RootState): DocumentsState["isDocumentExportLoading"] =>
  state.documents.isDocumentExportLoading;

const documentTypeOptionsSelector = createSelector(
  selectedDocumentTypesSelector,
  documentTypesSelector,
  (selectedDocumentTypes, documentTypesSlice): MultiselectOption[] => {
    const selectedDocumentTypesMap = CommonUtils.convertArrayToMap(selectedDocumentTypes);

    return CommonUtils.convertMapToArray(documentTypesSlice.data, (documentType) => ({
      id: documentType.documentTypeId,
      label: documentType.displayName ?? documentType.name,
      selected: selectedDocumentTypesMap[documentType.documentTypeId],
    })).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
  }
);

const accountOptionsSelector = createSelector(
  selectedAccountsSelector,
  selectedFundsSelector,
  entitledAccountsSelector,
  entitledFundsSelector,
  (selectedAccounts, selectedFunds, accountsSlice, fundsSlice): MultiselectOption[] => {
    const selectedAccountsMap = CommonUtils.convertArrayToMap(selectedAccounts);

    // if selectedFunds === null it means all funds are selected, thus display all accounts
    if (!selectedFunds) {
      return CommonUtils.convertMapToArray(accountsSlice.data, (entitledAccount) => ({
        id: entitledAccount.globalId,
        label: entitledAccount.name,
        selected: selectedAccountsMap[entitledAccount.globalId],
      }));
    }

    const accounts: MultiselectOption[] = [];

    // if some funds selected, need to get accounts related to those funds,
    // if some funds have same accounts, we dedup accounts using Set
    selectedFunds
      .reduce((acc, fundId) => {
        (fundsSlice.data![fundId]?.entitledAccountIds || []).forEach((accountId) => acc.add(accountId));

        return acc;
      }, new Set<string>())
      .forEach((accountId) => {
        accounts.push({
          id: accountsSlice.data![accountId].globalId,
          label: accountsSlice.data![accountId].name,
          selected: selectedAccountsMap[accountId],
        });
      });

    return sortBy(accounts, (account) => account.label.toLowerCase());
  }
);

const fundOptionsSelector = createSelector(
  selectedFundsSelector,
  entitledFundsSelector,
  (selectedFunds, fundsSlice): MultiselectOption[] => {
    const selectedFundsMap = CommonUtils.convertArrayToMap(selectedFunds);

    return CommonUtils.convertMapToArray(fundsSlice.data, (entitledFund) => ({
      id: entitledFund.globalId,
      label: entitledFund.entityName,
      selected: selectedFundsMap[entitledFund.globalId],
    }));
  }
);

const selectedFilterValuesSelector = createSelector(
  selectedDocumentTypesSelector,
  selectedFundsSelector,
  selectedAccountsSelector,
  (selectedDocumentTypes, selectedFunds, selectedAccounts) => ({
    selectedDocumentTypes,
    selectedFunds,
    selectedAccounts,
  })
);

export const documentsFiltersSelector = createSelector(
  documentTypeOptionsSelector,
  accountOptionsSelector,
  fundOptionsSelector,
  selectedTimeRangeSelector,
  selectedStatusSelector,
  selectedFilterValuesSelector,
  entitledAccountsByFundLoadingSelector,
  searchInputSelector,
  (
    documentTypeOptions,
    accountOptions,
    fundOptions,
    timeRange,
    status,
    selectedFilterValues,
    accountsLoading,
    searchInputValue
  ) => ({
    selectedFilterValues,
    documentTypeOptions,
    accountsLoading,
    accountOptions,
    fundOptions,
    timeRange,
    status,
    searchInputValue,
  })
);

export const documentsPageSelector = createSelector(
  documentTypesSelector,
  entitledFundsSelector,
  entitledAccountsSelector,
  documentsSliceSelector,
  entitledAccountsByFundLoadingSelector,
  documentsSortSelector,
  preferencesSelector,
  (
    documentTypesSlice,
    fundsSlice,
    accountsSlice,
    documentsSlice,
    entitledAccountsByFundLoading,
    sort,
    userPreferences
  ) => ({
    filtersLoading: documentTypesSlice.loading || fundsSlice.loading || accountsSlice.loading,
    documentsLoading: documentsSlice.loading || entitledAccountsByFundLoading,
    pagination: documentsSlice.pagination,
    dataRows: documentsSlice.data?.map((document) => ({
      ...document,
      publishDate:
        document.documentStatus === DocumentStatus.Active && document.publishDate
          ? DateUtils.formatDate(new Date(document.publishDate), userPreferences.DateFormat)
          : "N/A",
      rowMeta: {
        type:
          document.documentStatus === DocumentStatus.Pending ||
          document.documentStatus === DocumentStatus.PendingApproval
            ? RowType.Highlighted
            : RowType.Regular,
      },
    })),
    sort,
  })
);
