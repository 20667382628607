import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFSISArgs, FsisFlocReport } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchFsisFlocReports = createAsyncThunk<FsisFlocReport[], fetchFSISArgs, ThunkApiConfig>(
  "funds/fetchFsisFlocReports",
  async ({ fundId, date }, { rejectWithValue, extra, signal }) => {
    try {
      const { status, data } = await extra<FsisFlocReport[]>(
        `/reporting/v1/FPSIS/${encodeURIComponent(fundId)}/${encodeURIComponent(date.split("T")[0])}/adjustments`,
        { signal }
      );

      if (status === 404) {
        throw Error("FLOC reports not found");
      }

      return data;
    } catch (e) {
      return rejectWithValue("Failed to fetch FLOC reports");
    }
  }
);
