export enum SpecialAccountKeys {
  CarlyleGroupAccount = "-998",
  TotalFundAccount = "-999",
}

export enum ReportingPeriodTypes {
  QTD = "QTD",
  YTD = "YTD",
  ITD = "ITD",
}

export enum Quarters {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}
