import { createAsyncThunk } from "@reduxjs/toolkit";
import { ListOptionBase } from "@shared/components";
import { ThunkApiConfig } from "@shared/models/redux";
import { EntitledAccount } from "@store/Entities/models";
import { openNotificationThunk } from "@store/Notification/thunks";
import { reportMessages } from "@reporting/constants";

export const fetchFundInvestorAccounts = createAsyncThunk<ListOptionBase[], string, ThunkApiConfig>(
  "funds/fetchFundInvestorAccounts",
  async (fundId, { rejectWithValue, extra, dispatch }) => {
    try {
      const response = await extra<EntitledAccount[]>("/entitlements/v1/user/EntitledAccounts", {
        queryParams: { entitledEntityGlobalIds: fundId },
      });

      return response.data.map((ia) => ({ id: ia.globalId, label: ia.name }));
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: reportMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch Investor Accounts");
    }
  }
);
