import { CapitalAccountStatementDataTemplate } from "@capitalAccountStatement/models";
import { RowType } from "@shared/components";

// An enum for field keys. These are the only ones that are referenced outside of this template for display logic
export enum FieldKeys {
  Commitment = "commitmentBeginningRemainingCommitment",
  ChangeInAccruedCarriedInterest = "changeInAccruedCarriedInterest",
}

// The list of field keys and row names for the data in the "main" part of the CAS
export const templateMain: CapitalAccountStatementDataTemplate[] = [
  {
    fieldId: "capitalAccountBeginningBalanceAtFairValue",
    fieldDescriptor: "Capital Account - Beginning Balance, at Fair Value",
    type: RowType.SubHeader,
  },
  {
    fieldId: "contributionFundInvestments",
    fieldDescriptor: "Fund Investments",
  },
  {
    fieldId: "contributionManagementFees",
    fieldDescriptor: "Management Fees",
  },
  {
    fieldId: "contributionPartnershipExpenses",
    fieldDescriptor: "Partnership Expenses",
  },
  {
    fieldId: "contributionOrganizationalCosts",
    fieldDescriptor: "Organizational Costs",
  },
  {
    fieldId: "contributionUnallocatedCapital",
    fieldDescriptor: "Unallocated Capital",
  },
  {
    fieldId: "totalContributedCapital",
    fieldDescriptor: "Total Contributed Capital",
    type: RowType.SubHeader,
  },
  {
    fieldId: "accumulatedIncomeRealizedCapitalGain",
    fieldDescriptor: "Realized Capital Gain (Loss)",
  },
  {
    fieldId: "accumulatedIncomeDividendsOtherCurrentIncome",
    fieldDescriptor: "Dividends/Other Current Income (Loss)",
  },
  {
    fieldId: "accumulatedIncomeNonInvestmentIncome",
    fieldDescriptor: "Non-Investment Income (Loss)",
  },
  {
    fieldId: "accumulatedIncomeManagementFeeExpense",
    fieldDescriptor: "Management Fee Expense",
  },
  {
    fieldId: "accumulatedIncomeRealizedCarryPaidToGP",
    fieldDescriptor: "Realized Carry Paid to GP",
  },
  {
    fieldId: "totalAccumulatedIncome",
    fieldDescriptor: "Total Accumulated Income (Loss)",
    type: RowType.SubHeader,
  },
  {
    fieldId: "distributionsOfReturnOfCapital",
    fieldDescriptor: "Distributions of Return of Capital",
  },
  {
    fieldId: "distributionsOfRealizedCapitalGain",
    fieldDescriptor: "Distributions of Realized Capital (Gain) Loss",
  },
  {
    fieldId: "distributionsOfDividendsOtherCurrentIncome",
    fieldDescriptor: "Distributions of Dividends/Other Current (Income) Loss",
  },
  {
    fieldId: "distributionsOfNonInvestmentIncome",
    fieldDescriptor: "Distributions from Non-Investment Income",
  },
  {
    fieldId: "distributionsRealizedCarryPaidToGP",
    fieldDescriptor: "Realized Carry Paid to GP",
  },
  {
    fieldId: "totalDistributions",
    fieldDescriptor: "Total Distributions (Gross of Tax Withholding)",
    type: RowType.SubHeader,
  },
  {
    fieldId: "capitalAccountBeforeChangeInNetUnrealizedAppreciation",
    fieldDescriptor: "Capital Account, Before Change in Net Unrealized Appreciation",
  },
  {
    fieldId: "changeInUnrealizedAppreciation",
    fieldDescriptor: "Change in Unrealized Appreciation, Pre-Accrued Carried Interest",
  },
  {
    fieldId: "changeInAccruedCarriedInterest",
    fieldDescriptor: "Change in Accrued Carried Interest ",
  },
  {
    fieldId: "capitalAccountAtFairValue",
    fieldDescriptor: "Capital Account, at Fair Value",
    type: RowType.SubHeader,
  },
];

// The list of field keys and row names for the data in the "Remaining Commitments" part of the CAS
export const templateRemainingCommitment: CapitalAccountStatementDataTemplate[] = [
  {
    fieldId: FieldKeys.Commitment,
    fieldDescriptor: "Commitment / Beginning Remaining Commitment",
  },
  {
    fieldId: "contributionsForInvestments",
    fieldDescriptor: "Contributions for Investments",
  },
  {
    fieldId: "contributionsForUnallocatedCapital",
    fieldDescriptor: "Contributions for Unallocated Capital",
  },
  {
    fieldId: "recallableReturnOfCapitalDistributions",
    fieldDescriptor: "Recallable Return of Capital Distributions",
  },
  {
    fieldId: "contributionsForExpensesFees",
    fieldDescriptor: "Contribution for Expenses / Fees",
  },
  {
    fieldId: "recallableExpensesFees",
    fieldDescriptor: "Recallable Expenses / Fees",
  },
  {
    fieldId: "expiredCommitment",
    fieldDescriptor: "Expired Commitment",
  },
  {
    fieldId: "remainingCommitments",
    fieldDescriptor: "Remaining Commitment",
    type: RowType.SubHeader,
  },
];

// The list of field keys and row names for the data in the "Total Contributions Outside of Commitments" part of the CAS
export const templateTotalContributionsOutsideOfCommitments: CapitalAccountStatementDataTemplate[] = [
  {
    fieldId: "contributionsForInvestmentsOutsideCommitment",
    fieldDescriptor: "Contributions for Investments",
  },
  {
    fieldId: "contributionsForExpensesFeesOutsideCommitment",
    fieldDescriptor: "Contribution for Expenses / Fees",
  },
  {
    fieldId: "totalContributionsOutsideOfCommitment",
    fieldDescriptor: "Total Contribution Outside of Commitment",
    type: RowType.SubHeader,
  },
];
