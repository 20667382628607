import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsOfDate } from "@shared/models/filters";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";
import getLast3QuartersFunds from "@accountSummaryCarlyle/utils/getLast3QuartersFunds";
import {
  AccountSummaryAccountsFilter,
  AccountSummaryFilterOption,
  AccountSummaryFilterOptions,
  AccountSummaryFilterValues,
  AccountSummaryFundsFilter,
  AccountSummaryReport,
  AccountSummaryState,
  FundData,
} from "../models";
import { downloadAccountSummaryAsExcel, fetchAccountSummaryFilters, fetchAccountSummaryReport } from "./thunks";

const initialState: AccountSummaryState = {
  report: ReduxUtils.getAsyncSlice<AccountSummaryReport[]>(),
  filters: {
    funds: null,
    accounts: null,
    asOfDate: null,
  },
  fundData: ReduxUtils.getAsyncSlice<FundData[]>(),
  filterOptions: ReduxUtils.getAsyncSlice<AccountSummaryFilterOptions>(),
  isReportDownloading: false,
};

const accountSummarySlice = createSlice({
  name: "accountSummary",
  initialState,
  reducers: {
    resetState: (state) => {
      state.report = initialState.report;
      state.filters = initialState.filters;
      state.filterOptions = initialState.filterOptions;
    },
    changeAsOfDateFilter: (state, action: PayloadAction<Partial<AsOfDate>>) => {
      state.filters.asOfDate = {
        ...state.filters.asOfDate!,
        ...action.payload,
      };
    },
    updateFundFilter: (
      state,
      { payload: { id, value } }: PayloadAction<{ id: string; value: AccountSummaryFundsFilter }>
    ) => {
      if (state.filters.funds) {
        state.filters.funds[id] = value;
      }
    },
    resetFundFilter: (state) => {
      if (state.filters.funds) {
        for (const fundId in state.filters.funds) {
          state.filters.funds[fundId].selected = false;
        }
      }
    },
    toggleAccountFilter: (state) => {
      if (state.filters.accounts) {
        for (const id in state.filters.accounts) {
          state.filters.accounts[id] = false;
        }
      }
    },
    changeAccountFilter: (state, { payload }: PayloadAction<AccountSummaryAccountsFilter>) => {
      state.filters.accounts = payload;
    },
    updateAccountFilter: (state, { payload: { id, value } }: PayloadAction<{ id: string; value: boolean }>) => {
      if (state.filters.accounts) {
        state.filters.accounts[id] = value;
      }
    },
    resetAccountFilter: (state) => {
      state.filters.accounts = {}; // @todo may be null?
    },
    changeFilterValue: (state, action: PayloadAction<Partial<AccountSummaryFilterValues>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountSummaryFilters.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.filterOptions);
        ReduxUtils.defaultPendingActionHandler(state.fundData);
      })
      .addCase(fetchAccountSummaryFilters.fulfilled, (state, action) => {
        if (action.payload.filterOptions.length) {
          const latestRecord = action.payload.filterOptions[action.payload.filterOptions.length - 1];
          const associatedFunds = getLast3QuartersFunds(action.payload.filterOptions);

          state.filterOptions.loading = false;
          state.filterOptions.data = action.payload.filterOptions;
          state.filters.asOfDate = {
            year: latestRecord.year,
            quarter: latestRecord.quarter,
            current: true,
          };
          state.filters.funds = associatedFunds;
          state.fundData.data = action.payload.fundData;
          state.fundData.loading = false;
        } else {
          state.filterOptions.loading = false;
          state.filterOptions.data = [];
          state.fundData.data = [];
          state.fundData.loading = false;
          state.filters.asOfDate = null;
          state.filters.funds = null;
        }
      })
      .addCase(fetchAccountSummaryFilters.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(
          state.filterOptions,
          action.payload as string,
          [] as AccountSummaryFilterOption[]
        );
      })
      .addCase(fetchAccountSummaryReport.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.report);
      })
      .addCase(fetchAccountSummaryReport.fulfilled, (state, action) => {
        state.report.loading = false;
        state.report.data = action.payload.length ? action.payload : null;
      })
      .addCase(fetchAccountSummaryReport.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(state.report, action.payload as string, [] as AccountSummaryReport[]);
      })
      .addCase(downloadAccountSummaryAsExcel.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(downloadAccountSummaryAsExcel.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(downloadAccountSummaryAsExcel.rejected, (state) => {
        state.isReportDownloading = false;
      });
    builder.addCase(changeInvestorAccountsGroup.fulfilled, (state) => {
      state.filterOptions = initialState.filterOptions;
      state.report = initialState.report;
      state.filters = initialState.filters;
    });
  },
});

export const {
  resetState,
  changeFilterValue,
  changeAsOfDateFilter,
  updateFundFilter,
  resetFundFilter,
  toggleAccountFilter,
  changeAccountFilter,
  updateAccountFilter,
  resetAccountFilter,
} = accountSummarySlice.actions;

export default accountSummarySlice.reducer;
