import { saveAs } from "file-saver";
import { defaultTo, orderBy } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fundsSelector, investorsSelector } from "@store/Entities/selectors";
import { ListOptionBase } from "@shared/components";
import { CurrencyName } from "@shared/models/currency";
import contributions from "@cashFlowActivity/constants/contributions";
import distributions from "@cashFlowActivity/constants/distributions";
import DateUtils from "@shared/utils/DateUtils";
import { openNotificationThunk } from "@store/Notification/thunks";
import { reportMessages } from "@reporting/constants";
import { ThunkApiConfig } from "@shared/models/redux";
import { FileResponse } from "@shared/models/request";
import { preferencesSelector } from "@app/store/selectors";
import RequestUtils from "@shared/utils/RequestUtils";
import {
  CashFlowActivityDataRow,
  CashFlowActivityFilters,
  CashFlowActivityReport,
  CashFlowActivityReportRequestBody,
  CashFlowActivityState,
  CFAFilterResponse,
} from "../models";
import { CashflowActionNames, changeFilterValue } from "./actions";
import { filterOptionsSelector, filterSelector, filterValuesSelector, reportDataSelector } from "./selectors";

export const fetchCashFlowActivityFilters = createAsyncThunk<
  Record<string, { fundName: string; investors: ListOptionBase[] }>,
  void,
  ThunkApiConfig
>(
  "cashFlowActivity/fetchCashFlowActivityFilters",
  async (_, { rejectWithValue, extra, getState, signal }) => {
    try {
      const filters = await extra<CFAFilterResponse[]>("/reporting/v1/FundCashflows/filters", {
        signal,
      });

      const rootState = getState();
      const { data: investors } = investorsSelector(rootState);
      const { data: funds } = fundsSelector(rootState);

      const mapping = filters.data.reduce<Record<string, { fundName: string; investors: ListOptionBase[] }>>(
        (acc, curr) => {
          if (!funds?.[curr.fund] || !investors?.[curr.investor]) return acc;

          if (acc[curr.fund]) {
            acc[curr.fund].investors.push({
              id: curr.investor,
              label: investors[curr.investor].investorName,
            });
          } else {
            acc[curr.fund] = {
              fundName: funds[curr.fund].name,
              investors: [
                {
                  id: curr.investor,
                  label: investors[curr.investor].investorName,
                },
              ],
            };
          }

          return acc;
        },
        {}
      );

      return Object.fromEntries(
        orderBy<[string, { fundName: string; investors: ListOptionBase[] }]>(
          Object.entries(mapping).map(([id, data]) => [
            id,
            {
              ...data,
              investors: orderBy(data.investors, [(a) => a.label?.toLocaleLowerCase()]),
            },
          ]),
          ([, data]) => data.fundName.toLocaleLowerCase()
        )
      );
    } catch (e) {
      return rejectWithValue("Failed to fetch Cash Flow Activity Filters");
    }
  },
  {
    condition: (_, { getState }) => {
      const options = filterOptionsSelector(getState());

      return !options.loading && !options.data!.fundToInvestorsMapping;
    },
  }
);

export const fetchCashFlowActivityReport = createAsyncThunk<CashFlowActivityDataRow[], void, ThunkApiConfig>(
  "cashFlowActivity/fetchCashFlowActivityReport",
  async (_, { rejectWithValue, getState, extra, signal }) => {
    try {
      const state = getState();
      const filters = filterValuesSelector(state);
      const filterOptions = filterOptionsSelector(state);
      const { DateFormat } = preferencesSelector(state);
      const { selectedAccountGroupId } = reportDataSelector(state);
      const { accounts } = filterSelector(state);

      const investorAccounts =
        selectedAccountGroupId && !filters.accounts ? accounts.map((account) => String(account.id)) : filters.accounts;
      const body: CashFlowActivityReportRequestBody = {
        fund: filters.fund!,
        investors: investorAccounts,
        timePeriod: filters.timeRange.timePeriod,
        startDate: filters.timeRange.timePeriod === "EXACT_RANGE" ? filters.timeRange.start : undefined,
        endDate: filters.timeRange.timePeriod === "EXACT_RANGE" ? filters.timeRange.end : undefined,
        types:
          filters.contributions || filters.distributions
            ? [
                ...defaultTo(
                  filters.contributions,
                  filterOptions.data!.contributions.map((c) => c.id as string)
                ),
                ...defaultTo(
                  filters.distributions,
                  filterOptions.data!.distributions.map((d) => d.id as string)
                ),
              ]
            : null,
      };

      const response = await extra<CashFlowActivityReport[], CashFlowActivityReportRequestBody>(
        "/reporting/v1/FundCashflows/Report",
        {
          method: "post",
          body,
          signal,
        }
      );

      return response.data.map(
        (report): CashFlowActivityDataRow => ({
          investorName: report.investorName,
          currency: CurrencyName[report.currency],
          startDate: DateUtils.formatDate(new Date(report.startDate), DateFormat),
          endDate: DateUtils.formatDate(new Date(report.endDate), DateFormat),
          date: DateUtils.formatDate(new Date(report.date), DateFormat),
          type: contributions.some((contribution) => contribution.id === report.type) ? "Contribution" : "Distribution",
          category: [...contributions, ...distributions].find((t) => t.id === report.type)!.label,
          amount: report.amount,
          rowMeta: {
            currency: report.currency,
          },
        })
      );
    } catch (e) {
      return rejectWithValue("Failed to fetch Cash Flow Activity Reports");
    }
  }
);

export const changeCashFlowActivityFilters = createAsyncThunk<void, Partial<CashFlowActivityFilters>, ThunkApiConfig>(
  "cashFlowActivity/changeCashFlowActivityFilters",
  (updatedFilter, { dispatch }) => {
    dispatch(changeFilterValue(updatedFilter));
    const fetchCashFlowActivityReportCancelable = RequestUtils.getAbortThunk(fetchCashFlowActivityReport, dispatch);

    fetchCashFlowActivityReportCancelable();
  }
);

export const initCashFlowActivity = createAsyncThunk<void, void, ThunkApiConfig>(
  "cashFlowActivity/initCashFlowActivity",
  async (_, { dispatch }) => {
    const fetchCashFlowActivityFiltersCancelable = RequestUtils.getAbortThunk(fetchCashFlowActivityFilters, dispatch);
    const fetchCashFlowActivityReportCancelable = RequestUtils.getAbortThunk(fetchCashFlowActivityReport, dispatch);

    await fetchCashFlowActivityFiltersCancelable();
    await fetchCashFlowActivityReportCancelable();
  }
);

// Download casflow activity types & thunk
interface DownloadCashflowReportRequestBody {
  endDate?: string | null;
  fund: string;
  investors: string[];
  startDate?: string | null;
  timePeriod: string;
  types: string[] | null;
}

const getFundInvestorsList = (cashFlowActivity: CashFlowActivityState): string[] => {
  if (!cashFlowActivity.filters.fund) {
    throw new Error("getFundInvestorsList(): Fund must be provided");
  }

  if (!cashFlowActivity?.filterOptions?.data?.fundToInvestorsMapping) {
    throw new Error("getFundInvestorsList(): Fund must be provided");
  }

  return cashFlowActivity!.filterOptions!.data!.fundToInvestorsMapping[cashFlowActivity.filters.fund].investors.map(
    (investor) => String(investor.id)
  );
};

export const downloadCashflowReport = createAsyncThunk<void, void, ThunkApiConfig>(
  CashflowActionNames.downloadReport,
  async (_, { dispatch, rejectWithValue, extra, getState }) => {
    try {
      const { cashFlowActivity } = getState();
      const { filters } = cashFlowActivity;

      const fund = filters.fund;
      const investorsList = filters.accounts || getFundInvestorsList(cashFlowActivity);

      // if contributions and distributions are set to All - types is empty
      let types: string[] | null = null;

      // otherwise, types is union of contributions and distributions
      if (filters?.contributions || filters?.distributions) {
        const contributionsToSend = filters?.contributions ?? contributions.map((item) => item.id); // if contributions is null (i.e it is "All") - use all contributions list
        const distributionsToSend = filters?.distributions ?? distributions.map((item) => item.id); // if distributions is null (i.e it is "All") - use all distributions list

        types = [...contributionsToSend, ...distributionsToSend];
      }

      const reqBody: DownloadCashflowReportRequestBody = {
        fund: fund!,
        investors: investorsList,
        startDate:
          filters.timeRange.timePeriod === "EXACT_RANGE" ? filters.timeRange.start?.toISOString() || null : undefined,
        timePeriod: filters.timeRange.timePeriod ?? "CURRENT_QUARTER",
        endDate:
          filters.timeRange.timePeriod === "EXACT_RANGE" ? filters.timeRange.end?.toISOString() || null : undefined,
        types,
      };

      // get report
      const response = await extra<FileResponse>("/reporting/v1/FundCashflows/ReportDownload", {
        method: "post",
        body: reqBody,
        responseType: "arraybuffer",
      });

      // save it and show notification
      if (response.data.blob?.size) {
        saveAs(response.data.blob, response.data.fileName);
        dispatch(
          openNotificationThunk({
            variant: "info",
            text: reportMessages.success,
          })
        );
      } else {
        throw new Error("downloadCashflowReport(): no data returned on request");
      }
    } catch (e) {
      // show warnning
      console.warn("downloadCashflowReport() failed:", e);

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: reportMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch Cash Flow Activity Report");
    }
  }
);
