import { FooterSocial } from "@app/models/Footer";
import { ReactComponent as Instagram } from "@assets/svg/instagram.svg";
import { ReactComponent as Twitter } from "@assets/svg/twitter.svg";
import { ReactComponent as Facebook } from "@assets/svg/facebook.svg";
import { ReactComponent as Globe } from "@assets/svg/globe.svg";

export const footerSocialLinks: FooterSocial[] = [
  {
    label: "Instagram",
    link: "https://www.instagram.com/onecarlyle/",
    Icon: Instagram,
  },
  {
    label: "Twitter",
    link: "https://twitter.com/OneCarlyle",
    Icon: Twitter,
  },
  {
    label: "Facebook",
    link: "https://www.facebook.com/onecarlyle/",
    Icon: Facebook,
  },
  {
    label: "Carlyle.com",
    link: "https://www.carlyle.com/",
    Icon: Globe,
  },
];
