import { saveAs } from "file-saver";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { omit } from "lodash";
import { buildDocumentsRequestBody, getEntitiesListForDocumentsRequest } from "@documents/utils";
import { openNotificationThunk } from "@store/Notification/thunks";
import { fileGenerationMessages } from "@shared/models/fileGenerationMessages";
import { callDownloadDocumentsAPI } from "@documents/store/thunks/callDownloadDocumentsAPI";
import { ThunkApiConfig } from "@shared/models/redux";
import { selectedIAGroupIdSelector } from "@app/store/selectors";

export const downloadDocumentsExcel = createAsyncThunk<void, void, ThunkApiConfig>(
  "documents/downloadDocumentsExcel",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const rootState = getState();

    const {
      entities: { entitledAccounts, entitledFunds },
      impersonation: { canImpersonate: isAdmin },
      documents: {
        report: { pagination },
        filters,
        sort,
      },
    } = rootState;

    const selectedAccountGroup = selectedIAGroupIdSelector(rootState);

    const { investorAccountIds, fundIds } = getEntitiesListForDocumentsRequest({
      entitledAccounts,
      entitledFunds,
      isAnyIAGSelected: Boolean(selectedAccountGroup),
      selectedAccounts: filters.investorAccounts,
      selectedFunds: filters.funds,
    });

    const body = buildDocumentsRequestBody({
      downloadAsFile: true,
      filters: {
        ...omit(filters, ["funds", "investorAccounts"]),
        investorAccountIds,
        fundIds,
      },
      isAdmin,
      sort,
      pagination,
    });

    try {
      const response = await dispatch(callDownloadDocumentsAPI(body));

      const today = new Date();
      const fileName = `DocumentReport${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}.xlsx`;

      if (response.blob?.size) {
        saveAs(response.blob, fileName);

        dispatch(
          openNotificationThunk({
            variant: "info",
            text: fileGenerationMessages.success,
          })
        );
      }
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: fileGenerationMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch Documents");
    }
  }
);
