import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { fetchFSISArgs } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { FileResponse } from "@shared/models/request";
import { openNotificationThunk } from "@store/Notification/thunks";
import { reportMessages } from "@reporting/constants";

export const downloadFSISReport = createAsyncThunk<void, fetchFSISArgs, ThunkApiConfig>(
  "funds/downloadFSISReport",
  async ({ fundId, date }, { extra, dispatch, rejectWithValue }) => {
    const reportingDate = date.split("T")[0];

    try {
      const response = await extra<FileResponse>(
        `reporting/v1/FPSIS/report/${encodeURIComponent(fundId)}:${encodeURIComponent(reportingDate)}`,
        {
          method: "get",
          responseType: "blob",
        }
      );

      if (response.data.blob?.size) {
        saveAs(response.data.blob, response.data.fileName);
        dispatch(
          openNotificationThunk({
            variant: "info",
            text: reportMessages.success,
          })
        );
      } else {
        throw new Error("downloadFSISReport(): no data returned on request");
      }
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: reportMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch Summary Investment Schedule Report");
    }
  }
);
