import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductSegment } from "@user/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { callGetUserSegmentFunction } from "@user/store/thunks/callGetUserSegmentFunction";

export const fetchUserSegmentFunction = createAsyncThunk<Array<ProductSegment>, void, ThunkApiConfig>(
  "user/fetchUserSegmentFunction",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callGetUserSegmentFunction());
    } catch (error) {
      return rejectWithValue("Failed to load user segment function");
    }
  }
);
