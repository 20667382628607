import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AppRoute } from "@app/models/AppRoute";
import logo from "@assets/images/logo_white.png";
import { ReactComponent as HamburgerIcon } from "@assets/svg/hamburger_icon.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { useAppSelector } from "@app/hooks";
import { IMPERSONATION_BAR_HEIGHT_MOBILE, MOBILE_HEADER_HEIGHT } from "@app/constants/header";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { Container } from "@shared/components";
import AppMobileDrawer from "@app/components/AppMobileDrawer";

interface Props {
  isTransparent?: boolean;
}

const useStyles = makeStyles<Theme, { isPinned: boolean; isImpersonating: boolean } & Props>((theme) => ({
  root: {
    transition: "background .15s",
    background: ({ isTransparent, isPinned }) =>
      isTransparent && !isPinned ? "transparent" : theme.palette.primary.main,
    top: ({ isImpersonating }) => (isImpersonating ? IMPERSONATION_BAR_HEIGHT_MOBILE : 0),
    padding: theme.spacing(10, 0),
    height: MOBILE_HEADER_HEIGHT,
  },
  hamburgerIcon: {
    color: theme.palette.common.white,
    width: 30,
    height: 40,
  },
  closeIcon: {
    color: theme.palette.common.white,
    width: 30,
    height: 40,
    "& line": { strokeWidth: 2 },
  },
}));

const AppMobileHeader: FunctionComponent<Props> = ({ isTransparent }) => {
  const { isImpersonating } = useAppSelector(impersonationStateSelector);

  const [isPinned, setIsPinned] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const classes = useStyles({ isTransparent, isPinned: isDrawerOpen || isPinned, isImpersonating });

  useEffect(() => {
    const handleScroll = () => setIsPinned(window.scrollY > 0);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOpenDrawer = useCallback(() => setIsDrawerOpen(true), []);

  const handleCloseDrawer = useCallback(() => setIsDrawerOpen(false), []);

  return (
    <>
      <AppBar className={classes.root} elevation={0}>
        <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Link to={AppRoute.Home} onClick={handleCloseDrawer}>
                <img src={logo} alt="Carlyle" width={135} />
              </Link>
            </Grid>
            <Grid item>
              {isDrawerOpen ? (
                <CloseIcon className={classes.closeIcon} onClick={handleCloseDrawer} />
              ) : (
                <HamburgerIcon className={classes.hamburgerIcon} onClick={handleOpenDrawer} />
              )}
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <AppMobileDrawer open={isDrawerOpen} onClose={handleCloseDrawer} />
    </>
  );
};

export default AppMobileHeader;
