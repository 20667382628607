export enum QuarterLabel {
  First = "Mar. 31",
  Second = "Jun. 30",
  Third = "Sep. 30",
  Fourth = "Dec. 31",
}

export enum QuarterValue {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
}

export type Month = "03" | "06" | "09" | "12";

export const MonthToQuarterMap = {
  "03": QuarterLabel.First,
  "06": QuarterLabel.Second,
  "09": QuarterLabel.Third,
  "12": QuarterLabel.Fourth,
};
