import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentResponse } from "@documents/models/api.models";
import { ThunkApiConfig } from "@shared/models/redux";
import { callDocumentsAPI } from "@documents/store/thunks";
import { SortDirection } from "@shared/models/general";
import { openNotificationThunk } from "@store/Notification/thunks";
import { resetHighlightedVideo, setHighlightedVideo } from "@myCalendar/store/ducks";
import { eventHighlightedVideoSelector } from "@myCalendar/store/selectors";
import { documentStatusesAdmin, documentStatusesNonAdmin } from "@documents/constants";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

export const fetchEventDocuments = createAsyncThunk<
  DocumentResponse[],
  {
    entityId: number;
    sortDirection: SortDirection;
    sortKey: string;
  },
  ThunkApiConfig
>(
  "myCalendar/fetchEventDocuments",
  async ({ entityId, sortDirection, sortKey }, { dispatch, rejectWithValue, getState }) => {
    try {
      const eventHighlightedVideo = eventHighlightedVideoSelector(getState());
      const { canImpersonate: isAdmin } = impersonationStateSelector(getState());

      if (eventHighlightedVideo) {
        dispatch(resetHighlightedVideo());
      }

      const documents = await dispatch(
        callDocumentsAPI({
          documentLocation: "events",
          documentStatuses: isAdmin ? documentStatusesAdmin : documentStatusesNonAdmin,
          documentTypes: null,
          entitledEntityIds: [entityId],
          includeVideos: true,
          includeChildren: true,
          sortDirection: sortDirection,
          sortKey: sortKey,
          userId: null,
        })
      );

      const highlightedVideo = documents.results.find((document) => document.videoIsHighlighted);

      if (highlightedVideo) {
        dispatch(
          setHighlightedVideo({
            videoToken: highlightedVideo.videoToken,
            videoDescription: highlightedVideo.videoDescription,
            videoId: highlightedVideo.videoId,
            videoTokenExpiration: highlightedVideo.videoTokenExpiration,
            title: highlightedVideo.title,
          })
        );
      }

      return documents.results;
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load data",
        })
      );

      return rejectWithValue("Failed to load entity documents");
    }
  }
);
