import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResetPasswordBody } from "@profile/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";

export const resetPasswordThunk = createAsyncThunk<boolean, ResetPasswordBody, ThunkApiConfig>(
  "profile/resetPasswordThunk",
  async (body, { rejectWithValue, dispatch, extra }) => {
    try {
      const response = await extra<boolean, ResetPasswordBody>(process.env.REACT_APP_AUTH_RESET_PASSWORD_URI!, {
        method: "post",
        withCredentials: false,
        body,
      });

      if (response.data) {
        dispatch(
          openNotificationThunk({
            variant: "info",
            text: "We've just sent you an email to reset your password.",
          })
        );
      }

      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.[0] || "Some error occurred. Please try again.";

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: errorMessage,
        })
      );

      return rejectWithValue(errorMessage);
    }
  }
);
