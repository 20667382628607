import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { TAG_MANAGEMENT_SYSTEM_URL } from "@app/constants/globalUrls";

const DocumentHead = () => {
  return (
    <Helmet>
      <script async src={`${TAG_MANAGEMENT_SYSTEM_URL}/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`} />
    </Helmet>
  );
};

export default memo(DocumentHead);
