import { createSelector } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { RootState } from "@store/index";
import { ListOptionBase, MultiselectOption, Year, RowType } from "@shared/components";
import { QuarterLabel, QuarterValue } from "@shared/models/date";
import { fundsSelector, investorsSelector } from "@store/Entities/selectors";
import {
  getAccountSummaryPieChartData,
  getConsolidateFundValues,
  getCurrencyAbbreviationFromCurrencyName,
  getReportData,
  getTotalReportData,
} from "@accountSummaryCarlyle/utils";
import NumberUtils from "@shared/utils/NumberUtils";
import { CurrencyAbbreviation } from "@shared/models/currency";
import { SpecialAccountKeys } from "@shared/models/reporting";
import { preferencesSelector } from "@app/store/selectors";
import {
  AccountSummaryDataGridRow,
  AccountSummaryDataGridRowNames,
  AccountSummaryPieChartsData,
  AccountSummaryState,
} from "../models";

export const reportSelector = (state: RootState): AccountSummaryState["report"] => state.accountSummaryCarlyle.report;

export const filterValuesSelector = (state: RootState): AccountSummaryState["filters"] =>
  state.accountSummaryCarlyle.filters;

export const filterOptionsSelector = (state: RootState): AccountSummaryState["filterOptions"] =>
  state.accountSummaryCarlyle.filterOptions;

export const filtersFundsSelector = (state: RootState): AccountSummaryState["filters"]["funds"] =>
  state.accountSummaryCarlyle.filters.funds;

export const fundDataSelector = (state: RootState): AccountSummaryState["fundData"]["data"] =>
  state.accountSummaryCarlyle.fundData.data;

export const accountSummaryReportExportLoadingSelector = (
  state: RootState
): AccountSummaryState["isReportDownloading"] => state.accountSummaryCarlyle.isReportDownloading;

export const accountSummaryFundsSelector = createSelector(filtersFundsSelector, (funds): MultiselectOption[] | null => {
  if (!funds) {
    return null;
  }

  const labelSorter = (fund: ListOptionBase) => fund.label.toLowerCase();

  const fundOptions: MultiselectOption[] = Object.values(funds).map((fund) => ({
    id: fund.globalId,
    label: fund.label,
    selected: fund.selected,
  }));

  return orderBy(fundOptions, [labelSorter]);
});

const asOfDateSelector = createSelector(filterOptionsSelector, (filterOptions): Year[] | undefined => {
  if (filterOptions?.data === null) {
    return;
  }

  const years = Array.from(new Set(filterOptions.data.map(({ year }) => year)));

  return years
    .sort()
    .reverse()
    .map((year, index) => {
      const periods =
        filterOptions?.data?.filter((period) => {
          return period.year === year && period.associatedFunds !== null;
        }) ?? [];

      const quarters = periods
        .map(({ quarter }) => {
          const id = Number(quarter);
          const label = QuarterLabel[QuarterValue[id] as keyof typeof QuarterLabel];

          return { id, label };
        })
        .reverse();

      return {
        id: index + 1,
        label: year,
        quarters,
      };
    });
});

const selectedAsOfDateSelector = createSelector(asOfDateSelector, filterValuesSelector, (dateRange, filters) => {
  const year = dateRange && (dateRange.find((option: Year) => option.label === filters?.asOfDate?.year)?.id as number);

  if (!year) {
    return null;
  }

  const currentQuarterLabel = filters?.asOfDate?.quarter;

  return {
    year,
    quarter: Number(currentQuarterLabel),
  };
});

export const selectedFundsSelector = createSelector(filtersFundsSelector, (funds) => {
  return funds
    ? Object.values(funds)
        .filter((fund) => fund.selected)
        .map((item) => item.globalId)
    : null;
});

export const investorAccountsOptionsSelector = createSelector(
  filterValuesSelector,
  investorsSelector,
  (filters, investors) => {
    const investorAccountOptions: MultiselectOption[] = [];

    investors.data &&
      Object.values(investors.data).forEach((investor) => {
        if (filters.accounts && filters.accounts[investor.investorId] !== undefined) {
          investorAccountOptions.push({
            label: investor.investorName,
            id: investor.investorId,
            selected: filters.accounts[investor.investorId] || false,
          });
        }
      });

    const labelSorter = (account: ListOptionBase) => account.label.toLowerCase();

    return investorAccountOptions && orderBy(investorAccountOptions, [labelSorter]);
  }
);

export const reportingCriteriaAsOfDateSelector = createSelector(
  asOfDateSelector,
  filterValuesSelector,
  (dateRange, filters) => {
    const year =
      dateRange && (dateRange.find((option: Year) => option.label === filters?.asOfDate?.year)?.label as string);

    const quarterIndex = QuarterValue[Number(filters?.asOfDate?.quarter)];
    const quarterLabel = QuarterLabel[quarterIndex as keyof typeof QuarterLabel];

    return new Date(`${year} ${quarterLabel}`);
  }
);

export const reportingCriteriaSelectedAccounts = createSelector(
  filterValuesSelector,
  investorAccountsOptionsSelector,
  investorsSelector,
  (filters, investorAccounts, investors) => {
    if (filters.accounts && Object.keys(filters.accounts).length > 0) {
      const selectedAccounts: string[] = [];

      // Only show Reporting Criteria for those IA's that are manually selected by the user.
      const numSelectedIA = Object.values(filters.accounts).filter(Boolean).length;

      if (numSelectedIA > 0) {
        Object.entries(filters.accounts).forEach((account) => {
          if (account[1] && investors.data) {
            selectedAccounts.push(investors.data[`${account[0]}`].investorName);
          }
        });

        if (selectedAccounts.length === 0) {
          investorAccounts.forEach((account: MultiselectOption) => selectedAccounts.push(account.label));
        }
      }

      return orderBy(selectedAccounts);
    }

    return null;
  }
);

export const ASdataLoadingSelector = createSelector(
  investorsSelector,
  filterOptionsSelector,
  reportSelector,
  (investorList, filterOptions, report) => {
    let isLoading = false;

    if (investorList.loading || filterOptions.loading || (report.loading && !filterOptions.data) || report.loading) {
      isLoading = true;
    }

    return isLoading;
  }
);

export const filterSelector = createSelector(
  filterOptionsSelector,
  filterValuesSelector,
  investorAccountsOptionsSelector,
  accountSummaryFundsSelector,
  selectedFundsSelector,
  asOfDateSelector,
  selectedAsOfDateSelector,
  reportingCriteriaAsOfDateSelector,
  reportingCriteriaSelectedAccounts,
  ASdataLoadingSelector,
  reportSelector,
  fundDataSelector,
  (
    options,
    values,
    accounts,
    funds,
    selectedFunds,
    asOfDates,
    selectedAsOfDate,
    reportingCriteriaAsOfDate,
    reportingCriteriaSelectedAccounts,
    loading,
    report,
    fundData
  ) => ({
    accounts,
    funds,
    selectedFunds,
    asOfDates,
    selectedAsOfDate,
    options,
    rawValues: values,
    reportingCriteriaAsOfDate,
    reportingCriteriaSelectedAccounts,
    loading,
    report,
    fundData,
  })
);

export const selectedInvestorAccountsSelector = createSelector(
  (state: RootState) => investorAccountsOptionsSelector(state),
  (investorAccounts) => {
    return investorAccounts
      .filter(
        (investor) =>
          investor.id !== SpecialAccountKeys.CarlyleGroupAccount && investor.id !== SpecialAccountKeys.TotalFundAccount
      )
      .some((investor) => investor.selected)
      ? investorAccounts.filter((investor) => investor.selected).map((investor) => investor.id.toString())
      : investorAccounts.map((investor) => investor.id.toString());
  }
);

export const accountSummaryDataGridSelector = createSelector(
  (state: RootState) => reportSelector(state),
  (state: RootState) => fundsSelector(state),
  (state: RootState) => selectedInvestorAccountsSelector(state),
  (state: RootState) => preferencesSelector(state),
  (report, funds, selectedInvestors, { NumberFormat }): AccountSummaryDataGridRow[] => {
    const getFormattedNumber = (number: number, fractionDigits?: number): string =>
      NumberUtils.formatNumber(number, NumberFormat, fractionDigits ?? 0);

    const reportData = getReportData(report, funds, selectedInvestors);

    const totalReportData = getTotalReportData(report, funds, selectedInvestors);

    const rows = reportData.map((item) => ({
      ...item,
      rowMeta: {
        currency: getCurrencyAbbreviationFromCurrencyName(item.currency),
      },
      commitment: getFormattedNumber(item.commitment),
      remainingCommitments: getFormattedNumber(item.remainingCommitments),
      totalContributedCapital: getFormattedNumber(item.totalContributedCapital),
      totalDistributions: getFormattedNumber(item.totalDistributions),
      capitalAccountAtFairValue: getFormattedNumber(item.capitalAccountAtFairValue),
      netMoic:
        item.totalContributedCapital === 0
          ? "-"
          : `${getFormattedNumber(
              (item.capitalAccountAtFairValue - item.totalDistributions) / item.totalContributedCapital,
              2
            )}x`,
    }));

    const totalReportRow = {
      fundName: AccountSummaryDataGridRowNames.Total,
      rowMeta: {
        type: RowType.SubHeader,
        currency: CurrencyAbbreviation.USD,
        nonClickableRow: true,
      },
      commitment: getFormattedNumber(totalReportData.commitment),
      remainingCommitments: getFormattedNumber(totalReportData.remainingCommitments),

      totalContributedCapital: getFormattedNumber(totalReportData.totalContributedCapital),
      totalDistributions: getFormattedNumber(totalReportData.totalDistributions),
      capitalAccountAtFairValue: getFormattedNumber(totalReportData.capitalAccountAtFairValue),
    };

    return [...rows, totalReportRow];
  }
);

export const accountSummaryPieChartsDataSelector = createSelector(
  (state: RootState) => reportSelector(state),
  (state: RootState) => fundsSelector(state),
  (state: RootState) => selectedInvestorAccountsSelector(state),
  (report, funds, selectedInvestorAccounts): AccountSummaryPieChartsData => {
    if (!funds.data || !report.data || !selectedInvestorAccounts) {
      return {
        capitalAccountAtFairValue: [],
        remainingCommitments: [],
      };
    }

    const consolidatedFundValues = getConsolidateFundValues(report.data, funds.data, selectedInvestorAccounts);

    return {
      capitalAccountAtFairValue: getAccountSummaryPieChartData(consolidatedFundValues, "capitalAccountAtFairValue"),
      remainingCommitments: getAccountSummaryPieChartData(consolidatedFundValues, "remainingCommitments"),
    };
  }
);
