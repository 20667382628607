import { createAction } from "@reduxjs/toolkit";
import { CashFlowActivityFilters } from "../models";

export enum CashflowActionNames {
  changeFilterValue = "cashFlowActivity/changeFilterValue",
  downloadReport = "cashFlowActivity/downloadReport",
}

export const changeFilterValue = createAction<Partial<CashFlowActivityFilters>>(CashflowActionNames.changeFilterValue);

export const downloadReport = createAction<void>(CashflowActionNames.downloadReport);
