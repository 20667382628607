import { fetchUserTerms } from "@terms/store/thunks/fetchUserTerms";
import { InitiateTermsArgs, OutstandingTermStatus, TermAppearanceType, TermAssociation } from "@terms/models";
import initiateTermsPopup from "@terms/store/thunks/initiateTermsPopup";
import { AppRoute } from "@app/models/AppRoute";
import { GetAppListener } from "@shared/models/redux";
import { termRulesHandlersDataSelector, termsDialogDataSelector } from "@terms/store/selectors";
import { changeTermStatus, closeTermsDialog, pushFinalRulesToExecute, resetRules } from "@terms/store/ducks";
import { postAcceptedTerms } from "@terms/store/thunks/postAcceptedTerms";

/**
 * Each time App loads user terms, the system should show initial T&C popup.
 * There are 2 scenarios:
 * 1) App initialization
 * 2) Start/End impersonation session
 * Initial T&C should contain all SiteWide and Fund(EntitledEntity) specific terms.
 * Besides, if initial page is reporting or fund profile page - initial T&C should also contain Reporting term.
 */
export const getTermsLoadedListener: GetAppListener = (addAppListener) =>
  addAppListener({
    actionCreator: fetchUserTerms.fulfilled,
    effect: (action, { dispatch }) => {
      const termsConfig: InitiateTermsArgs = {
        type: TermAppearanceType.InitializeApplication,
        associations: {
          [TermAssociation.SiteWide]: null,
          [TermAssociation.EntitledEntity]: null,
        },
      };

      const [, fundProfilePath] = AppRoute.FundProfile.split("/");

      const shouldShowReportingTerm =
        window.location.pathname.startsWith(AppRoute.ReportingBase) ||
        window.location.pathname.startsWith(`/${fundProfilePath}`);

      if (shouldShowReportingTerm) {
        termsConfig.associations![TermAssociation.Reporting] = null;
      }

      dispatch(initiateTermsPopup(termsConfig));
    },
  });

/**
 * Each time term gets Accepted/Declined, corresponding immediate rules should be executed.
 */
export const getImmediateTermRuleListener: GetAppListener = (addAppListener, { history }) =>
  addAppListener({
    actionCreator: changeTermStatus,
    effect: (action, { getState, dispatch }) => {
      const { rules, outstandingTermsData } = termRulesHandlersDataSelector(getState());

      const activeTermData = outstandingTermsData.find((term) => term.id === action.payload.id)!;

      rules.afterEach
        .filter(({ condition }) => condition(activeTermData, outstandingTermsData))
        .forEach(({ action }) => action({ history, dispatch }));
    },
  });

/**
 * Once all terms are processed, corresponding final rules should be executed.
 * Another scenario is when user commits a protected action (like download a document) but there are no outstanding T&C for that document type => system should perform that action
 */
export const getFinalTermRuleListener: GetAppListener = (addAppListener, { history }) =>
  addAppListener({
    predicate: (action, state) => {
      if (action.type === pushFinalRulesToExecute.type) return true;
      if (action.type !== changeTermStatus.type) return false;

      const { termsToDisplay } = termsDialogDataSelector(state);

      return termsToDisplay.every((term) => term.status !== OutstandingTermStatus.Pending);
    },
    effect: (_, { getState, dispatch }) => {
      const { rules, outstandingTermsData } = termRulesHandlersDataSelector(getState());

      rules.afterAll
        .filter(({ condition }) => condition(outstandingTermsData))
        .forEach(({ action }) => action({ history, dispatch }));

      dispatch(postAcceptedTerms());

      // Cleanup
      dispatch(resetRules());
      dispatch(closeTermsDialog());
    },
  });
