import { FileResponse } from "@shared/models/request";
import { AppThunkAPI } from "@shared/models/redux";

type CallGetDownloadDocumentDataAPI = (url: string) => AppThunkAPI<Promise<FileResponse | Blob>>;

export const callGetDownloadDocumentData: CallGetDownloadDocumentDataAPI =
  (url) => async (_dispatch, _state, extra) => {
    const response = await extra<FileResponse | Blob>(
      url,
      { responseType: "blob" },
      {
        includeUserId: false,
        includeAccountGroupId: false,
      }
    );

    return response.data;
  };
