import React, { FunctionComponent } from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SectionTabProps } from "./models";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    height: 56,
    padding: theme.spacing(6),
    borderBottomStyle: "none",
    borderBottomWidth: 0,
    borderRadius: 0,
    fontSize: 18,
    textTransform: "capitalize",
    boxShadow: "none",

    "&:hover": {
      boxShadow: "none",
    },
  },
}));

const SectionTab: FunctionComponent<SectionTabProps> = ({ children, variant = "contained", onClick }) => {
  const classes = useStyles();

  return (
    <Button classes={classes} variant={variant} color="primary" onClick={onClick}>
      {children}
    </Button>
  );
};

export default SectionTab;
