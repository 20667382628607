import React, { memo, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { Input } from "@shared/components/Input";
import { SelectedOptionsList } from "../SelectedOptionsList";

interface MultiselectInputProps {
  entity: string;
  placeholder: string;
  selectedOptions: Array<string>;
  previewExpandable?: boolean;
  onAdd(value: string): void;
  onDelete(value: string): void;
  onClearAll(): void;
  testId: string;
}

const MultiselectInput = ({
  entity,
  onAdd,
  onDelete,
  onClearAll,
  previewExpandable,
  placeholder,
  selectedOptions,
  testId,
}: MultiselectInputProps) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value.endsWith(",")) {
        const newValue = value.replace(",", "").trim();

        onAdd(newValue);
        setInputValue("");
      }

      if (value.endsWith(";")) {
        const newValue = value.replace(";", "").trim();

        onAdd(newValue);
        setInputValue("");
      }
    },
    [onAdd]
  );

  const handleEnterKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        const trimmedValue = inputValue.trim();

        if (!trimmedValue) return;

        onAdd(trimmedValue);
        setInputValue("");
      }
    },
    [inputValue, onAdd]
  );

  const handleInputBlur = useCallback(() => {
    const trimmedValue = inputValue.trim();

    if (!trimmedValue) return;

    onAdd(inputValue);
    setInputValue("");
  }, [inputValue, onAdd]);

  return (
    <Box>
      {!!selectedOptions.length && (
        <SelectedOptionsList
          selectedOptions={selectedOptions}
          previewExpandable={previewExpandable}
          entity={entity}
          onClearAll={onClearAll}
          optionsLength={selectedOptions.length}
          onSelect={onDelete}
          testId={testId}
        />
      )}
      <Input
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyPress={handleEnterKeyPress}
        testId={testId}
      />
    </Box>
  );
};

export default memo(MultiselectInput) as typeof MultiselectInput;
