import React from "react";
import { createSelector } from "@reduxjs/toolkit";
import { flow, isEmpty, orderBy } from "lodash";
import { format, lastDayOfMonth, lastDayOfYear, subMonths, subYears } from "date-fns";
import {
  FsisFlocReport,
  FsisFlocReportAdjustmentTypeId,
  FSISReport,
  FsisReportsByInvestmentStatus,
  FsisReportTotal,
  FundDates,
  FundDocumentsRow,
  FundGeneralInfoItem,
  FundsState,
  InvestmentScheduleSelectorOptions,
  InvestmentStatusDescriptor,
  KeyDatesRows,
  MoicMeta,
  WaterfallTermsRows,
} from "@funds/models";
import { RootState } from "@store/index";
import { CurrencyName, CurrencySymbol } from "@shared/models/currency";
import DateUtils from "@shared/utils/DateUtils";
import { QuarterLabel, QuarterValue } from "@shared/models/date";
import { SelectedOption, Year, BaseRow, RowType } from "@shared/components";
import NumberUtils from "@shared/utils/NumberUtils";
import { documentTypesSelector } from "@store/Entities/selectors";
import CommonUtils from "@shared/utils/CommonUtils";
import { DocumentStatus } from "@documents/models/filter.models";
import { Nullable } from "@shared/models/general";
import { Fund } from "@store/Entities/models";
import { GeographyName } from "@shared/models/geography";
import { NumberFormat } from "@user/models";
import { preferencesSelector } from "@app/store/selectors";
import { formatNumber } from "../../../util/numberTransforms";

export const selectedFundDates = (state: RootState): Nullable<FundDates> => state.funds.fundDates.data;

export const selectedFsisDates = (state: RootState): FundsState["fsisDates"] => state.funds.fsisDates;

export const selectedFsisReports = (state: RootState): FundsState["fsisReports"] => state.funds.fsisReports;

export const selectedHistoricMoic = (state: RootState): FundsState["historicMoic"] => state.funds.historicMoic;

export const selectedFsisFlocReports = (state: RootState): FundsState["fsisFlocReports"] => state.funds.fsisFlocReports;

export const selectedSummaryInvestmentScheduleFilters = (
  state: RootState
): FundsState["summaryInvestmentScheduleFilters"] => state.funds.summaryInvestmentScheduleFilters;

export const selectedFundDocumentsFilters = (state: RootState): FundsState["fundDocumentsFilters"] =>
  state.funds.fundDocumentsFilters;

export const fundDocumentsSelector = (state: RootState): FundsState["fundDocuments"] => state.funds.fundDocuments;

export const fundDocumentsFilterOptionsSelector = (state: RootState): FundsState["fundDocumentsFilterOptions"] =>
  state.funds.fundDocumentsFilterOptions;

export const selectedDocumentTypesFundDocumentsSelector = (
  state: RootState
): FundsState["fundDocumentsFilters"]["documentTypes"] => state.funds.fundDocumentsFilters.documentTypes;

export const selectedInvestorAccountsFundDocumentsSelector = (
  state: RootState
): FundsState["fundDocumentsFilters"]["investorAccounts"] => state.funds.fundDocumentsFilters.investorAccounts;

export const investorAccountOptionsFundDocumentsSelector = (
  state: RootState
): FundsState["fundDocumentsFilterOptions"]["investorAccounts"] =>
  state.funds.fundDocumentsFilterOptions.investorAccounts;
export const fsisExportLoadingSelector = (state: RootState): FundsState["isExportFsisReportLoading"] =>
  state.funds.isExportFsisReportLoading;

export const preparedDocumentTypesFundDocumentsSelector = createSelector(
  documentTypesSelector,
  selectedDocumentTypesFundDocumentsSelector,
  (documentTypesSlice, selectedDocumentTypeIds) => {
    const selectedDocumentTypeIdsMap = CommonUtils.convertArrayToMap(selectedDocumentTypeIds);

    return orderBy(
      CommonUtils.convertMapToArray(documentTypesSlice.data, (documentType) => ({
        id: documentType.documentTypeId,
        label: documentType.displayName ?? documentType.name,
        selected: selectedDocumentTypeIdsMap[documentType.documentTypeId],
      })),
      [({ label }) => label.toLowerCase()]
    );
  }
);

export const preparedInvestorAccountsFunDocumentSelector = createSelector(
  investorAccountOptionsFundDocumentsSelector,
  selectedInvestorAccountsFundDocumentsSelector,
  (investorAccountsSlice, selectedInvestorAccountIds) => {
    const selectedInvestorAccountIdsMap = CommonUtils.convertArrayToMap(selectedInvestorAccountIds);

    return (investorAccountsSlice.data ?? []).map((ia) => ({
      ...ia,
      selected: selectedInvestorAccountIdsMap[ia.id],
    }));
  }
);

export const fundDocumentsMetaSelector = createSelector(
  documentTypesSelector,
  investorAccountOptionsFundDocumentsSelector,
  (documentTypesSlice, investorAccountsSlice) => ({
    loading: documentTypesSlice.loading || investorAccountsSlice.loading,
    error: documentTypesSlice.error || investorAccountsSlice.error,
    // add meta of docs request itself here
  })
);

export const fundDocumentsFilterSelector = createSelector(
  selectedFundDocumentsFilters,
  fundDocumentsFilterOptionsSelector,
  preparedDocumentTypesFundDocumentsSelector,
  preparedInvestorAccountsFunDocumentSelector,
  fundDocumentsMetaSelector,
  (values, options, documentTypes, investorAccounts, { loading, error }) => ({
    loading,
    error,
    documentTypes,
    investorAccounts,
    selectedInvestorAccountIds: values.investorAccounts,
    selectedDocumentTypeIds: values.documentTypes,
    selectedTimeRange: values.timeRange,
    selectedStatus: values.status,
    fileName: values.fileName,
    timeRangeOptions: options.timeOptions,
    statusOptions: options.statuses,
  })
);

export const sortedFsisDateOptionsSelector = createSelector(
  selectedFsisDates,
  ({ data: fsisDates }): Nullable<Year[]> => {
    if (fsisDates === null) {
      return null;
    }

    const options = fsisDates.reduce((result: Record<string, Year>, date) => {
      const { year, quarter } = DateUtils.convertDateToYearQuarter(new Date(date));

      const yearLabel = String(year);
      const quarterLabel = QuarterLabel[QuarterValue[quarter] as keyof typeof QuarterLabel];

      return {
        ...result,
        [yearLabel]: {
          id: year,
          label: yearLabel,
          quarters: [
            ...(result?.[yearLabel]?.quarters ?? []),
            {
              id: quarter,
              label: quarterLabel,
            },
          ],
        },
      };
    }, {});

    return Object.keys(options)
      .sort()
      .reverse()
      .map((year) => options[year]);
  }
);

export const latestFsisAsOfDateOptionSelector = createSelector(
  selectedFsisDates,
  ({ data: fsisDates }): Nullable<SelectedOption> => {
    const latestDate = fsisDates?.slice().sort().pop();

    return latestDate ? DateUtils.convertDateToYearQuarter(new Date(latestDate)) : null;
  }
);

export const selectedFsisDateSelector = createSelector(
  selectedFsisDates,
  selectedSummaryInvestmentScheduleFilters,
  ({ data: fsisDates }, { asOfDate }): Nullable<string> => {
    if (fsisDates === null || asOfDate === null) {
      return null;
    }

    return (
      fsisDates.find((date) => {
        const { year, quarter } = DateUtils.convertDateToYearQuarter(new Date(date));

        return year === asOfDate.year && quarter === asOfDate.quarter;
      }) ?? null
    );
  }
);

export const selectedFsisReportSelector = createSelector(
  selectedFsisReports,
  selectedSummaryInvestmentScheduleFilters,
  selectedFsisDateSelector,
  (_state: RootState, fundId: string) => fundId,
  ({ data: reports }, { asOfDate }, date, fundId): Nullable<FSISReport> => {
    if (date === null) {
      return null;
    }

    const fundReports = reports?.[fundId]?.[date] ?? null;

    if (fundReports === null || asOfDate === null) {
      return null;
    }

    return (
      fundReports.find(({ fund, reportingDate }) => {
        const { year, quarter } = DateUtils.convertDateToYearQuarter(new Date(reportingDate));

        return fund === fundId && year === asOfDate.year && quarter === asOfDate.quarter;
      }) ?? null
    );
  }
);

export const fundDatesSelector = createSelector(
  selectedFundDates,
  preferencesSelector,
  (fundPeriods, { DateFormat }) => {
    return fundPeriods ? [`As of ${DateUtils.formatDate(new Date(fundPeriods[0]), DateFormat)}`] : [];
  }
);

export const selectedFundFPSIS = (state: RootState): FundsState["fundFPSIS"] => state.funds.fundFPSIS;

export const fundFPSISSelector = createSelector(
  (state: RootState): FundsState["fundFPSIS"] => selectedFundFPSIS(state),
  ({ data }) => {
    let remainingTotalValue = 0;

    const industries =
      data &&
      data.reduce((acc, item) => {
        if (item.industry) {
          remainingTotalValue += item.remainingFmv;

          if (acc[item.industry]) {
            acc[item.industry] += item.remainingFmv;
          } else {
            acc[item.industry] = item.remainingFmv;
          }
        }

        return acc;
      }, {} as { [key: string]: number });

    return industries
      ? Object.entries(industries)
          .map(([name, value]) => ({
            name,
            value: Number(((100 * value) / remainingTotalValue).toFixed(1)),
          }))
          .filter((datum) => datum.value)
      : [];
  }
);

export const fundHeadsSelector = (state: RootState): FundsState["fundHeads"] => state.funds.fundHeads;

export const selectedFundSelector = (state: RootState, id: string): Nullable<Fund> =>
  state.entities.funds.data ? state.entities.funds.data[id] : null;

export const generalFundInfoSelector = createSelector(
  (state: RootState, id: string) => selectedFundSelector(state, id),
  preferencesSelector,
  (selectedFund, { DateFormat, NumberFormat }): Nullable<FundGeneralInfoItem[]> => {
    if (!selectedFund) return null;

    const { fundStatus, currency, geography, strategy, grossIrr, netIrr, vintage, irrAsOfDate, size, assetClass } =
      selectedFund;

    const generalInfo: FundGeneralInfoItem[] = [
      { label: "Fund Segment", value: [{ text: assetClass }] },
      {
        label: "Currency",
        value: [{ text: CurrencyName[currency as keyof typeof CurrencyName] }],
      },
      { label: "Vintage", value: [{ text: String(vintage) }] },
      { label: "Strategy", value: [{ text: strategy }] },
      { label: "Status", value: [{ text: fundStatus }] },
      {
        label: "Geography",
        value: [{ text: GeographyName[geography as keyof typeof GeographyName] }],
      },
      {
        label: "Fund Performance",
        subLabel: `(as of ${DateUtils.formatDate(new Date(irrAsOfDate), DateFormat)})`,
        value: [
          {
            text: grossIrr ? `Gross IRR - ${formatNumber(grossIrr * 100, NumberFormat, 1)}%` : "N/A",
            tooltip:
              'Gross Internal Rate of Return ("Gross IRR")  represents the annualized IRR for the period indicated on aggregate Limited Partner invested capital based on contributions, distributions and unrealized value before management fees, expenses and carried interest.',
          },
          {
            text: netIrr ? `Net IRR - ${formatNumber(netIrr * 100, NumberFormat, 1)}%` : "N/A",
            tooltip:
              'Net Internal Rate of Return ("Net IRR") represents the annualized IRR for the period indicated on aggregate Limited Partner \ninvested capital based on contributions, distributions and unrealized value before management fees, expenses and carried interest. ',
          },
        ],
      },
      {
        label: "Total Fund Size",
        value: [
          {
            text: `${CurrencySymbol[currency as keyof typeof CurrencySymbol]}${formatNumber(size, NumberFormat)}`,
          },
        ],
      },
    ];

    return generalInfo;
  }
);

export const fundSelectors = createSelector(
  fundHeadsSelector,
  fundDatesSelector,
  selectedFundDates,
  selectedFundFPSIS,
  (state: RootState, id: string) => selectedFundSelector(state, id),
  generalFundInfoSelector,
  fundFPSISSelector,
  (fundHeads, fundDates, selectedDates, selectedFPSIS, selectedFund, generalInfo, fundFPSIS) => ({
    fundHeads,
    fundDates,
    selectedFPSIS,
    selectedDates,
    selectedFund,
    generalInfo,
    fundFPSIS,
  })
);

export const keyDatesDataGridSelector = createSelector(
  (state: RootState, id: string) => selectedFundSelector(state, id),
  preferencesSelector,
  (selectedFund, { DateFormat }) => {
    if (!selectedFund) return null;

    const {
      investmentPeriodOriginal,
      investmentPeriodCurrent,
      followOnPeriodOriginal,
      followOnPeriodCurrent,
      termExpirationOriginal,
      termExpirationCurrent,
    } = selectedFund;

    const getFormattedDate = (date: string): string => (date ? DateUtils.formatDate(new Date(date), DateFormat) : "");

    return [
      {
        fieldDescriptor: KeyDatesRows.InvestmentPeriod,
        original: getFormattedDate(investmentPeriodOriginal),
        current: getFormattedDate(investmentPeriodCurrent),
      },
      {
        fieldDescriptor: KeyDatesRows.FollowOnPeriod,
        original: getFormattedDate(followOnPeriodOriginal),
        current: getFormattedDate(followOnPeriodCurrent),
      },
      {
        fieldDescriptor: KeyDatesRows.TermExpiration,
        original: getFormattedDate(termExpirationOriginal),
        current: getFormattedDate(termExpirationCurrent),
      },
    ];
  }
);

export const WaterfallTermsDataGridSelector = createSelector(
  (state: RootState, id: string) => selectedFundSelector(state, id),
  preferencesSelector,
  (selectedFund, { NumberFormat }) => {
    if (!selectedFund) return null;

    const { catchUpSplitByGpLp, hurdleRatePct, carriedIntPct } = selectedFund;

    const getFormattedNumber = (number: number): string => (number ? `${formatNumber(number, NumberFormat, 2)}%` : "");

    return [
      {
        fieldDescriptor: WaterfallTermsRows.InterestCatchUpRate,
        fieldData: catchUpSplitByGpLp,
      },
      {
        fieldDescriptor: WaterfallTermsRows.PreferredReturn,
        fieldData: getFormattedNumber(hurdleRatePct),
      },
      {
        fieldDescriptor: WaterfallTermsRows.CarriedInterestRate,
        fieldData: getFormattedNumber(carriedIntPct),
      },
    ];
  }
);

export const fsisNotePositionsSelector = createSelector(
  selectedFsisReports,
  selectedFsisFlocReports,
  selectedFsisDateSelector,
  (_state: RootState, options: InvestmentScheduleSelectorOptions) => options.fundId,
  (
    { data: fsisReports },
    { data: flocReports },
    date,
    fundId
  ): Nullable<Record<string, { position: number; notes: string }>> => {
    if (date === null || !fsisReports?.[fundId]?.[date] || !flocReports?.[fundId]?.[date]) {
      return null;
    }

    const noteReports = fsisReports[fundId][date].filter(({ notes }) => !!notes);

    const flocNoteReports = flocReports[fundId][date].filter(({ notes }) => !!notes);

    const fsisNotes = noteReports.reduce(
      (result, { companyName, notes }, index) => ({
        ...result,
        [companyName]: { position: index + 4, notes },
      }),
      {}
    );

    const flocNotes = flocNoteReports.reduce(
      (result, { adjustmentTypeValue, notes }, index) => ({
        ...result,
        [adjustmentTypeValue]: {
          position: noteReports.length + index + 4,
          notes,
        },
      }),
      {}
    );

    return { ...fsisNotes, ...flocNotes };
  }
);

const filterReports = (
  reports: FSISReport[],
  status: string,
  sortByDate?: keyof Pick<FSISReport, "exitDate" | "acquisitionDate" | "reportingDate">
): FSISReport[] => {
  const filteredReports = reports.filter(({ investStatusDesc }) => investStatusDesc === status);

  return sortByDate
    ? filteredReports.sort(
        (current, next) => new Date(current[sortByDate]).valueOf() - new Date(next[sortByDate]).valueOf()
      )
    : filteredReports;
};

const calculateTotal = (
  reports: (FSISReport | FsisReportTotal | FsisFlocReport)[],
  excludedFields: string[] = ["moic", "previousQuarterMOIC", "endPreviousYearMOIC", "sameQuarterPreviousYearMOIC"]
): FsisReportTotal => {
  const total = reports.reduce((result: FsisReportTotal, report) => {
    Object.entries(report).forEach(([key, value]) => {
      if (excludedFields.includes(key)) {
        return;
      }

      result[key] = typeof value === "number" ? Number(result[key] ?? 0) + value : null;
    });

    return result;
  }, {});

  const totalValue = Number(total.totalValue);
  const equityInvested = Number(total.equityInvested);
  const hasMoic = !Number.isNaN(totalValue) && !Number.isNaN(equityInvested);

  return hasMoic
    ? {
        ...total,
        moic: totalValue / equityInvested,
      }
    : total;
};

export const moicSelector = createSelector(
  selectedFsisDates,
  (_state: RootState, date: { year: number; quarter: number }) => date,
  ({ data: fsisDates }, { year, quarter }): MoicMeta => {
    if (fsisDates === null) {
      return {
        hasPreviousQuarterMoic: quarter !== QuarterValue.First,
        hasPreviousYearMoic: quarter !== QuarterValue.Third,
        previousQuarterDate: null,
        previousYearLastQuarterDate: null,
        previousYearSameQuarterDate: null,
      };
    }

    const asOfEndDate = DateUtils.convertYearQuarterToEndDate(year, quarter);

    const hasPreviousQuarterMoic = quarter !== QuarterValue.First;
    const hasPreviousYearMoic = quarter !== QuarterValue.Fourth;

    return {
      hasPreviousQuarterMoic,
      hasPreviousYearMoic,
      previousQuarterDate: hasPreviousQuarterMoic
        ? format(lastDayOfMonth(subMonths(asOfEndDate, 3)), "MM-dd-yyyy")
        : null,
      previousYearLastQuarterDate: hasPreviousYearMoic
        ? format(lastDayOfYear(subYears(asOfEndDate, 1)), "MM-dd-yyyy")
        : null,
      previousYearSameQuarterDate: format(subYears(asOfEndDate, 1), "MM-dd-yyyy"),
    };
  }
);

export const fsisReportsByInvestmentStatusSelector = createSelector(
  selectedFsisFlocReports,
  selectedFsisReports,
  selectedFsisDateSelector,
  (_state: RootState, options: InvestmentScheduleSelectorOptions) => options.fundId,
  ({ data: flocReports }, { data: reports }, date, fundId): FsisReportsByInvestmentStatus => {
    const fundFlocReports = date === null ? null : flocReports?.[fundId]?.[date] ?? null;

    const fundReports = date === null ? null : reports?.[fundId]?.[date] ?? null;

    if (fundFlocReports === null || fundReports === null) {
      return {
        realizedReports: [],
        realizedTotal: null,
        unrealizedReports: [],
        unrealizedTotal: null,
        publicTradeReports: [],
        publicTradeTotal: null,
        adjustmentReports: [],
        adjustmentTotal: null,
        flocFirstThirdReports: [],
        flocSecondFourthReports: [],
        totalInvestment: null,
        totalFunds: null,
      };
    }

    const realizedReports = filterReports(fundReports, InvestmentStatusDescriptor.Realized, "exitDate");

    const realizedTotal = calculateTotal(realizedReports);

    const unrealizedReports = filterReports(
      fundReports,
      InvestmentStatusDescriptor.UnrealizedPartiallyRealized,
      "acquisitionDate"
    );

    const unrealizedTotal = calculateTotal(unrealizedReports);

    const publicTradeReports = filterReports(fundReports, InvestmentStatusDescriptor.PubliclyTraded, "acquisitionDate");

    const publicTradeTotal = calculateTotal(publicTradeReports);

    const adjustmentReports = filterReports(fundReports, InvestmentStatusDescriptor.Adjustments);

    const adjustmentTotal = calculateTotal(adjustmentReports);

    const flocFirstThirdReports = fundFlocReports.filter(
      ({ adjustmentTypeID }) =>
        adjustmentTypeID === FsisFlocReportAdjustmentTypeId.First ||
        adjustmentTypeID === FsisFlocReportAdjustmentTypeId.Third
    );

    const flocSecondFourthReports = fundFlocReports.filter(
      ({ adjustmentTypeID }) =>
        adjustmentTypeID === FsisFlocReportAdjustmentTypeId.Second ||
        adjustmentTypeID === FsisFlocReportAdjustmentTypeId.Forth
    );

    const totalInvestment = calculateTotal([realizedTotal, unrealizedTotal, publicTradeTotal, adjustmentTotal]);

    const totalFunds = calculateTotal([...fundReports, ...fundFlocReports, adjustmentTotal]);

    return {
      realizedReports,
      realizedTotal,
      unrealizedReports,
      unrealizedTotal,
      publicTradeReports,
      publicTradeTotal,
      adjustmentReports,
      adjustmentTotal,
      flocFirstThirdReports,
      flocSecondFourthReports,
      totalInvestment,
      totalFunds,
    };
  }
);

const sisGridFormatter = ({
  numberFormat,
  notePositions,
}: {
  numberFormat: NumberFormat;
  notePositions: Record<string, number>;
}): Record<string, Function> => ({
  companyName: (value: string): string | JSX.Element =>
    notePositions[value]
      ? React.createElement("span", null, `${value}\xA0`, React.createElement("sup", null, notePositions[value]))
      : value,
  acquisitionDate: (value: string): string =>
    // @todo Fix backend response: date can be null | string but not "null"
    value && value !== "null" ? format(new Date(value), "MMM-yy") : "",
  equityInvested: (value: number): string => NumberUtils.formatNumber(value ?? 0, numberFormat, 1),
  cashReceived: (value: number): string => NumberUtils.formatNumber(value ?? 0, numberFormat, 1),
  remainingFmv: (value: number): string => NumberUtils.formatNumber(value ?? 0, numberFormat, 1),
  totalValue: (value: number): string => NumberUtils.formatNumber(value ?? 0, numberFormat, 1),
  moic: (value: Nullable<number>): string =>
    value === null ? "N/A" : `${NumberUtils.formatNumber(value ?? 0, numberFormat, 2)}x`,
  previousQuarterMOIC: (value: number): string =>
    value === null ? "N/A" : `${NumberUtils.formatNumber(value ?? 0, numberFormat, 2)}x`,
  endPreviousYearMOIC: (value: number): string =>
    value === null ? "N/A" : `${NumberUtils.formatNumber(value ?? 0, numberFormat, 2)}x`,
  sameQuarterPreviousYearMOIC: (value: number): string =>
    value === null ? "N/A" : `${NumberUtils.formatNumber(value ?? 0, numberFormat, 2)}x`,
});

const formatFsisGridRow = ({
  report,
  numberFormat,
  notePositions,
}: {
  report: Partial<FSISReport> | FsisFlocReport | FsisReportTotal;
  numberFormat: NumberFormat;
  notePositions: Record<string, number>;
}) => {
  const formatters = sisGridFormatter({ numberFormat, notePositions });

  return Object.entries(report).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: formatters?.[key]?.(value) ?? value,
    }),
    {}
  );
};

const formatFsisGridRows = ({
  reports,
  numberFormat,
  notePositions,
}: {
  reports: (Partial<FSISReport> | FsisFlocReport | FsisReportTotal)[];
  numberFormat: NumberFormat;
  notePositions: Record<string, number>;
}) => {
  return reports.map((report) => {
    return formatFsisGridRow({ report, numberFormat, notePositions });
  });
};

export const sisGridDataSelector = createSelector(
  selectedFsisFlocReports,
  selectedHistoricMoic,
  fsisNotePositionsSelector,
  fsisReportsByInvestmentStatusSelector,
  selectedFsisDateSelector,
  preferencesSelector,
  (_state: RootState, options: InvestmentScheduleSelectorOptions) => options.fundId,
  (_state: RootState, options: InvestmentScheduleSelectorOptions) => options.currency,
  (
    { data: flocReports },
    { data: historicMoic },
    noteMap,
    reports,
    date,
    { NumberFormat: numberFormat },
    fundId,
    currency
  ): ReadonlyArray<FSISReport & BaseRow> => {
    if (date === null || !flocReports?.[fundId]?.[date] || !historicMoic?.[fundId]?.[date]) {
      return [];
    }

    if (noteMap === null) {
      return [];
    }

    const notePositions = Object.entries(noteMap).reduce<Record<string, number>>((acc, [key, { position }]) => {
      acc[key] = position;

      return acc;
    }, {});

    const {
      realizedReports,
      realizedTotal,
      unrealizedReports,
      unrealizedTotal,
      publicTradeReports,
      publicTradeTotal,
      adjustmentReports,
      flocFirstThirdReports,
      flocSecondFourthReports,
      totalInvestment,
      totalFunds,
    } = reports;

    return flow(
      (rows) => {
        return realizedReports.length && realizedTotal
          ? [
              ...rows,
              {
                companyName: "Realized Investments",
                rowMeta: {
                  type: RowType.Divider,
                },
              },
              ...formatFsisGridRows({
                reports: realizedReports.map((report) => ({
                  ...report,
                  rowMeta: {
                    currency,
                  },
                })),
                numberFormat,
                notePositions,
              }),
              {
                ...formatFsisGridRow({
                  report: realizedTotal,
                  numberFormat,
                  notePositions,
                }),
                companyName: "Total Realized Investments",
                rowMeta: {
                  type: RowType.SubHeader,
                  currency,
                },
              },
            ]
          : rows;
      },
      (rows) => {
        return publicTradeReports.length && publicTradeTotal
          ? [
              ...rows,
              {
                companyName: "Publicly Traded Investments",
                rowMeta: {
                  type: RowType.Divider,
                },
              },
              ...formatFsisGridRows({
                reports: publicTradeReports.map((report) => ({
                  ...report,
                  rowMeta: {
                    currency,
                  },
                })),
                numberFormat,
                notePositions,
              }),
              {
                ...formatFsisGridRow({
                  report: publicTradeTotal,
                  numberFormat,
                  notePositions,
                }),
                companyName: "Total Publicly Traded Investments",
                rowMeta: {
                  type: RowType.SubHeader,
                  currency,
                },
              },
            ]
          : rows;
      },
      (rows) => {
        return unrealizedReports.length && unrealizedTotal
          ? [
              ...rows,
              {
                companyName: "Unrealized/Partially Realized Investments",
                rowMeta: {
                  type: RowType.Divider,
                },
              },
              ...formatFsisGridRows({
                reports: unrealizedReports.map((report) => ({
                  ...report,
                  rowMeta: {
                    currency,
                  },
                })),
                numberFormat,
                notePositions,
              }),
              {
                ...formatFsisGridRow({
                  report: unrealizedTotal,
                  numberFormat,
                  notePositions,
                }),
                companyName: "Total Unrealized/Partially Realized Investments",
                rowMeta: {
                  type: RowType.SubHeader,
                  currency,
                },
              },
            ]
          : rows;
      },
      (rows) => {
        return [
          ...rows,
          ...formatFsisGridRows({
            reports: flocFirstThirdReports.map((report) => ({
              ...report,
              companyName: report.adjustmentTypeValue,
              rowMeta: {
                currency,
              },
            })),
            numberFormat,
            notePositions,
          }),
        ];
      },
      (rows) => {
        return !isEmpty(totalInvestment)
          ? [
              ...rows,
              {
                ...formatFsisGridRow({
                  report: {
                    ...totalInvestment,
                    endPreviousYearMOIC: historicMoic?.[fundId]?.[date]?.endPreviousYearMOIC,
                    previousQuarterMOIC: historicMoic?.[fundId]?.[date]?.previousQuarterMOIC,
                    sameQuarterPreviousYearMOIC: historicMoic?.[fundId]?.[date]?.sameQuarterPreviousYearMOIC,
                  },
                  numberFormat,
                  notePositions,
                }),
                companyName: "Total Investments",
                rowMeta: {
                  type: RowType.SubHeader,
                  currency,
                },
              },
            ]
          : rows;
      },
      (rows) => {
        return [
          ...rows,
          ...formatFsisGridRows({
            reports: flocSecondFourthReports.map((report) => ({
              ...report,
              companyName: report.adjustmentTypeValue,
              rowMeta: {
                currency,
              },
            })),
            numberFormat,
            notePositions,
          }),
        ];
      },
      (rows) => {
        return adjustmentReports.length
          ? [
              ...rows,
              ...formatFsisGridRows({
                reports: adjustmentReports.map(
                  ({ moic, previousQuarterMOIC, endPreviousYearMOIC, sameQuarterPreviousYearMOIC, ...report }) => ({
                    ...report,
                    rowMeta: {
                      currency,
                    },
                  })
                ),
                numberFormat,
                notePositions,
              }),
            ]
          : rows;
      },
      (rows) => {
        return totalInvestment && !isEmpty(totalInvestment) && totalFunds && !isEmpty(totalFunds)
          ? [
              ...rows,
              {
                ...formatFsisGridRow({
                  report: totalFunds,
                  numberFormat,
                  notePositions,
                }),
                companyName: "Total Fund",
                rowMeta: {
                  type: RowType.SubHeader,
                  currency,
                },
              },
            ]
          : rows;
      }
    )([]);
  }
);

export const selectFSISAppreciation = (state: RootState): FundsState["fundFSISAppreciation"] =>
  state.funds.fundFSISAppreciation;

export const isFundDocumentsDataLoadingSelector = createSelector(
  fundDocumentsSelector,
  (fundDocuments) => fundDocuments.loading
);

export const fundDocumentsDataGridRowsSelector = createSelector(
  fundDocumentsSelector,
  preferencesSelector,
  (fundDocuments, { DateFormat }): Nullable<FundDocumentsRow[]> => {
    if (!fundDocuments.data) {
      return null;
    }

    return fundDocuments.data.map((document) => ({
      id: document.id,
      publishDate:
        document.publishDate && document.documentStatus === DocumentStatus.Active
          ? DateUtils.formatDate(new Date(document.publishDate), DateFormat)
          : "N/A",
      investorAccountName: document.investorAccountName || "All Investors",
      markedRead: document.markedRead,
      actionItem: document.actionItem,
      documentStatus: document.documentStatus,
      title: document.title,
      fileType: document.fileType,
      fundId: document.fundId,
      documentSubtype: document.documentSubtype,
      isLoading: document.isLoading,
      rowMeta: {
        type:
          document.documentStatus === DocumentStatus.Pending ||
          document.documentStatus === DocumentStatus.PendingApproval
            ? RowType.Highlighted
            : RowType.Regular,
      },
    }));
  }
);

export const investmentScheduleSelector = createSelector(
  selectedFsisDates,
  selectedFsisReports,
  selectedFsisDateSelector,
  sortedFsisDateOptionsSelector,
  selectedFsisFlocReports,
  selectedHistoricMoic,
  latestFsisAsOfDateOptionSelector,
  selectedSummaryInvestmentScheduleFilters,
  (state: RootState, options: InvestmentScheduleSelectorOptions) => selectedFsisReportSelector(state, options.fundId),
  (state: RootState, options: InvestmentScheduleSelectorOptions) => sisGridDataSelector(state, options),
  (
    fsisDates,
    fsisReports,
    selectedFsisDate,
    fsisDatesOptions,
    flocReports,
    historicMoic,
    latestFsisAsOfDate,
    filters,
    selectedFsisReport,
    gridData
  ) => ({
    fsisDates,
    fsisReports,
    selectedFsisDate,
    fsisDatesOptions,
    flocReports,
    historicMoic,
    latestFsisAsOfDate,
    filters,
    selectedFsisReport,
    gridData,
  })
);
