import { createSlice } from "@reduxjs/toolkit";
import { VideoState } from "@videos/models";

const initialState: VideoState = {
  videoName: null,
  videoId: null,
  videoToken: null,
  isOpenVideoDialog: false,
};

export const videosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    resetState: (state) => {
      state.videoName = initialState.videoName;
      state.videoId = initialState.videoId;
      state.videoToken = initialState.videoToken;
      state.isOpenVideoDialog = initialState.isOpenVideoDialog;
    },
    openVideoDialog: (state, action) => {
      state.videoName = action.payload.videoName;
      state.videoId = action.payload.videoId;
      state.videoToken = action.payload.videoToken;
      state.isOpenVideoDialog = true;
    },
    closeVideoDialog: (state) => {
      state.videoName = initialState.videoName;
      state.videoId = initialState.videoId;
      state.videoToken = initialState.videoToken;
      state.isOpenVideoDialog = false;
    },
  },
});

export const { resetState, openVideoDialog, closeVideoDialog } = videosSlice.actions;

export default videosSlice.reducer;
