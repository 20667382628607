import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFSISArgs, FSISReport } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchFSISReports = createAsyncThunk<FSISReport[], fetchFSISArgs, ThunkApiConfig>(
  "funds/fetchFSISReports",
  async ({ fundId, date }, { rejectWithValue, extra, signal }) => {
    try {
      const { status, data } = await extra<FSISReport[]>(
        `/reporting/v1/FPSIS/${encodeURIComponent(fundId)}:${encodeURIComponent(date.split("T")[0])}`,
        { signal }
      );

      if (status === 404) {
        throw Error("FSIS reports not found");
      }

      return data;
    } catch (e) {
      return rejectWithValue("Failed to fetch FSIS reports");
    }
  }
);
