import { Year, ListOptionBase } from "@shared/components";

export const dateOptions: Array<
  ListOptionBase<"ALL_DATES" | "CURRENT_QUARTER" | "PREVIOUS_QUARTER" | "CURRENT_YEAR" | "EXACT_RANGE">
> = [
  { label: "All Dates", id: "ALL_DATES" },
  {
    label: "Last Published Quarter",
    id: "CURRENT_QUARTER",
  },
  {
    label: "Last Two Published Quarters",
    id: "PREVIOUS_QUARTER",
  },
  {
    label: "Since Beginning of Year",
    id: "CURRENT_YEAR",
  },
  { label: "Exact Date Range", id: "EXACT_RANGE" },
];

const quarterOptionsStart = [
  { label: "Oct. 1", id: 4 },
  { label: "Jul. 1", id: 3 },
  { label: "Apr. 1", id: 2 },
  { label: "Jan. 1", id: 1 },
];

const quarterOptionsEnd = [
  { label: "Mar. 31", id: 1 },
  { label: "Jun. 30", id: 2 },
  { label: "Sep. 30", id: 3 },
  { label: "Dec. 31", id: 4 },
];

export const calculateYearOptions = (type: "start" | "end"): Year[] => {
  const startingYear = 1991;
  const currentDate = new Date();
  const endingYear = currentDate.getMonth() <= 2 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

  const yearOptions: Year[] = [];

  for (let iYear = endingYear; iYear >= startingYear; iYear--) {
    yearOptions.push({
      label: String(iYear),
      id: iYear,
      quarters: type === "start" ? quarterOptionsStart : quarterOptionsEnd,
    });
  }

  return yearOptions;
};

export const getDefaultStartDate = () => {
  const currentDate = new Date();

  if (currentDate.getMonth() <= 2) {
    return new Date(Date.UTC(currentDate.getFullYear() - 1, 9, 1));
  } else if (currentDate.getMonth() <= 5) {
    return new Date(Date.UTC(currentDate.getFullYear(), 0, 1));
  } else if (currentDate.getMonth() <= 8) {
    return new Date(Date.UTC(currentDate.getFullYear(), 3, 1));
  } else {
    return new Date(Date.UTC(currentDate.getFullYear(), 6, 1));
  }
};

export const getDefaultEndDate = () => {
  const currentDate = new Date();

  if (currentDate.getMonth() <= 2) {
    return new Date(Date.UTC(currentDate.getFullYear() - 1, 11, 31));
  } else if (currentDate.getMonth() <= 5) {
    return new Date(Date.UTC(currentDate.getFullYear(), 2, 31));
  } else if (currentDate.getMonth() <= 8) {
    return new Date(Date.UTC(currentDate.getFullYear(), 5, 30));
  } else {
    return new Date(Date.UTC(currentDate.getFullYear(), 8, 30));
  }
};
