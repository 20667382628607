import { createSelector } from "@reduxjs/toolkit";
import { ImpersonationState } from "@impersonateUser/models";
import { RootState } from "@store/index";
import { userSegmentFunctionSelector } from "@user/store/selectors";
import { ProductSegment } from "@user/models";

export const impersonationStateSelector = (state: RootState): ImpersonationState => state.impersonation;

export const isAdminNotImpersonatingSelector = createSelector(
  impersonationStateSelector,
  ({ canImpersonate, isImpersonating }) => canImpersonate && !isImpersonating
);

export const isAlpInvestAdminNotImpersonatingSelector = createSelector(
  impersonationStateSelector,
  userSegmentFunctionSelector,
  ({ isImpersonating }, userSegmentFunction) =>
    !isImpersonating &&
    Boolean(userSegmentFunction?.some((segmentFunction) => segmentFunction === ProductSegment.AlpInvestAdmin))
);
