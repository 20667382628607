import DateUtils from "@shared/utils/DateUtils";
import { Nullable } from "@shared/models/general";

export const dateConverter = (selectedDate: Nullable<Date>) => {
  const currentDate = new Date();

  if (!selectedDate) {
    selectedDate = currentDate;
  }

  return {
    start: DateUtils.setUtcTimeZone(DateUtils.setStartOfDay(selectedDate)),
    end: DateUtils.setUtcTimeZone(DateUtils.setEndOfDay(selectedDate)),
  };
};
