import React, { FunctionComponent, useCallback, useState, memo } from "react";
import { Box, Collapse, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RoundedIcon } from "@shared/components/RoundedIcon";
import { ReactComponent as MinusIcon } from "@assets/svg/minus.svg";
import { ReactComponent as PlusIcon } from "@assets/svg/plus.svg";
import { TileProps } from "./models";

const useStyles = makeStyles<Theme, Pick<TileProps, "expandable"> & { active: boolean }>((theme) => ({
  helpText: {
    alignItems: "center",
    display: "flex",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
    justifyContent: "center",
    fontSize: 14,
    paddingTop: theme.spacing(5),
    color: theme.palette.grey[800],
  },
  tileTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    transition: "all ease-in .15s",
    marginBottom: ({ active, expandable }) => theme.spacing(!expandable || active ? 5 : 0),
    "&:hover": {
      cursor: ({ expandable }) => (expandable ? "pointer" : "auto"),
      "& span": {
        color: ({ expandable }) => (expandable ? theme.palette.info.main : "auto"),
      },
      "& > div": {
        backgroundColor: theme.palette.info.main,
      },
    },
  },
  titleText: {
    textTransform: "uppercase",
    width: "100%",
    transition: "all ease-in .15s",
  },
}));

const Tile: FunctionComponent<TileProps> = ({
  children,
  title,
  expandable = false,
  open = true,
  uncontrolled = true,
  expanded = false,
  onToggle,
  footnote,
  testId,
}) => {
  const [expandedInternal, setExpandedInternal] = useState(false);

  const onClick = useCallback(() => {
    if (!expandable) {
      return;
    }

    if (uncontrolled) {
      setExpandedInternal((value) => !value);
    } else {
      onToggle?.(!expanded);
    }
  }, [uncontrolled, expanded, expandable, onToggle]);

  const active = uncontrolled ? expandedInternal : expanded;

  const classes = useStyles({ expandable, active });

  const ExpandIcon = active ? MinusIcon : PlusIcon;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Collapse in={open}>
      <Box
        bgcolor="background.paper"
        sx={{
          width: 1,
          padding: theme.spacing(10),
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(7.5),
          },
        }}
      >
        {title && (
          <Box className={classes.tileTitle} onClick={onClick}>
            <Typography
              variant={isSmall ? "subhead3" : "subhead2"}
              className={classes.titleText}
              data-testid={testId ? `reporting-tile-title-${testId}` : "reporting-tile-title"}
            >
              {title}
            </Typography>
            {expandable && <RoundedIcon Icon={ExpandIcon} interactive testId={testId} />}
          </Box>
        )}
        <Collapse in={expandable ? active : true} data-testid={testId ? `tile-children-${testId}` : "tile-children"}>
          {children}
        </Collapse>
        <Collapse in={!!footnote}>
          <Box mt={15} fontSize={16} className={classes.helpText}>
            {footnote}
          </Box>
        </Collapse>
      </Box>
    </Collapse>
  );
};

export default memo(Tile);
