import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AccountSummaryReport,
  AccountSummaryReportRequestBody,
  AccountSummaryReportResponse,
} from "@accountSummaryCarlyle/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { filterValuesSelector } from "@accountSummaryCarlyle/store/selectors";
import DateUtils from "@shared/utils/DateUtils";
import { ReportingPeriodTypes } from "@shared/models/reporting";

export const fetchAccountSummaryReport = createAsyncThunk<AccountSummaryReport[], void, ThunkApiConfig>(
  "accountSummary/fetchAccountSummaryReport",
  async (_, { rejectWithValue, getState, extra, signal }) => {
    try {
      const filters = filterValuesSelector(getState());
      const fundArray: string[] = [];

      if (!filters.funds || !filters.asOfDate) {
        throw new Error("No required data provided");
      }

      // Set fundArray = all selected items in the Fund filter
      for (const [id, fund] of Object.entries(filters.funds)) {
        if (fund.selected) {
          fundArray.push(id);
        }
      }

      const year = filters.asOfDate.year.toString();
      const quarter = filters.asOfDate.quarter;
      const reportingDate = DateUtils.convertYearQuarterToEndDate(year, quarter);

      const current = !!filters.asOfDate.current;
      const fundIds = fundArray.length ? fundArray : Object.keys(filters.funds);

      const body = {
        fundIds,
        reportingDate,
        period: ReportingPeriodTypes.ITD,
        current,
      };

      const response = await extra<AccountSummaryReportResponse, AccountSummaryReportRequestBody>(
        "/reporting/v1/Cas/report",
        {
          method: "post",
          body,
          signal,
        }
      );

      if (response.status === 204) {
        return [];
      }

      return response.data.reports;
    } catch (error) {
      return rejectWithValue("Failed to fetch Account Summary report");
    }
  }
);
