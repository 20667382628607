import React, { useCallback } from "react";
import InvestorRequestEntitiesDropdowns from "@investorRequest/components/InvestorRequestEntitiesDropdowns";
import { investorRequestPreparedDataSelector, investorRequestSelector } from "@investorRequest/store/selectors";
import InvestorRequestFormTitle from "@investorRequest/components/InvestorRequestFormTitle";
import InvestorRequestTextarea from "@investorRequest/components/InvestorRequestTextarea";
import { updateCommonRequestData } from "@investorRequest/store/ducks";
import { useAppDispatch, useAppSelector } from "@app/hooks";

const GeneralRequest = () => {
  const { funds, accounts, message } = useAppSelector(investorRequestPreparedDataSelector);
  const { validationWarningShown } = useAppSelector(investorRequestSelector);
  const dispatch = useAppDispatch();

  const handleMessageChange = useCallback(
    (message: string) => dispatch(updateCommonRequestData({ message })),
    [dispatch]
  );

  return (
    <>
      <div data-testid="general-request" />
      <InvestorRequestFormTitle text="Related to:" />
      <InvestorRequestEntitiesDropdowns accounts={accounts} funds={funds} />
      <InvestorRequestTextarea
        placeholder="Tell us briefly how we can assist you"
        value={message}
        onChange={handleMessageChange}
        error={validationWarningShown && !message}
      />
    </>
  );
};

export default GeneralRequest;
