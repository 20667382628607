export enum ReportSections {
  YourInterest = "yourInterest",
  CarlyleGroup = "carlyleGroup",
  TotalFund = "totalFund",
}

export const reportSectionHeaders: Record<ReportSections, string> = {
  [ReportSections.YourInterest]: "Your Interest",
  [ReportSections.CarlyleGroup]: "Carlyle Group*",
  [ReportSections.TotalFund]: "Total Fund",
};

export enum ReportSubSections {
  Main = "main",
  RemainingCommitment = "remainingCommitment",
  TotalContributionsOutsideOfCommitment = "totalContributionsOutsideOfCommitment",
}
