import { FooterLink, FooterLinkLabel } from "@app/models/Footer";
import { AppRoute } from "@app/models/AppRoute";
import { Permission } from "@user/models";
import { AccountSummaryReportName } from "@reporting/models";
import FeatureToggleService from "@services/FeatureToggleService";
import { ToggledFeature } from "@shared/models/featureToggle";
import { GLOBAL_INSIGHTS_URL } from "@app/constants/globalUrls";

export const getFooterLinks: (permissions: Record<Permission, boolean>) => FooterLink[] = (permissions) => [
  {
    isActive: permissions.ViewAccountSummaryCarlyle,
    label: permissions.ViewAccountSummaryAlpInvest ? AccountSummaryReportName.Carlyle : AccountSummaryReportName.Single,
    getLink: () => AppRoute.AccountSummaryCarlyle,
  },
  {
    isActive: permissions.ViewAccountSummaryAlpInvest,
    label: permissions.ViewAccountSummaryCarlyle ? AccountSummaryReportName.AlpInvest : AccountSummaryReportName.Single,
    getLink: () => AppRoute.AccountSummaryAlpInvest,
  },
  {
    isActive: true,
    label: FooterLinkLabel.DataRooms,
    getLink: () => AppRoute.DataRooms,
  },
  {
    isActive: true,
    label: FooterLinkLabel.GlobalInsights,
    getLink: () => GLOBAL_INSIGHTS_URL,
  },
  {
    isActive:
      permissions.ViewReportsCarlyle ||
      permissions.ViewPortfolioPerformanceReport ||
      permissions.ViewDiversificationAnalysisReport,
    label: FooterLinkLabel.Reporting,
    getLink: () => AppRoute.ReportingBase,
  },
  {
    isActive: true,
    label: FooterLinkLabel.Documents,
    getLink: () => AppRoute.Documents,
  },
  {
    isActive: FeatureToggleService.isFeatureEnabled(ToggledFeature.MyCalendar),
    label: FooterLinkLabel.MyCalendar,
    getLink: () => AppRoute.MyCalendar,
  },
  {
    isActive: true,
    label: FooterLinkLabel.FAQs,
    getLink: (link: string) => link || AppRoute.Help,
  },
];
