import { SoiDataGridFundInvestmentsValues } from "@scheduleOfInvestments/models";
import { RowType } from "@shared/components";

export const onlyFairValueRowsConfig = [
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.FairValueOfEscrow,
    accessor: "fairValueOfEscrow",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.FairValueOfGuaranteeObligations,
    accessor: "fairValueOfGuaranteeObligations",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.FairValueOfHedges,
    accessor: "fairValueOfHedges",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.NonControllingInterestInSubsidiary,
    accessor: "nonControllingInterestInSubsidiary",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.InterestReceivable,
    accessor: "interestReceivable",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.UncalledInvestmentsAtCost,
    accessor: "uncalledInvestmentsAtCost",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.UndistributedProceeds,
    accessor: "undistributedProceeds",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.InvestmentsAtFairValueGAAP,
    accessor: "investmentsAtFairValueGaap",
    type: RowType.SubHeader,
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.TieInToCapitalAccountAtFairValue,
    accessor: "",
    type: RowType.SubHeader,
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.AccruedCarry,
    accessor: "accruedCarry",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.AdvancesToPortfolioCompanies,
    accessor: "advancesToPortfolioCompanies",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.CashAndCashEquivalents,
    accessor: "cashAndCashEquivalents",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.RestrictedCash,
    accessor: "restrictedCash",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.ProceedsAvailableFromInvestments,
    accessor: "proceedsAvailableFromInvestments",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.DueFromToPartners,
    accessor: "dueFromToPartners",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.DueFromToAffiliates,
    accessor: "dueFromToAffiliates",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.PrepaidManagementFees,
    accessor: "prepaidManagementFees",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.Deposits,
    accessor: "deposits",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.DeferredFinancingCostsNet,
    accessor: "deferredFinancingCostsNet",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.OtherAssets,
    accessor: "otherAssets",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.DerivativeInstrumentsAtFairValue,
    accessor: "hedgeInstrumentsAtFairValue",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.BankCreditFacility,
    accessor: "bankCreditFacility",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.NotePayable,
    accessor: "notePayable",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.DeferredCredit,
    accessor: "deferredCredit",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.DeferredGain,
    accessor: "deferredGain",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.GuaranteeObligations,
    accessor: "guaranteeObligations",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.OtherLiabilities,
    accessor: "otherLiabilities",
  },
  {
    fundInvestments: SoiDataGridFundInvestmentsValues.CapitalAccountAtFairValue,
    accessor: "capitalAccountAtFairValue",
    type: RowType.SubHeader,
  },
];
