import { format } from "date-fns";
import { DateFormat } from "@user/models";

export default class DateUtils {
  static dateFnsFormats = {
    [DateFormat.MonthDayYearDash]: "LLL - dd - yyyy",
    [DateFormat.MonthDayYearSpace]: "LLLL dd, yyyy",
    [DateFormat.MonthDayYearSlash]: "M/d/yyyy",
    [DateFormat.DayMonthYearDot]: "dd.M.yyyy",
    [DateFormat.YearMonthDayDot]: "yyyy.M.dd",
  };

  static convertYearQuarterToStartDate(year: number | string, quarter: number | string) {
    const day =
      (Number(quarter) === 1 && 1) ||
      (Number(quarter) === 2 && 1) ||
      (Number(quarter) === 3 && 1) ||
      (Number(quarter) === 4 && 1);
    const month =
      (Number(quarter) === 1 && 0) ||
      (Number(quarter) === 2 && 3) ||
      (Number(quarter) === 3 && 6) ||
      (Number(quarter) === 4 && 9);

    return new Date(Date.UTC(Number(year), Number(month), Number(day)));
  }

  static convertYearQuarterToEndDate(year: number | string, quarter: number | string) {
    const day =
      (Number(quarter) === 1 && 31) ||
      (Number(quarter) === 2 && 30) ||
      (Number(quarter) === 3 && 30) ||
      (Number(quarter) === 4 && 31);
    const month =
      (Number(quarter) === 1 && 2) ||
      (Number(quarter) === 2 && 5) ||
      (Number(quarter) === 3 && 8) ||
      (Number(quarter) === 4 && 11);

    return new Date(Date.UTC(Number(year), Number(month), Number(day)));
  }

  static convertDateToYearQuarter(date: Date): {
    year: number;
    quarter: number;
  } {
    const timeZoneOffsetMs = date.getTimezoneOffset() * 60 * 1000;
    const utcDate = new Date(date.getTime() + timeZoneOffsetMs);

    let quarter;
    const year = utcDate.getFullYear();
    const month = utcDate.getMonth();

    if (month <= 2) {
      quarter = 1;
    } else if (month <= 5) {
      quarter = 2;
    } else if (month <= 8) {
      quarter = 3;
    } else {
      quarter = 4;
    }

    return { year, quarter };
  }

  static formatDate(date: Date, dateFormat: DateFormat) {
    return format(date, DateUtils.dateFnsFormats[dateFormat]);
  }

  static setStartOfDay(date: Date): Date {
    const startOfDayDate = new Date(date.getTime());

    startOfDayDate.setHours(0);
    startOfDayDate.setMinutes(0);
    startOfDayDate.setSeconds(0);
    startOfDayDate.setMilliseconds(0);

    return startOfDayDate;
  }

  static setEndOfDay(date: Date): Date {
    const endOfDayDate = new Date(date.getTime());

    endOfDayDate.setHours(23);
    endOfDayDate.setMinutes(59);
    endOfDayDate.setSeconds(59);
    endOfDayDate.setMilliseconds(999);

    return endOfDayDate;
  }

  static setUtcTimeZone(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  }
}
