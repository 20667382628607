import { useCallback, useEffect, useState } from "react";
import { ListOptionBase } from "@shared/components/List";

export const useListSearch = <ListOption extends ListOptionBase>(options: Array<ListOption>) => {
  const [searchValue, setSearchValue] = useState("");
  const [displayOptions, setDisplayOptions] = useState(options);

  useEffect(() => {
    setDisplayOptions(options);
  }, [options]);

  const handleSearch = useCallback(
    (value: string) => {
      const searchResult = options.filter((o) => o.label.toLowerCase().includes(value.toLowerCase()));

      setDisplayOptions(searchResult);
      setSearchValue(value);
    },
    [options]
  );

  return { searchValue, displayOptions, handleSearch };
};
