import { useLocation } from "react-router-dom";
import { AppRoute } from "@app/models/AppRoute";
import { HelpAnchor } from "@app/models/HelpAnchor";

const useHelpLink = () => {
  const { pathname } = useLocation();
  let anchor = "";

  switch (true) {
    case pathname === AppRoute.Home:
      anchor = HelpAnchor.Home;
      break;
    case pathname === AppRoute.AccountSummaryCarlyle:
      anchor = HelpAnchor.Accounts;
      break;
    case pathname === AppRoute.DataRooms:
      anchor = HelpAnchor.DataRooms;
      break;
    case pathname === AppRoute.CapitalAccountStatement:
    case pathname === AppRoute.ScheduleOfInvestments:
    case pathname === AppRoute.CashFlowActivity:
      anchor = HelpAnchor.Reports;
      break;
    case pathname === AppRoute.Documents:
      anchor = HelpAnchor.Documents;
      break;
    case pathname.includes("/fund-profile"):
      anchor = HelpAnchor.Funds;
      break;
    case pathname.includes(AppRoute.UserProfile):
      anchor = HelpAnchor.Login;
      break;
  }

  return `${AppRoute.Help}${anchor}`;
};

export default useHelpLink;
