import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReduxUtils from "@shared/utils/ReduxUtils";
import {
  AccountGroup,
  DocumentType,
  EntitiesState,
  EntitledAccount,
  EntitledFund,
  Fund,
  Funds,
  Investor,
  Investors,
} from "./models";
import {
  fetchAccountGroups,
  fetchAllFunds,
  fetchDocumentTypes,
  fetchEntitledAccounts,
  fetchEntitledAccountsByEntity,
  fetchEntitledFunds,
  fetchInvestors,
} from "./thunks";

const initialState: EntitiesState = {
  entitledAccountsByFundLoading: false,
  entitledAccounts: ReduxUtils.getAsyncSlice<Record<EntitledAccount["globalId"], EntitledAccount>>(),
  entitledFunds: ReduxUtils.getAsyncSlice<Record<EntitledFund["globalId"], EntitledFund>>(),
  documentTypes: ReduxUtils.getAsyncSlice<Record<DocumentType["documentTypeId"], DocumentType>>(),
  accountGroups: ReduxUtils.getAsyncSlice<AccountGroup[]>(),
  investors: ReduxUtils.getAsyncSlice<Investors>(),
  funds: ReduxUtils.getAsyncSlice<Funds>(),
};

const entitiesSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    resetState: (state) => {
      state.entitledAccounts = initialState.entitledAccounts;
      state.entitledFunds = initialState.entitledFunds;
      state.documentTypes = initialState.documentTypes;
      state.investors = initialState.investors;
      state.accountGroups = initialState.accountGroups;
    },
    createAccountGroupLocally: (state, action: PayloadAction<AccountGroup>) => {
      state.accountGroups.data!.push(action.payload);
    },
    updateAccountGroupLocally: (state, action: PayloadAction<AccountGroup>) => {
      state.accountGroups.data = state.accountGroups.data!.map((group) =>
        group.groupId === action.payload.groupId ? action.payload : group
      );
    },
    deleteAccountGroupLocally: (state, action: PayloadAction<AccountGroup["groupId"]>) => {
      state.accountGroups.data = state.accountGroups.data!.filter((group) => group.groupId !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder

      // fetchEntitledAccounts
      .addCase(fetchEntitledAccounts.pending, (state) => {
        state.entitledAccounts.loading = true;
        state.entitledAccounts.error = null;
      })
      .addCase(fetchEntitledAccounts.fulfilled, (state, action) => {
        state.entitledAccounts.loading = false;
        state.entitledAccounts.data = action.payload.reduce((result, entitledAccount) => {
          result[entitledAccount.globalId] = entitledAccount;

          return result;
        }, {} as Record<string, EntitledAccount>);
      })
      .addCase(fetchEntitledAccounts.rejected, (state, action) => {
        state.entitledAccounts.loading = false;
        state.entitledAccounts.data = null;
        state.entitledAccounts.error = action.payload as string;
      })

      //fetchEntitledAccountsByEntity
      .addCase(fetchEntitledAccountsByEntity.pending, (state) => {
        state.entitledAccountsByFundLoading = true;
      })
      .addCase(fetchEntitledAccountsByEntity.fulfilled, (state, action) => {
        state.entitledAccountsByFundLoading = false;
        Object.assign(state.entitledFunds.data![action.meta.arg], {
          entitledAccountIds: action.payload.map((ea) => ea.globalId),
        });
      })
      .addCase(fetchEntitledAccountsByEntity.rejected, (state, action) => {
        state.entitledAccountsByFundLoading = false;
        Object.assign(state.entitledFunds.data![action.meta.arg], {
          entitledAccountIds: null,
        });
      })

      // fetchInvestors
      .addCase(fetchInvestors.pending, (state) => {
        state.investors.loading = true;
        state.investors.error = null;
      })
      .addCase(fetchInvestors.fulfilled, (state, action) => {
        state.investors.loading = false;
        state.investors.data = action.payload.reduce<Record<string, Investor>>((acc, investor) => {
          acc[investor.investorId] = investor;

          return acc;
        }, {});
      })
      .addCase(fetchInvestors.rejected, (state, action) => {
        state.investors.loading = false;
        state.investors.data = {};
        state.investors.error = action.payload as string;
      })

      // fetchEntitledFunds
      .addCase(fetchEntitledFunds.pending, (state) => {
        state.entitledFunds.loading = true;
        state.entitledFunds.error = null;
      })
      .addCase(fetchEntitledFunds.fulfilled, (state, action) => {
        state.entitledFunds.loading = false;
        state.entitledFunds.data = action.payload.reduce((result, entitledFund) => {
          result[entitledFund.globalId] = {
            ...entitledFund,
            entitledAccountIds: null,
          };

          return result;
        }, {} as Record<string, EntitledFund>);
      })
      .addCase(fetchEntitledFunds.rejected, (state, action) => {
        state.entitledFunds.loading = false;
        state.entitledFunds.data = null;
        state.entitledFunds.error = action.payload as string;
      })

      // fetchDocumentTypes
      .addCase(fetchDocumentTypes.pending, (state) => {
        state.documentTypes.loading = true;
        state.documentTypes.error = null;
      })
      .addCase(fetchDocumentTypes.fulfilled, (state, action) => {
        state.documentTypes.loading = false;
        state.documentTypes.data = action.payload.reduce((result, documentType) => {
          result[documentType.documentTypeId] = documentType;

          return result;
        }, {} as Record<string, DocumentType>);
      })
      .addCase(fetchDocumentTypes.rejected, (state, action) => {
        state.documentTypes.loading = false;
        state.documentTypes.data = null;
        state.documentTypes.error = action.payload as string;
      })

      // fetchAccountGroups
      .addCase(fetchAccountGroups.pending, (state) => {
        state.accountGroups.loading = true;
        state.accountGroups.error = null;
      })
      .addCase(fetchAccountGroups.fulfilled, (state, action) => {
        state.accountGroups.loading = false;
        state.accountGroups.data = action.payload.map((g) => ({
          entitledUserId: g.entitledUserId,
          groupName: g.groupName,
          groupId: g.groupId,
          investorAccountIds: g.investorAccounts.map((ia) => Number(ia.id)),
        }));
      })
      .addCase(fetchAccountGroups.rejected, (state, action) => {
        state.accountGroups.loading = false;
        state.accountGroups.data = [];
        state.accountGroups.error = action.payload as string;
      })

      // fetchAllFunds
      .addCase(fetchAllFunds.pending, (state) => {
        state.funds.loading = true;
        state.funds.error = null;
      })
      .addCase(fetchAllFunds.fulfilled, (state, action) => {
        state.funds.loading = false;
        state.funds.error = null;
        state.funds.data = action.payload.reduce((acc, fund: Fund) => {
          acc[fund.id] = fund;

          return acc;
        }, {} as Record<string, Fund>);
      })
      .addCase(fetchAllFunds.rejected, (state, action) => {
        state.funds.loading = false;
        state.funds.error = action.error.message!;

        state.funds.data = null;
      });
  },
});

export const { resetState, createAccountGroupLocally, updateAccountGroupLocally, deleteAccountGroupLocally } =
  entitiesSlice.actions;
export default entitiesSlice.reducer;
