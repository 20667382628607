import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortDirection } from "@shared/models/general";
import { statusFilterOptions, timeRangeOptions } from "@documents/constants";
import { changeDocumentsFiltersValues } from "@documents/store/actions";
import ReduxUtils from "@shared/utils/ReduxUtils";
import {
  changeDocumentsFilters,
  downloadDocumentsExcel,
  downloadSelectedDocuments,
  fetchDocuments,
  fetchMoreDocuments,
} from "@documents/store/thunks";
import { normalizeDocumentResponse } from "@documents/utils";
import { ActionRequiredItemsApiResponse, DocumentsState } from "@documents/models";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { SortKeys } from "@documents/models/sort.models";
import { updateReadFlag } from "@documents/store/thunks/updateReadFlag";
import CommonUtils from "@shared/utils/CommonUtils";
import { ToggleDocumentActionItemFlagPayload } from "@documents/models/api.models";
import { fetchNumberOfActionRequiredItems } from "@documents/store/thunks/fetchNumberOfActionRequiredItems";
import { downloadDocument } from "@documents/store/thunks/downloadDocument";

const initialState: DocumentsState = {
  report: ReduxUtils.getPaginatedAsyncSlice<DocumentRow[]>(),
  filters: {
    documentTypes: null,
    funds: null,
    investorAccounts: null,
    timeRange: {
      timePeriod: timeRangeOptions[0],
      start: null,
      end: null,
    },
    status: statusFilterOptions[0],
    fileName: "",
  },
  sort: {
    key: SortKeys.PUBLISH_DATE,
    direction: SortDirection.DESC,
  },
  numberOfActionRequiredItems: ReduxUtils.getAsyncSlice<ActionRequiredItemsApiResponse>(),
  isDocumentExportLoading: false,
};

const newDocumentsSlice = createSlice({
  name: "newDocuments",
  initialState,
  reducers: {
    resetState: () => initialState,
    changeSorting: (state, action: PayloadAction<{ columnId: SortKeys; direction: SortDirection }>) => {
      state.sort.key = action.payload.columnId;
      state.sort.direction = action.payload.direction;
    },
    resetPagination: (state) => {
      state.report.pagination = initialState.report.pagination;
    },
    updateActionItemFlagState: (state, { payload }: PayloadAction<ToggleDocumentActionItemFlagPayload>) => {
      state.report.data = state.report.data!.map((row) => {
        if (row.id === payload.id) {
          row.actionItem = payload.actionItem;
        }

        return row;
      });
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(changeDocumentsFiltersValues, (state, action) => {
        state.filters = { ...state.filters, ...action.payload };
      })
      .addCase(changeDocumentsFilters.pending, (state) => {
        state.report.pagination.start = 1;
      })
      .addCase(fetchDocuments.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.report);
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.report.loading = false;
        state.report.pagination.total = action.payload.total;

        state.report.data = action.payload.results.map(normalizeDocumentResponse);
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        if (action.meta.aborted) return;

        ReduxUtils.defaultRejectedActionHandler(state.report, action.payload as string);
      })
      .addCase(fetchMoreDocuments.pending, (state, action) => {
        state.report.pagination.loading = true;
        state.report.pagination.start = action.meta.arg;
      })
      .addCase(fetchMoreDocuments.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledPaginatedActionHandler(state.report, action.payload.total);

        state.report.data = state.report.data!.concat(action.payload.results.map(normalizeDocumentResponse));
      })
      .addCase(fetchMoreDocuments.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(state.report, action.error.message!, state.report.data);
      })
      .addCase(updateReadFlag.pending, (state, action) => {
        const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg);

        state.report.data =
          state.report.data?.map((record) => ({
            ...record,
            markedRead: idsToMarkReadMap[record.id!] ?? record.markedRead,
          })) ?? [];
      })
      .addCase(updateReadFlag.rejected, (state, action) => {
        const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg);

        state.report.data =
          state.report.data?.map((record) => ({
            ...record,
            markedRead: idsToMarkReadMap[record.id!] ? false : record.markedRead,
          })) ?? [];
      })
      .addCase(fetchNumberOfActionRequiredItems.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.numberOfActionRequiredItems);
      })
      .addCase(fetchNumberOfActionRequiredItems.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.numberOfActionRequiredItems, action.payload);
      })
      .addCase(fetchNumberOfActionRequiredItems.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(state.numberOfActionRequiredItems, action.error.message!);
      })
      .addCase(downloadDocument.pending, (state, action) => {
        const row = state.report.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = true;
        }
      })
      .addCase(downloadDocument.fulfilled, (state, action) => {
        const row = state.report.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = false;
        }
      })
      .addCase(downloadDocument.rejected, (state, action) => {
        const row = state.report.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = false;
        }
      })
      .addCase(downloadSelectedDocuments.pending, (state, action) => {
        const selectedRows = state.report.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = true));
        }
      })
      .addCase(downloadSelectedDocuments.fulfilled, (state, action) => {
        const selectedRows = state.report.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      })
      .addCase(downloadSelectedDocuments.rejected, (state, action) => {
        const selectedRows = state.report.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      })
      .addCase(downloadDocumentsExcel.pending, (state) => {
        state.isDocumentExportLoading = true;
      })
      .addCase(downloadDocumentsExcel.fulfilled, (state) => {
        state.isDocumentExportLoading = false;
      })
      .addCase(downloadDocumentsExcel.rejected, (state) => {
        state.isDocumentExportLoading = false;
      });
  },
});

export const { resetState, changeSorting, resetPagination, updateActionItemFlagState } = newDocumentsSlice.actions;
export default newDocumentsSlice.reducer;
