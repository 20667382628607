import { AppThunkAPI } from "@shared/models/redux";

type CallGetDocumentDownloadUrl = (documentId: string) => AppThunkAPI<Promise<string>>;

export const callGetDocumentDownloadUrl: CallGetDocumentDownloadUrl =
  (documentId) => async (_dispatch, _state, extra) => {
    const response = await extra<string>(`documents/v1/documents/${documentId}/download?save=true`);

    return response.data;
  };
