import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { callGetDocumentDownloadUrl } from "@documents/store/thunks/callGetDocmentDownloadUrlApi";
import { callGetDownloadDocumentData } from "@documents/store/thunks/callGetDownloadDocumentDataAPI";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { updateReadFlag } from "@documents/store/thunks/updateReadFlag";
import { DownloadDocumentArgs } from "@documents/models/api.models";
import { checkDocumentTerms } from "@terms/store/thunks/checkDocumentTerms";

export const downloadDocument = createAsyncThunk<void, DownloadDocumentArgs, ThunkApiConfig>(
  "documents/downloadDocument",
  async (row, { dispatch, rejectWithValue }) => {
    try {
      const termsAccepted = await dispatch(
        checkDocumentTerms({
          fundIds: [row.fundId],
          documentSubTypes: [String(row.documentSubtype)],
        })
      );

      if (!termsAccepted) return;

      const url = await dispatch(callGetDocumentDownloadUrl(row.id!));
      const documentData = await dispatch(callGetDownloadDocumentData(url));
      const fileName = `${row.title.replace(",", "")}.${row.fileType}`;
      const blobData = documentData instanceof Blob ? documentData : documentData.blob;

      if (!blobData || blobData.size === 0) {
        throw new Error("downloadDocument(): download failed");
      }

      saveAs(blobData, fileName);

      if (!row.markedRead) {
        dispatch(updateReadFlag([row.id!]));
      }

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: "Document download successful.",
        })
      );
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "The document download failed",
        })
      );

      return rejectWithValue("Failed to download document");
    }
  }
);
