import React, { FunctionComponent, memo, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dropdown, List, Button } from "@shared/components";
import { ProfileMenuOption, ProfileMenuOptionLabel } from "@app/models/Header";
import { getProfileMenuOptions } from "@app/utils/getProfileMenuOptions";
import { ReactComponent as ProfileIcon } from "@assets/svg/profile_icon.svg";
import { ReactComponent as Arrow } from "@assets/svg/arrow_dropdown.svg";

interface Props {
  name: string;
  canImpersonate: boolean;
  isImpersonating: boolean;
  iconClassName: string;
  onMenuOptionClick(option: ProfileMenuOption): void;
  onSignOutButtonClick(): void;
}

const useStyles = makeStyles((theme) => ({
  name: {
    color: theme.palette.primary.main,
    padding: theme.spacing(6, 5, 3),
    fontSize: 24,
    lineHeight: 1,
  },
  button: {
    padding: theme.spacing(3, 5, 6),
  },
  listEndAdornment: {
    transform: "rotate(-90deg)",
    width: 10,
  },
}));

const ProfileDropdown: FunctionComponent<Props> = ({
  name,
  canImpersonate,
  isImpersonating,
  iconClassName,
  onMenuOptionClick,
  onSignOutButtonClick,
}) => {
  const classes = useStyles();

  const listEndAdornment = useMemo(() => <Arrow className={classes.listEndAdornment} />, [classes.listEndAdornment]);

  const dropdownOptions = useMemo(
    () =>
      getProfileMenuOptions(canImpersonate, isImpersonating).map((option) => ({
        ...option,
        endAdornment: listEndAdornment,
      })),
    [canImpersonate, isImpersonating, listEndAdornment]
  );

  return (
    <Dropdown
      withArrow
      stretchToContentWidth
      width={230}
      placement="bottom-end"
      renderToggle={({ ref, toggleDropdown }) => (
        <ProfileIcon ref={ref} className={iconClassName} onClick={toggleDropdown} />
      )}
    >
      {({ closeDropdown }) => (
        <>
          <Typography className={classes.name} variant="h2">
            {name.split(" ").map((word) => (
              <p key={word}>{word}</p>
            ))}
          </Typography>
          <List
            variant="singleselect"
            options={dropdownOptions}
            onOptionClick={(option) => {
              onMenuOptionClick(option);
              closeDropdown();
            }}
          />
          <Box className={classes.button}>
            <Button fullWidth variant="outlined" shape="rounded" onClick={onSignOutButtonClick}>
              {ProfileMenuOptionLabel.SignOut}
            </Button>
          </Box>
        </>
      )}
    </Dropdown>
  );
};

export default memo<Props>(ProfileDropdown);
