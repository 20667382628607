import React, { ChangeEventHandler, FunctionComponent, memo, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import cn from "classnames";
import { InputProps } from "@shared/components/Input/models";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { ReactComponent as SearchIcon } from "@assets/svg/search_icon.svg";
import { ReactComponent as SearchIconMobile } from "@assets/svg/search_icon_mobile.svg";

const useStyles = makeStyles<Theme, Pick<InputProps, "variant" | "error" | "disabled" | "dense">>((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    transition: "all ease-in .2s",
    color: ({ disabled }) => (disabled ? theme.palette.primary.light : theme.palette.primary.main),
    backgroundColor: ({ variant }) => (variant === "lightgray" ? theme.palette.grey[200] : theme.palette.common.white),
    border: ({ error }) => (error ? `1px solid ${theme.palette.warning.main}` : "initial"),
    borderBottom: ({ variant }) => (variant === "underline" ? `1px solid ${theme.palette.primary.main}` : "none"),
    "&:hover:not(:focus-within)": {
      backgroundColor: ({ disabled }) => (disabled ? undefined : `${theme.palette.primary.main}0a`),
    },
  },
  root: {
    padding: ({ dense }) => (dense ? 0 : theme.spacing(5)),
    flex: 6,
    height: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: "Value Sans",
    fontSize: "inherit",
    letterSpacing: "inherit",
    backgroundColor: "transparent",
    color: "inherit",
    border: "none",
    outline: "none",
    transition: "all ease-in .2s",
    "&::placeholder": {
      color: ({ disabled }) => (disabled ? theme.palette.primary.light : theme.palette.primary.main),
      letterSpacing: 1,
    },
    "&:focus::placeholder": {
      color: theme.palette.primary.light,
    },
    "&:placeholder-shown": {
      textOverflow: "ellipsis",
    },
  },
  searchIcon: {
    flex: 1,
    height: 30,
    [theme.breakpoints.down("lg")]: {
      height: 20,
    },
  },
  clearIcon: {
    flex: 1,
    height: "30%",
    color: theme.palette.warning.main,
    cursor: "pointer",
  },
  large: {
    height: 55,
    fontSize: 18,
    letterSpacing: 1.8,
  },
  medium: {
    height: 45,
    fontSize: 16,
    letterSpacing: 1.6,
  },
  small: {
    height: 35,
    fontSize: 14,
    letterSpacing: 1.4,
  },
}));

const Input: FunctionComponent<InputProps> = ({
  onChange,
  onBlur,
  onKeyPress,
  defaultValue,
  placeholder,
  size,
  autoFocus,
  variant,
  value,
  error,
  search,
  disabled,
  dense,
  testId,
  isMobile,
}) => {
  const classes = useStyles({ variant, error, disabled, dense });

  const className = useMemo(
    () =>
      cn({
        [classes.wrapper]: true,
        [classes.small]: size === "small",
        [classes.medium]: size === "medium",
        [classes.large]: size === "large",
      }),
    [classes, size]
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (event) => {
      if (!disabled) {
        onChange(event.target.value);
      }
    },
    [onChange, disabled]
  );

  const handleClear = useCallback(() => onChange(""), [onChange]);

  return (
    <Box className={className}>
      <input
        data-testid={testId ?? "input-container"}
        autoFocus={autoFocus}
        placeholder={placeholder}
        disabled={disabled}
        className={classes.root}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onBlur={onBlur}
        onKeyDown={onKeyPress}
      />
      {value ? (
        <CloseIcon
          className={classes.clearIcon}
          onClick={handleClear}
          data-testid={testId ? `${testId}-close-icon` : "close-icon"}
        />
      ) : (
        search && (
          <>
            {isMobile ? (
              <SearchIconMobile className={classes.searchIcon} />
            ) : (
              <SearchIcon
                className={classes.searchIcon}
                data-testid={testId ? `${testId}-search-icon` : "search-icon"}
              />
            )}
          </>
        )
      )}
    </Box>
  );
};

Input.defaultProps = {
  autoFocus: false,
  dense: true,
  size: "medium",
  variant: "underline",
};

export default memo<InputProps>(Input);
