import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserResponse } from "@user/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchUserProfile = createAsyncThunk<UserResponse, void, ThunkApiConfig>(
  "user/fetchUserProfile",
  async (_, { extra, rejectWithValue }) => {
    try {
      const response = await extra<UserResponse>("/entitlements/v1/user/profile", {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);

      return rejectWithValue("Failed to load user profile");
    }
  }
);
