import React, { forwardRef } from "react";
import { ListOptionBase, Singleselect } from "@shared/components";

interface Props {
  loading: boolean;
  className: string;
  dropdownLabel: string;
  dropdownOptions: Array<ListOptionBase>;
  onDropdownOptionClick(event: void, route: string): void;
}

const AppNavigationFundsDropdown = forwardRef<HTMLDivElement, Props>((props, outerRef) => (
  <div ref={outerRef} className={props.className}>
    <Singleselect
      withSearch
      dropdownWidth={440}
      size="small"
      variant="transparent"
      loading={props.loading}
      label={props.dropdownLabel}
      options={props.dropdownOptions}
      onSelect={(option) => props.onDropdownOptionClick(undefined, option.id)}
    />
  </div>
));

AppNavigationFundsDropdown.displayName = "forwardRef(AppNavigationFundsDropdown)";

export default AppNavigationFundsDropdown;
