import React, { ChangeEvent, FunctionComponent, memo, useCallback } from "react";
import { Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  placeholder: string;
  value: string;
  onChange(value: string): void;
  error?: boolean;
}

const useStyles = makeStyles<Theme, Pick<Props, "error">>((theme) => ({
  root: {
    border: ({ error }) => (error ? `1px solid ${theme.palette.warning.main}` : "none"),
    width: "100%",
    outline: "none",
    height: 214,
    padding: theme.spacing(5),
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "&::placeholder": {
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus::placeholder": {
      color: theme.palette.primary.light,
    },
    [theme.breakpoints.down("md")]: {
      "&::placeholder": {
        fontSize: 18,
        letterSpacing: 0.36,
        fontWeight: theme.typography.fontWeightMedium,
      },
      fontSize: 18,
      height: 91,
      letterSpacing: 0.36,
    },
  },
}));

const InvestorRequestTextarea: FunctionComponent<Props> = ({ placeholder, value, onChange, error }) => {
  const classes = useStyles({ error });

  const handleMessageChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value),
    [onChange]
  );

  return (
    <Grid item xs={12}>
      <textarea
        className={classes.root}
        placeholder={placeholder}
        onChange={handleMessageChange}
        value={value}
        data-testid="investor-request-textarea"
      />
    </Grid>
  );
};

export default memo<Props>(InvestorRequestTextarea);
