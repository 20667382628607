import { isAnyOf } from "@reduxjs/toolkit";
import { startImpersonationSession, stopImpersonationSession } from "@impersonateUser/store/actions";
import { fetchUserTerms } from "@terms/store/thunks/fetchUserTerms";
import { GetAppListener } from "@shared/models/redux";
import { LocalStorageKeys } from "@app/constants/localStorage";

export const getImpersonationListener: GetAppListener = (addAppListener) =>
  addAppListener({
    matcher: isAnyOf(startImpersonationSession, stopImpersonationSession),
    effect: (action, { dispatch }) => {
      localStorage.removeItem(LocalStorageKeys.LpcActiveSession);

      dispatch(fetchUserTerms());
    },
  });
