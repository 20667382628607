import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { FileResponse } from "@shared/models/request";
import { timeRangeConverter } from "@documents/utils";

export const generateWhoAccessedEventReplayReportThunk = createAsyncThunk<void, void, ThunkApiConfig>(
  "administrationReports/generateWhoAccessedEventReplayReportThunk",
  async (_, { getState, dispatch, rejectWithValue, extra }) => {
    const {
      administrationReports: { filters },
    } = getState();

    const { start, end } = timeRangeConverter(filters.timeRange);

    const body = {
      startDate: start,
      endDate: end,
      userEmail: filters.userEmail.length ? filters.userEmail : undefined,
      eventReplayGlobalIds: filters.selectedEventReplays,
      commonNameGlobalIds: filters.commonNames,
    };

    try {
      const response = await extra<FileResponse>("/documents/v1/EventReplayTracking/GenerateReport", {
        method: "post",
        responseType: "blob",
        body,
      });

      if (response.status == 204) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: "Report has no data for the parameters given",
          })
        );

        return;
      }

      const today = new Date();
      const fileName = `WhoAccessedEventsReport_${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}.xlsx`;

      if (response.data.blob?.size) {
        saveAs(response.data.blob, fileName);

        dispatch(
          openNotificationThunk({
            variant: "info",
            text: "Report generation successful.",
          })
        );
      }
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Report download failed.",
        })
      );

      return rejectWithValue("The document download failed");
    }
  }
);
