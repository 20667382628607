import { createSlice } from "@reduxjs/toolkit";
import { fetchAccountSummaryEmbedUrlThunk } from "@accountSummaryAlpInvest/store/thunks/fetchAccountSummaryEmbedUrlThunk";
import { postAccountSummaryExportThunk } from "@accountSummaryAlpInvest/store/thunks/postAccountSummaryExportThunk";
import { fetchAccountSummaryExportStatusThunk } from "@accountSummaryAlpInvest/store/thunks/fetchAccountSummaryExportStatusThunk";
import { downloadAccountSummaryThunk } from "@accountSummaryAlpInvest/store/thunks/downloadAccountSummaryThunk";
import { AccountSummaryAlpInvestState } from "@accountSummaryAlpInvest/models";
import ReduxUtils from "@shared/utils/ReduxUtils";

const initialState: AccountSummaryAlpInvestState = {
  embedUrlConfig: ReduxUtils.getAsyncSlice(),
  export: ReduxUtils.getAsyncSlice(),
  isReportDownloading: false,
};

const accountSummaryAlpInvestSlice = createSlice({
  name: "accountSummaryAlpInvest",
  initialState,
  reducers: {
    resetState: (state) => {
      state.embedUrlConfig = initialState.embedUrlConfig;
      state.export = initialState.export;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountSummaryEmbedUrlThunk.pending, (state) =>
        ReduxUtils.defaultPendingActionHandler(state.embedUrlConfig)
      )
      .addCase(fetchAccountSummaryEmbedUrlThunk.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.embedUrlConfig, {
          embedUrl: action.payload.embedUrl,
          reportingDate: action.payload.reportingDate,
        });
      })
      .addCase(fetchAccountSummaryEmbedUrlThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.embedUrlConfig, action.payload as string)
      )
      .addCase(postAccountSummaryExportThunk.pending, (state) => {
        state.isReportDownloading = true;
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          status: "Initialized",
        });
      })
      .addCase(postAccountSummaryExportThunk.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          exportId: action.payload.exportId,
          status: "Initialized",
          percentComplete: action.payload.percentComplete,
          resourceFileExtension: action.payload.resourceFileExtension,
        });
      })
      .addCase(postAccountSummaryExportThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.export, action.payload as string)
      )
      .addCase(fetchAccountSummaryExportStatusThunk.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.export);
      })
      .addCase(fetchAccountSummaryExportStatusThunk.fulfilled, (state, action) => {
        if (action.payload.status === "Failed") {
          state.isReportDownloading = false;
        }
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          exportId: action.payload.exportId,
          status: action.payload.status,
          percentComplete: action.payload.percentComplete,
          resourceFileExtension: action.payload.resourceFileExtension,
        });
      })
      .addCase(fetchAccountSummaryExportStatusThunk.rejected, (state, action) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
        ReduxUtils.defaultRejectedActionHandler(state.export, action.payload as string);
      })
      .addCase(downloadAccountSummaryThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(downloadAccountSummaryThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
      })
      .addCase(downloadAccountSummaryThunk.rejected, (state) => {
        state.isReportDownloading = false;
        state.export = initialState.export;
      });
  },
});

export const { resetState } = accountSummaryAlpInvestSlice.actions;
export default accountSummaryAlpInvestSlice.reducer;
