import { Column } from "react-table";
import { Nullable } from "@shared/models/general";
import { AsyncSlice } from "@shared/models/redux";
import { AsOfDate } from "@shared/models/filters";
import { BaseRow, ColumnMeta, DonutValue } from "@shared/components";
import { Currency } from "@shared/models/currency";
import { ReportingPeriodTypes } from "@shared/models/reporting";

export interface AccountSummaryFundsFilter {
  globalId: string;
  label: string;
  selected: boolean;
}

export interface AccountSummaryAccountsFilter {
  [key: string]: boolean;
}

export interface AccountSummaryFilterValues {
  asOfDate: Nullable<AsOfDate>;
  funds: Nullable<{ [key: string]: AccountSummaryFundsFilter }>;
  accounts: Nullable<AccountSummaryAccountsFilter>;
}

export interface ASFilterRow {
  fundId: string;
  investorId: string;
  reportingDate: string;
}

export interface AccountSummaryFilterOption {
  year: string;
  quarter: string;
  associatedFunds: { [key: string]: AccountSummaryFundsFilter };
}

export type AccountSummaryFilterOptions = AccountSummaryFilterOption[];

export interface AccountSummaryReportResponse {
  reports: AccountSummaryReport[];
}

export interface AccountSummaryReportRequestBody {
  fundIds: string[];
  reportingDate: Date;
  period: ReportingPeriodTypes;
  current?: boolean;
}

export interface AccountSummaryReport {
  fundId: string;
  investorId: string;
  period: string; // @TODO ENUM?
  reportingDate: string;
  reportingCurrency: Currency;
  capitalAccountBeginningBalanceAtFairValue: number;
  contributionFundInvestments: number;
  contributionManagementFees: number;
  contributionPartnershipExpenses: number;
  contributionOrganizationalCosts: number;
  contributionUnallocatedCapital: number;
  totalContributedCapital: number;
  accumulatedIncomeRealizedCapitalGain: number;
  accumulatedIncomeDividendsOtherCurrentIncome: number;
  accumulatedIncomeNonInvestmentIncome: number;
  accumulatedIncomeManagementFeeExpense: number;
  accumulatedIncomeRealizedCarryPaidToGP: number;
  totalAccumulatedIncome: number;
  distributionsOfReturnOfCapital: number;
  distributionsOfRealizedCapitalGain: number;
  distributionsOfDividendsOtherCurrentIncome: number;
  distributionsOfNonInvestmentIncome: number;
  distributionsRealizedCarryPaidToGP: number;
  totalDistributions: number;
  capitalAccountBeforeChangeInNetUnrealizedAppreciation: number;
  changeInUnrealizedAppreciation: number;
  changeInAccruedCarriedInterest: number;
  capitalAccountAtFairValue: number;
  commitmentBeginningRemainingCommitment: number;
  contributionsForInvestments: number;
  contributionsForUnallocatedCapital: number;
  recallableReturnOfCapitalDistributions: number;
  contributionsForExpensesFees: number;
  recallableExpensesFees: number;
  remainingCommitments: number;
  contributionsForInvestmentsOutsideCommitment: number;
  contributionsForExpensesFeesOutsideCommitment: number;
  totalContributionsOutsideOfCommitment: number;
  exchangeRateToUSD: number;
  exchangeRateToEUR: number;
  expiredCommitment: number;
  netMOIC: Nullable<string>;
  lastUpdatedDatetime: Nullable<string>;
  totalInvestorCommitmentFundCurrency: number;
}

export interface FundData {
  fundId: string;
  investorId: string;
  quarter: string;
  reportingDate: string;
  year: string;
}

export interface AccountSummaryState {
  report: AsyncSlice<AccountSummaryReport[]>;
  filters: AccountSummaryFilterValues;
  filterOptions: AsyncSlice<AccountSummaryFilterOptions>;
  fundData: AsyncSlice<FundData[]>;
  isReportDownloading: boolean;
}

export interface AccountSummaryDataGridRow extends BaseRow {
  fundId?: string;
  fundName?: string;
  fundType?: string;
  currency?: string;
  reportingDate?: string;
  commitment: string;
  remainingCommitments: string;
  totalContributedCapital: string;
  totalDistributions: string;
  capitalAccountAtFairValue: string;
  netMoic?: string;
}

export interface DataGridAccountSummaryProps {
  rows: AccountSummaryDataGridRow[];
}

export interface AccountSummaryReportData {
  fundId: string;
  fundName: string;
  fundType: string;
  currency?: string;
  reportingDate?: string;
  commitment: number;
  remainingCommitments: number;
  totalContributedCapital: number;
  totalDistributions: number;
  capitalAccountAtFairValue: number;
}

export interface AccountSummaryReportTotal {
  commitment: number;
  remainingCommitments: number;
  totalContributedCapital: number;
  totalDistributions: number;
  capitalAccountAtFairValue: number;
}

export type AccountSummaryDataGridColumn = ColumnMeta<AccountSummaryDataGridRow> & Column<AccountSummaryDataGridRow>;

export enum AccountSummaryDataGridColumnNames {
  FundName = "Fund Name",
  FundType = "Fund Type",
  Currency = "Currency",
  AsOf = "As Of",
  Commitment = "Commitment",
  RemainingCommitment = "Remaining Commitment",
  TotalContributedCapital = "Total Contributed Capital",
  TotalDistributions = "Total Distributions",
  CapitalAccountAtFairValue = "Capital Account at Fair Value",
  NetMoic = "Net MOIC",
}

export enum AccountSummaryDataGridRowNames {
  Total = "Total*",
}

export interface AccountSummaryPieChartsData {
  capitalAccountAtFairValue: DonutValue[] | [];
  remainingCommitments: DonutValue[] | [];
}
