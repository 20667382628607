import { Nullable } from "@shared/models/general";
import { ListOptionBase } from "@shared/components";
import { DocumentStatusId } from "@documents/models/filter.models";

export const getDocumentReadFlag = (status: Nullable<ListOptionBase>) => {
  switch (status?.id) {
    case DocumentStatusId.Read:
      return true;
    case DocumentStatusId.Unread:
    case DocumentStatusId.UnreadActionItems:
      return false;
    default:
      return null;
  }
};
