import { Nullable } from "@shared/models/general";
import { EntitiesState } from "@store/Entities/models";
import { convertEntityGlobalIdsToIds } from "@documents/utils/convertEntityGlobalIdsToIds";

type GetEntitiesListForDocumentsRequest = (arg: {
  isAnyIAGSelected: boolean;
  selectedAccounts: Nullable<Array<string>>;
  selectedFunds: Nullable<Array<string>>;
  entitledAccounts: EntitiesState["entitledAccounts"];
  entitledFunds: EntitiesState["entitledFunds"];
}) => {
  investorAccountIds: Nullable<Array<number>>;
  fundIds: Nullable<Array<number>>;
};

export const getEntitiesListForDocumentsRequest: GetEntitiesListForDocumentsRequest = ({
  isAnyIAGSelected,
  selectedAccounts,
  selectedFunds,
  entitledAccounts,
  entitledFunds,
}) => {
  let investorAccounts = selectedAccounts;
  let funds = selectedFunds;

  if (!entitledAccounts.data || !entitledFunds.data) {
    return { investorAccountIds: null, fundIds: null };
  }

  if (isAnyIAGSelected) {
    if (!selectedAccounts) {
      investorAccounts = Object.keys(entitledAccounts.data);
    }

    if (!selectedFunds) {
      funds = Object.keys(entitledFunds.data);
    }
  }

  return {
    investorAccountIds: investorAccounts && convertEntityGlobalIdsToIds(entitledAccounts.data, investorAccounts),
    fundIds: funds && convertEntityGlobalIdsToIds(entitledFunds.data, funds),
  };
};
