import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportingEmbedTokenConfigResponse } from "@reporting/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { callEmbedTokenAPI } from "@reporting/store/thunks/callEmbedTokenAPI";
import { openNotificationThunk } from "@store/Notification/thunks";
import { reportingEmbedTokenConfigSelector } from "@reporting/store/selectors";

export const fetchEmbedTokenThunk = createAsyncThunk<ReportingEmbedTokenConfigResponse, void, ThunkApiConfig>(
  "reporting/fetchEmbedTokenThunk",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callEmbedTokenAPI());
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load report",
        })
      );

      return rejectWithValue("Failed to fetch Embed Token");
    }
  },
  {
    condition: (_, { getState }) => {
      const tokenConfig = reportingEmbedTokenConfigSelector(getState());

      if (tokenConfig.data) {
        const expiresInMs = new Date(tokenConfig.data.expiration).getTime() - Date.now();

        return expiresInMs > 60000;
      }

      return !tokenConfig.loading;
    },
  }
);
