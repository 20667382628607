import { HomePageVideoDetailsResponse } from "@home/models";
import { AppThunkAPI } from "@shared/models/redux";

type CallHomePageVideoDetailsAPI = () => AppThunkAPI<Promise<HomePageVideoDetailsResponse>>;

export const callHomePageVideoDetailsAPI: CallHomePageVideoDetailsAPI = () => async (_dispatch, _getState, extra) => {
  const response = await extra<HomePageVideoDetailsResponse>("entitlements/v1/user/Content");

  return response.data;
};
