import { createAsyncThunk } from "@reduxjs/toolkit";
import { Report } from "powerbi-client";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { callExportAPI } from "@reporting/store/thunks/callExportAPI";
import { ReportingExport } from "@reporting/models";
import { getExportParams } from "@diversificationAnalysis/utils/getExportParams";

export const postDiversificationAnalysisExportThunk = createAsyncThunk<
  ReportingExport,
  { type: string; report: Report },
  ThunkApiConfig
>(
  "diversificationAnalysis/postDiversificationAnalysisExportThunk",
  async ({ type, report }, { dispatch, rejectWithValue }) => {
    try {
      const exportParams = await getExportParams(report);

      return await dispatch(callExportAPI("DiversificationAnalysis", type, JSON.stringify(exportParams)));
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to export report",
        })
      );

      return rejectWithValue("Failed to fetch export details");
    }
  }
);
