import { models, Report } from "powerbi-client";
import { format } from "date-fns";
import { Param } from "@diversificationAnalysis/models";

// Function to adjust reporting date
export function adjustDate(reportDate: Date) {
  // Subtract one day, because embed report date filter uses less than condition, paginated/export does not.
  const reportDateAdjusted = new Date(reportDate.getTime() - 86400000);

  // Correct Date for local timezone.
  return format(reportDateAdjusted, "yyyy-MM-dd") + "T00:00:00";
}

// Function to construct Export Parameters from Report Slicers and Filters
export async function getExportParams(report: Report) {
  const activePage = await report.getActivePage();
  const visuals = await activePage.getVisuals();
  let filterList: models.IFilter[] = [];

  // Retrieve the slicer visuals and get filters
  const slicers = visuals.filter(function (visual) {
    return visual.type === "slicer";
  });

  let displayCurrencyValue = "";
  let reportDateValue = "";

  slicers.forEach(async (slicer) => {
    const state = await slicer.getSlicerState();
    const filter: models.IFilter = state.filters[0];
    const target = <models.IFilterColumnTarget>filter.target;
    const targetcolumn = `${target.table}:${target.column}`;

    if (targetcolumn === Param.Currency) {
      displayCurrencyValue = <string>(<models.IBasicFilter>filter).values[0];
    }

    if (targetcolumn === Param.Date) {
      const dateFilter = <models.IAdvancedFilter>filter;

      if (dateFilter.conditions !== undefined) {
        const condition: models.IAdvancedFilterCondition = dateFilter.conditions[0];
        const reportDate = new Date(condition.value as string);

        if (reportDate) reportDateValue = adjustDate(reportDate);
      }
    }
  });

  // Add Report Level filters
  const filters = await report.getFilters();

  filterList = filterList.concat(filters);

  // Remove Hidden filters
  const visibleFilters = filterList.filter(function (filter) {
    const isHidden = filter.displaySettings && filter.displaySettings.isHiddenInViewMode === true;

    return !isHidden;
  });

  interface IParamList {
    [index: string]: string[];
  }

  const filterParams = visibleFilters.reduce((paramList: IParamList, filter: models.IFilter) => {
    const basicFilter = <models.IBasicFilter>filter;
    const target = <models.IFilterColumnTarget>filter.target;
    const parameter = `${target.table}:${target.column}`;

    if (basicFilter.values !== undefined && basicFilter.values !== null && basicFilter.operator !== "All") {
      paramList[parameter] = <string[]>basicFilter.values;
    } else {
      paramList[parameter] = ["All"];
    }

    return paramList;
  }, {});

  const exportParams = {
    "Display Currency": displayCurrencyValue,
    "Report Date": reportDateValue,
    Investor: filterParams[Param.Investor],
    Fund: filterParams[Param.Fund],
    "Mandate Fund": filterParams[Param.MandateFund],
    "Portfolio Type": filterParams[Param.Portfolio],
    "General Partner": filterParams[Param.GeneralPartner],
    "World Region": filterParams[Param.WorldRegion],
    "Secondary Deal": filterParams[Param.SecondaryDeal],
    Country: filterParams[Param.Country],
    Sector: filterParams[Param.Sector],
    "Industry Group": filterParams[Param.IndustryGroup],
    "Vintage Year": filterParams[Param.VintageYear],
    "Company Name": filterParams[Param.CompanyName],
  };

  return exportParams;
}
