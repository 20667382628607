import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { ReportingPeriodsResponse } from "@reporting/models";

export const callFundInvestorQuarterOptionsAPI = createAsyncThunk<ReportingPeriodsResponse[], void, ThunkApiConfig>(
  "capitalAccountStatement/callFundInvestorQuarterOptions",
  async (_, { extra, signal }) => {
    const response = await extra<ReportingPeriodsResponse[]>("/reporting/v1/Cas/periods", { signal });

    return response.data;
  }
);
