import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { ThunkApiConfig } from "@shared/models/redux";
import { FileResponse } from "@shared/models/request";
import { openNotificationThunk } from "@store/Notification/thunks";
import { reportMessages } from "@reporting/constants";
import { getFilename } from "@accountSummaryAlpInvest/utils/getFilename";
import { MimeType } from "@shared/models/mimeType";

export const downloadAccountSummaryThunk = createAsyncThunk<void, { exportId: string }, ThunkApiConfig>(
  "accountSummaryAlpInvest/downloadAccountSummaryThunk",
  async ({ exportId }, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra<FileResponse>(`/entitlements/v1/reports/exportDownload/${exportId}`, {
        method: "get",
        responseType: "blob",
      });

      if (response && response.data && response.data.blob && response.data.blob.type) {
        const fileType = response.data.blob.type === MimeType.PDF ? ".pdf" : ".xlsx";
        const fileName = getFilename("Report-Account-Summary") + fileType;

        // save it and show notification
        if (response.data.blob?.size) {
          saveAs(response.data.blob, fileName);
          dispatch(
            openNotificationThunk({
              variant: "info",
              text: reportMessages.success,
            })
          );
        }
      } else {
        throw new Error("downloadAccountSummaryThunk(): no data returned on request");
      }
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: reportMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch AlpInvest Account Summary Report");
    }
  }
);
