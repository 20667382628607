import { FinalTermRule, TermRuleGetter } from "@terms/models";

export const viewDocumentIfAllEntitledEntitiesAndDocumentTypesTermsAccepted: TermRuleGetter<FinalTermRule> = (
  associations,
  extraArgs
) => ({
  condition: (outstandingTerms) => {
    return outstandingTerms.every((term) => term.accepted);
  },
  action: () => extraArgs!.onAccept(),
});

export const preventViewDocumentIfAnyTermIsDeclined: TermRuleGetter<FinalTermRule> = (associations, extraArgs) => ({
  condition: (outstandingTerms) => {
    return outstandingTerms.some((term) => !term.accepted);
  },
  action: () => extraArgs!.onDecline(),
});
