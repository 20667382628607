import { Draft } from "@reduxjs/toolkit";
import { AsyncSlice, DataGridAsyncSlice, PaginatedAsyncSlice } from "@shared/models/redux";

class ReduxUtils {
  static getAsyncSlice<T, E = string>(initialData?: T): AsyncSlice<T, E> {
    return {
      data: initialData || null,
      loading: false,
      error: null,
    };
  }

  static getPaginatedAsyncSlice<T>(initialData?: T): PaginatedAsyncSlice<T> {
    return {
      data: initialData || null,
      loading: false,
      error: null,
      pagination: {
        count: 50,
        start: 1,
        total: 0,
        loading: false,
      },
    };
  }

  static getDataGridAsyncSlice<T>(initialData?: T): DataGridAsyncSlice<T> {
    return {
      data: initialData || null,
      loading: false,
      error: null,
      pagination: {
        count: 50,
        start: 1,
        total: 0,
        loading: false,
      },
      sort: {
        key: null,
        direction: "ASC",
      },
    };
  }

  static defaultPendingActionHandler<T extends Draft<AsyncSlice<unknown>>>(slice: T): void {
    slice.loading = true;
    slice.error = null;
  }

  static defaultFulfilledActionHandler<K, T extends Draft<AsyncSlice<K>>>(slice: T, data: Draft<K>): void {
    slice.loading = false;
    slice.data = data;
  }

  static defaultFulfilledPaginatedActionHandler<K, T extends Draft<PaginatedAsyncSlice<K>>>(
    slice: T,
    total: number
  ): void {
    slice.loading = false;
    slice.pagination.loading = false;
    slice.pagination.total = total;
  }

  static defaultRejectedActionHandler<K, T extends Draft<AsyncSlice<K>>>(
    slice: T,
    error?: string,
    data?: Draft<K>
  ): void {
    slice.loading = false;
    slice.data = data || null;
    slice.error = error ?? "Unknown error";
  }
}

export default ReduxUtils;
