import { AppThunkAPI } from "@shared/models/redux";
import { PreferenceFullObject, UserPreferenceName } from "@user/models";

type CallGetUserPreferenceAPI<S extends UserPreferenceName> = (arg: {
  userId: string;
  preferenceName: S;
}) => AppThunkAPI<Promise<PreferenceFullObject<S>>>;

export const callGetUserPreference: CallGetUserPreferenceAPI<UserPreferenceName> =
  ({ userId, preferenceName }) =>
  async (_dispatch, _state, extra) => {
    const response = await extra<PreferenceFullObject<UserPreferenceName>>(
      `/entitlements/v1/user/UserPreferences/${preferenceName}?userId=${userId}`
    );

    return response.data;
  };
