import { chain } from "lodash";
import { CustomFieldsMap, InvestorRequestType, RequiredFieldsPath } from "@investorRequest/models";

export const customFieldsMap: CustomFieldsMap = {
  [InvestorRequestType.General]: {},
  [InvestorRequestType.UpdateWireInstructions]: {
    bankName: "",
    beneficiaryAccountName: "",
    bankCountry: "",
    bankAccountNumber: "",
    routingNumber: "",
    effectiveDate: "",
  },
  [InvestorRequestType.NoData]: {},
};

export const requiredFieldsPath: RequiredFieldsPath = {
  [InvestorRequestType.General]: ["commonFields.message"],
  [InvestorRequestType.UpdateWireInstructions]: [
    "commonFields.message",
    "commonFields.selectedFunds",
    "commonFields.selectedAccounts",
    ...chain(customFieldsMap[InvestorRequestType.UpdateWireInstructions])
      .omit("effectiveDate")
      .keys()
      .map((field) => `customFields.${field}`)
      .value(),
  ],
  [InvestorRequestType.NoData]: ["commonFields.message"],
};

export const investorRequestTypeTextMap = {
  [InvestorRequestType.General]: "Information Request/General Support",
  [InvestorRequestType.UpdateWireInstructions]: "Update Wire Instructions",
  [InvestorRequestType.NoData]: "No Data Request",
};
