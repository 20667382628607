import { AppThunkAPI } from "@shared/models/redux";
import { ReportingEmbedTokenConfigResponse } from "@reporting/models";

type CallEmbedTokenAPI = () => AppThunkAPI<Promise<ReportingEmbedTokenConfigResponse>>;

export const callEmbedTokenAPI: CallEmbedTokenAPI = () => async (_dispatch, _state, extra) => {
  const response = await extra<ReportingEmbedTokenConfigResponse>("entitlements/v1/reports/EmbedToken");

  return response.data;
};
