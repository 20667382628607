import { createAsyncThunk } from "@reduxjs/toolkit";
import { fundsSelector } from "@store/Entities/selectors";
import { ThunkApiConfig } from "@shared/models/redux";
import { Fund } from "../models";

export const fetchAllFunds = createAsyncThunk<Fund[], boolean | undefined, ThunkApiConfig>(
  "entities/fetchAllFunds",
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra<Fund[]>("/reporting/v1/Funds?pageNumber=1&pageSize=1500");

      return response.data;
    } catch (e) {
      return rejectWithValue("Failed to fetch funds");
    }
  },
  {
    condition: (refresh, { getState }) => {
      if (refresh) return true;

      const funds = fundsSelector(getState());

      return !funds.loading && !funds.data;
    },
  }
);
