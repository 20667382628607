import { RefObject, useEffect, useState } from "react";

const useIsOverflowing = (ref: RefObject<HTMLElement>) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    setIsOverflowing(ref.current.clientWidth < ref.current.scrollWidth);
  }, [ref]);

  return isOverflowing;
};

export default useIsOverflowing;
