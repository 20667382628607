import React, { FunctionComponent, useEffect, useMemo } from "react";
import { match, Route, RouteProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import initiateTermsPopup from "@terms/store/thunks/initiateTermsPopup";
import { InitiateTermsArgs, TermAppearanceType, TermAssociation } from "@terms/models";
import { termsSelector } from "@terms/store/selectors";

interface Props extends RouteProps {
  type: TermAppearanceType;
  computedMatch?: match<{ entityID: string }>;
}

const TermedRoute: FunctionComponent<Props> = ({ type, ...props }) => {
  const dispatch = useAppDispatch();
  const { data: termsData, loading } = useAppSelector(termsSelector);

  const isAllRequiredTermsAccepted = useMemo(
    () =>
      termsData
        .filter((term) => {
          if (type === TermAppearanceType.OpenReportingPage) {
            return term.associationType === TermAssociation.Reporting;
          }

          if (type === TermAppearanceType.OpenFundProfilePage) {
            const { entityID } = props.computedMatch!.params;

            return (
              term.associationType === TermAssociation.Reporting ||
              (term.associationType === TermAssociation.EntitledEntity && term.associations.includes(entityID))
            );
          }

          return false;
        })
        .every((term) => term.accepted),
    [props.computedMatch, termsData, type]
  );

  const { entityID } = props.computedMatch!.params;

  useEffect(() => {
    if (isAllRequiredTermsAccepted) return;

    const termsConfig: InitiateTermsArgs = {
      type,
      associations: {
        [TermAssociation.Reporting]: null,
      },
    };

    if (type === TermAppearanceType.OpenFundProfilePage) {
      termsConfig.associations![TermAssociation.EntitledEntity] = [entityID];
    }

    dispatch(initiateTermsPopup(termsConfig));
  }, [dispatch, entityID, type, isAllRequiredTermsAccepted]);

  return !loading && isAllRequiredTermsAccepted ? <Route {...props} /> : null;
};

export default TermedRoute;
