import { FetchDocumentResponse, FetchDocumentsRequestBody } from "@documents/models/api.models";
import { AppThunkAPI } from "@shared/models/redux";
import { RequestConfig } from "@shared/models/request";

type CallDocumentsAPI = (
  body: FetchDocumentsRequestBody,
  options?: RequestConfig<FetchDocumentsRequestBody>
) => AppThunkAPI<Promise<FetchDocumentResponse>>;

export const callDocumentsAPI: CallDocumentsAPI = (body, options) => async (_dispatch, _state, extra) => {
  const response = await extra<FetchDocumentResponse>("/documents/v1/Users/documents/search", {
    ...options,
    method: "post",
    body,
  });

  if (response.status === 204) {
    return { total: 0, results: [] };
  }

  return response.data;
};
