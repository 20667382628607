import { UserPreferenceName, UserPreferencesListResponse, UserPreferencesState } from "@user/models";
import { convertIAGroupId } from "@user/utils/convertIAGroupId";

export const convertPreferencesArrayToMap = (preferencesArray: UserPreferencesListResponse): UserPreferencesState =>
  preferencesArray.reduce((preferencesMap, preference) => {
    if (preference.setting === UserPreferenceName.UserDefaultInvestorsGroup) {
      preferencesMap.UserDefaultInvestorsGroup = convertIAGroupId(preference.value);
    } else {
      // @ts-ignore
      preferencesMap[preference.setting] = preference.value;
    }

    return preferencesMap;
  }, {} as UserPreferencesState);
