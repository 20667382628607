import React, { FunctionComponent, memo } from "react";
import cn from "classnames";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { AppRoute } from "@app/models/AppRoute";
import { ReportingOption } from "@reporting/models";

interface Props {
  routes: Array<ReportingOption>;
  activeRoute: AppRoute;
  onLinkClick(): void;
}

const useStyles = makeStyles((theme) => ({
  reportingOption: {
    width: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 56,
    paddingLeft: theme.spacing(3.5),
    fontSize: theme.typography.body2.fontSize,

    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: 0,
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,

    transition: theme.transitions.create(["opacity"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },

    "&:hover": {
      "& a": {
        textDecoration: "none",
        color: theme.palette.info.main,
      },

      cursor: "pointer",
    },
  },
  reportingOptionActive: {
    background: "rgba(235, 235, 235, 0.5)",
    borderRight: `8px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
    "& a": {
      textDecoration: "none",
      color: `${theme.palette.primary.main} !important`,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

const ReportingDrawerRoutes: FunctionComponent<Props> = ({ routes, activeRoute, onLinkClick }) => {
  const classes = useStyles();

  return (
    <>
      {routes.map(
        ({ label, id, isActive }) =>
          isActive && (
            <Box
              className={cn({
                [classes.reportingOption]: true,
                [classes.reportingOptionActive]: id === activeRoute,
              })}
              key={id}
            >
              <Link to={id as AppRoute} onClick={onLinkClick}>
                {label}
              </Link>
            </Box>
          )
      )}
    </>
  );
};

export default memo<Props>(ReportingDrawerRoutes);
