import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { fetchAccountSummaryFilters } from "@accountSummaryCarlyle/store/thunks/fetchAccountSummaryFilters";
import { fetchAccountSummaryReport } from "@accountSummaryCarlyle/store/thunks/fetchAccountSummaryReport";
import RequestUtils from "@shared/utils/RequestUtils";

export const initAccountSummary = createAsyncThunk<void, void, ThunkApiConfig>(
  "accountSummary/initAccountSummary",
  async (_, { dispatch }) => {
    const fetchAccountSummaryFiltersCancelable = RequestUtils.getAbortThunk(fetchAccountSummaryFilters, dispatch);
    const fetchAccountSummaryReportCancelable = RequestUtils.getAbortThunk(fetchAccountSummaryReport, dispatch);

    await fetchAccountSummaryFiltersCancelable();

    await fetchAccountSummaryReportCancelable();
  }
);
