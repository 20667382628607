import { useState } from "react";
import { Nullable } from "@shared/models/general";

const SPACING = 40; // Above and below the horizontal stroke

const useDataGridHeaderHeight = () => {
  const [headerRef, setHeaderRef] = useState<Nullable<HTMLDivElement>>(null);

  return {
    headerHeight: headerRef?.clientHeight ? headerRef?.clientHeight + SPACING : 0,
    setHeaderRef,
    headerRef,
  };
};

export default useDataGridHeaderHeight;
