import { UserData, UserResponse } from "@user/models";
import { convertPreferencesArrayToMap } from "@user/utils/convertPreferencesArrayToMap";

export const normalizeUserResponse = (userResponse: UserResponse): UserData => {
  const preferences = convertPreferencesArrayToMap(userResponse.preferences);

  return {
    preferences,
    id: userResponse.userId,
    userType: userResponse.userType,
    email: userResponse.email,
    name: `${userResponse.firstName} ${userResponse.lastName}`,
    external: userResponse.external,
    permissions: userResponse.permissions,
    segmentFunction: userResponse.segmentFunction,
    selectedAccountGroup: preferences.UserDefaultInvestorsGroup,
  };
};
