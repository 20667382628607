import { TermsRulesAssociationsConfig } from "@terms/models/termRules";

export enum TermAppearanceType {
  InitializeApplication = "InitializeApplication",
  OpenReportingPage = "OpenReportingPage",
  OpenFundProfilePage = "OpenFundProfilePage",
  ViewDocument = "ViewDocument",
}

export interface InitiateTermsArgs {
  type: TermAppearanceType;
  associations?: TermsRulesAssociationsConfig;
  extraArgs?: Record<string, any>;
}
