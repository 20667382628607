import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutstandingTerm, OutstandingTermStatus, TermsState } from "@terms/models";
import { fetchUserTerms } from "@terms/store/thunks/fetchUserTerms";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { reportingTerm } from "@terms/constants";
import { TermRuleConfig } from "@terms/models/termRules";

const initialState: TermsState = {
  dialogOpen: false,
  data: [reportingTerm],
  loading: true,
  error: null,
  outstandingTerms: [],
  rules: {
    afterEach: [],
    afterAll: [],
  },
};

const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    openTermsDialog(
      state,
      action: PayloadAction<{
        outstandingTerms: Array<OutstandingTerm>;
        rules: TermRuleConfig;
      }>
    ) {
      state.dialogOpen = true;
      state.outstandingTerms = action.payload.outstandingTerms;
      state.rules = action.payload.rules;
    },
    pushFinalRulesToExecute(state, action: PayloadAction<TermRuleConfig["afterAll"]>) {
      state.rules.afterAll = action.payload;
    },
    closeTermsDialog(state) {
      state.dialogOpen = false;
    },
    resetRules(state) {
      state.rules = initialState.rules;
    },
    resetOutstandingTerms(state) {
      state.outstandingTerms = [];
    },
    changeTermStatus(state, action: PayloadAction<Pick<OutstandingTerm, "id" | "status">>) {
      const term = state.data.find((term) => term.id === action.payload.id)!;

      term.accepted = action.payload.status === OutstandingTermStatus.Accepted;

      const outstandingTermToUpdateIndex = state.outstandingTerms.findIndex(
        (outstandingTerm) => outstandingTerm.id === action.payload.id
      );

      state.outstandingTerms[outstandingTermToUpdateIndex].status = action.payload.status;
      state.outstandingTerms[outstandingTermToUpdateIndex].active = false;

      if (state.outstandingTerms[outstandingTermToUpdateIndex + 1]) {
        state.outstandingTerms[outstandingTermToUpdateIndex + 1].active = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTerms.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state);
      })
      .addCase(fetchUserTerms.fulfilled, (state, action) => {
        const termsData = action.payload
          .map((term) => ({
            ...term,
            accepted: false,
          }))
          .concat([reportingTerm]);

        ReduxUtils.defaultFulfilledActionHandler(state, termsData);
      })
      .addCase(fetchUserTerms.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(state, action.payload as string, initialState.data);
      });
  },
});

export const {
  resetState,
  openTermsDialog,
  pushFinalRulesToExecute,
  closeTermsDialog,
  resetOutstandingTerms,
  resetRules,
  changeTermStatus,
} = termsSlice.actions;
export default termsSlice.reducer;
