import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { callEmbedUrlAPI } from "@reporting/store/thunks/callEmbedUrlAPI";
import { ReportingEmbedUrlConfig } from "@reporting/models";
import { accountSummaryAlpInvestEmbedUrlSelector } from "@accountSummaryAlpInvest/store/selectors";

export const fetchAccountSummaryEmbedUrlThunk = createAsyncThunk<ReportingEmbedUrlConfig, void, ThunkApiConfig>(
  "accountSummaryAlpInvest/fetchAccountSummaryEmbedUrlThunk",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callEmbedUrlAPI("AccountSummary"));
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load report",
        })
      );

      return rejectWithValue("Failed to fetch Embed Url");
    }
  },
  {
    condition: (_, { getState }) => {
      const accountSummaryAlpInvestEmbedUrl = accountSummaryAlpInvestEmbedUrlSelector(getState());

      return !accountSummaryAlpInvestEmbedUrl.data && !accountSummaryAlpInvestEmbedUrl.loading;
    },
  }
);
