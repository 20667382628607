import React, { useCallback, useMemo } from "react";
import { get } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { BaseRow, CellProps, RowProps, RowType } from "@shared/components/DataGrid/models";
import { Cell } from "@shared/components/DataGrid/components";

const fontWeightMap = {
  [RowType.Regular]: "fontWeightMedium",
  [RowType.SubHeader]: "fontWeightBold",
  [RowType.Highlighted]: "fontWeightMedium",
  [RowType.Divider]: "fontWeightRegular",
  [RowType.Video]: "fontWeightMedium",
};
const bgColorsMap = {
  [RowType.Regular]: "grey.100",
  [RowType.SubHeader]: "grey.300",
  [RowType.Highlighted]: "accent.light",
  [RowType.Divider]: "common.white",
  [RowType.Video]: "grey.100",
};
const defaultMeta = {
  type: RowType.Regular,
};

const useStyles = makeStyles<Theme, { type: RowType }>((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    height: 50,
    width: "100%",
    margin: theme.spacing(2, 0),
    padding: ({ type }) => (type === RowType.Divider ? theme.spacing(0, 2.5) : "initial"),
    fontStyle: ({ type }) => (type === RowType.Divider ? "italic" : "inherit"),
    fontSize: ({ type }) => (type === RowType.SubHeader ? 15 : 14),
    fontWeight: ({ type }) => get(theme.typography, fontWeightMap[type]),
    background: ({ type }) => get(theme.palette, bgColorsMap[type]),
    "&:hover": {
      background: ({ type }) =>
        type !== RowType.SubHeader && type !== RowType.Divider && type === RowType.Highlighted
          ? theme.palette.accent.main
          : theme.palette.grey["300"],
    },
  },
}));

const Row = <D extends BaseRow>(props: RowProps<D>) => {
  const meta = useMemo(() => ({ ...defaultMeta, ...(props.original.rowMeta || {}) }), [props.original.rowMeta]);
  const classes = useStyles({ type: meta.type });

  const renderCell = useCallback((c: CellProps<D>) => {
    const { key } = c.getCellProps();

    return <Cell {...c} key={key} />;
  }, []);

  return (
    <div {...props.getRowProps({ style: props.style })}>
      <div data-testid="datagrid-row" className={classes.row}>
        {meta.type === RowType.Divider ? props.cells[0].value : props.cells.map(renderCell)}
      </div>
    </div>
  );
};

export default Row;
