import { Store } from "redux";
import axios, { AxiosResponse } from "axios";
import { format } from "date-fns";
import { MimeType } from "@shared/models/mimeType";
import { FileResponse, RequestConfig, RequestExtra, ThunkExtra } from "@shared/models/request";
import { LocalStorageKeys } from "@app/constants/localStorage";
import { AppRoute } from "@app/models/AppRoute";

class RequestService {
  private readonly axios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XmlHttpRequest",
    },
    withCredentials: true,
  });

  private _store: Store = {} as Store;

  public set store(store: Store) {
    this._store = store;
  }

  public request: ThunkExtra = async <R, D>(
    url: string,
    { method = "get", queryParams = {}, ...config }: RequestConfig<D> = {
      method: "get",
      queryParams: {},
    },
    { includeUserId = true, includeAccountGroupId = true }: RequestExtra = {
      includeAccountGroupId: true,
      includeUserId: true,
    }
  ) => {
    const params = {
      ...queryParams,
      ...this.configureGlobalQueryParams({
        includeAccountGroupId,
        includeUserId,
      }),
    };

    try {
      const response = await this.axios.request<R>({
        url,
        params,
        method,
        data: config.body,
        responseType: config.responseType,
        withCredentials: config.withCredentials,
        signal: config.signal,
      });

      if (response.status === 204) {
        return this.handleNoDataResponse(response);
      }
      if (response.headers["content-type"] === MimeType.XLSX || response.headers["content-type"] === MimeType.PDF) {
        return this.handleFileResponse(response as unknown as AxiosResponse<ArrayBuffer>);
      }

      return response;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(`Request cancelled: ${url}`);

        return Promise.reject(error);
      }

      if (!error.response) {
        console.error(`Request error: ${error.message}`);
      }

      if (error.response.status === 401) {
        localStorage.removeItem(LocalStorageKeys.LpcActiveSession);

        if (window.location.pathname !== AppRoute.Home) {
          localStorage.setItem(LocalStorageKeys.DeepLink, window.location.pathname);
        }

        window.location.replace(process.env.REACT_APP_AUTH_LOGIN_URI as string);
      }

      if (error.response.status === 300) {
        window.location.replace(error.response.headers.get("location"));
      }

      return Promise.reject(error);
    }
  };

  private handleNoDataResponse<R>(response: AxiosResponse<R>): AxiosResponse<R> {
    return {
      ...response,
      data: [] as unknown as R,
    };
  }

  private handleFileResponse(response: AxiosResponse<ArrayBuffer>): AxiosResponse<FileResponse> {
    const disposition = response.headers["content-disposition"];

    let fileName = `Report_${format(new Date(), "MMddyyyy")}.xlsx`;

    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);

      if (matches !== null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }

    return {
      ...response,
      data: {
        fileName,
        blob: new Blob([response.data], {
          type: response.headers["content-type"],
        }),
      },
    };
  }

  private configureGlobalQueryParams(config: {
    includeAccountGroupId: boolean;
    includeUserId: boolean;
  }): Record<string, string | number> {
    const {
      user: { data: userData },
      impersonation,
    } = this._store.getState();

    const params: Record<string, string | number> = {};

    if (!userData) return params;

    if (config.includeUserId) {
      params.entitledUserId = impersonation.isImpersonating ? impersonation.user.id : userData.id;
    }

    if (config.includeAccountGroupId) {
      params.investorAccountGroupId = impersonation.isImpersonating
        ? impersonation.user.selectedAccountGroup
        : userData.selectedAccountGroup;
    }

    return params;
  }
}

export default RequestService;
