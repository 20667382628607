import { InsightsHubArticlesListResponse } from "@insightsHub/models";
import { AppThunkAPI } from "@shared/models/redux";

type CallInsightsHubApi = () => AppThunkAPI<Promise<InsightsHubArticlesListResponse>>;

export const callInsightsHubApi: CallInsightsHubApi = () => async (_dispatch, _getState, extra) => {
  const response = await extra<InsightsHubArticlesListResponse>("/entitlements/v1/user/cms/portals");

  return response.data;
};
