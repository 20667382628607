import { ProfileMenuOptionId, ProfileMenuOptionLabel, ProfileMenuOptions } from "@app/models/Header";

export const HEADER_BAR_HEIGHT = 172;
export const APP_NAVIGATION_HEIGHT = 59;
export const HEADER_HEIGHT = HEADER_BAR_HEIGHT + APP_NAVIGATION_HEIGHT;

export const MOBILE_HEADER_HEIGHT = 120;

export const NOTIFICATION_BAR_HEIGHT = 42;
export const IMPERSONATION_BAR_HEIGHT = 42;
export const IMPERSONATION_BAR_HEIGHT_MOBILE = 74;

export const profileMenuOptions: ProfileMenuOptions = {
  [ProfileMenuOptionId.Preferences]: {
    id: ProfileMenuOptionId.Preferences,
    label: ProfileMenuOptionLabel.Preferences,
  },
  [ProfileMenuOptionId.StartImpersonation]: {
    id: ProfileMenuOptionId.StartImpersonation,
    label: ProfileMenuOptionLabel.ImpersonateUser,
  },
  [ProfileMenuOptionId.StopImpersonation]: {
    id: ProfileMenuOptionId.StopImpersonation,
    label: ProfileMenuOptionLabel.StopImpersonating,
  },
};
