import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserPreferenceName } from "@user/models";
import { useAppSelector } from "@app/hooks";
import { userSliceSelector } from "@user/store/selectors";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { LocalStorageKeys } from "@app/constants/localStorage";
import FeatureToggleService from "@services/FeatureToggleService";
import { ToggledFeature } from "@shared/models/featureToggle";

const useLandingPageRedirect = () => {
  const history = useHistory();

  const { userData, isImpersonating, impersonatedUserData } = useAppSelector((state) => {
    const { data: userData } = userSliceSelector(state);
    const { isImpersonating, user: impersonatedUserData } = impersonationStateSelector(state);

    return {
      userData,
      isImpersonating,
      impersonatedUserData,
    };
  });

  const activeSession = localStorage.getItem(LocalStorageKeys.LpcActiveSession);
  const deepLink = localStorage.getItem(LocalStorageKeys.DeepLink);

  useEffect(() => {
    if (activeSession) return;

    localStorage.setItem(LocalStorageKeys.LpcActiveSession, "true");

    if (deepLink) {
      history.push(deepLink);
      localStorage.removeItem(LocalStorageKeys.DeepLink);

      return;
    }

    if (!FeatureToggleService.isFeatureEnabled(ToggledFeature.DefaultLandingPage)) {
      return;
    }

    const userLandingPage =
      isImpersonating && impersonatedUserData
        ? impersonatedUserData.preferences[UserPreferenceName.UserLandingPage]
        : userData?.preferences[UserPreferenceName.UserLandingPage] ?? null;

    if (userLandingPage) {
      history.push(userLandingPage);
    }
  }, [activeSession, history, impersonatedUserData, isImpersonating, userData, deepLink]);
};

export default useLandingPageRedirect;
