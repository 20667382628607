import React, { FunctionComponent, memo } from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import { Multiselect, MultiselectOption } from "@shared/components";
import { investorRequestSelector } from "@investorRequest/store/selectors";
import useMultiselectHandlers from "@shared/hooks/useMultiselectHandlers";
import { updateCommonRequestData } from "@investorRequest/store/ducks";
import { useAppSelector, useMobileLayout } from "@app/hooks";

export const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    "&:first-child > div": {
      border: `1px solid ${theme.palette.warning.main}`,
    },
    "&:nth-child(2) > div": {
      border: `1px solid ${theme.palette.warning.main}`,
    },
  },
}));

interface Props {
  required?: boolean;
  funds: MultiselectOption[];
  accounts: MultiselectOption[];
}

const InvestorRequestEntitiesDropdowns: FunctionComponent<Props> = ({ funds, accounts, required }) => {
  const { commonFields, validationWarningShown } = useAppSelector(investorRequestSelector);
  const { selectedAccounts, selectedFunds } = useAppSelector((state) => ({
    selectedAccounts: state.investorRequest.commonFields.selectedAccounts,
    selectedFunds: state.investorRequest.commonFields.selectedFunds,
  }));

  const [handleSelectFund, handleClearAllFunds] = useMultiselectHandlers({
    entityName: "selectedFunds",
    nullIfEmpty: false,
    selectedValues: selectedFunds,
    action: updateCommonRequestData,
  });

  const [handleSelectAccount, handleClearAllAccounts] = useMultiselectHandlers({
    entityName: "selectedAccounts",
    nullIfEmpty: false,
    selectedValues: selectedAccounts,
    action: updateCommonRequestData,
  });

  const classes = useStyles();
  const isMobile = useMobileLayout();

  return (
    <Grid item xs={12}>
      <Grid
        item
        container
        direction={isMobile ? "column" : "row"}
        spacing={isMobile ? 4 : 7}
        data-testid="investor-request-entities-dropdowns"
      >
        <Grid
          item
          xs={6}
          className={cn({
            [classes.gridWrapper]: required && validationWarningShown && !commonFields.selectedFunds.length,
          })}
        >
          <Multiselect
            entity="fund"
            size="large"
            variant="contained"
            options={funds}
            onSelect={handleSelectFund}
            onClearAll={handleClearAllFunds}
          />
        </Grid>
        <Grid
          item
          xs={6}
          className={cn({
            [classes.gridWrapper]: required && validationWarningShown && !commonFields.selectedAccounts.length,
          })}
        >
          <Multiselect
            entity="account"
            size="large"
            variant="contained"
            options={accounts}
            onSelect={handleSelectAccount}
            onClearAll={handleClearAllAccounts}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo<Props>(InvestorRequestEntitiesDropdowns);
