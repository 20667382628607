import { createSlice } from "@reduxjs/toolkit";
import CommonUtils from "@shared/utils/CommonUtils";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { CashFlowActivityDataRow, CashFlowActivityFilterOptions, CashFlowActivityState } from "../models";
import contributions from "../constants/contributions";
import distributions from "../constants/distributions";
import { calculateYearOptions, dateOptions, getDefaultEndDate, getDefaultStartDate } from "../constants/dateOptions";
import { fetchCashFlowActivityFilters, fetchCashFlowActivityReport, downloadCashflowReport } from "./thunks";
import { changeFilterValue } from "./actions";

/**
 * @prop filterOptions keep all the possible options to build filter controls.
 * contributions & distributions are stored on UI side.
 * fundToInvestorsMapping - generated on UI side based on filter API response:
 * funds (key) are populated to Funds dropdown,
 * investor accounts (value) are populated to Investor Accounts dropdown depending on the Fund that is currently selected.
 * @prop report is array of the report data that goes to the DataGrid
 * @prop filters keep current selected filter value.
 */
const initialState: CashFlowActivityState = {
  filterOptions: ReduxUtils.getAsyncSlice<CashFlowActivityFilterOptions>({
    contributions,
    distributions,
    fundToInvestorsMapping: null,
    timeRange: dateOptions,
    timeRangeStart: calculateYearOptions("start"),
    timeRangeEnd: calculateYearOptions("end"),
  }),
  report: ReduxUtils.getAsyncSlice<CashFlowActivityDataRow[]>(),
  filters: {
    timeRange: {
      timePeriod: dateOptions[1].id,
      start: getDefaultStartDate(),
      end: getDefaultEndDate(),
    },
    fund: null,
    accounts: null,
    contributions: null,
    distributions: null,
  },
  isReportDownloading: false,
};

export const cashFlowActivitySlice = createSlice({
  name: "cashFlowActivity",
  initialState,
  reducers: {
    resetState: (state) => {
      state.filterOptions = initialState.filterOptions;
      state.report = initialState.report;
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    /**
     * Action to change filter values.
     * If an user changes the fund, the system should preserve only an intersected accounts
     */
    builder.addCase(changeFilterValue, (state, action) => {
      const newFilters = { ...action.payload };
      const selectedAccountsMap = CommonUtils.convertArrayToMap(state.filters.accounts);

      if (action.payload.fund) {
        newFilters.accounts = state.filterOptions
          .data!.fundToInvestorsMapping![action.payload.fund].investors.filter((a) => selectedAccountsMap[a.id])
          .map((a) => a.id as string);

        if (!newFilters.accounts.length) {
          newFilters.accounts = null;
        }
      }
      if (action.payload.timeRange) {
        newFilters.timeRange = {
          ...state.filters.timeRange,
          ...action.payload.timeRange,
        };
      }

      state.filters = { ...state.filters, ...newFilters };
    });

    // fetchCashFlowFilters
    builder.addCase(fetchCashFlowActivityFilters.pending, (state) => {
      state.filterOptions.loading = true;
      state.filterOptions.error = null;
    });

    builder.addCase(fetchCashFlowActivityFilters.fulfilled, (state, action) => {
      state.filterOptions.loading = false;
      state.filterOptions.data!.fundToInvestorsMapping = action.payload;
      state.filters.fund = Object.keys(action.payload)[0];
      state.filterOptions.error = null;
    });

    builder.addCase(fetchCashFlowActivityFilters.rejected, (state, action) => {
      if (action.error.name !== "AbortError") {
        state.filterOptions.error = action.error.message!;
        state.filterOptions.loading = false;
      }
      state.report.loading = false;
    });

    // fetchCashFlowReport
    builder.addCase(fetchCashFlowActivityReport.pending, (state) => {
      state.report.loading = true;
      state.report.data = null;
      state.report.error = null;
    });

    builder.addCase(fetchCashFlowActivityReport.fulfilled, (state, action) => {
      state.report.loading = false;
      state.report.data = action.payload;
      state.report.error = null;
    });

    builder.addCase(fetchCashFlowActivityReport.rejected, (state, action) => {
      if (action.error.name !== "AbortError") {
        state.report.data = null;
        state.filterOptions.error = action.error.message!;
      }
      state.report.loading = false;
    });
    builder.addCase(downloadCashflowReport.pending, (state) => {
      state.isReportDownloading = true;
    });
    builder.addCase(downloadCashflowReport.fulfilled, (state) => {
      state.isReportDownloading = false;
    });
    builder.addCase(downloadCashflowReport.rejected, (state) => {
      state.isReportDownloading = false;
    });
  },
});

export const { resetState } = cashFlowActivitySlice.actions;

export default cashFlowActivitySlice.reducer;
