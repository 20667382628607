import { AccountSummaryFilterOptions, AccountSummaryFilterValues } from "@accountSummaryCarlyle/models";
import { Nullable } from "@shared/models/general";

const getLast3QuartersFunds = (
  filterOptions: Nullable<AccountSummaryFilterOptions>
): AccountSummaryFilterValues["funds"] => {
  if (!filterOptions) return {};

  const currentQuarterReports = filterOptions[filterOptions.length - 1] ?? {};
  const prevQuarterReports = filterOptions[filterOptions.length - 2] ?? {};
  const prev2QuarterReports = filterOptions[filterOptions.length - 3] ?? {};

  return {
    ...prev2QuarterReports.associatedFunds,
    ...prevQuarterReports.associatedFunds,
    ...currentQuarterReports.associatedFunds,
  };
};

export default getLast3QuartersFunds;
