import React, { FunctionComponent, memo, useMemo } from "react";
import { Grid } from "@mui/material";
import { InvestorRequestType } from "@investorRequest/models";
import { Nullable } from "@shared/models/general";
import { investorRequestTypeTextMap } from "@investorRequest/constants";
import { Singleselect } from "@shared/components";
import { useMobileLayout } from "@app/hooks";

interface Props {
  requestType: Nullable<InvestorRequestType>;
  onSelect(requestType: InvestorRequestType): void;
}

const InvestorRequestTypesDropdown: FunctionComponent<Props> = ({ requestType, onSelect }) => {
  const options = useMemo(
    () =>
      Object.values(InvestorRequestType).map((type) => ({
        id: type,
        label: investorRequestTypeTextMap[type],
      })),
    []
  );

  const label = requestType ? investorRequestTypeTextMap[requestType] : "I need to...";
  const isMobile = useMobileLayout();

  return (
    <Grid item xs={12} data-testid="investor-request-types-dropdown" pt={isMobile ? 7.5 : undefined}>
      <Singleselect
        label={label}
        size="large"
        variant="contained"
        options={options}
        onSelect={(option) => onSelect(option.id)}
      />
    </Grid>
  );
};

export default memo(InvestorRequestTypesDropdown);
