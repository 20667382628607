import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from "react";
import { Box, ClickAwayListener, Grow, Paper, Popper, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { isFunction } from "lodash";
import { DropdownProps, RenderPropsBase, RenderToggleArgs } from "./models";

const useStyles = makeStyles<
  Theme,
  {
    width?: number;
    stretchToContentWidth?: boolean;
  }
>((theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
    minWidth: ({ width }) => width,
    maxWidth: ({ stretchToContentWidth, width }) => (stretchToContentWidth ? "auto" : width),
  },
  arrow: {
    width: 10,
    height: 10,
    "&::before": {
      content: '""',
      display: "block",
      transform: "translateX(10px) rotate(45deg)",
    },
  },
}));

const Dropdown: FunctionComponent<DropdownProps> = ({
  children,
  renderToggle,
  withArrow,
  width,
  placement,
  stretchToContentWidth,
}) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLElement>(null);

  const classes = useStyles({
    width: width ?? anchorEl.current?.clientWidth,
    stretchToContentWidth,
  });

  const handleToggleDropdown = useCallback(() => setOpen(!open), [setOpen, open]);

  const handleOpenDropdown = useCallback(() => setOpen(true), [setOpen]);

  const handleCloseDropdown = useCallback(() => setOpen(false), [setOpen]);

  const renderProps = useMemo<RenderPropsBase>(
    () => ({
      toggleDropdown: handleToggleDropdown,
      openDropdown: handleOpenDropdown,
      closeDropdown: handleCloseDropdown,
    }),
    [handleOpenDropdown, handleCloseDropdown, handleToggleDropdown]
  );

  const renderToggleArgs = useMemo<RenderToggleArgs>(
    () => ({
      open,
      ref: anchorEl,
      ...renderProps,
    }),
    [open, renderProps]
  );

  const modifiers = useMemo(
    () => [
      {
        name: "arrow",
        enabled: withArrow,
      },
    ],
    [withArrow]
  );

  return (
    <ClickAwayListener onClickAway={handleCloseDropdown}>
      <Box>
        {renderToggle(renderToggleArgs)}
        <Popper
          transition
          placement={placement}
          open={open}
          anchorEl={anchorEl.current}
          className={classes.popper}
          modifiers={modifiers}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Box>
                {withArrow && <span data-popper-arrow={true} className={classes.arrow} />}
                <Paper elevation={5} data-testid="dropdown-children">
                  {isFunction(children) ? children(renderProps) : children}
                </Paper>
              </Box>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

Dropdown.defaultProps = {
  withArrow: false,
  stretchToContentWidth: false,
  placement: "bottom",
};

export default Dropdown;
