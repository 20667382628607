export const GLOBAL_INSIGHTS_URL = "https://www.carlyle.com/global-insights";
export const VIDEO_PROVIDER_URL = "https://view.vzaar.com";
export const WEB_ACTIVITY_TRACKER_URL = "https://www.google-analytics.com";
export const EMBEDDED_REPORT_PROVIDER_URL = "https://app.powerbi.com";
export const TAG_MANAGEMENT_SYSTEM_URL = "https://www.googletagmanager.com";
export const SPOTIFY_URL = "https://open.spotify.com";

export const INTERNAL_COINVESTMENT_URL = {
  development: "https://secure1.paymentus.com/cp/cpmg",
  qa: "https://secure1.paymentus.com/cp/cpmg",
  uat: "https://secure1.paymentus.com/cp/cpmg",
  production: "https://ipn.paymentus.com/cp/tcgp",
};
