import { NumberFormat } from "@user/models";

class NumberUtils {
  static formatNumber(number: number, format: NumberFormat, fractionDigits?: number) {
    const locale = format === NumberFormat.DotComma ? "de-DE" : "en-US";
    const options: {
      minimumFractionDigits?: number;
      maximumFractionDigits?: number;
    } = {};

    if (typeof fractionDigits === "number") {
      options.minimumFractionDigits = fractionDigits;
      options.maximumFractionDigits = fractionDigits;
    }

    let formattedNumber = new Intl.NumberFormat(locale, options).format(number);

    if (formattedNumber === "-0") {
      return "0";
    }

    if (formattedNumber[0] === "-") {
      formattedNumber = `(${formattedNumber.slice(1)})`;
    }

    return formattedNumber;
  }
}

export default NumberUtils;
