import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { ThemeProvider as ThemeProviderLegacy } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import store from "@store/index";
import theme from "@app/constants/theme";

const AppProviders: FunctionComponent = ({ children }) => (
  <BrowserRouter>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ThemeProviderLegacy theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              {children}
            </LocalizationProvider>
          </ThemeProviderLegacy>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </BrowserRouter>
);

export default AppProviders;
