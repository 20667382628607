import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AccountSummaryFilterOptions,
  AccountSummaryFundsFilter,
  ASFilterRow,
  FundData,
} from "@accountSummaryCarlyle/models";
import { ThunkApiConfig } from "@shared/models/redux";
import DateUtils from "@shared/utils/DateUtils";
import { entitledFundsSelector } from "@store/Entities/selectors";
import { Nullable } from "@shared/models/general";
import { asyncArray } from "@utils/array";

export const fetchAccountSummaryFilters = createAsyncThunk<
  {
    filterOptions: AccountSummaryFilterOptions;
    fundData: FundData[];
  },
  void,
  ThunkApiConfig
>("accountSummary/fetchAccountSummaryFilters", async (_, { rejectWithValue, extra, signal, getState }) => {
  try {
    const { status, data: filters } = await extra<ASFilterRow[]>("/reporting/v1/AccountSummary/filter", { signal });

    if (status === 204) {
      return {
        filterOptions: [],
        fundData: [],
      };
    }

    const fundData = await asyncArray(filters).map((filter) => {
      const { year, quarter } = DateUtils.convertDateToYearQuarter(new Date(filter.reportingDate));

      return {
        ...filter,
        year: String(year),
        quarter: String(quarter),
      };
    });

    const entitledFunds = entitledFundsSelector(getState()).data;

    if (entitledFunds === null) {
      return {
        filterOptions: [],
        fundData,
      };
    }

    const fundsByReportingDate = await asyncArray(filters).reduce<Record<string, string[]>>(
      (result, { fundId, reportingDate }) => {
        result[reportingDate] = result[reportingDate]
          ? result[reportingDate].includes(fundId)
            ? result[reportingDate]
            : result[reportingDate].concat(fundId)
          : [fundId];

        return result;
      },
      {}
    );

    const fundsByYearQuarter = await asyncArray(Object.keys(fundsByReportingDate)).reduce<
      Record<string, Record<string, Nullable<string[]>>>
    >((result, date) => {
      const { year, quarter } = DateUtils.convertDateToYearQuarter(new Date(date));

      if (!result[year]) {
        result[year] = {
          "1": null,
          "2": null,
          "3": null,
          "4": null,
        };
      }

      result[year][quarter] = fundsByReportingDate[date];

      return result;
    }, {});

    const filterOptions = await Object.entries(fundsByYearQuarter).reduce<AccountSummaryFilterOptions>(
      (result, [year, fundsByQuarter]) => {
        Object.entries(fundsByQuarter).forEach(async ([quarter, funds]) => {
          if (funds === null) {
            return;
          }

          const associatedFunds = await asyncArray(funds).reduce<Record<string, AccountSummaryFundsFilter>>(
            (fundFilters, fundId) => {
              fundFilters[fundId] = {
                globalId: fundId,
                label: entitledFunds?.[fundId]?.entityName ?? "",
                selected: false,
              };

              return fundFilters;
            },
            {}
          );

          result.push({
            year,
            quarter,
            associatedFunds,
          });
        });

        return result;
      },
      []
    );

    filterOptions.sort(
      (filterOptionFirst, filterOptionSecond) => Number(filterOptionFirst.year) - Number(filterOptionSecond.year)
    );

    return {
      filterOptions,
      fundData,
    };
  } catch (_) {
    return rejectWithValue("Failed to fetch account summary filters");
  }
});
