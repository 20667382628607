import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";
import { selectedFsisDateSelector, selectedFundDocumentsFilters, selectedFundSelector } from "@funds/store/selectors";
import { AppRoute } from "@app/models/AppRoute";
import { fetchFSISDates, fetchFSISReports, fetchFundDocuments, fetchFundInvestorAccounts } from "@funds/store/thunks";
import { entitledAccountsSelector } from "@store/Entities/selectors";
import { changeFundDocumentsFilterValue } from "@funds/store/actions";
import { AddAppListener, GetAppListener } from "@shared/models/redux";
import { FundType } from "@funds/models";
import RouterUtils from "@shared/utils/RouterUtils";
import RequestUtils from "@shared/utils/RequestUtils";

export const getIAGroupChangedFundProfilePageListener: GetAppListener = (addAppListener: AddAppListener, { history }) =>
  addAppListener({
    actionCreator: changeInvestorAccountsGroup.fulfilled,
    effect: async (action, { getState, dispatch, cancelActiveListeners }) => {
      cancelActiveListeners();

      const routeParams = RouterUtils.checkPathMatchExactRouteAndReturnRouteParams<{
        entityID: string;
      }>(history, AppRoute.FundProfile);

      if (!routeParams) return;

      const state = getState();
      const { entityID: fundId } = routeParams;
      const selectedFund = selectedFundSelector(state, fundId);

      if (!selectedFund) {
        history.replace(AppRoute.Home);

        return;
      }

      if (selectedFund.type === FundType.Fund) {
        const selectedFSISDate = selectedFsisDateSelector(state);
        const { data: accountsMap } = entitledAccountsSelector(state);
        const filters = selectedFundDocumentsFilters(state);

        await dispatch(fetchFundInvestorAccounts(fundId));

        const selectedAccounts = (filters.investorAccounts || []).filter((id) => accountsMap?.[id]);

        const investorAccounts = selectedAccounts.length ? selectedAccounts : null;
        const fetchFSISDatesCancelable = RequestUtils.getAbortThunk(fetchFSISDates, dispatch);

        fetchFSISDatesCancelable();
        if (selectedFSISDate) {
          const fetchFSISReportsCancelable = RequestUtils.getAbortThunk(fetchFSISReports, dispatch);

          fetchFSISReportsCancelable({ fundId, date: selectedFSISDate });
        }

        dispatch(changeFundDocumentsFilterValue({ investorAccounts }));

        const fetchFundDocumentsCancelable = RequestUtils.getAbortThunk(fetchFundDocuments, dispatch);

        fetchFundDocumentsCancelable(fundId);
      }
    },
  });
