export enum AppStage {
  Dev = "development",
  Qa = "qa",
  Sec = "sec",
  Uat = "uat",
  Prod = "production",
}

export enum ToggledFeature {
  DefaultLandingPage = "DefaultLandingPage",
  MyCalendar = "MyCalendar",
  GoogleAnalytics = "GoogleAnalytics",
}

export type FeatureConfig = {
  [key in AppStage]: boolean;
};
