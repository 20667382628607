import React, { memo, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Dialog } from "@shared/components";
import { InvestorRequestType } from "@investorRequest/models";
import GeneralRequest from "@investorRequest/containers/GeneralRequest";
import WireInstructionsRequest from "@investorRequest/containers/WireInstructionsRequest";
import NoDataRequest from "@investorRequest/containers/NoDataRequest";
import InvestorRequestControls from "@investorRequest/containers/InvestorRequestControls";
import InvestorRequestTypesDropdown from "@investorRequest/components/InvestorRequestTypesDropdown";
import InvestorRequestFormTitle from "@investorRequest/components/InvestorRequestFormTitle";
import InvestorRequestLoadingPlaceholder from "@investorRequest/components/InvestorRequestLoadingPlaceholder";
import { closeInvestorRequestDialog, resetInvestorRequestState, selectRequestType } from "@investorRequest/store/ducks";
import { investorRequestSelector } from "@investorRequest/store/selectors";
import { submitFormThunk } from "@investorRequest/store/thunks";
import { useAppDispatch, useAppSelector, useMobileLayout } from "@app/hooks";

const InvestorRequestDialog = () => {
  const { open, requestType, loading } = useAppSelector(investorRequestSelector);
  const dispatch = useAppDispatch();

  const TransitionProps = useMemo<TransitionProps>(
    () => ({
      onExited: () => dispatch(resetInvestorRequestState()),
    }),
    [dispatch]
  );

  const handleCloseDialog = useCallback(() => {
    dispatch(closeInvestorRequestDialog());
  }, [dispatch]);

  const handleSelectRequestType = useCallback(
    (requestType: InvestorRequestType) => {
      dispatch(selectRequestType({ requestType }));
    },
    [dispatch]
  );

  const handleSubmit = useCallback(() => dispatch(submitFormThunk()), [dispatch]);
  const isMobile = useMobileLayout();

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={open}
      TransitionProps={TransitionProps}
      title="New Request"
      maxWidth="lg"
      fullWidth
      actions={isMobile ? <InvestorRequestControls onSubmit={handleSubmit} loading={loading} /> : undefined}
    >
      <Grid container spacing={isMobile ? 7.5 : 10}>
        <InvestorRequestFormTitle text="Have a question? Let us know, we are listening." />
        <InvestorRequestTypesDropdown requestType={requestType} onSelect={handleSelectRequestType} />
        {loading && requestType ? (
          <InvestorRequestLoadingPlaceholder />
        ) : (
          <>
            {requestType === InvestorRequestType.General && <GeneralRequest />}
            {requestType === InvestorRequestType.UpdateWireInstructions && <WireInstructionsRequest />}
            {requestType === InvestorRequestType.NoData && <NoDataRequest />}
          </>
        )}
        {!isMobile && <InvestorRequestControls onSubmit={handleSubmit} loading={loading} />}
      </Grid>
    </Dialog>
  );
};

export default memo(InvestorRequestDialog);
