import React, { useCallback, useMemo } from "react";
import { omit } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { BaseRow, HeaderCellProps } from "@shared/components/DataGrid/models";
import { HeaderMenu } from "@shared/components/DataGrid/components";
import { ReactComponent as ActionFlagIcon } from "@assets/svg/flag_icon.svg";
import { ReactComponent as SortableIcon } from "@assets/svg/sortable_icon.svg";
import { ReactComponent as SortDirectionIcon } from "@assets/svg/sort_direction_icon.svg";
import { SortDirection } from "@shared/models/general";

const useStyles = makeStyles<
  Theme,
  Pick<
    HeaderCellProps<any>,
    | "headerAlign"
    | "headerContentAlign"
    | "width"
    | "flex"
    | "type"
    | "letterSpacing"
    | "pinned"
    | "sortable"
    | "sortDirection"
    | "minWidth"
  >
>((theme) => ({
  headerCell: {
    position: ({ pinned }) => (pinned ? "sticky" : "relative"),
    left: ({ pinned }) => (pinned ? 0 : "auto"),
    zIndex: ({ pinned }) => (pinned ? 1 : 0),
    backgroundColor: "inherit",
    alignSelf: "stretch",
    alignItems: "flex-start",
    display: "flex",
    justifyContent: ({ headerContentAlign }) => headerContentAlign,
    fontSize: 14,
    lineHeight: "14px",
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0, 2.5),
    letterSpacing: ({ letterSpacing }) => letterSpacing,
    textAlign: ({ headerAlign }) => headerAlign,
    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    flex: ({ flex }) => flex,
    cursor: ({ type, sortable }) => (type === "checkbox" || sortable ? "pointer" : "default"),
  },
  actionFlag: {
    width: 13,
    height: 13,
  },
  sortIcon: {
    marginLeft: theme.spacing(1),
    transition: "all ease-in .25s",
    transform: ({ sortDirection }) => (sortDirection === SortDirection.DESC ? "rotate(180deg)" : "none"),
  },
}));

const HeaderCell = <D extends BaseRow>(props: HeaderCellProps<D>) => {
  const classes = useStyles({
    headerAlign: props.headerAlign,
    headerContentAlign: props.headerContentAlign,
    width: props.width,
    flex: props.flex,
    type: props.type,
    letterSpacing: props.letterSpacing,
    pinned: props.pinned,
    sortable: props.sortable,
    sortDirection: props.sortDirection,
    minWidth: props.minWidth,
  });

  const content = useMemo(() => {
    switch (props.type) {
      case "checkbox":
        return (
          <HeaderMenu
            menu={props.menu}
            isAnyRowSelected={props.isAnyRowSelected}
            toggleAllRowsSelected={props.toggleAllRowsSelected}
            selectedRowIds={props.selectedRowIds}
          />
        );
      case "actionFlag":
        return <ActionFlagIcon className={classes.actionFlag} data-testid={props.testId} />;
      default:
        return props.render("Header");
    }
  }, [props, classes]);

  const sortIcon = useMemo(() => {
    if (!props.sortable) return null;

    return props.sortDirection ? (
      <SortDirectionIcon
        className={classes.sortIcon}
        data-tesid={props.testId ? `sort-direction-icon-${props.testId}` : "sort-direction-icon"}
      />
    ) : (
      <SortableIcon
        className={classes.sortIcon}
        data-tesid={props.testId ? `sortable-icon-${props.testId}` : "sortable-icon"}
      />
    );
  }, [classes.sortIcon, props.sortDirection, props.sortable, props.testId]);

  const handleClick = useCallback(() => {
    if (props.sortable && props.onSortChange) {
      props.onSortChange(props.id, props.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC);
    }
  }, [props]);

  return (
    <div
      {...omit(props.getHeaderProps(), "style")}
      data-testid={props.testId}
      className={classes.headerCell}
      onClick={handleClick}
    >
      {content}
      {sortIcon}
    </div>
  );
};

HeaderCell.defaultProps = {
  headerContentAlign: "flex-end",
  headerAlign: "right",
  align: "center",
  type: "string",
  flex: "initial",
  letterSpacing: "1.75px",
  pinned: false,
};

export default HeaderCell;
