import { RootState } from "@store/index";
import { PortfolioPerformanceState } from "@portfolioPerformance/models";

export const portfolioPerformanceEmbedUrlSelector = (state: RootState) => state.portfolioPerformance.embedUrlConfig;

export const portfolioPerformanceExportSelector = (state: RootState) => state.portfolioPerformance.export;

export const portfolioPerformanceReportExportLoadingSelector = (
  state: RootState
): PortfolioPerformanceState["isReportDownloading"] => state.portfolioPerformance.isReportDownloading;
