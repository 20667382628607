import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentsState } from "@documents/models";
import { changeDocumentsFiltersValues } from "@documents/store/actions";
import { TimeRangeIDs } from "@documents/models/filter.models";
import { fetchEntitledAccountsByEntity } from "@store/Entities/thunks";
import { entitledFundsSelector } from "@store/Entities/selectors";
import { selectedAccountsSelector } from "@documents/store/selectors";
import CommonUtils from "@shared/utils/CommonUtils";
import { fetchDocuments } from "@documents/store/thunks/fetchDocuments";
import { ThunkApiConfig } from "@shared/models/redux";
import RequestUtils from "@shared/utils/RequestUtils";

export const changeDocumentsFilters = createAsyncThunk<void, Partial<DocumentsState["filters"]>, ThunkApiConfig>(
  "documents/changeFilterValue",
  async (updatedFilter, { dispatch, getState }) => {
    dispatch(changeDocumentsFiltersValues(updatedFilter));

    const exactRangeSelected = updatedFilter.timeRange?.timePeriod.id === TimeRangeIDs.EXACTRANGE;
    const noStartDateSelected = !updatedFilter.timeRange?.start && updatedFilter.timeRange?.end;
    const noEndDateSelected = updatedFilter.timeRange?.start && !updatedFilter.timeRange?.end;

    if (exactRangeSelected && (noStartDateSelected || noEndDateSelected)) {
      return;
    }

    // 1) if some funds have been selected, we need to fetch corresponding accounts
    // 2) then if some account were selected before, we need to remove selection
    // if selected account does not belong to selected fund
    if (updatedFilter.funds) {
      await Promise.all(updatedFilter.funds.map((id) => dispatch(fetchEntitledAccountsByEntity(id))));

      const rootState = getState();

      const fundsSlice = entitledFundsSelector(rootState);
      const selectedAccounts = selectedAccountsSelector(rootState);

      if (selectedAccounts) {
        const availableAccountIds = updatedFilter.funds.reduce(
          (acc, id) => acc.concat(fundsSlice.data![id]!.entitledAccountIds || []),
          [] as string[]
        );

        const availableAccountIdsMap = CommonUtils.convertArrayToMap(availableAccountIds);

        const updatedSelectedAccounts = selectedAccounts.filter((id) => availableAccountIdsMap[id]);

        dispatch(
          changeDocumentsFiltersValues({
            investorAccounts: updatedSelectedAccounts.length ? updatedSelectedAccounts : null,
          })
        );
      }
    }

    const fetchDocumentsCancelable = RequestUtils.getAbortThunk(fetchDocuments, dispatch);

    fetchDocumentsCancelable(true);
  }
);
