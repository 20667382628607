import { Nullable } from "@shared/models/general";

class CommonUtils {
  static convertArrayToMap<T extends string | number>(array: Nullable<T[]>, defaultValue = true): Record<T, boolean> {
    if (!array) return {} as Record<T, boolean>;

    return array.reduce<Record<T, boolean>>((acc, curr) => {
      acc[curr] = defaultValue;

      return acc;
    }, {} as Record<T, boolean>);
  }

  static convertMapToArray<V, U>(map: Nullable<Record<string, V>>, callback: (element: V, key: string) => U): U[] {
    if (!map) return [];

    const result: U[] = [];

    for (const key in map) {
      result.push(callback(map[key], key));
    }

    return result;
  }
}

export default CommonUtils;
