import { AppThunkAPI } from "@shared/models/redux";

type CallTrackInsightsHubArticleAccessAPI = (args: {
  articleId: string;
  articleTitle: string;
  articleAuthor: string;
}) => AppThunkAPI<Promise<unknown>>;

export const callTrackInsightsHubArticleAccessAPI: CallTrackInsightsHubArticleAccessAPI =
  ({ articleId, articleTitle, articleAuthor }) =>
  async (_dispatch, _state, extra) => {
    await extra(`documents/v1/InsightsHubTracking/${articleId}`, {
      method: "post",
      body: {
        articleTitle,
        articleAuthor,
      },
    });
  };
