import { ListOptionBase } from "@shared/components";

export interface IAGroupAllOption extends ListOptionBase {
  id: "ALL";
}

export interface IAGroupCreateOption extends ListOptionBase {
  id: "CREATE";
}

export interface IAGroupUserOption extends ListOptionBase {
  id: number;
}

export type IAGroupOption = IAGroupUserOption | IAGroupAllOption | IAGroupCreateOption;

export enum MenuOptions {
  Help = "Help",
  Administration = "Administration",
  InvestorRequest = "Investor Request",
}

export enum HeaderTabLabel {
  InsightsHub = "Global Insights",
  Funds = "Funds",
  DataRooms = "Data Rooms",
  Reporting = "Reporting",
  Documents = "Documents",
  MyCalendar = "My Calendar",
}

export type ReportingTabOptionsMap = {
  [key: string]: {
    targetPage: string;
    redirectLink: string;
  };
};

export enum HeaderNavigationTargetPage {
  AccountSummary = "Account summmary page",
  DataRooms = "Data rooms page",
  Documents = "Documents page",
  CapitalAccountStatement = "CAS report page",
  ScheduleOfInvestments = "SOI report page",
  CashFlowActivity = "CFA report page",
  AdminControl = "Admin control page",
  Help = "Help page",
}

export enum ProfileMenuOptionLabel {
  Preferences = "Preferences",
  ImpersonateUser = "Impersonate User",
  StopImpersonating = "Stop Impersonating",
  SignOut = "Sign out",
}

export enum ProfileMenuOptionId {
  Preferences,
  StartImpersonation,
  StopImpersonation,
}

export interface ProfileMenuOption extends ListOptionBase {
  id: ProfileMenuOptionId;
}

export type ProfileMenuOptions = Record<ProfileMenuOptionId, ProfileMenuOption>;

export interface MobileDrawerSubmenuSection extends ListOptionBase {
  withSearch?: boolean;
  options: ({ isActive?: boolean } & ListOptionBase)[];
}
