import React, { FunctionComponent, memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { IAGroupOption } from "@app/models/Header";
import { Singleselect } from "@shared/components";
import { AppRoute } from "@app/models/AppRoute";
import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { appHeaderBarSelector } from "@app/store/selectors";

interface Props {
  fullWidth?: boolean;
}

const IAGroupDropdown: FunctionComponent<Props> = ({ fullWidth }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { selectedIAGroup, investorAccountGroups, investorAccountGroupsLoading } = useAppSelector(appHeaderBarSelector);

  const handleIAGroupChange = useCallback(
    (group: IAGroupOption) => {
      if (group.id === "CREATE") {
        history.push(AppRoute.UserProfile);

        return;
      }

      dispatch(changeInvestorAccountsGroup(group.id === "ALL" ? null : group.id));
    },
    [dispatch, history]
  );

  return (
    <Box width={fullWidth ? "100%" : 240} bgcolor="common.white">
      <Singleselect
        variant="contained"
        displayCount={8}
        options={investorAccountGroups}
        selectedOption={selectedIAGroup}
        loading={investorAccountGroupsLoading}
        onSelect={handleIAGroupChange}
      />
    </Box>
  );
};

export default memo<Props>(IAGroupDropdown);
