import { userSegmentFunctionSelector } from "@user/store/selectors";
import { useAppSelector } from "@app/hooks";
import CommonUtils from "@shared/utils/CommonUtils";
import { Permission, ProductSegment } from "@user/models";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

export const useGetUserPermissions = (): Record<Permission, boolean> => {
  const userSegmentFunction = useAppSelector(userSegmentFunctionSelector);
  const impersonationState = useAppSelector(impersonationStateSelector);

  const segmentFunctionToUse = impersonationState.isImpersonating
    ? impersonationState.user!.segmentFunction
    : userSegmentFunction;

  if (!segmentFunctionToUse) {
    return {
      [Permission.ViewAccountSummaryCarlyle]: false,
      [Permission.ViewReportsCarlyle]: false,
      [Permission.ViewAccountSummaryAlpInvest]: false,
      [Permission.ExportAccountSummaryAlpInvest]: false,
      [Permission.ViewDiversificationAnalysisReport]: false,
      [Permission.ExportDiversificationAnalysisReport]: false,
      [Permission.ViewPortfolioPerformanceReport]: false,
      [Permission.ExportPortfolioPerformanceReport]: false,
      [Permission.ViewFunds]: false,
      [Permission.ViewDocumentsRestricted]: false,
      [Permission.ViewDataRooms]: false,
    };
  }

  const userSegmentFunctionsMap = CommonUtils.convertArrayToMap(segmentFunctionToUse);

  return {
    [Permission.ViewAccountSummaryCarlyle]: Boolean(
      userSegmentFunctionsMap[ProductSegment.CorporateAccountSummary] ||
        userSegmentFunctionsMap[ProductSegment.GlobalCreditAccountSummary] ||
        userSegmentFunctionsMap[ProductSegment.GlobalPrivateEquityAccountSummary]
    ),
    [Permission.ViewReportsCarlyle]: Boolean(
      userSegmentFunctionsMap[ProductSegment.CorporateReports] ||
        userSegmentFunctionsMap[ProductSegment.GlobalCreditReports] ||
        userSegmentFunctionsMap[ProductSegment.GlobalPrivateEquityReports]
    ),
    [Permission.ViewAccountSummaryAlpInvest]: Boolean(
      userSegmentFunctionsMap[ProductSegment.InvestmentSolutionsAccountSummary]
    ),
    [Permission.ExportAccountSummaryAlpInvest]: Boolean(
      userSegmentFunctionsMap[ProductSegment.InvestmentSolutionsAccountSummaryExport]
    ),
    [Permission.ViewPortfolioPerformanceReport]: Boolean(
      userSegmentFunctionsMap[ProductSegment.InvestmentSolutionsPortfolioPerformance]
    ),
    [Permission.ExportPortfolioPerformanceReport]: Boolean(
      userSegmentFunctionsMap[ProductSegment.InvestmentSolutionsPortfolioPerformanceExport]
    ),
    [Permission.ViewDiversificationAnalysisReport]: Boolean(
      userSegmentFunctionsMap[ProductSegment.InvestmentSolutionsDiversificationAnalysis]
    ),
    [Permission.ExportDiversificationAnalysisReport]: Boolean(
      userSegmentFunctionsMap[ProductSegment.InvestmentSolutionsDiversificationAnalysisExport]
    ),
    [Permission.ViewFunds]: Boolean(
      userSegmentFunctionsMap[ProductSegment.CorporateFunds] ||
        userSegmentFunctionsMap[ProductSegment.GlobalCreditFunds] ||
        userSegmentFunctionsMap[ProductSegment.GlobalPrivateEquityFunds]
    ),
    [Permission.ViewDataRooms]: Boolean(
      userSegmentFunctionsMap[ProductSegment.CorporateDataRooms] ||
        userSegmentFunctionsMap[ProductSegment.GlobalCreditDataRooms] ||
        userSegmentFunctionsMap[ProductSegment.GlobalPrivateEquityDataRooms] ||
        userSegmentFunctionsMap[ProductSegment.InvestmentSolutionsDataRooms]
    ),
    [Permission.ViewDocumentsRestricted]: Boolean(userSegmentFunctionsMap[ProductSegment.DemoUser]),
  };
};
