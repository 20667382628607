import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserData } from "@user/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { fetchSiteWideEntities } from "@store/Entities/thunks";
import { changeLoggedInInvestorAccountsGroup } from "@user/store/ducks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { changeImpersonatedUserInvestorAccountsGroup } from "@impersonateUser/store/ducks";

export const changeInvestorAccountsGroup = createAsyncThunk<void, UserData["selectedAccountGroup"], ThunkApiConfig>(
  "user/changeInvestorAccountsGroup",
  async (investorAccountGroupId, { dispatch, getState }) => {
    const impersonationState = impersonationStateSelector(getState());

    if (impersonationState.isImpersonating) {
      dispatch(changeImpersonatedUserInvestorAccountsGroup(investorAccountGroupId));
    } else {
      dispatch(changeLoggedInInvestorAccountsGroup(investorAccountGroupId));
    }

    await dispatch(fetchSiteWideEntities(true));
  }
);
