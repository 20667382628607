const distributions = [
  {
    id: "DISTRIBUTION_OTHER",
    label: "Dividends/Other Current Income (Loss)",
  },
  {
    id: "DISTRIBUTION_INCOME_DIVIDENDS",
    label: "Non-Investment Income",
  },
  {
    id: "DISTRIBUTION_REALIZED_GAIN_LOSS_CASH",
    label: "Realized Capital Gain (Loss)",
  },
  { id: "DISTRIBUTION_CARRY", label: "Realized Carry Paid to GP" },
  {
    id: "DISTRIBUTION_RETURN_OF_CAPITAL_CASH",
    label: "Return of Capital",
  },
];

export default distributions;
