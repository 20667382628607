import { Nullable } from "@shared/models/general";
import { MimeType } from "@shared/models/mimeType";

export type CustomFieldsMap = {
  [key in InvestorRequestType]: {
    [key: string]: string;
  };
};

export type RequiredFieldsPath = {
  [key in InvestorRequestType]: string[];
};

export enum InvestorRequestType {
  General = "information-request-general-support",
  UpdateWireInstructions = "update-wire-instructions",
  NoData = "no-data-request",
}

export interface CommonFields {
  selectedFunds: string[];
  selectedAccounts: string[];
  message: string;
  attachment: Nullable<{
    type: MimeType;
    name: string;
    base64: string;
  }>;
}

export type CustomFields = { [key: string]: any };

export interface InvestorRequestState {
  open: boolean;
  requestType: Nullable<InvestorRequestType>;
  commonFields: CommonFields;
  customFields: CustomFields;
  retainedCustomFields: CustomFieldsMap;
  loading: boolean;
  validationWarningShown: boolean;
}

export interface InvestorRequestBody {
  requestType: InvestorRequestType;
  entitledEntities: string[];
  entitledAccounts: string[];
  request: string;
  customFields: CustomFields;
  attachmentName?: string;
  attachment?: string; // base64 string
}
