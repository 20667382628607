import { useCallback, useEffect, useState } from "react";
import {
  APP_NAVIGATION_HEIGHT,
  HEADER_BAR_HEIGHT,
  IMPERSONATION_BAR_HEIGHT,
  IMPERSONATION_BAR_HEIGHT_MOBILE,
  MOBILE_HEADER_HEIGHT,
  NOTIFICATION_BAR_HEIGHT,
} from "@app/constants/header";
import { useMobileLayout } from "@app/hooks/index";

type UseGetNotificationSnackbarTopOffset = (arg: { isNotificationOpen: boolean; isImpersonating: boolean }) => number;

const useGetNotificationSnackbarTopOffset: UseGetNotificationSnackbarTopOffset = ({
  isNotificationOpen,
  isImpersonating,
}) => {
  const isMobile = useMobileLayout();
  const [offset, setOffset] = useState(0);

  const calculateOffset = useCallback(() => {
    if (isMobile) {
      if (isImpersonating) {
        const newOffset =
          window.scrollY >= MOBILE_HEADER_HEIGHT - IMPERSONATION_BAR_HEIGHT_MOBILE
            ? MOBILE_HEADER_HEIGHT + IMPERSONATION_BAR_HEIGHT_MOBILE
            : IMPERSONATION_BAR_HEIGHT_MOBILE;

        setOffset(newOffset);

        return;
      } else {
        setOffset(window.scrollY >= MOBILE_HEADER_HEIGHT - NOTIFICATION_BAR_HEIGHT ? MOBILE_HEADER_HEIGHT : 0);

        return;
      }
    } else {
      if (isImpersonating) {
        const newOffset =
          window.scrollY >= HEADER_BAR_HEIGHT - IMPERSONATION_BAR_HEIGHT
            ? APP_NAVIGATION_HEIGHT + IMPERSONATION_BAR_HEIGHT
            : IMPERSONATION_BAR_HEIGHT;

        setOffset(newOffset);

        return;
      }

      if (window.scrollY >= HEADER_BAR_HEIGHT - NOTIFICATION_BAR_HEIGHT) {
        setOffset(APP_NAVIGATION_HEIGHT);

        return;
      }
    }

    setOffset(0);
  }, [setOffset, isImpersonating, isMobile]);

  useEffect(() => {
    calculateOffset();

    if (isNotificationOpen) {
      window.addEventListener("scroll", calculateOffset);
    } else {
      window.removeEventListener("scroll", calculateOffset);
    }
  }, [calculateOffset, isNotificationOpen]);

  return offset;
};

export default useGetNotificationSnackbarTopOffset;
