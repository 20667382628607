import { Column } from "react-table";
import { CSSProperties } from "@mui/styles/withStyles/withStyles";
import { Nullable, SortDirection } from "@shared/models/general";
import { AsyncSlice, PaginatedAsyncSlice } from "@shared/models/redux";
import { BaseRow, ColumnMeta, ListOptionBase } from "@shared/components";
import { statusFilterOptions, timeRangeOptions } from "@documents/constants";
import { DocumentsState } from "@documents/models";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { SortKeys } from "@documents/models/sort.models";
import { Fund } from "@store/Entities/models";
import { Currency } from "@shared/models/currency";

export interface FundHead {
  id: string;
  lastName: string;
  firstName: string;
  hyperlink: Nullable<string>;
  imageUrl: string;
}

export interface FundHeads {
  [key: string]: FundHead[];
}

export type FundDates = Array<string>;

export type FundPeriods = Array<string>;

export interface FundsState {
  fundHeads: AsyncSlice<FundHeads>;
  fundDates: AsyncSlice<FundDates>;
  fundFPSIS: AsyncSlice<FundFPSIS[]>;
  fundFSISAppreciation: AsyncSlice<FundFPSISAppreciation>;
  fsisDates: AsyncSlice<FSISDate[]>;
  fsisReports: AsyncSlice<Record<string, Record<string, FSISReport[]>>, Record<string, Record<string, string | null>>>;
  fsisFlocReports: AsyncSlice<
    Record<string, Record<string, FsisFlocReport[]>>,
    Record<string, Record<string, string | null>>
  >;
  historicMoic: AsyncSlice<
    Record<string, Record<string, HistoricMoic | null>>,
    Record<string, Record<string, string | null>>
  >;
  summaryInvestmentScheduleFilters: SummaryInvestmentScheduleFilters;
  fundDocumentsFilters: FundDocumentsFilters;
  fundDocumentsFilterOptions: FundDocumentFilterOptions;
  fundDocuments: PaginatedAsyncSlice<DocumentRow[]>;
  isExportFsisReportLoading: boolean;
}

type FundDocumentsFilters = Omit<DocumentsState["filters"], "funds"> & {
  sortKey: SortKeys;
  sortDirection: SortDirection;
};

export interface FundDocumentFilterOptions {
  statuses: typeof statusFilterOptions;
  timeOptions: typeof timeRangeOptions;
  investorAccounts: AsyncSlice<ListOptionBase[]>;
}

export interface FundFPSIS {
  acquisitionDate: string;
  cashReceived: number;
  company: string;
  companyName: string;
  companyType: string;
  currentState: string;
  endPreviousYearMOIC: number;
  equityInvested: number;
  exitDate: string;
  fund: string;
  fundAbbrev: string;
  fundCurrency: string;
  fundName: string;
  geography: string;
  industry: string;
  investStatusDesc: string;
  moic: number;
  netRemainingFmv: number;
  notes: string | null;
  previousQuarterMOIC: number;
  remainingFmv: number;
  reportingDate: string;
  sameQuarterPreviousYearMOIC: number;
  totalValue: number;
}

export type FSISDate = string;
export type FSISReport = FundFPSIS;

export interface FundFPSISAppreciation {
  appreciationPercentageFromInception: number;
  appreciationPercentageFromPriorQuarter: number;
  appreciationPercentageFromPriorYear: number;
  appreciationPercentageFromYearEnd: number;
  fund: string;
  reportingDate: string;
}

export interface HistoricMoic {
  currentMOIC: number;
  currentReportingDate: string;
  endPreviousYearMOIC: number;
  endPreviousYearReportingDate: string;
  fund: string;
  previousQuarterMOIC: number;
  previousQuarterReportingDate: string;
  sameQuarterPreviousYearMOIC: number;
  sameQuarterPreviousYearReportingDate: string;
}

export enum FsisFlocReportAdjustmentTypeId {
  First = "1",
  Second = "2",
  Third = "3",
  Forth = "4",
}

export interface FsisFlocReport {
  fund: string;
  reportingDate: string;
  adjustmentTypeID: string;
  adjustmentTypeValue: string;
  equityInvested: number;
  cashReceived: number;
  remainingFmv: number;
  totalValue: number;
  notes: string | null;
}

export interface SummaryInvestmentScheduleFilters {
  asOfDate: Nullable<{
    year: number;
    quarter: number;
  }>;
}

type FundGeneralInfoItemValue = {
  text: string;
  tooltip?: string;
};

export type FundGeneralInfoItem = {
  label: string;
  value: FundGeneralInfoItemValue[];
  subLabel?: string;
};

export interface FundGeneralInfoStyleProp {
  fundGeneralInfo: CSSProperties;
  label: CSSProperties;
  value: CSSProperties;
  text: CSSProperties;
}

export interface FundGeneralInfoProps {
  fundGeneralInfoItems: Nullable<FundGeneralInfoItem[]>;
  styles?: FundGeneralInfoStyleProp;
}

export interface CarriedInterestFundProps {
  fund: Fund;
}

export interface CoinvestmentFundProps {
  fund: Fund;
}

export interface InternalCoinvestmentFundProps {
  fund: Fund;
}

export interface ExternalFundProps {
  fund: Fund;
  entityId: string;
}

export interface InvestmentScheduleProps {
  fund: Fund;
}

export interface FundDocumentProps {
  fund: Fund;
}

export interface InvestmentScheduleSelectorOptions {
  fundId: string;
  currency: Currency;
}

export interface InvestmentScheduleDataGridProps {
  data: ReadonlyArray<FSISReport & BaseRow>;
  asOfDate: {
    year: number;
    quarter: number;
  };
}

export type InvestmentScheduleDataGridColumn = ColumnMeta<FSISReport & BaseRow> & Column<FSISReport & BaseRow>;

export enum FundId {
  Payconnexion = "F000122523",
}

export enum FundType {
  ExternalCoInvest = "External Co-Invest",
  ManagedAccount = "Managed Account",
  Internal = "Internal",
  Fund = "Fund",
}

export enum FundPanelType {
  SummaryInvestmentSchedule = "Summary Investment Schedule",
  KeyDates = "Key Dates",
  WaterfallTerms = "Waterfall Terms",
  FundDocuments = "Fund Documents",
}

export interface KeyDatesDataRow extends BaseRow {
  fieldDescriptor: string;
  original: string;
  current: string;
}

export enum KeyDatesRows {
  InvestmentPeriod = "Investment Period",
  FollowOnPeriod = "Follow on Period",
  TermExpiration = "Term Expiration",
}

export interface WaterfallTermsDataRow extends BaseRow {
  fieldDescriptor: string;
  fieldData: string;
}

export enum WaterfallTermsRows {
  InterestCatchUpRate = "Interest Catch-Up Rate GP/LP",
  PreferredReturn = "Preferred Return",
  CarriedInterestRate = "Carried Interest Rate",
}

export enum InvestmentStatusDescriptor {
  Realized = "Realized Investments",
  UnrealizedPartiallyRealized = "Unrealized/Partially Realized Investments",
  PubliclyTraded = "Publicly Traded Investments",
  Adjustments = "Adjustments",
}

export type FsisReportTotal = Record<string, string | number | null>;

export interface FsisReportsByInvestmentStatus {
  realizedReports: FSISReport[];
  realizedTotal: FsisReportTotal | null;
  unrealizedReports: FSISReport[];
  unrealizedTotal: FsisReportTotal | null;
  publicTradeReports: FSISReport[];
  publicTradeTotal: FsisReportTotal | null;
  adjustmentReports: FSISReport[];
  adjustmentTotal: FsisReportTotal | null;
  flocFirstThirdReports: FsisFlocReport[];
  flocSecondFourthReports: FsisFlocReport[];
  totalInvestment: FsisReportTotal | null;
  totalFunds: FsisReportTotal | null;
}

export interface MoicMeta {
  hasPreviousQuarterMoic: boolean;
  hasPreviousYearMoic: boolean;
  previousQuarterDate: string | null;
  previousYearLastQuarterDate: string | null;
  previousYearSameQuarterDate: string | null;
}

export interface fetchFSISArgs {
  fundId: string;
  date: string;
}

export type FundDocumentsRow = Omit<DocumentRow, "fundDisplayName">;

export type FundDocumentColumn = ColumnMeta<FundDocumentsRow> & Column<FundDocumentsRow>;

export interface FundDocumentsDataGridColumnsProps {
  handleToggleActionItemFlag: (row: FundDocumentsRow) => void;
  handleDownloadDocumentClick: (row: FundDocumentsRow) => void;
  handleViewDocument: (row: FundDocumentsRow) => void;
}
