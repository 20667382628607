import { AppThunkAPI } from "@shared/models/redux";
import { ProductSegment } from "@user/models";

type CallGetUserSegmentFunction = (entitledUserId?: number) => AppThunkAPI<Promise<Array<ProductSegment>>>;

export const callGetUserSegmentFunction: CallGetUserSegmentFunction =
  (entitledUserId) => async (_dispatch, _state, extra) => {
    const response = await extra<Array<ProductSegment>>(
      "/entitlements/v1/user/segmentFunction",
      entitledUserId ? { queryParams: { entitledUserId } } : undefined,
      { includeUserId: !entitledUserId }
    );

    return response.data;
  };
