import React, { memo, useCallback, useState } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import { ceil } from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { Nullable } from "@shared/models/general";
import { DonutProps, DonutValue, LegendPayload } from "@shared/components/Donut/models";
import CustomTooltip from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import {
  COLORS,
  donutHeight,
  donutHeightMobile,
  donutInnerRadius,
  donutInnerRadiusMobile,
  donutLeftAlignOffset,
  donutOuterRadius,
  donutOuterRadiusMobile,
  donutWidth,
  legendLineHeight,
  legendLinesNumberBeforeAppearsScroll,
  legendLineWidth,
  legendLineWidthMobile,
  scrollableLegendColumnHeight,
} from "./constants";

const useStyles = makeStyles(() => ({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cellWithOpacity: {
    opacity: 0.5,
  },
  cellWithNoOpacity: {
    opacity: 1,
  },
}));

const Donut = ({ data, isLegendColumnView, leftAlign, numberFormat }: DonutProps) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState<Nullable<number>>(null);
  const external = { activeIndex, setActiveIndex }; // name 'external' is predefined by manual

  const legendColumnHeight =
    data.length <= legendLinesNumberBeforeAppearsScroll
      ? data.length * legendLineHeight + 30
      : scrollableLegendColumnHeight;
  const theme = useTheme();

  const shouldRenderSmallSize = useMediaQuery(theme.breakpoints.down("md"));

  const chartHeight = shouldRenderSmallSize ? donutHeightMobile : donutHeight;
  const pieChartHeight = isLegendColumnView
    ? legendColumnHeight + chartHeight
    : ceil(data.length / 2) * legendLineHeight + donutHeight;

  const legendWidth = isLegendColumnView
    ? shouldRenderSmallSize
      ? legendLineWidthMobile
      : legendLineWidth
    : donutWidth;

  const handleMouseEnter = useCallback((payload, index) => {
    setActiveIndex(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveIndex(-1);
  }, []);

  if (data?.length === 0) {
    return (
      <div className={classes.noData} data-testid="no-data-donut">
        <span>No Data</span>
      </div>
    );
  }

  return (
    <PieChart width={legendWidth} height={pieChartHeight} data-testid="donut-container">
      <Pie
        data={data}
        innerRadius={shouldRenderSmallSize ? donutInnerRadiusMobile : donutInnerRadius}
        outerRadius={shouldRenderSmallSize ? donutOuterRadiusMobile : donutOuterRadius}
        startAngle={380}
        endAngle={-20}
        cx={leftAlign ? donutLeftAlignOffset : "50%"}
        dataKey="value"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        paddingAngle={1} // gap between cells
      >
        {data.map((entry: DonutValue, index: number) => {
          const isActiveCell = cn({
            [classes.cellWithOpacity]: activeIndex === index,
            [classes.cellWithNoOpacity]: activeIndex !== index,
          });

          return <Cell className={isActiveCell} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
        })}
      </Pie>

      <Tooltip content={<CustomTooltip numberFormat={numberFormat} />} />

      <Legend
        wrapperStyle={
          isLegendColumnView
            ? {
                bottom: shouldRenderSmallSize ? -24 : -4,
                height: `${legendColumnHeight}px`,
                overflowY: "auto",
                overflowX: "hidden",
                padding: shouldRenderSmallSize ? 0 : "initial",
                paddingInlineStart: "0 !important",
              }
            : { bottom: -25 }
        }
        content={(props) => (
          <CustomLegend
            payload={props.payload as LegendPayload[] | undefined}
            external={external}
            numberFormat={numberFormat}
            legendWidth={legendWidth}
          />
        )}
      />
    </PieChart>
  );
};

export default memo(Donut);
