import { createSelector } from "@reduxjs/toolkit";
import { isEmpty, memoize, orderBy } from "lodash";
import { RootState } from "@store/index";
import { QuarterLabel, QuarterValue } from "@shared/models/date";
import { MultiselectOption, SelectedOption, Year, RowType } from "@shared/components";
import { Investors } from "@store/Entities/models";
import { AsyncSlice } from "@shared/models/redux";
import {
  ReportCompanies,
  ReportCompany,
  ScheduleOfInvestments,
  ScheduleOfInvestmentsFilterValues,
  SoiDataGridFundInvestmentsValues,
  SoiDataGridRow,
  SOIFundOption,
} from "@scheduleOfInvestments/models";
import {
  getAggregatedSummationField,
  getCompanySummationField,
  getIsDateBeforeSpecifiedDate,
  getOnlyFairValueRowsConfig,
  getReportCompanies,
} from "@scheduleOfInvestments/utils";
import DateUtils from "@shared/utils/DateUtils";
import { Currency } from "@shared/models/currency";
import { preferencesSelector } from "@app/store/selectors";

export const reportSelector = (state: RootState): ScheduleOfInvestments["report"] => state.scheduleOfInvestments.report;

export const filterOptionsSelector = (state: RootState): ScheduleOfInvestments["filterOptions"] =>
  state.scheduleOfInvestments.filterOptions;

export const filterValuesSelector = (state: RootState): ScheduleOfInvestments["filters"] =>
  state.scheduleOfInvestments.filters;

export const soiReportExportLoadingSelector = (state: RootState): ScheduleOfInvestments["isReportDownloading"] =>
  state.scheduleOfInvestments.isReportDownloading;

const selectedFundSelector = createSelector(
  filterValuesSelector,
  (filterValues): { id: string; label: string } | undefined => {
    if (filterValues.funds === null) {
      return;
    }

    return {
      id: filterValues.funds.value,
      label: filterValues.funds.label,
    };
  }
);

export const fundsSelector = createSelector(
  filterOptionsSelector,
  filterValuesSelector,
  (filterOptions, filters): SOIFundOption[] | undefined => {
    const { year, quarter } = filters?.asOfDate ?? {};

    if (!year || !quarter) {
      return;
    }

    const fundListOptions: SOIFundOption[] | undefined = filterOptions?.data
      ?.find((option) => {
        return option.year === year && option.quarter === quarter;
      })
      ?.associatedFunds.map((fund) => {
        return {
          id: fund.value,
          label: fund.label,
          key: `${fund.value}-${fund.currency}`,
        };
      });

    return fundListOptions ? orderBy(fundListOptions, [({ label }) => label.toLowerCase()]) : undefined;
  }
);

const asOfDateSelector = createSelector(filterOptionsSelector, (filterOptions): Year[] | undefined => {
  if (filterOptions?.data === null) {
    return;
  }

  const years = Array.from(new Set(filterOptions.data.map(({ year }) => year)));

  return years
    .sort()
    .reverse()
    .map((year, index) => {
      const periods =
        filterOptions?.data?.filter((period) => {
          return period.year === year && period.associatedFunds !== null;
        }) ?? [];

      const quarters = periods
        .map(({ quarter }) => {
          const id = Number(quarter);
          const label = QuarterLabel[QuarterValue[id] as keyof typeof QuarterLabel];

          return { id, label };
        })
        .reverse();

      return {
        id: index + 1,
        label: year,
        quarters,
      };
    });
});

const selectedAsOfDateSelector = createSelector(
  asOfDateSelector,
  filterValuesSelector,
  (dateRange, filters): SelectedOption | undefined => {
    if (!dateRange) {
      return;
    }

    const option = dateRange.find(({ label }) => label === filters?.asOfDate?.year);

    if (!option) {
      return;
    }

    const year = Number(option.id);
    const quarter = Number(filters?.asOfDate?.quarter);

    if (Number.isNaN(year) || Number.isNaN(quarter)) {
      return;
    }

    return { year, quarter };
  }
);

const buildInvestorAccountOptions = (
  investors: AsyncSlice<Investors>,
  filters: ScheduleOfInvestmentsFilterValues
): MultiselectOption[] => {
  if (investors.data === null) {
    return [];
  }

  const options = Object.values(investors.data).reduce((result: MultiselectOption[], investor) => {
    if (filters?.accounts?.[investor.investorId] === undefined) {
      return result;
    }

    return [
      ...result,
      {
        id: investor.investorId,
        label: investor.investorName,
        selected: filters.accounts[investor.investorId] || false,
      },
    ];
  }, []);

  return orderBy(options, [({ label }) => label.toLowerCase()]);
};

export const investorAccountsOptionsSelector = createSelector(filterValuesSelector, (filters) => {
  return memoize((investors: AsyncSlice<Investors>): MultiselectOption[] =>
    buildInvestorAccountOptions(investors, filters)
  );
});

export const reportingCriteriaAsOfDateSelector = createSelector(
  asOfDateSelector,
  filterValuesSelector,
  (dateRange, filters): Date | undefined => {
    if (!dateRange) {
      return;
    }

    const option = dateRange.find(({ label }) => label === filters?.asOfDate?.year);

    if (!option) {
      return;
    }

    const year = Number(option.label);
    const quarter = Number(filters?.asOfDate?.quarter);

    if (Number.isNaN(year) || Number.isNaN(quarter)) {
      return;
    }

    const quarterLabel = QuarterLabel[QuarterValue[quarter] as keyof typeof QuarterLabel];

    return new Date(`${year} ${quarterLabel}`);
  }
);

export const reportingCriteriaSelectedAccounts = createSelector(filterValuesSelector, (filters) => {
  return memoize((investors: AsyncSlice<Investors>): string[] => {
    if (filters.accounts === null) {
      return [];
    }

    const investorAccountOptions = buildInvestorAccountOptions(investors, filters);

    const selectedAccountIds = Object.keys(filters.accounts).filter((accountId) => filters.accounts?.[accountId]);

    const hasSelectedAccounts = selectedAccountIds.length && investors.data;
    const investorNames = hasSelectedAccounts
      ? selectedAccountIds.map((id) => investors.data?.[id]?.investorName ?? "")
      : investorAccountOptions.map((account) => account.label);

    return orderBy(investorNames);
  });
});

export const soiDataLoadingSelector = createSelector(reportSelector, filterOptionsSelector, (report, filterOptions) => {
  return memoize(
    (investors: AsyncSlice<Investors>): boolean => report.loading || filterOptions.loading || investors.loading
  );
});

export const filterSelector = createSelector(
  filterOptionsSelector,
  filterValuesSelector,
  fundsSelector,
  selectedFundSelector,
  asOfDateSelector,
  selectedAsOfDateSelector,
  reportingCriteriaAsOfDateSelector,
  investorAccountsOptionsSelector,
  reportingCriteriaSelectedAccounts,
  soiDataLoadingSelector,
  (
    options,
    values,
    funds,
    selectedFund,
    asOfDates,
    selectedAsOfDate,
    reportingCriteriaAsOfDate,
    selectAccounts,
    selectSelectedReportingCriteriaAccounts,
    selectLoadingFlag
  ) => ({
    funds,
    selectedFund,
    asOfDates,
    selectedAsOfDate,
    options,
    rawValues: values,
    reportingCriteriaAsOfDate,
    selectAccounts,
    selectSelectedReportingCriteriaAccounts,
    selectLoadingFlag,
  })
);

export const scheduleOfInvestmentsDataGridSelector = createSelector(
  (state: RootState) => reportSelector(state),
  (state: RootState) => filterValuesSelector(state),
  (state: RootState) => preferencesSelector(state),
  (report, filters, { DateFormat }) => {
    return memoize((investors: AsyncSlice<Investors>): SoiDataGridRow[] => {
      const companies: ReportCompanies = getReportCompanies(report?.data?.soiDetailed);

      const { asOfDate } = filters;
      const isAsOfDateBeforeQ12017 = asOfDate && getIsDateBeforeSpecifiedDate(asOfDate, "2017", "1");

      const reportingCurrency = report?.data?.soiDetailed[0] ? report?.data?.soiDetailed[0].reportingCurrency : "USD";

      const investorAccounts = buildInvestorAccountOptions(investors, filters);
      const selectedInvestors = investorAccounts.every((investor) => !investor.selected)
        ? investorAccounts.map((investor) => investor.id)
        : investorAccounts.filter((investor) => investor.selected).map((investor) => investor.id);

      const companyRows = companies.map((company: [string, ReportCompany]): SoiDataGridRow => {
        const [key, value] = company;

        const getDetailedCompanySummationValue = (fieldName: string): number =>
          getCompanySummationField(report?.data?.soiDetailed, fieldName, key, selectedInvestors);

        return {
          rowMeta: {
            currency: value.currency as Currency,
          },
          fundInvestments: value.companyName,
          initialInvestmentDate: DateUtils.formatDate(new Date(value.initialInvestmentDate), DateFormat),
          capitalContributed: getDetailedCompanySummationValue("capitalContributed"),
          uncalledInvestmentsAtCost: getDetailedCompanySummationValue("uncalledInvestmentsAtCost"),
          investmentIncome: getDetailedCompanySummationValue("investmentIncome"),
          investmentDistributionsGrossOfTaxWithholding: getDetailedCompanySummationValue(
            "investmentDistributionsGrossOfTaxWithholding"
          ),
          remainingInvestedCapitalAndUndistributedProceedsAtCost: getDetailedCompanySummationValue(
            "remainingIvestedCapitalAndUndistributedProceeds"
          ),
          gaapUnrealizedAppreciationPreAccruedCarry: getDetailedCompanySummationValue(
            "gaapUnrealizedAppreciationPreAccruedCarry"
          ),
          remainingInvestedCapitalAndUndistributedProceedsAtFairValue: getDetailedCompanySummationValue(
            "remainingInvestedCapitalAndUndistributedProceedsAtFairValue"
          ),
        };
      });

      const getAggregatedSummationValue = (fieldName: string) =>
        getAggregatedSummationField(report?.data?.soiAggregated, fieldName, selectedInvestors);

      const onlyFairValueRows = getOnlyFairValueRowsConfig(isAsOfDateBeforeQ12017).map((row) => {
        return {
          rowMeta: {
            currency: reportingCurrency as Currency,
            type: row.type ?? RowType.Regular,
          },
          fundInvestments: row.fundInvestments,
          remainingInvestedCapitalAndUndistributedProceedsAtFairValue: getAggregatedSummationValue(row.accessor),
        };
      });

      return [
        ...companyRows,
        {
          rowMeta: {
            type: RowType.SubHeader,
            currency: reportingCurrency as Currency,
          },
          fundInvestments: SoiDataGridFundInvestmentsValues.TotalFundInvestment,
          capitalContributed: getAggregatedSummationValue("capitalContributed"),
          uncalledInvestmentsAtCost: getAggregatedSummationValue("uncalledInvestmentsAtCost"),
          investmentIncome: getAggregatedSummationValue("investmentIncome"),
          investmentDistributionsGrossOfTaxWithholding: getAggregatedSummationValue(
            "investmentDistributionsGrossOfTaxWithholding"
          ),
          remainingInvestedCapitalAndUndistributedProceedsAtCost: getAggregatedSummationValue(
            "remainingInvestedCapitalAndUndistributedProceedsAtCost"
          ),
          gaapUnrealizedAppreciationPreAccruedCarry: getAggregatedSummationValue(
            "gaapUnrealizedAppreciationPreAccruedCarry"
          ),
          remainingInvestedCapitalAndUndistributedProceedsAtFairValue: getAggregatedSummationValue(
            "remainingInvestedCapitalAndUndistributedProceedsAtFairValue"
          ),
        },
        {
          rowMeta: {
            currency: reportingCurrency as Currency,
          },
          fundInvestments: SoiDataGridFundInvestmentsValues.LessRealizedCarry,
          investmentIncome: getAggregatedSummationValue("lessCarryInvestmentIncome"),
          investmentDistributionsGrossOfTaxWithholding: getAggregatedSummationValue("lessCarryInvestmentDistributions"),
        },
        {
          rowMeta: {
            type: RowType.SubHeader,
            currency: reportingCurrency as Currency,
          },
          fundInvestments: SoiDataGridFundInvestmentsValues.NetOfCarry,
          investmentIncome: getAggregatedSummationValue("netOfCarryInvestmentIncome"),
          investmentDistributionsGrossOfTaxWithholding: getAggregatedSummationValue(
            "netOfCarryInvestmentDistributions"
          ),
        },
        ...onlyFairValueRows,
      ];
    });
  }
);

export const isSOIReportDownloadableSelector = createSelector(
  reportSelector,
  filterOptionsSelector,
  (report, filterOptions) => {
    const loading = report.loading || filterOptions.loading;
    const error = report.error || filterOptions.error;
    const hasData = !isEmpty(report.data);

    return !loading && !error && hasData;
  }
);
