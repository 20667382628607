export enum AccountSummaryReportName {
  Single = "Account Summary",
  Carlyle = "Carlyle Account Summary",
  AlpInvest = "AlpInvest Account Summary",
}

export enum ReportName {
  CapitalAccountStatement = "Capital Account Statement",
  ScheduleOfInvestments = "Schedule of Investments",
  CashFlowActivity = "Cash Flow Activity",
  PortfolioPerformance = "Portfolio Performance",
  DiversificationAnalysis = "Diversification Analysis",
}
