import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { callGetDocumentDownloadUrl } from "@documents/store/thunks/callGetDocmentDownloadUrlApi";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ViewDocumentArgs } from "@documents/models/api.models";
import { checkDocumentTerms } from "@terms/store/thunks/checkDocumentTerms";

export const viewDocument = createAsyncThunk<void, ViewDocumentArgs, ThunkApiConfig>(
  "documents/viewDocument",
  async ({ id, markedRead, documentSubtype, fundId, onViewUnreadDocument }, { dispatch, rejectWithValue }) => {
    try {
      const hasAcceptedTerms = await dispatch(
        checkDocumentTerms({
          documentSubTypes: [String(documentSubtype)],
          fundIds: [fundId],
        })
      );

      if (!hasAcceptedTerms) {
        return;
      }
      const downloadUrl = await dispatch(callGetDocumentDownloadUrl(id!));

      if (!markedRead) {
        dispatch(onViewUnreadDocument([id!]));
      }

      window.open(downloadUrl, "_blank")?.focus();
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Something went wrong. Please try again",
        })
      );

      return rejectWithValue("Failed to view the document");
    }
  }
);
