export const COLORS = [
  "#00293A",
  "#6C849C",
  "#61A1E0",
  "#B6E7FA",
  "#D0E8F8",
  "#1D6571",
  "#5C7D8A",
  "#99C1C1",
  "#D4E0E2",
  "#AB8433",
  "#EDD99D",
  "#E4DFDA",
  "#EBEBEB",
];

export const donutWidth = 640;
export const donutHeight = 370;
export const donutHeightMobile = 258;
export const donutInnerRadius = 102;
export const donutInnerRadiusMobile = 85;
export const donutOuterRadius = 160;
export const donutOuterRadiusMobile = 129;
export const legendLineHeight = 20;
export const legendLineWidth = 400;
export const legendLineWidthMobile = 265;
export const legendLinesNumberBeforeAppearsScroll = 8;
export const scrollableLegendColumnHeight = 176;
const defaultLegendPadding = 40;

export const donutLeftAlignOffset = donutOuterRadius + defaultLegendPadding;
