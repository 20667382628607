import { createAsyncThunk } from "@reduxjs/toolkit";
import { omit } from "lodash";
import { FetchDocumentResponse } from "@documents/models/api.models";
import { buildDocumentsRequestBody, getEntitiesListForDocumentsRequest } from "@documents/utils";
import { callDocumentsAPI } from "@documents/store/thunks/callDocumentsAPI";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ThunkApiConfig } from "@shared/models/redux";
import { LOAD_DOCUMENTS_ERROR_MESSAGE } from "@documents/constants";
import { selectedIAGroupIdSelector } from "@app/store/selectors";

export const fetchMoreDocuments = createAsyncThunk<FetchDocumentResponse, number, ThunkApiConfig>(
  "documents/fetchMoreDocuments",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const rootState = getState();

    const {
      entities: { entitledAccounts, entitledFunds },
      impersonation: { canImpersonate: isAdmin },
      documents: {
        report: { pagination },
        filters,
        sort,
      },
    } = rootState;

    const selectedAccountGroup = selectedIAGroupIdSelector(rootState);

    const { investorAccountIds, fundIds } = getEntitiesListForDocumentsRequest({
      entitledAccounts,
      entitledFunds,
      isAnyIAGSelected: Boolean(selectedAccountGroup),
      selectedAccounts: filters.investorAccounts,
      selectedFunds: filters.funds,
    });

    const body = buildDocumentsRequestBody({
      isAdmin,
      pagination,
      sort,
      filters: {
        ...omit(filters, ["funds", "investorAccounts"]),
        investorAccountIds,
        fundIds,
      },
    });

    try {
      const response = await dispatch(callDocumentsAPI(body));

      return response;
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: LOAD_DOCUMENTS_ERROR_MESSAGE,
        })
      );

      return rejectWithValue("Failed to fetch Documents");
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        documents: {
          report: { pagination },
        },
      } = getState();

      return pagination.total === pagination.count;
    },
  }
);
