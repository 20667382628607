import React, { Component, ErrorInfo } from "react";
import { Box, Typography } from "@mui/material";
import { Container } from "@shared/components";

type Props = {};

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Box mt={20} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5">Something went wrong.</Typography>
            <Typography>Please try to refresh the page.</Typography>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
