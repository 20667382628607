import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { fetchAccountGroups, fetchAllFunds, fetchEntitledAccounts, fetchEntitledFunds, fetchInvestors } from "./";

export const fetchSiteWideEntities = createAsyncThunk<void, boolean, ThunkApiConfig>(
  "entities/fetchSiteWideEntities",
  async (refresh, { dispatch }) => {
    await Promise.all([
      dispatch(fetchEntitledAccounts(refresh)),
      dispatch(fetchEntitledFunds(refresh)),
      dispatch(fetchInvestors(refresh)),
      dispatch(fetchAllFunds(refresh)),
      dispatch(fetchAccountGroups()),
    ]);
  }
);
