import { createSlice } from "@reduxjs/toolkit";
import { InsightsHubArticle, InsightsHubState } from "@insightsHub/models";
import { fetchInsightsHubArticles } from "@insightsHub/store/thunks";
import ReduxUtils from "@shared/utils/ReduxUtils";

const initialState: InsightsHubState = {
  data: null,
  loading: false,
  error: null,
};

const insightsHubSlice = createSlice({
  name: "insightsHub",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsightsHubArticles.pending, (state) => ReduxUtils.defaultPendingActionHandler(state))
      .addCase(fetchInsightsHubArticles.fulfilled, (state, action) =>
        ReduxUtils.defaultFulfilledActionHandler(
          state,
          action.payload.data.map(
            (articleDto): InsightsHubArticle => ({
              id: articleDto.id,
              authorName: articleDto.attributes.Author_Name ?? "N/A",
              authorTitle: articleDto.attributes.Author_Title,
              title: articleDto.attributes.title ?? "N/A",
              content: articleDto.attributes.content ?? "N/A",
              shortDescription: articleDto.attributes.ShortDescription,
              publishDate: articleDto.attributes.publishedAt,
              publised_date: articleDto.attributes.publised_date,
              contentImage: articleDto.attributes.content_image.data.attributes.url,
              homepageImage: articleDto.attributes.homepage_image.data.attributes.url,
            })
          )
        )
      )
      .addCase(fetchInsightsHubArticles.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state, action.payload as string)
      );
  },
});

export const { resetState } = insightsHubSlice.actions;
export default insightsHubSlice.reducer;
