import { createSelector } from "@reduxjs/toolkit";
import { differenceInHours, differenceInMinutes, format } from "date-fns";
import { orderBy, sortBy, uniqBy } from "lodash";
import { DateArray, EventAttributes } from "ics";
import { preferencesSelector } from "@app/store/selectors";
import DateUtils from "@shared/utils/DateUtils";
import { RowType } from "@shared/components";
import { Nullable, SortDirection } from "@shared/models/general";
import { RootState } from "@store/index";
import { EventDocumentRow, MonthOption, MyCalendarState, RegistrationStatus, StatusOption } from "@myCalendar/models";
import { entitledFundsSelector } from "@store/Entities/selectors";
import { HomePageVideoDetails } from "@home/models";
import { DocumentStatus } from "@documents/models/filter.models";
import { FundraisingStatus } from "@store/Entities/models";

export const selectEvents = (state: RootState): MyCalendarState["events"] => state.myCalendar.events;

export const selectFilters = (state: RootState): MyCalendarState["filters"] => state.myCalendar.filters;

export const selectSort = (state: RootState): MyCalendarState["sort"] => state.myCalendar.sort;

export const selectEventPageDetails = (state: RootState): MyCalendarState["eventPageDetails"] =>
  state.myCalendar.eventPageDetails;

export const selectEventDocumentSort = createSelector(selectEventPageDetails, (details) => details.sort);

export const calendarEventsFromEntitledFundsSelector = createSelector(
  entitledFundsSelector,
  ({
    data: entitledFunds,
  }): {
    id: number;
    globalId: string;
    title: string;
    subTitle: Nullable<string>;
    productSegment: string;
    isPastEvent: boolean;
    eventLocation: string;
    eventStartDate: string;
  }[] => {
    if (entitledFunds === null) {
      return [];
    }

    const events = Object.values(entitledFunds)
      .filter(
        (entitledFund) =>
          entitledFund.fundraisingStatus === FundraisingStatus.Fundraising &&
          entitledFund.productFamily === "Investor Conferences"
      )
      .filter((event) => !!event.eventActivationDate);

    return events.map(
      ({ id, globalId, entityDisplayName, entityName, productFamily, productSegment, eventActivationDate }) => ({
        id,
        globalId,
        title: entityDisplayName ?? entityName,
        subTitle: productFamily,
        productSegment,
        isPastEvent: true,
        eventLocation: "On Demand",
        eventStartDate: eventActivationDate!,
      })
    );
  }
);

export const selectedEventSelector = createSelector(
  [calendarEventsFromEntitledFundsSelector, (_, fundGlobalId) => fundGlobalId],
  (events, fundGlobalId) => {
    return events.find((event) => event.globalId === fundGlobalId);
  }
);

export const eventDocumentsSelector = createSelector(selectEventPageDetails, (details) => details.eventDocuments);

export const selectStatusOptions = (): StatusOption[] => {
  return [RegistrationStatus.ALL, RegistrationStatus.REGISTERED, RegistrationStatus.NON_REGISTERED].map((status) => ({
    id: status,
    label: status,
  }));
};

export const monthOptionSelector = createSelector(
  calendarEventsFromEntitledFundsSelector,
  selectFilters,
  (events, filters): MonthOption[] => {
    const months =
      events?.map((event) => {
        const date = new Date(event.eventStartDate);
        const month = date.getMonth();

        return {
          id: month,
          label: format(date, "LLLL"),
          selected: filters.months.includes(month),
        };
      }) ?? [];

    return uniqBy(sortBy(months, "id"), "id");
  }
);

export const filteredAndSortedEventSelector = createSelector(
  calendarEventsFromEntitledFundsSelector,
  selectFilters,
  selectSort,
  (
    events,
    filters,
    sort
  ): {
    id: number;
    globalId: string;
    title: string;
    subTitle: Nullable<string>;
    productSegment: string;
    isPastEvent: boolean;
    eventLocation: string;
    eventStartDate: string;
  }[] => {
    const filteredEvents =
      events
        ?.filter(({ eventStartDate }) =>
          filters.months.length ? filters.months.includes(new Date(eventStartDate).getMonth()) : true
        )
        .filter(({ globalId, title, eventLocation }) => {
          return [globalId, title, eventLocation]
            .map((source) => source?.toLowerCase())
            .some((source) => source?.includes(filters.keyword.toLowerCase()));
        }) ?? [];

    return orderBy(filteredEvents, sort.key, sort.direction === SortDirection.ASC ? "asc" : "desc");
  }
);

export const eventPageSelector = createSelector(
  [selectEvents, (state, eventId: string) => eventId],
  (eventsSlice, eventId) => {
    return {
      loading: eventsSlice.loading,
      error: eventsSlice.error,
      eventData: (eventsSlice.data ?? []).find((event) => event.eventId === eventId),
    };
  }
);

export const eventDocumentRowSelector = createSelector(
  eventDocumentsSelector,
  preferencesSelector,
  (eventDocuments, preferences): EventDocumentRow[] => {
    if (eventDocuments.data === null) {
      return [];
    }

    return eventDocuments.data.map(
      ({
        actionItem,
        title,
        publishDate,
        videoId,
        videoToken,
        documentStatus,
        markedRead,
        fileType,
        id,
        documentSubtype,
        documentTypeId,
        fundId,
        otherAccounts,
        isLoading,
      }) => {
        const row = {
          actionItem,
          title,
          publishDate:
            documentStatus === DocumentStatus.Active && publishDate
              ? DateUtils.formatDate(new Date(publishDate), preferences.DateFormat)
              : "N/A",
          markedRead: markedRead,
          id: id,
          isLoading: isLoading,
          fileType,
          documentSubtype,
          fundId,
          documentTypeId,
          otherAccounts,
        };

        return videoId === null || undefined
          ? {
              ...row,
              markedRead,
              rowMeta: {
                nonClickableRow: !fileType,
                type:
                  documentStatus === DocumentStatus.Pending || documentStatus === DocumentStatus.PendingApproval
                    ? RowType.Highlighted
                    : RowType.Regular,
              },
            }
          : {
              ...row,
              publishDate: publishDate ? DateUtils.formatDate(new Date(publishDate), preferences.DateFormat) : "N/A",
              videoId,
              videoToken,
              markedRead,
              rowMeta: {
                type: RowType.Video,
                cellRenderers: {
                  actionItem: () => null,
                  download: () => null,
                  markedRead: () => null,
                },
              },
            };
      }
    );
  }
);

export const eventIcsDataSelector = createSelector([eventPageSelector], (eventPageData): Nullable<EventAttributes> => {
  const eventData = eventPageData.eventData;

  if (!eventData) {
    return null;
  }

  const eventStartDate = new Date(eventData.eventStartDate);
  const eventEndDate = new Date(eventData.eventEndDate);
  const timeDiffInHours = differenceInHours(eventEndDate, eventStartDate);
  const timeDiffInMinutes = differenceInMinutes(eventEndDate, eventStartDate) % 60;

  const startDateArray: DateArray = [
    eventStartDate.getFullYear(),
    eventStartDate.getMonth() + 1,
    eventStartDate.getDate(),
    eventStartDate.getHours(),
    eventStartDate.getMinutes(),
  ];

  const eventIcsData = {
    start: startDateArray,
    duration: { hours: timeDiffInHours, minutes: timeDiffInMinutes },
    title: eventData.title,
    description: eventData.description,
    location: eventData.eventLocation,
    organizer: eventData.plannerEmail
      ? {
          name:
            eventData.plannerFirstName && eventData.plannerLastName
              ? `${eventData.plannerFirstName} ${eventData.plannerLastName}`
              : undefined,
          email: eventData.plannerEmail,
        }
      : undefined,
  };

  return eventIcsData;
});

export const eventHighlightedVideoSelector = (state: RootState): MyCalendarState["highlightedVideo"] =>
  state.myCalendar.highlightedVideo;

export const eventHighlightedVideoSectionDetailsSelector = createSelector(
  eventHighlightedVideoSelector,
  (highlightedVideo): Nullable<HomePageVideoDetails> => {
    if (
      !highlightedVideo ||
      highlightedVideo.videoId === null ||
      highlightedVideo.videoTokenExpiration === null ||
      highlightedVideo.videoToken === null
    ) {
      return null;
    }

    return {
      id: highlightedVideo.videoId,
      title: highlightedVideo.title,
      type: "New Opportunity Spotlight",
      tokenExpiration: highlightedVideo.videoTokenExpiration,
      token: highlightedVideo.videoToken,
      description: highlightedVideo.videoDescription,
    };
  }
);
