import React, { FunctionComponent, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import { ReactComponent as SortableIcon } from "@assets/svg/sortable_icon.svg";
import { ReactComponent as SortDirectionIcon } from "@assets/svg/sort_direction_icon.svg";
import { SortDirection } from "@shared/models/general";
import { ListHeaderProps } from "@shared/components/ListHeader/models";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(6.25, 9),
    margin: theme.spacing(0, 0, 6.25),
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    listStyle: "none",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 0, 5, 0),
    },
    lineHeight: 1,
  },
  column: {
    marginRight: theme.spacing(6),
  },
  content: {
    textDecoration: "none",
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.75,
    color: "inherit",
  },
  sortIcon: {
    marginLeft: theme.spacing(1),
    transition: "all ease-in .25s",
  },
  sortIconDesc: {
    transform: "rotate(180deg)",
  },
}));

const ListHeader: FunctionComponent<ListHeaderProps> = ({ columns, sort, onColumnClick, testId }) => {
  const classes = useStyles();

  const handleColumnClick = useCallback(
    (columnKey) => {
      return (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        onColumnClick(columnKey);
      };
    },
    [onColumnClick]
  );

  return (
    <ul className={classes.root} data-testid={testId ? `${testId}-list-header-container` : "list-header-container"}>
      {columns.map(({ width, key, label }) => (
        <li
          className={classes.column}
          style={{ minWidth: width ?? "auto" }}
          key={key}
          data-testid={`list-header-item-${label.toLowerCase().replaceAll(" ", "-")}`}
        >
          <a className={classes.content} href="#" onClick={handleColumnClick(key)}>
            {label}
            {key === sort.key ? (
              <SortDirectionIcon
                className={cn([classes.sortIcon, sort.direction === SortDirection.DESC && classes.sortIconDesc])}
              />
            ) : (
              <SortableIcon className={classes.sortIcon} />
            )}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default ListHeader;
