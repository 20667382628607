import React, { FunctionComponent, memo, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import AppHeaderBar from "@app/containers/AppHeaderBar";
import AppNavigation from "@app/containers/AppNavigation";
import AppFooter from "@app/containers/AppFooter";
import { HEADER_HEIGHT, IMPERSONATION_BAR_HEIGHT_MOBILE, MOBILE_HEADER_HEIGHT } from "@app/constants/header";
import { AppRoute } from "@app/models/AppRoute";
import AppMobileHeader from "@app/containers/AppMobileHeader";
import { useAppSelector, useMobileLayout } from "@app/hooks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

const FOOTER_HEIGHT = 304;
const routesWithCustomLayout = [AppRoute.Login, AppRoute.SignOut];

const useStyles = makeStyles<Theme, { isMobile: boolean; isImpersonating: boolean }>(() => ({
  pageContent: {
    minHeight: ({ isMobile }) => `calc(100vh - ${isMobile ? 0 : HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
    paddingTop: ({ isMobile, isImpersonating }) => {
      let offset = 0;

      if (isMobile) {
        offset += MOBILE_HEADER_HEIGHT;
        if (isImpersonating) {
          offset += IMPERSONATION_BAR_HEIGHT_MOBILE;
        }
      }

      return offset;
    },
  },
}));

const Layout: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const isMobile = useMobileLayout();
  const { isImpersonating } = useAppSelector(impersonationStateSelector);

  const classes = useStyles({ isMobile, isImpersonating });

  const shouldRenderCustomLayout = routesWithCustomLayout.some((route) => route === location.pathname);

  if (shouldRenderCustomLayout) return <>{children}</>;

  const shouldRenderHomePage = location.pathname === AppRoute.Home;

  return (
    <>
      {isMobile ? (
        <AppMobileHeader isTransparent={shouldRenderHomePage} />
      ) : (
        <>
          <AppHeaderBar isTransparent={shouldRenderHomePage} />
          <AppNavigation isTransparent={shouldRenderHomePage} />
        </>
      )}
      <main className={classes.pageContent}>{children}</main>
      <AppFooter />
    </>
  );
};

export default memo<{ children: ReactNode }>(Layout);
