const monthToQuarterMap: Record<string, string> = {
  "03": "1",
  "06": "2",
  "09": "3",
  "12": "4",
};

export const getYearQuarterFromDate = (date: string): { year: string; quarter: string } => {
  const [year, month] = date.split("-");

  return { year, quarter: monthToQuarterMap[month] };
};
