import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { fetchDocumentTypes } from "@store/Entities/thunks";
import { fetchFundDocuments, fetchFundInvestorAccounts } from "@funds/store/thunks";
import RequestUtils from "@shared/utils/RequestUtils";

export const initFundDocuments = createAsyncThunk<void, string, ThunkApiConfig>(
  "funds/initFundDocuments",
  async (fundId, { dispatch }) => {
    await dispatch(fetchDocumentTypes());
    await dispatch(fetchFundInvestorAccounts(fundId));

    const fetchFundDocumentsCancelable = RequestUtils.getAbortThunk(fetchFundDocuments, dispatch);

    await fetchFundDocumentsCancelable(fundId);
  }
);
