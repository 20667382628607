import { createAsyncThunk } from "@reduxjs/toolkit";
import { entitledAccountsSelector } from "@store/Entities/selectors";
import { ThunkApiConfig } from "@shared/models/redux";
import { EntitledAccount } from "../models";

export const fetchEntitledAccounts = createAsyncThunk<EntitledAccount[], boolean | undefined, ThunkApiConfig>(
  "entities/fetchEntitledAccounts",
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra<EntitledAccount[]>("/entitlements/v1/user/EntitledAccounts");

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to load entitled accounts");
    }
  },
  {
    condition: (refresh, { getState }) => {
      if (refresh) return true;

      const entitledAccounts = entitledAccountsSelector(getState());

      return !entitledAccounts.loading && !entitledAccounts.data;
    },
  }
);
