import React from "react";
import { Box, CircularProgress } from "@mui/material";

const AppLoadingIndicator = () => (
  <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
    <CircularProgress />
  </Box>
);

export default AppLoadingIndicator;
