import React, { FunctionComponent, memo } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { TabButton } from "@shared/components/TabButton";
import { TabPanelProps } from "./models";

const useStyles = makeStyles<Theme, Pick<TabPanelProps, "dense" | "isPinned">>((theme) => ({
  bottomBorderLine: {
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",

    [theme.breakpoints.down("xl")]: {
      display: "flex",
    },
    [theme.breakpoints.between("md", "xl")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      padding: ({ dense }) => (dense ? 0 : theme.spacing(0, 10)),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      padding: 0,
      position: ({ isPinned }) => (isPinned ? "fixed" : "relative"),
      top: ({ isPinned }) => (isPinned ? 121 : "unset"),
      backgroundColor: ({ isPinned }) => (isPinned ? theme.palette.common.white : "unset"),
      left: 0,
      width: "100%",
      zIndex: 1,
    },
  },
}));

const TabPanel: FunctionComponent<TabPanelProps> = ({ onTabClick, activeTab, tabs, dense, testId, isPinned }) => {
  const classes = useStyles({ dense, isPinned });

  return (
    <Box className={classes.bottomBorderLine} data-testid={testId}>
      {Object.keys(tabs).map((tabKey) => (
        <TabButton
          key={tabKey}
          variant={activeTab === tabKey ? "contained" : "outlined"}
          onClick={() => {
            onTabClick(tabKey);
          }}
          testId={tabs[tabKey].toLowerCase().replaceAll(" ", "-")}
        >
          {tabs[tabKey]}
        </TabButton>
      ))}
    </Box>
  );
};

export default memo(TabPanel);
