import { IReportEmbedConfiguration, models } from "powerbi-client";

export const embedReportConfigConfig: IReportEmbedConfiguration = {
  type: "report",
  tokenType: models.TokenType.Embed,
  settings: {
    visualRenderedEvents: true,
    panes: {
      filters: {
        expanded: true,
        visible: true,
      },
      pageNavigation: {
        visible: true,
        position: models.PageNavigationPosition.Left,
      },
    },
  },
};
