import React, { memo, useCallback, useMemo, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { Dialog } from "@shared/components";
import { termsDialogDataSelector } from "@terms/store/selectors";
import { changeTermStatus, closeTermsDialog, resetOutstandingTerms, resetRules } from "@terms/store/ducks";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { OutstandingTermStatus, Term } from "@terms/models";
import MultipleTermsContent from "@terms/components/MultipleTermsContent";
import TermsDialogActions from "@terms/components/TermsDialogActions";

const TermsDialog = () => {
  const dispatch = useAppDispatch();
  const { termsToDisplay, dialogOpen } = useAppSelector(termsDialogDataSelector);

  const [clickedHandleAll, setClickedHandleAll] = useState<boolean>(false);

  const allTermsPending = useMemo(
    () => termsToDisplay.every((term) => term.status === OutstandingTermStatus.Pending),
    [termsToDisplay]
  );

  const handleButtonClick = useCallback(
    (status: OutstandingTermStatus, termId: Term["id"]) => {
      if (termsToDisplay.length === 1) {
        setClickedHandleAll(true);
      }
      dispatch(
        changeTermStatus({
          status,
          id: termId,
        })
      );
    },
    [dispatch, termsToDisplay]
  );

  const handleAcceptOrRejectAll = useCallback(
    (status: OutstandingTermStatus) => {
      if (allTermsPending) {
        setClickedHandleAll(true);
      }
      termsToDisplay.map((term) => {
        if (term.status === OutstandingTermStatus.Pending) {
          dispatch(
            changeTermStatus({
              status,
              id: term!.id,
            })
          );
        }
      });
    },
    [dispatch, termsToDisplay, allTermsPending]
  );

  const handleClosePopup = useCallback(
    ({ reason }) => {
      if (reason === "historyChange") {
        dispatch(resetRules());
        dispatch(closeTermsDialog());

        return;
      }

      termsToDisplay.forEach((term) => {
        if (term.status !== OutstandingTermStatus.Pending) return;

        dispatch(
          changeTermStatus({
            status: OutstandingTermStatus.Declined,
            id: term.id,
          })
        );
      });
    },
    [dispatch, termsToDisplay]
  );

  const TransitionProps = useMemo<TransitionProps>(
    () => ({
      onExited: () => dispatch(resetOutstandingTerms()),
    }),
    [dispatch]
  );

  return (
    <Dialog
      open={dialogOpen}
      maxWidth="md"
      title="Terms & Conditions"
      TransitionProps={TransitionProps}
      onClose={handleClosePopup}
      actions={
        <TermsDialogActions
          onButtonClick={handleAcceptOrRejectAll}
          allPending={allTermsPending}
          clickedHandleAll={clickedHandleAll}
        />
      }
    >
      <MultipleTermsContent terms={termsToDisplay} onButtonClick={handleButtonClick} />
    </Dialog>
  );
};

export default memo(TermsDialog);
