import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { fetchFundInvestorQuarterOptions } from "@capitalAccountStatement/store/thunks/fetchFundInvestorQuarterOptions";
import {
  CapitalAccountStatement,
  CapitalAccountStatementFilterOptions,
  CapitalAccountStatementFilters,
  CapitalAccountStatementReports,
} from "../models";
import { downloadCapitalAccountStatementReport, fetchCapitalAccountStatementReport } from "./thunks";

const initialState: CapitalAccountStatement = {
  report: ReduxUtils.getAsyncSlice<CapitalAccountStatementReports>(),
  filters: {
    funds: null,
    accounts: null,
    asOfDate: null,
  },
  filterOptions: ReduxUtils.getAsyncSlice<CapitalAccountStatementFilterOptions>(),
  isReportDownloading: false,
};

export const capitalAccountStatementSlice = createSlice({
  name: "capitalAccountStatement",
  initialState,
  reducers: {
    resetState: (state) => {
      state.report = initialState.report;
      state.filterOptions = initialState.filterOptions;
      state.filters = initialState.filters;
    },
    changeFilterValue: (state, action: PayloadAction<Partial<CapitalAccountStatementFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFundInvestorQuarterOptions.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.filterOptions);
      })
      .addCase(fetchFundInvestorQuarterOptions.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.filterOptions, action.payload);
      })
      .addCase(fetchFundInvestorQuarterOptions.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(
          state.filterOptions,
          action.payload as string,
          {} as CapitalAccountStatementFilterOptions
        );
      })
      .addCase(fetchCapitalAccountStatementReport.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.report);
      })
      .addCase(fetchCapitalAccountStatementReport.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.report, action.payload);
      })
      .addCase(fetchCapitalAccountStatementReport.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(
          state.report,
          action.payload as string,
          {} as CapitalAccountStatementReports
        );
      })
      .addCase(downloadCapitalAccountStatementReport.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(downloadCapitalAccountStatementReport.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(downloadCapitalAccountStatementReport.rejected, (state) => {
        state.isReportDownloading = false;
      });
  },
});

export const { resetState, changeFilterValue } = capitalAccountStatementSlice.actions;

export default capitalAccountStatementSlice.reducer;
