import { Column } from "react-table";
import { AsyncSlice } from "@shared/models/redux";
import { Nullable, SortDirection } from "@shared/models/general";
import { ListOptionBase, MultiselectOption } from "@shared/components/List";
import { ColumnMeta } from "@shared/components/DataGrid";
import { DocumentResponse } from "@documents/models/api.models";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { EntityDocumentGridColumn, EntityDocumentGridRow } from "@dataRooms/models";

// import { DocumentRow } from "@documents/models/dataGrid.models";

export enum MyCalendarView {
  List = "List View",
  Calendar = "Calendar View",
}

export enum MyCalendarEventColumn {
  Date = "eventStartDate",
  Name = "title",
}

export enum RegistrationStatus {
  REGISTERED = "Registered",
  NON_REGISTERED = "Non-registered",
  ALL = "All Events",
}

export interface CalendarEventApiResponseItem {
  title: string;
  description: string;
  registration: string;
  summary: string;
  invitation: string;
  plannerFirstName: Nullable<string>;
  plannerLastName: Nullable<string>;
  plannerEmail: Nullable<string>;
  timeZone: string;
  code: string;
  attendeeId: string;
  eventLocation: string;
  eventId: string;
  isRegistered: boolean;
  eventStartDate: string;
  eventEndDate: string;
  isPastEvent: boolean;
  globalId: string;
}

export interface MyCalendarState {
  events: AsyncSlice<CalendarEventApiResponseItem[]>;
  filters: {
    months: number[];
    keyword: string;
    registrationStatus: RegistrationStatus;
  };
  sort: {
    key: MyCalendarEventColumn;
    direction: SortDirection;
  };
  eventPageDetails: {
    eventDocuments: AsyncSlice<DocumentRow[]>;
    sort: {
      key: string;
      direction: SortDirection;
    };
  };

  highlightedVideo: Nullable<
    Pick<DocumentResponse, "videoDescription" | "videoToken" | "videoId" | "videoTokenExpiration" | "title">
  >;
}

export type MonthOption = MultiselectOption<number>;

export type StatusOption = ListOptionBase<RegistrationStatus>;

// @TODO remove
export interface EventDocument {
  id: string;
  title: string;
  fileType: string;
  publishDate: string;
}

// @TODO remove
export interface PastEventApiResponse {
  documents: Array<EventDocument>;
  video: {
    videoToken: string;
    videoId: string;
  };
}

export type EventDocumentRow = Omit<DocumentRow, "fundDisplayName" | "investorAccountName" | "documentStatus">;

export type EventDocumentColumn = ColumnMeta<EventDocumentRow> & Column<EventDocumentRow>;

// export interface EventDocumentColumnsProps {
//   onViewDocument: (row: EventDocumentRow) => void;
//   // onClickDownloadDocument: (row: EventDocumentRow) => void;
//   update: Record<string, Pick<EventDocumentColumn, "sortDirection">>;
// }
export interface EventDocumentColumnsProps {
  handleToggleActionItemFlag: (row: EntityDocumentGridRow) => void;
  handleDownloadDocumentClick: (row: EntityDocumentGridRow) => void;
}

export interface InitializeEventDocumentsGridColumnsProps {
  handleToggleActionItemFlag: (row: EntityDocumentGridRow) => void;
  handleDownloadDocumentClick: (row: EntityDocumentGridRow) => void;
  update?: Record<string, Pick<EntityDocumentGridColumn, "onClick" | "sortDirection">>;
}
