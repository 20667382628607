import { AppThunkAPI } from "@shared/models/redux";
import { InitiateTermsArgs, TermAppearanceType, TermAssociation } from "@terms/models";
import initiateTermsPopup from "@terms/store/thunks/initiateTermsPopup";

type CheckDocumentTerms = (args: {
  documentSubTypes: Array<string>;
  fundIds: Array<string>;
}) => AppThunkAPI<Promise<boolean>>;

export const checkDocumentTerms: CheckDocumentTerms =
  ({ documentSubTypes, fundIds }) =>
  (dispatch) => {
    const termsConfig: InitiateTermsArgs = {
      type: TermAppearanceType.ViewDocument,
      associations: {
        [TermAssociation.DocumentType]: documentSubTypes,
        [TermAssociation.EntitledEntity]: fundIds,
      },
    };

    return new Promise<boolean>((resolve) => {
      termsConfig.extraArgs = {
        onAccept: () => resolve(true),
        onDecline: () => resolve(false),
      };

      dispatch(initiateTermsPopup(termsConfig));
    });
  };
