import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReduxUtils from "@shared/utils/ReduxUtils";
import {
  ScheduleOfInvestments,
  ScheduleOfInvestmentsFilterOptions,
  ScheduleOfInvestmentsFilterValues,
  ScheduleOfInvestmentsReport,
} from "../models";
import {
  downloadScheduleOfInvestmentsReport,
  fetchScheduleOfInvestmentsFilters,
  fetchScheduleOfInvestmentsReport,
} from "./thunks";

const initialState: ScheduleOfInvestments = {
  filterOptions: ReduxUtils.getAsyncSlice<ScheduleOfInvestmentsFilterOptions>(),
  filters: {
    asOfDate: null,
    funds: null,
    accounts: null,
  },
  report: ReduxUtils.getAsyncSlice<ScheduleOfInvestmentsReport>(),
  isReportDownloading: false,
};

export const scheduleOfInvestmentsSlice = createSlice({
  name: "scheduleOfInvestments",
  initialState,
  reducers: {
    resetState: (state) => {
      state.filterOptions = initialState.filterOptions;
      state.filters = initialState.filters;
      state.report = initialState.report;
    },
    changeFilterValue: (state, action: PayloadAction<Partial<ScheduleOfInvestmentsFilterValues>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScheduleOfInvestmentsFilters.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.filterOptions);
      })
      .addCase(fetchScheduleOfInvestmentsFilters.fulfilled, (state, action) => {
        if (action.payload.length) {
          const latestRecord: any = action.payload[action.payload.length - 1];
          const associatedFund = latestRecord.associatedFunds[0];

          state.filterOptions.loading = false;
          state.filterOptions.data = action.payload;
          state.filters.asOfDate = {
            year: latestRecord.year,
            quarter: latestRecord.quarter,
          };
          state.filters.funds = {
            label: associatedFund.label,
            value: `${associatedFund.value}-${associatedFund.currency}`,
            currency: `${associatedFund.currency}`,
          };
        } else {
          state.filterOptions.loading = false;
          state.filterOptions.data = [];
          state.filters.asOfDate = null;
          state.filters.funds = null;
        }
      })
      .addCase(fetchScheduleOfInvestmentsFilters.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(
          state.filterOptions,
          action.payload as string,
          [] as ScheduleOfInvestmentsFilterOptions
        );
      })
      .addCase(fetchScheduleOfInvestmentsReport.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.report);
      })
      .addCase(fetchScheduleOfInvestmentsReport.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.report, action.payload);
      })
      .addCase(fetchScheduleOfInvestmentsReport.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(
          state.report,
          action.payload as string,
          {} as ScheduleOfInvestmentsReport
        );
      })
      .addCase(downloadScheduleOfInvestmentsReport.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(downloadScheduleOfInvestmentsReport.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(downloadScheduleOfInvestmentsReport.rejected, (state) => {
        state.isReportDownloading = false;
      });
  },
});

export const { resetState, changeFilterValue } = scheduleOfInvestmentsSlice.actions;

export default scheduleOfInvestmentsSlice.reducer;
