import { combineReducers, isAnyOf } from "@reduxjs/toolkit";
import { pick } from "lodash";
import administrationReportsReducer from "@administrationReports/store/ducks";
import myCalendarReducer from "@myCalendar/store/ducks";
import homeReducer from "@home/store/ducks";
import { startImpersonationSession, stopImpersonationSession } from "@impersonateUser/store/actions";
import impersonationReducer from "@impersonateUser/store/ducks";
import fundsReducer from "@funds/store/ducks";
import reportingReducer from "@reporting/store/ducks";
import accountSummaryCarlyleReducer from "@accountSummaryCarlyle/store/ducks";
import accountSummaryAlpInvestReducer from "@accountSummaryAlpInvest/store/ducks";
import documentsReducer from "@documents/store/ducks";
import dataRoomsReducer from "@dataRooms/store/ducks";
import capitalAccountStatementReducer from "@capitalAccountStatement/store/ducks";
import scheduleOfInvestmentsReducer from "@scheduleOfInvestments/store/ducks";
import cashFlowActivityReducer from "@cashFlowActivity/store/ducks";
import portfolioPerformanceReducer from "@portfolioPerformance/store/ducks";
import diversificationAnalysisReducer from "@diversificationAnalysis/store/ducks";
import investorRequestReducer from "@investorRequest/store/ducks";
import profileReducer from "@profile/store/ducks";
import termsReducer from "@terms/store/ducks";
import notificationReducer from "@store/Notification/ducks";
import userReducer from "@user/store/ducks";
import entitiesReducer from "@store/Entities/ducks";
import videosReducer from "@videos/store/ducks";
import insightsHubReducer from "@insightsHub/store/ducks";

export const appReducersMap = {
  home: homeReducer,
  reporting: reportingReducer,
  accountSummaryAlpInvest: accountSummaryAlpInvestReducer,
  accountSummaryCarlyle: accountSummaryCarlyleReducer,
  capitalAccountStatement: capitalAccountStatementReducer,
  scheduleOfInvestments: scheduleOfInvestmentsReducer,
  cashFlowActivity: cashFlowActivityReducer,
  portfolioPerformance: portfolioPerformanceReducer,
  diversificationAnalysis: diversificationAnalysisReducer,
  documents: documentsReducer,
  dataRooms: dataRoomsReducer,
  funds: fundsReducer,
  profile: profileReducer,
  user: userReducer,
  terms: termsReducer,
  entities: entitiesReducer,
  notification: notificationReducer,
  impersonation: impersonationReducer,
  investorRequest: investorRequestReducer,
  videos: videosReducer,
  myCalendar: myCalendarReducer,
  administrationReports: administrationReportsReducer,
  insightsHub: insightsHubReducer,
};

const appReducers = combineReducers(appReducersMap);

const rootReducer: typeof appReducers = (state, action) => {
  const persistedState = isAnyOf(startImpersonationSession, stopImpersonationSession)(action)
    ? pick(state, ["user", "impersonation"])
    : state;

  return appReducers(persistedState as ReturnType<typeof appReducers>, action);
};

export default rootReducer;
