import { createAsyncThunk } from "@reduxjs/toolkit";
import { random } from "lodash";
import { AccountGroupResponse } from "@store/Entities/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { createAccountGroupLocally } from "@store/Entities/ducks";
import { openNotificationThunk } from "@store/Notification/thunks";
import { fetchAccountGroups } from "@store/Entities/thunks";

export const createAccountGroupThunk = createAsyncThunk<AccountGroupResponse["groupId"], void, ThunkApiConfig>(
  "profile/createAccountGroupThunk",
  async (_, { extra, getState, dispatch, rejectWithValue }) => {
    const {
      profile: {
        myGroups: { selectedAccountIds, groupNameInput },
      },
      user: { data: userData },
      impersonation: { isImpersonating, user: impersonatedUserData },
    } = getState();

    if (isImpersonating) {
      const groupId = random(1, 10000);

      dispatch(
        createAccountGroupLocally({
          groupId,
          groupName: groupNameInput,
          investorAccountIds: selectedAccountIds,
          entitledUserId: Number(impersonatedUserData!.id),
        })
      );

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: `${groupNameInput} group has been created`,
        })
      );

      return groupId;
    }

    try {
      const response = await extra<AccountGroupResponse>("/entitlements/v1/user/InvestorAccountGroups", {
        method: "post",
        body: {
          groupName: groupNameInput,
          entitledUserId: userData!.id,
          createDate: null,
          updatedDate: null,
        },
      });

      await Promise.all(
        selectedAccountIds.map((accountId: number) =>
          extra("/entitlements/v1/user/InvestorAccountGroupsAssociation", {
            method: "post",
            body: { accountId, groupId: response.data.groupId },
          })
        )
      );

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: `${groupNameInput} group has been created`,
        })
      );

      dispatch(fetchAccountGroups(true));

      return response.data.groupId;
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to create new group",
        })
      );

      return rejectWithValue(false);
    }
  }
);
