import { NOT_ACCEPTED_TERMS_ERROR_MESSAGE } from "@terms/constants";

class TermsNotAcceptedError extends Error {
  constructor() {
    super();
    this.name = "Terms not accepted error";
    this.message = NOT_ACCEPTED_TERMS_ERROR_MESSAGE;
  }
}

export default TermsNotAcceptedError;
