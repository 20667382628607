import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { changeFilterValue } from "@capitalAccountStatement/store/ducks";
import { Investors } from "@store/Entities/models";
import { Nullable } from "@shared/models/general";
import { Currency } from "@shared/models/currency";
import { fetchInvestors } from "@store/Entities/thunks";
import { openNotificationThunk } from "@store/Notification/thunks";
import {
  templateMain,
  templateRemainingCommitment,
  templateTotalContributionsOutsideOfCommitments,
} from "@capitalAccountStatement/constants/dataGridDataTemplates";
import { ReportingPeriodTypes, SpecialAccountKeys } from "@shared/models/reporting";
import { ThunkApiConfig } from "@shared/models/redux";
import { reportMessages } from "@reporting/constants";
import { FileResponse } from "@shared/models/request";
import DateUtils from "@shared/utils/DateUtils";
import { fetchFundInvestorQuarterOptions } from "@capitalAccountStatement/store/thunks/fetchFundInvestorQuarterOptions";
import RequestUtils from "@shared/utils/RequestUtils";
import {
  CapitalAccountStatementAccountsFilter,
  CapitalAccountStatementDataSetFields,
  CapitalAccountStatementFilters,
  CapitalAccountStatementFundsFilter,
  CapitalAccountStatementRawDataItem,
  CapitalAccountStatementReports,
} from "../../models";
import { CapitalAccountStatementActions } from "../actions";
import {
  capitalAccountStatementFilterOptionsSelector,
  capitalAccountStatementFiltersSelector,
  capitalAccountStatementFundsFilter,
  capitalAccountStatementReportSelector,
} from "../selectors";

const createBaseReportFromReturnData = (
  data: CapitalAccountStatementRawDataItem[],
  fundCurrency: Currency,
  investorListFilter: string[]
): CapitalAccountStatementReports => {
  const keyedDataRows: {
    [key: string]: CapitalAccountStatementDataSetFields;
  } = {};

  const allFieldKeys: string[] = [];

  templateMain.forEach((row: any) => {
    const rowData: CapitalAccountStatementDataSetFields = {
      fieldId: row.fieldId,
      fieldDescriptor: row.fieldDescriptor,
      investorAccounts: {},
    };

    keyedDataRows[row.fieldId] = rowData;
    allFieldKeys.push(row.fieldId);
  });

  templateRemainingCommitment.forEach((row: any) => {
    const rowData: CapitalAccountStatementDataSetFields = {
      fieldId: row.fieldId,
      fieldDescriptor: row.fieldDescriptor,
      investorAccounts: {},
    };

    keyedDataRows[row.fieldId] = rowData;
    allFieldKeys.push(row.fieldId);
  });

  templateTotalContributionsOutsideOfCommitments.forEach((row: any) => {
    const rowData: CapitalAccountStatementDataSetFields = {
      fieldId: row.fieldId,
      fieldDescriptor: row.fieldDescriptor,
      investorAccounts: {},
    };

    keyedDataRows[row.fieldId] = rowData;
    allFieldKeys.push(row.fieldId);
  });

  // An array to track investor accounts that appear in the report. This is used to populate the Investor Accounts Filter with options
  const investorAccountsUsedInReport: string[] = [];
  let totalFundReportingCurrency: Currency = "USD";
  let carlyleGroupReportingCurrency: Currency = "USD";
  let totalFundExchangeRateToUSD = 1;
  let totalFundAmount = 0;

  data.forEach((row: any) => {
    if (investorListFilter.includes(row.investorId)) {
      if (!investorAccountsUsedInReport.includes(row.investorId)) {
        investorAccountsUsedInReport.push(row.investorId);
      }

      allFieldKeys.forEach((key: string) => {
        if (!Object.keys(keyedDataRows[key].investorAccounts).includes(row.investorId)) {
          keyedDataRows[key].investorAccounts[row.investorId] = {
            QTD: 0,
            YTD: 0,
            ITD: 0,
          };
        }
        keyedDataRows[key].investorAccounts[row.investorId][row.period] += row[key];
      });
    }

    // Specifically pull from the data the remaining commitment for the total-fund account code and pull the currency and exchange rate from it.
    if (row.investorId === SpecialAccountKeys.TotalFundAccount && row.period === ReportingPeriodTypes.ITD) {
      totalFundAmount = row.commitmentBeginningRemainingCommitment;
      totalFundReportingCurrency = row.reportingCurrency;
      totalFundExchangeRateToUSD = row.exchangeRateToUSD;
    }

    // Specifically pull from the data the remaining commitment for the total-fund account code and pull the currency and exchange rate from it.
    if (row.investorId === SpecialAccountKeys.CarlyleGroupAccount && row.period === ReportingPeriodTypes.ITD) {
      carlyleGroupReportingCurrency = row.reportingCurrency;
    }
  });

  const report: CapitalAccountStatementReports = {
    fundCurrency: fundCurrency,
    carlyleGroupCurrency: carlyleGroupReportingCurrency,
    totalFundCurrency: totalFundReportingCurrency,
    totalFundAmount: totalFundAmount,
    exchangeRateToUSD: totalFundExchangeRateToUSD,
    investorAccounts: investorAccountsUsedInReport,
    originalData: keyedDataRows,
  };

  return report;
};

export const fetchCapitalAccountStatementReport = createAsyncThunk<
  CapitalAccountStatementReports,
  void,
  ThunkApiConfig
>(
  "capitalAccountStatement/fetchCapitalAccountStatementReport",
  async (_, { rejectWithValue, extra, getState, signal }) => {
    try {
      const state = getState();

      const filterValues = capitalAccountStatementFiltersSelector(state);

      if (filterValues.funds && filterValues.asOfDate) {
        const fund = filterValues.funds.value;
        const curr: Currency = filterValues.funds.currency as Currency;

        const reportingDate = DateUtils.convertYearQuarterToEndDate(
          filterValues.asOfDate.year,
          filterValues.asOfDate.quarter
        );

        const fetchReportArgs = {
          fundIds: [fund],
          reportingCurrency: curr,
          reportingDate,
        };

        const response = await extra<any>("/reporting/v1/Cas/report", {
          body: fetchReportArgs,
          method: "post",
          signal,
        });
        const { data, status } = response;

        if (status === 204) {
          return {} as CapitalAccountStatementReports;
        }
        if (typeof data.reports === "undefined") {
          return rejectWithValue("no data");
        }

        const investorList: string[] = Object.keys(state.entities.investors.data!);

        const report: CapitalAccountStatementReports = createBaseReportFromReturnData(data.reports, curr, investorList);

        return report;
      } else {
        return {} as CapitalAccountStatementReports;
      }
    } catch (error) {
      return rejectWithValue("could not fetch data");
    }
  }
);

export const extractInvestorOptionsFromReport = (report: any, investorList: Investors) => {
  const reportInvestors = report.investorAccounts.reduce((acc: any, cur: string) => {
    if (
      investorList[cur] &&
      cur !== SpecialAccountKeys.CarlyleGroupAccount &&
      cur !== SpecialAccountKeys.TotalFundAccount
    ) {
      acc[cur] = false;
    }

    return acc;
  }, {});

  return reportInvestors;
};

export const changeCasFilterValues = createAsyncThunk<void, Partial<CapitalAccountStatementFilters>, ThunkApiConfig>(
  "capitalAccountStatement/changeCasFilterValues",
  async (filterValues, { getState, dispatch }) => {
    const filterOptions = capitalAccountStatementFilterOptionsSelector(getState());

    const filters = capitalAccountStatementFiltersSelector(getState());
    const newFilterValues: Partial<CapitalAccountStatementFilters> = {};
    const fetchCapitalAccountStatementReportCancelable = RequestUtils.getAbortThunk(
      fetchCapitalAccountStatementReport,
      dispatch
    );

    if (Object.keys(filterValues).length === 1 && filterValues.asOfDate) {
      if (filters?.asOfDate?.year === filterValues.asOfDate.year) {
        if (filters.asOfDate.quarter !== filterValues.asOfDate.quarter) {
          const newAsOfDate = {
            year: filterValues.asOfDate.year,
            quarter: filterValues?.asOfDate?.quarter,
          };

          newFilterValues["asOfDate"] = newAsOfDate;
          await dispatch(changeFilterValue(newFilterValues));
          const fundList = await capitalAccountStatementFundsFilter(getState());

          if (fundList && fundList.length) {
            if (filters.funds) {
              const currentSelectedFund = fundList.find((fund) => {
                return fund.id === filters.funds!.value;
              });

              if (!currentSelectedFund) {
                const latestFund = {
                  value: fundList[0].id as string,
                  label: fundList[0].label as string,
                  currency: fundList[0].key && fundList[0].key.split("-")[1],
                };

                await dispatch(
                  changeFilterValue({
                    funds: latestFund as CapitalAccountStatementFundsFilter,
                  })
                );
              }
            }
          }

          await fetchCapitalAccountStatementReportCancelable();
        }
      } else if (filters?.asOfDate?.year !== filterValues.asOfDate.year) {
        const quarters: string[] = [];

        filterOptions?.data?.periods.map((period) => {
          if (period.year === filterValues?.asOfDate?.year) {
            if (period.associatedFunds) {
              quarters.push(period.quarter);
            }
          }
        });
        const newAsOfDate = {
          year: filterValues.asOfDate.year,
          quarter:
            quarters.indexOf(filterValues.asOfDate.quarter) !== -1
              ? filterValues.asOfDate.quarter
              : quarters.sort()[quarters.length - 1],
        };

        newFilterValues.asOfDate = newAsOfDate;
        await dispatch(changeFilterValue(newFilterValues));
        const fundList = capitalAccountStatementFundsFilter(getState());

        if (fundList && fundList.length) {
          if (filters.funds) {
            const currentSelectedFund = fundList.find((fund) => {
              return fund.id === filters.funds!.value;
            });

            if (!currentSelectedFund) {
              const latestFund = {
                value: fundList[0].id as string,
                label: fundList[0].label as string,
                currency: fundList[0].key && fundList[0].key.split("-")[1],
              };

              await dispatch(
                changeFilterValue({
                  funds: latestFund as CapitalAccountStatementFundsFilter,
                })
              );
            }
          }
        }

        await fetchCapitalAccountStatementReportCancelable();
      }
    } else {
      dispatch(changeFilterValue(filterValues));

      await fetchCapitalAccountStatementReportCancelable();
    }

    const rootState = getState();
    const report = capitalAccountStatementReportSelector(rootState).data;
    const investorList = rootState.entities.investors.data;
    const accounts = Object.keys(filters.accounts ?? {}).reduce((result, accountId) => {
      if (!Object.prototype.hasOwnProperty.call(result, accountId)) {
        return result;
      }

      return {
        ...result,
        [accountId]: filters.accounts?.[accountId],
      };
    }, extractInvestorOptionsFromReport(report, investorList!));

    dispatch(changeFilterValue({ accounts }));
  }
);

// export const fetchFundInvestorQuarterOptions = createAsyncThunk<
//   CapitalAccountStatementFilterOptions,
//   void,
//   ThunkApiConfig
// >(
//   "capitalAccountStatement/fetchFundInvestorQuarterOptions",
//   async (_, { extra, rejectWithValue, dispatch, getState }) => {
//     try {
//       const response = await extra<ReportingPeriodsResponse[]>(
//         "/reporting/v1/Cas/periods"
//       );
//
//       const periods = normalizeReportingPeriodsResponse(response.data);
//
//       if (periods.length) {
//         const filterSelection = capitalAccountStatementFiltersSelector(
//           getState()
//         );
//
//         const latestPeriod = periods[periods.length - 1];
//
//         dispatch(
//           changeCasFilterValues({
//             ...filterSelection,
//             funds: latestPeriod.associatedFunds[0],
//             asOfDate: {
//               year: latestPeriod.year,
//               quarter: latestPeriod.quarter,
//             },
//           })
//         );
//       }
//
//       return { periods };
//     } catch (error) {
//       console.dir(error);
//
//       return rejectWithValue("Failed to fetch filters data");
//     }
//   },
//   {
//     condition: (_, { getState }) => {
//       const options = capitalAccountStatementFilterOptionsSelector(getState());
//
//       return !options.loading && !options.data;
//     },
//   }
// );

export const initCapitalAccountStatement = createAsyncThunk<void, void, ThunkApiConfig>(
  "capitalAccountStatement/initCapitalAccountStatement",
  async (_, { dispatch }) => {
    const fetchFundInvestorQuarterOptionsCancelable = RequestUtils.getAbortThunk(
      fetchFundInvestorQuarterOptions,
      dispatch
    );
    const fetchInvestorsCancelable = RequestUtils.getAbortThunk(fetchInvestors, dispatch);

    await fetchInvestorsCancelable();
    await fetchFundInvestorQuarterOptionsCancelable();
  }
);

// Download report
interface DownloadCASReportRequestBody {
  fundIds: string[];
  investorIds: Nullable<string[]>;
  reportingCurrency: Nullable<string>;
  reportingDate: string;
}

const getAccountsIds = (accountsMap: Nullable<CapitalAccountStatementAccountsFilter>) =>
  accountsMap ? Object.keys(accountsMap).filter((accountId) => accountsMap[accountId] === true) : [];

export const downloadCapitalAccountStatementReport = createAsyncThunk<void, void, ThunkApiConfig>(
  CapitalAccountStatementActions.downloadReport,
  async (_, { dispatch, rejectWithValue, extra, getState }) => {
    try {
      const state = getState();

      const filterValues = capitalAccountStatementFiltersSelector(state);

      if (!filterValues.funds || !filterValues.asOfDate) {
        throw new Error("No required filter data provided");
      }

      // array, but always one fund
      const fundIds = [filterValues.funds.value];
      // get array of investor accounts id
      const investorIdArr = getAccountsIds(filterValues.accounts);
      const investorIds = investorIdArr.length ? investorIdArr : null;
      // get currency
      const reportingCurrency: Currency = filterValues.funds.currency as Currency;
      // made Date from year and quarter dropbox values
      const reportingDate = DateUtils.convertYearQuarterToEndDate(
        filterValues.asOfDate.year,
        filterValues.asOfDate.quarter
      ).toISOString();

      const reqBody: DownloadCASReportRequestBody = {
        fundIds,
        investorIds,
        reportingCurrency,
        reportingDate,
      };

      // get report
      const response = await extra<FileResponse>("/reporting/v1/Cas/ReportDownload", {
        method: "post",
        body: reqBody,
        responseType: "arraybuffer",
      });

      // save it and show notification
      if (response.data.blob?.size) {
        saveAs(response.data.blob, response.data.fileName);
        dispatch(
          openNotificationThunk({
            variant: "info",
            text: reportMessages.success,
          })
        );
      } else {
        throw new Error("downloadCASReport(): no data returned on request");
      }
    } catch (e) {
      // show warnning
      console.warn("downloadCASReport() failed:", e);

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: reportMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch Capital Account Statement Report");
    }
  }
);
