import { noop } from "lodash";
import { InfiniteScrollConfig } from "@shared/components/DataGrid";

const useDataGridInfiniteScroll = (rowsCount: number, infiniteScrollConfig?: InfiniteScrollConfig) => {
  if (!infiniteScrollConfig) {
    return {
      itemCount: rowsCount,
      isItemLoaded: () => true,
      handleLoadMore: noop,
    };
  }
  const hasNextPortion = infiniteScrollConfig.total - rowsCount > 0;
  const itemCount = infiniteScrollConfig.loading ? rowsCount + 1 : rowsCount;

  const isItemLoaded = (index: number) => !hasNextPortion || index < rowsCount;

  const handleLoadMore = () => {
    if (hasNextPortion && !infiniteScrollConfig.loading) {
      infiniteScrollConfig.loadMore(rowsCount + 1);
    }
  };

  return {
    itemCount,
    isItemLoaded,
    handleLoadMore,
  };
};

export default useDataGridInfiniteScroll;
