import { AppThunkAPI } from "@shared/models/redux";
import { PreferenceDefaultInvestorGroupResponse, PreferenceShortenObject, UserPreferenceName } from "@user/models";

type CallCreateUserPreferenceAPI<S extends UserPreferenceName> = (arg: {
  userId: string;
  preference: PreferenceShortenObject<S> | PreferenceDefaultInvestorGroupResponse;
}) => AppThunkAPI<Promise<PreferenceShortenObject<S>>>;

export const callCreateUserPreferenceAPI: CallCreateUserPreferenceAPI<UserPreferenceName> =
  ({ userId, preference }) =>
  async (_dispatch, _state, extra) => {
    const response = await extra<PreferenceShortenObject<UserPreferenceName>>(
      `/entitlements/v1/user/UserPreferences?userId=${userId}`,
      {
        method: "POST",
        body: {
          Setting: preference.setting,
          Value: preference.value,
        },
      }
    );

    return response.data;
  };
