import { CallActionItemFlagAPIArgs } from "@documents/models/api.models";
import { AppThunkAPI } from "@shared/models/redux";

type CallActionItemFlagAPI = (args: CallActionItemFlagAPIArgs) => AppThunkAPI<Promise<unknown>>;

export const callActionItemFlagAPI: CallActionItemFlagAPI =
  ({ actionItem, id }) =>
  async (_dispatch, _state, extra) => {
    await extra(`/documents/v1/Users/${id}/actionItem/${!actionItem}`, {
      method: "post",
    });
  };
