import { useCallback, useState } from "react";
import { Nullable } from "@shared/models/general";
import useMutationObservable from "@shared/hooks/useMutationObservable";

interface UseDataGridScrollWidthResult {
  verticalScrollbarWidth: number;
  horizontalScrollbarHeight: number;
  scrollerRef: Nullable<HTMLDivElement>;

  setScrollerRef(node: HTMLDivElement): void;
}

const useDataGridScrollDimensions = (): UseDataGridScrollWidthResult => {
  const [scrollerRef, setScrollerRef] = useState<Nullable<HTMLDivElement>>(null);
  const [verticalScrollbarWidth, setVerticalScrollbarWidth] = useState(0);
  const [horizontalScrollbarHeight, setHorizontalScrollbarHeight] = useState(0);

  const handleScrollerMutation = useCallback<MutationCallback>(
    ([mutation]) => {
      setVerticalScrollbarWidth(
        (mutation.target as HTMLElement).offsetWidth - (mutation.target as HTMLElement).clientWidth
      );

      setHorizontalScrollbarHeight(
        (mutation.target as HTMLElement).offsetHeight - (mutation.target as HTMLElement).clientHeight
      );
    },

    []
  );

  useMutationObservable(scrollerRef, handleScrollerMutation);

  return {
    scrollerRef,
    setScrollerRef,
    verticalScrollbarWidth,
    horizontalScrollbarHeight,
  };
};

export default useDataGridScrollDimensions;
