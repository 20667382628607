import { SortDirection } from "@shared/models/general";
import { HeaderMenuOption, HeaderMenuOptionId } from "@shared/components";
import { DataGridDocumentColumn, DocumentsDataGridColumnsProps } from "@documents/models/dataGrid.models";

export const getDocumentsDataGridColumns = ({
  handleToggleActionItemFlag,
  handleDownloadDocumentClick,
  handleViewDocument,
  sort,
}: DocumentsDataGridColumnsProps): Array<DataGridDocumentColumn> => {
  const docColumns: Array<DataGridDocumentColumn> = [
    {
      accessor: "markedRead",
      Header: "",
      width: 35,
      align: "center",
      headerAlign: "center",
      type: "readIndicator",
      minWidth: 35,
    },
    {
      accessor: "selected",
      Header: "",
      width: 35,
      align: "center",
      headerAlign: "center",
      type: "checkbox",
      minWidth: 35,
    },
    {
      accessor: "actionItem",
      Header: "",
      width: 35,
      align: "center",
      headerAlign: "center",
      type: "actionFlag",
      minWidth: 35,
      onClick: ({ original }) => handleToggleActionItemFlag(original),
    },
    {
      accessor: "title",
      Header: "NAME",
      flex: 4,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      sortable: true,
      withTooltip: true,
      minWidth: 450,
      onClick: ({ original }) => handleViewDocument(original),
    },
    {
      accessor: "fundDisplayName",
      Header: "FUND",
      flex: 2,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      sortable: true,
      withTooltip: true,
      minWidth: 190,
    },
    {
      accessor: "investorAccountName",
      Header: "INVESTOR ACCOUNT",
      flex: 2,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      sortable: true,
      withTooltip: true,
      minWidth: 120,
    },
    {
      accessor: "publishDate",
      Header: "PUBLISH DATE",
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerContentAlign: "left",
      sortable: true,
      sortDirection: SortDirection.DESC,
      minWidth: 130,
    },
    {
      accessor: "download",
      Header: "",
      width: 35,
      align: "center",
      headerAlign: "center",
      type: "download",
      minWidth: 35,
      onClick: ({ original }) => handleDownloadDocumentClick(original),
    },
  ];

  if (!sort) {
    return docColumns;
  }

  return docColumns.map((column) => {
    return column.sortable && sort.key === column.accessor
      ? { ...column, sortDirection: sort.direction }
      : { ...column, sortDirection: undefined };
  });
};

export const menu = ({
  onDownload,
  onMarkRead,
}: {
  onDownload?: (ids: string[]) => void;
  onMarkRead?: (ids: string[]) => void;
}): Array<HeaderMenuOption> => {
  return [
    {
      id: HeaderMenuOptionId.DOWNLOAD_SELECTED,
      label: "Download Selected",
      onClick: (ids: Array<string>) => {
        if (onDownload) {
          onDownload(ids);
        }
      },
    },
    {
      id: HeaderMenuOptionId.MARK_READ,
      label: "Mark as Read",
      onClick: (ids: Array<string>) => {
        if (onMarkRead) {
          onMarkRead(ids);
        }
      },
    },
  ];
};
