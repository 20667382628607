import { IAGroupAllOption, IAGroupCreateOption, IAGroupUserOption } from "@app/models/Header";

class IAGroupUtils {
  public readonly defaultIAGroupOptions: [IAGroupAllOption, IAGroupCreateOption] = [
    {
      id: "ALL",
      label: "All Investor Accounts",
    },
    {
      id: "CREATE",
      label: "Create New Group",
    },
  ];

  public getHeaderIAGroupOptions(groups?: IAGroupUserOption[]) {
    return [...this.defaultIAGroupOptions, ...(groups ?? [])];
  }

  public getProfileIAGroupOptions(groups?: IAGroupUserOption[]) {
    return [this.defaultIAGroupOptions[0], ...(groups ?? [])];
  }
}

export default new IAGroupUtils();
