import { Term, TermAcceptPeriod, TermAssociation, TermStatus } from "@terms/models";

export const REPORTING_TERM_ID = "reporting-term";

export const reportingTerm: Term = {
  activatedDate: null,
  id: REPORTING_TERM_ID,
  accepted: false,
  name: "General Terms & Conditions",
  associationType: TermAssociation.Reporting,
  status: TermStatus.Active,
  whenAccepted: TermAcceptPeriod.Session,
  associations: [],
  text: "<p>U.S. federal securities laws prohibit you and your organization from trading in any public security or making investment decisions about any public security on the basis of information included in this report, including without limitation shares of The Carlyle Group Inc. (“Carlyle”). You agree and acknowledge that all information in this report is confidential and is subject to your existing contractual duty to protect the confidentiality of information that you receive about any investment fund managed by a Carlyle affiliate, including any portfolio investments of any such fund. If you are not already subject to a contractual duty of confidentiality relating to the information in this report, you hereby consent to protect the confidentiality of the information in this report and agree not to share the contents of this report with any other person and agree not to make any investment decisions or trades involving public securities on the basis of the information contained in this report.</p>",
};
