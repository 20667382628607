import React, { FunctionComponent, memo, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AsOfDateControlProps } from "@shared/components/AsOfDateControl/models";
import { Singleselect } from "@shared/components/Select";
import { ReactComponent as InfoIcon } from "@assets/svg/info_circle.svg";

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.info.main,
    "&:hover > path": {
      fill: theme.palette.info.main,
    },
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  root: {
    paddingRight: 3,
    "&&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiButtonBase-root": {
      paddingRight: 3,
    },
    "& .MuiSvgIcon-root": {
      width: 21,
      height: 21,
    },
  },
  checkboxContainer: {
    padding: theme.spacing(2, 0, 0, 5),
  },
  checkedbox: {
    backgroundColor: "transparent",
  },
  labelIconContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
  },
}));

const AsOfDateControl: FunctionComponent<AsOfDateControlProps> = ({
  years,
  onSelectOption,
  selectedOption,
  showCurrentCheckbox,
  current,
  testId,
}) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectOption("current", event.target.checked);
  };

  const selectedYear = useMemo(() => years.find((y) => y.id === selectedOption.year), [years, selectedOption]);
  const selectedQuarter = useMemo(
    () => selectedYear!.quarters.find((q) => q.id === selectedOption.quarter) || selectedYear!.quarters[0],
    [selectedYear, selectedOption]
  );

  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2} sx={{ [theme.breakpoints.down("sm")]: { flexDirection: "column" } }}>
      <Grid
        item
        xs={isSmall ? 12 : 6}
        sx={{
          [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
          },
        }}
      >
        <Box data-testid={testId ? `year-selector-${testId}` : "year-selector"}>
          <Singleselect
            label={selectedYear!.label}
            variant="underlined"
            testId={testId ? `${testId}-year` : "year"}
            displayCount={8}
            options={years}
            disabled={showCurrentCheckbox && current}
            onSelect={(option) => onSelectOption("year", option.id)}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={isSmall ? 12 : 6}
        sx={{
          [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
          },
        }}
      >
        <Box data-testid={testId ? `quarter-selector-${testId}` : "quarter-selector"}>
          <Singleselect
            label={selectedQuarter.label}
            variant="underlined"
            testId={testId ? `${testId}-quarter` : "quarter"}
            displayCount={8}
            options={selectedYear!.quarters}
            disabled={showCurrentCheckbox && current}
            onSelect={(option) => onSelectOption("quarter", option.id)}
          />
        </Box>
      </Grid>
      <Box width="100%" height={0} />
      {showCurrentCheckbox ? (
        <Grid container direction={"row"} className={classes.checkboxContainer}>
          <Checkbox
            checked={current}
            className={classes.root}
            onChange={handleCheckboxChange}
            name="checkedB"
            color="primary"
          />

          <Box className={classes.labelIconContainer}>
            <Box style={{ fontWeight: 600, fontSize: 12 }}>CURRENT</Box>
            <Tooltip
              title={
                "When data for the current quarter is not available, data from two previous quarters, if available, will be displayed."
              }
              placement="right"
            >
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default memo(AsOfDateControl);
