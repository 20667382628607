import React, { FunctionComponent } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SectionTab from "./SectionTab";
import { Tab, SectionTabsProps } from "./models";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(12.5),
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
}));

const SectionTabs: FunctionComponent<SectionTabsProps> = ({ tabs, activeTab, onTabClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {tabs.map((tab: Tab) => (
        <SectionTab key={tab} variant={tab === activeTab ? "contained" : "outlined"} onClick={() => onTabClick(tab)}>
          {tab}
        </SectionTab>
      ))}
    </Box>
  );
};

export default React.memo(SectionTabs);
