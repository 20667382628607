import React, { forwardRef, FunctionComponent, memo, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Dropdown, RenderToggleArgs } from "@shared/components/Dropdown";
import { ReactComponent as DropdownArrow } from "@assets/svg/arrow_dropdown.svg";
import { List } from "@shared/components/List";
import { HeaderMenuOption, HeaderMenuOptionId, HeaderMenuProps } from "@shared/components/DataGrid";
import { ToggleButtonProps } from "@shared/components/ToggleButton/models";

const useStyles = makeStyles<Theme, Pick<RenderToggleArgs, "open">>(() => ({
  arrow: {
    transition: "transform ease-in .25s",
    transform: ({ open }) => (open ? "rotate(180deg)" : "none"),
  },
}));

const DropdownArrowComponent = forwardRef<HTMLDivElement, Pick<ToggleButtonProps, "open" | "onClick">>(
  ({ open, onClick }, ref) => {
    const classes = useStyles({ open });

    return (
      <div ref={ref} className={classes.arrow} role="dropdownIndicator" onClick={onClick} data-testid="dropdown-arrow">
        <DropdownArrow />
      </div>
    );
  }
);

DropdownArrowComponent.displayName = "DropdownArrowComponent";

const HeaderMenu: FunctionComponent<HeaderMenuProps> = ({
  menu,
  isAnyRowSelected,
  toggleAllRowsSelected,
  selectedRowIds,
}) => {
  const defaultMenuOption: HeaderMenuOption = useMemo(
    () => ({
      id: HeaderMenuOptionId.TOGGLE_SELECT_ALL,
      label: isAnyRowSelected ? "Deselect All" : "Select All",
      onClick: () => toggleAllRowsSelected!(!isAnyRowSelected),
    }),
    [isAnyRowSelected, toggleAllRowsSelected]
  );

  const menuOptions = useMemo<Array<HeaderMenuOption>>(
    () => [
      ...menu!.map((menuOption) => {
        if (menuOption.id === HeaderMenuOptionId.DOWNLOAD_SELECTED) {
          return {
            ...menuOption,
            disabled: selectedRowIds.length === 0 || selectedRowIds.length > 50,
            tooltip: selectedRowIds.length > 50 ? "Please select 50 documents or fewer" : "",
          };
        }

        return menuOption;
      }),
      defaultMenuOption,
    ],
    [defaultMenuOption, menu, selectedRowIds.length]
  );

  return (
    <Dropdown
      width={185}
      placement="bottom-start"
      renderToggle={({ ref, open, toggleDropdown }) => (
        <DropdownArrowComponent ref={ref} open={open} onClick={toggleDropdown} />
      )}
    >
      {({ closeDropdown }) => (
        <List
          size="small"
          variant="singleselect"
          options={menuOptions}
          onOptionClick={(option) => {
            option.onClick(selectedRowIds);
            closeDropdown();
          }}
        />
      )}
    </Dropdown>
  );
};

export default memo<HeaderMenuProps>(HeaderMenu);
