import React, { FunctionComponent, memo, useCallback, useMemo, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import Collapse from "@mui/material/Collapse";
import { AccordionProps } from "@shared/components/Accordion/models";
import { ReactComponent as PlusIcon } from "@assets/svg/plus.svg";
import { ReactComponent as MinusIcon } from "@assets/svg/minus.svg";

const useStyles = makeStyles<Theme, { isControlledExternally: boolean }>((theme: Theme) => ({
  accordion: {
    width: "100%",
  },
  accordionTitle: {
    display: "flex",
    alignItems: "center",
    padding: "0 30px",
    width: "100%",
    cursor: "pointer",
    "&:hover $title": {
      color: theme.palette.info.main,
    },
    "&:hover $svgIconWrapper": {
      background: theme.palette.info.main,
    },
  },
  nonInteractiveSvg: {
    background: `${theme.palette.primary.main} !important`,
  },
  nonInteractive: {
    cursor: "default !important",
    color: "inherit !important",
  },
  title: {
    fontSize: 22,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: "0.44px",
    color: theme.palette.text.primary,
    transition: "0.15s ease-out",
  },
  svgIconWrapper: {
    borderRadius: "50%",
    marginLeft: "auto",
    backgroundColor: theme.palette.text.primary,
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.15s ease-out",
  },
  svgIcon: {
    width: 15,
    height: 15,
    color: theme.palette.common.white,
  },
  content: {
    marginTop: 34,
    width: "100%",
    letterSpacing: "0.44px",
    fontSize: "20px",
    color: theme.palette.grey[900],
    padding: "0 30px",
    overflow: "hidden",
  },
}));

const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const [activeInternal, setActiveInternal] = useState<boolean>(false);

  const isControlledExternally = useMemo(() => typeof props.active === "boolean", [props.active]);

  const active = useMemo(
    () => (isControlledExternally ? props.active : activeInternal),
    [isControlledExternally, props.active, activeInternal]
  );

  const classes = useStyles({ isControlledExternally });

  const toggleActive = useCallback(() => {
    if (isControlledExternally) return;

    setActiveInternal(!activeInternal);
  }, [activeInternal, setActiveInternal, isControlledExternally]);

  return (
    <section className={classes.accordion} data-testid="main-container">
      <div
        className={cn(classes.accordionTitle, isControlledExternally && classes.nonInteractive)}
        data-testid="clickable-container"
        onClick={toggleActive}
      >
        <h5
          className={cn(classes.title, isControlledExternally && classes.nonInteractive)}
          data-testid={`${props.testId}-question`}
        >
          {props.title}
        </h5>
        <div className={cn(classes.svgIconWrapper, isControlledExternally && classes.nonInteractiveSvg)}>
          {active ? (
            <MinusIcon className={classes.svgIcon} data-testid="minus-icon" />
          ) : (
            <PlusIcon className={classes.svgIcon} data-testid="plus-icon" />
          )}
        </div>
      </div>
      <Collapse in={active}>
        <div className={classes.content} data-testid={`${props.testId}-answer`}>
          {props.content}
        </div>
      </Collapse>
    </section>
  );
};

export default memo<AccordionProps>(Accordion);
