import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, get, isEmpty } from "lodash";
import { customFieldsMap, requiredFieldsPath } from "@investorRequest/constants";
import { CommonFields, CustomFields, InvestorRequestState, InvestorRequestType } from "../models";
import { sendInvestorRequestThunk } from "./thunks";

const initialState: InvestorRequestState = {
  open: false,
  requestType: null,
  commonFields: {
    selectedFunds: [],
    selectedAccounts: [],
    message: "",
    attachment: null,
  },
  customFields: {},
  retainedCustomFields: cloneDeep(customFieldsMap),
  loading: false,
  validationWarningShown: false,
};

const investorRequestSlice = createSlice({
  name: "investorRequest",
  initialState,
  reducers: {
    openInvestorRequestDialog: (
      state,
      action: PayloadAction<{ requestType: InvestorRequestType; customFields?: CustomFields } | undefined>
    ) => {
      state.open = true;

      if (!action.payload) return;

      state.requestType = action.payload.requestType;

      if (action.payload.customFields) {
        state.customFields = action.payload.customFields;

        state.commonFields.selectedAccounts = action.payload.customFields.filterParamsInvestorAccounts;
        state.commonFields.selectedFunds = action.payload.customFields.filterParamsFunds;
      } else {
        state.customFields = customFieldsMap[action.payload.requestType];
      }
    },
    closeInvestorRequestDialog: (state) => {
      state.open = false;
    },
    selectRequestType: (state, action: PayloadAction<{ requestType: InvestorRequestType }>) => {
      if (state.requestType) {
        state.retainedCustomFields[state.requestType] = {
          ...state.customFields,
        };
      }

      state.validationWarningShown = false;
      state.requestType = action.payload.requestType;
      state.customFields = state.retainedCustomFields[action.payload.requestType];
    },
    updateCommonRequestData: (state, action: PayloadAction<Partial<CommonFields>>) => {
      state.commonFields = {
        ...state.commonFields,
        ...action.payload,
      };

      if (state.validationWarningShown) {
        state.validationWarningShown = requiredFieldsPath[state.requestType!].some((path) => isEmpty(get(state, path)));
      }
    },
    updateCustomRequestData: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.customFields = {
        ...state.customFields,
        ...action.payload,
      };

      if (state.validationWarningShown) {
        state.validationWarningShown = requiredFieldsPath[state.requestType!].some((path) => isEmpty(get(state, path)));
      }
    },
    validateFields: (state) => {
      state.validationWarningShown = requiredFieldsPath[state.requestType!].some((path) => isEmpty(get(state, path)));
    },
    resetInvestorRequestState: (state) => {
      state.requestType = initialState.requestType;
      state.commonFields = initialState.commonFields;
      state.customFields = initialState.customFields;
      state.validationWarningShown = initialState.validationWarningShown;
      state.retainedCustomFields = initialState.retainedCustomFields;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendInvestorRequestThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendInvestorRequestThunk.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) state.open = false;
      })
      .addCase(sendInvestorRequestThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  openInvestorRequestDialog,
  closeInvestorRequestDialog,
  selectRequestType,
  updateCommonRequestData,
  updateCustomRequestData,
  validateFields,
  resetInvestorRequestState,
} = investorRequestSlice.actions;
export default investorRequestSlice.reducer;
