import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { updateReadFlag } from "@documents/store/thunks/updateReadFlag";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { getDocumentFilesMap } from "@documents/utils/getDocumentFilesMap";
import TermsNotAcceptedError from "@terms/utils/TermsNotAcceptedError";
import { downloadAndArchiveDocuments } from "./downloadAndArchiveDocuments";

export const downloadSelectedDocuments = createAsyncThunk<void, DocumentRow[], ThunkApiConfig>(
  "documents/downloadSelectedDocuments",
  async (rows, { dispatch, rejectWithValue }) => {
    try {
      const documentFiles = await dispatch(downloadAndArchiveDocuments(rows));
      const documentFilesMap = getDocumentFilesMap(documentFiles);

      const unreadDocumentsIds = rows
        .filter(({ id, markedRead }) => documentFilesMap[id] && !markedRead)
        .map(({ id }) => id);

      dispatch(updateReadFlag(unreadDocumentsIds));

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: "Document download successful.",
        })
      );
    } catch (error) {
      if (error instanceof TermsNotAcceptedError) {
        return;
      }

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "The document download failed",
        })
      );

      return rejectWithValue("Failed to download document");
    }
  }
);
