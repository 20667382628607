import { configureStore } from "@reduxjs/toolkit";
import RequestService from "@services/RequestService";
import rootReducer from "@store/rootReducer";
import listenerMiddleware from "@store/listenerMiddleware";

const requestService = new RequestService();

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: { extraArgument: requestService.request },
      serializableCheck: false,
      immutableCheck: false,
    }).concat([listenerMiddleware.middleware]),
});

requestService.store = store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export default store;
