import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { ServerTerm } from "@terms/models";

export const fetchUserTerms = createAsyncThunk<ServerTerm[], void, ThunkApiConfig>(
  "terms/fetchUserTerms",
  async (_, { extra, rejectWithValue }) => {
    try {
      const response = await extra<ServerTerm[]>("/entitlements/v1/user/Terms");

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to load user terms");
    }
  }
);
