import { createAsyncThunk } from "@reduxjs/toolkit";
import { InsightsHubArticlesListResponse } from "@insightsHub/models";
import { insightsHubSelector } from "@insightsHub/store/selectors";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ThunkApiConfig } from "@shared/models/redux";
import { callInsightsHubApi } from "@insightsHub/store/thunks/callInsightsHubApi";

export const fetchInsightsHubArticles = createAsyncThunk<InsightsHubArticlesListResponse, void, ThunkApiConfig>(
  "insightsHub/fetchInsightsHubArticles",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callInsightsHubApi());
    } catch (error) {
      console.error(error);

      dispatch(openNotificationThunk({ variant: "warning", text: "Failed to load Featured Insights" }));

      return rejectWithValue("Failed to fetch");
    }
  },
  {
    condition: (_, { getState }) => {
      const { data, loading } = insightsHubSelector(getState());

      return !data && !loading;
    },
  }
);
