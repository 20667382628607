import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { callFetchImpersonatedUserAPI } from "@impersonateUser/store/thunks/callImpersonatedUserDataAPI";
import { callGetUserPreferencesListAPI } from "@user/store/thunks/callGetUserPreferencesListAPI";
import { startImpersonationSession } from "@impersonateUser/store/actions";
import { convertPreferencesArrayToMap } from "@user/utils/convertPreferencesArrayToMap";
import { UserPreferenceName } from "@user/models";
import { callGetUserSegmentFunction } from "@user/store/thunks/callGetUserSegmentFunction";

export const initializeImpersonationSession = createAsyncThunk<void, string, ThunkApiConfig>(
  "impersonation/initializeImpersonationSession",
  async (email, { dispatch, rejectWithValue }) => {
    try {
      const [impersonatedUserData] = await dispatch(callFetchImpersonatedUserAPI(email));

      const [preferencesResponse, segmentFunctionResponse] = await Promise.all([
        dispatch(callGetUserPreferencesListAPI(impersonatedUserData.userId)),
        dispatch(callGetUserSegmentFunction(impersonatedUserData.userId)),
      ]);

      const preferences = convertPreferencesArrayToMap(preferencesResponse);

      dispatch(
        startImpersonationSession({
          id: String(impersonatedUserData.userId),
          email: impersonatedUserData.email,
          name: `${impersonatedUserData.firstName} ${impersonatedUserData.lastName}`,
          isAdmin: impersonatedUserData.isAdmin,
          selectedAccountGroup: preferences[UserPreferenceName.UserDefaultInvestorsGroup],
          segmentFunction: segmentFunctionResponse,
          preferences,
        })
      );
    } catch (error) {
      console.error(error);

      return rejectWithValue("Impersonation failed");
    }
  }
);
