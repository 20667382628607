import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CalendarEventApiResponseItem,
  MyCalendarEventColumn,
  MyCalendarState,
  RegistrationStatus,
} from "@myCalendar/models";
import { fetchCalendarEvents } from "@myCalendar/store/thunks/fetchCalendarEvents";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { SortDirection } from "@shared/models/general";
import { ToggleDocumentActionItemFlagPayload } from "@documents/models/api.models";
import { fetchEventDocuments } from "@myCalendar/store/thunks/fetchEventDocuments";
import { updateEventDocumentsReadFlag } from "@myCalendar/store/thunks/updateEventDocumentsReadFlag";
import CommonUtils from "@shared/utils/CommonUtils";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { normalizeDocumentResponse } from "@documents/utils";
import { downloadDocument } from "@documents/store/thunks/downloadDocument";
import { downloadSelectedEventDocuments } from "@myCalendar/store/thunks/downloadSelectedEventDocuments";

const initialState: MyCalendarState = {
  events: ReduxUtils.getAsyncSlice<CalendarEventApiResponseItem[]>(),
  filters: {
    months: [],
    keyword: "",
    registrationStatus: RegistrationStatus.ALL,
  },
  sort: {
    key: MyCalendarEventColumn.Date,
    direction: SortDirection.DESC,
  },
  eventPageDetails: {
    eventDocuments: ReduxUtils.getAsyncSlice<DocumentRow[]>(),
    sort: {
      key: "title",
      direction: SortDirection.ASC,
    },
  },
  highlightedVideo: null,
};

export const myCalendarSlice = createSlice({
  name: "myCalendar",
  initialState,
  reducers: {
    resetState: () => initialState,
    updateCalendarFilters: (state, action: PayloadAction<Partial<MyCalendarState["filters"]>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    updateSort: (state, action: PayloadAction<MyCalendarState["sort"]>) => {
      state.sort = action.payload;
    },
    resetPastEvent: (state) => {
      state.eventPageDetails = initialState.eventPageDetails;
    },
    setHighlightedVideo: (state, action) => {
      state.highlightedVideo = action.payload;
    },
    resetHighlightedVideo: (state) => {
      state.highlightedVideo = initialState.highlightedVideo;
    },

    updateEventDocumentsActionItemFlagState: (state, action: PayloadAction<ToggleDocumentActionItemFlagPayload>) => {
      const { documentType, id, actionItem } = action.payload;

      if (documentType) {
        const documents = state.eventPageDetails.eventDocuments.data!;

        state.eventPageDetails.eventDocuments.data! = documents.map((document) => {
          if (document.id === id) {
            document.actionItem = actionItem;
          }

          return document;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCalendarEvents.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.events);
      })
      .addCase(fetchCalendarEvents.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.events, action.payload);
      })
      .addCase(fetchCalendarEvents.rejected, (state, action) => {
        ReduxUtils.defaultRejectedActionHandler(state.events, action.error.message);
      })
      .addCase(fetchEventDocuments.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state.eventPageDetails.eventDocuments);
      })
      .addCase(fetchEventDocuments.fulfilled, (state, action) => {
        const { sortDirection: direction, sortKey: key } = action.meta.arg;

        state.eventPageDetails.sort = {
          direction,
          key,
        };

        ReduxUtils.defaultFulfilledActionHandler(
          state.eventPageDetails.eventDocuments,
          action.payload.map(normalizeDocumentResponse)
        );
      })
      .addCase(updateEventDocumentsReadFlag.pending, (state, action) => {
        const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg.documentIds);

        state.eventPageDetails.eventDocuments.data =
          state.eventPageDetails.eventDocuments.data?.map((record) => ({
            ...record,
            markedRead: idsToMarkReadMap[record.id!] ?? record.markedRead,
          })) ?? [];
      })
      .addCase(updateEventDocumentsReadFlag.rejected, (state, action) => {
        const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg.documentIds);

        state.eventPageDetails.eventDocuments.data =
          state.eventPageDetails.eventDocuments.data?.map((record) => ({
            ...record,
            markedRead: idsToMarkReadMap[record.id!] ? false : record.markedRead,
          })) ?? [];
      })
      .addCase(downloadDocument.pending, (state, action) => {
        const row = state.eventPageDetails.eventDocuments.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = true;
        }
      })
      .addCase(downloadDocument.fulfilled, (state, action) => {
        const row = state.eventPageDetails.eventDocuments.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = false;
        }
      })
      .addCase(downloadDocument.rejected, (state, action) => {
        const row = state.eventPageDetails.eventDocuments.data?.find((row) => row.id === action.meta.arg.id);

        if (row) {
          row.isLoading = false;
        }
      })
      .addCase(downloadSelectedEventDocuments.pending, (state, action) => {
        const selectedRows = state.eventPageDetails.eventDocuments.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = true));
        }
      })
      .addCase(downloadSelectedEventDocuments.fulfilled, (state, action) => {
        const selectedRows = state.eventPageDetails.eventDocuments.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      })
      .addCase(downloadSelectedEventDocuments.rejected, (state, action) => {
        const selectedRows = state.eventPageDetails.eventDocuments.data?.filter((row) =>
          action.meta.arg.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      });
  },
});

export const {
  resetState,
  updateCalendarFilters,
  updateSort,
  resetPastEvent,
  setHighlightedVideo,
  resetHighlightedVideo,
  updateEventDocumentsActionItemFlagState,
} = myCalendarSlice.actions;

export default myCalendarSlice.reducer;
