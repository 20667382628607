import { FinalTermRule, ImmediateTermRule, TermAssociation } from "@terms/models";
import { AppRoute } from "@app/models/AppRoute";
import { fetchSiteWideEntities } from "@store/Entities/thunks";

export const signOutIfSiteWideTermDeclined: ImmediateTermRule = {
  condition: (currentTerm) => !currentTerm.accepted && currentTerm.associationType === TermAssociation.SiteWide,
  action: ({ history }) => history.push(AppRoute.SignOut),
};

export const fetchApplicationWideEntitiesIfAllSiteWideTermsAccepted: ImmediateTermRule = {
  condition: (currentTerm, outstandingTerms) => {
    if (currentTerm.accepted && currentTerm.associationType === TermAssociation.SiteWide) {
      const siteWideTerms = outstandingTerms.filter((term) => term.associationType === TermAssociation.SiteWide);

      return siteWideTerms.every((term) => (term.id === currentTerm.id ? currentTerm.accepted : term.accepted));
    }

    return false;
  },
  action: ({ dispatch }) => dispatch(fetchSiteWideEntities(false)),
};

export const fetchApplicationWideEntitiesIfThereIsNoSiteWideTerms: FinalTermRule = {
  condition: (outstandingTerms) => outstandingTerms.every((term) => term.associationType !== TermAssociation.SiteWide),
  action: ({ dispatch }) => dispatch(fetchSiteWideEntities(false)),
};

export const redirectToHomePageIfReportingTermDeclinedOnAppInitialize: FinalTermRule = {
  condition: (terms) => {
    const reportingTerm = terms.find((term) => term.associationType === TermAssociation.Reporting);

    if (reportingTerm) {
      return !reportingTerm.accepted;
    } else {
      return false;
    }
  },
  action: ({ history }) => history.replace(AppRoute.Home),
};

export const redirectToHomePageIfFundTermDeclinedOnAppInitialize: FinalTermRule = {
  condition: (outstandingTerms) => {
    const [fundId] = window.location.pathname.split("/").reverse();

    if (!fundId) return false;

    const fundTerm = outstandingTerms.find(
      (term) => term.associationType === TermAssociation.EntitledEntity && term.associations.includes(fundId)
    );

    if (fundTerm) {
      return !fundTerm.accepted;
    } else {
      return false;
    }
  },
  action: ({ history }) => history.replace(AppRoute.Home),
};
