import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/index";
import { VideoState } from "@videos/models";

export const videoNameSelector = (state: RootState): VideoState["videoName"] => state.videos.videoName;

export const videoIdSelector = (state: RootState): VideoState["videoId"] => state.videos.videoId;

export const videoTokenSelector = (state: RootState): VideoState["videoToken"] => state.videos.videoToken;

export const openVideoDialogFlagSelector = (state: RootState): VideoState["isOpenVideoDialog"] =>
  state.videos.isOpenVideoDialog;

export const videoSelector = createSelector(
  videoNameSelector,
  videoIdSelector,
  videoTokenSelector,
  openVideoDialogFlagSelector,
  (videoName, videoId, videoToken, isOpenVideoDialog) => ({
    videoName,
    videoId,
    videoToken,
    isOpenVideoDialog,
  })
);
