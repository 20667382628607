import React, { FunctionComponent, memo } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Button } from "@shared/components";
import { OutstandingTermStatus } from "@terms/models";
import { useMobileLayout } from "@app/hooks";

interface Props {
  onButtonClick(status: OutstandingTermStatus): void;
  allPending: boolean;
  clickedHandleAll: boolean;
}
const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    "& > button": {
      fontSize: 14,
      letterSpacing: 1.4,
      padding: theme.spacing(0, 7.5),
    },
  },
}));

const TermsDialogActions: FunctionComponent<Props> = ({ onButtonClick, allPending, clickedHandleAll }) => {
  const isMobile = useMobileLayout();
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%",
        pl: 0,
        ml: 0,
        [theme.breakpoints.down("sm")]: {
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "auto",
        },
      }}
    >
      <Box className={classes.buttonWrapper}>
        <Button
          fullWidth
          size="medium"
          variant="outlined"
          shape="rounded"
          onClick={() => onButtonClick(OutstandingTermStatus.Declined)}
        >
          {allPending || clickedHandleAll ? (
            <>
              I DISAGREE {isMobile && <br />}
              WITH ALL TERMS
            </>
          ) : (
            <>I DISAGREE WITH {isMobile && <br />} REMAINING TERMS</>
          )}
        </Button>
      </Box>

      <Box
        sx={{
          [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(6),
          },
        }}
        className={classes.buttonWrapper}
      >
        <Button
          autoFocus
          fullWidth
          size="medium"
          variant="contained"
          shape="rounded"
          onClick={() => onButtonClick(OutstandingTermStatus.Accepted)}
        >
          {allPending || clickedHandleAll ? (
            <>
              I AGREE {isMobile && <br />}
              WITH ALL TERMS
            </>
          ) : (
            <>I AGREE WITH {isMobile && <br />} REMAINING TERMS</>
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default memo<Props>(TermsDialogActions);
