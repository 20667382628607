import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "@mui/x-date-pickers";
import ToggleButton from "@shared/components/ToggleButton/ToggleButton";
import { ToggleButtonVariant } from "@shared/components/ToggleButton/models";
import { DateRangePickerPlaceholders } from "./constants";
import { DateRangePickerProps } from "./models";

const useStyles = makeStyles(() => ({
  root: {
    height: 55,
    fontSize: 18,
    display: "flex",
    alignItems: "center",
  },
}));

const TextField = ({ inputProps, value, label, id, setOpen, isOpen, testId }: any) => {
  const classes = useStyles();

  return (
    <Box
      id={id}
      className={classes.root}
      data-testid={`${testId}-text-field`}
      ref={inputProps!.ref}
      onClick={() => setOpen((open: boolean) => !open)}
    >
      <ToggleButton open={isOpen} variant={ToggleButtonVariant.Underlined} testId={`${testId}-toggle-button`}>
        {value || label}
      </ToggleButton>
    </Box>
  );
};

const DateRangePicker = ({
  startDate,
  endDate,
  testId,
  dateRangeRequire = true,
  handleStartDateChange,
  handleEndDateChange,
}: DateRangePickerProps) => {
  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<HTMLInputElement>(null);
  const currentDate = useMemo(() => new Date(), []);
  const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);
  const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);

  useEffect(() => {
    if (startDate && !endDate) {
      handleEndDateChange(currentDate);
    }
    if (endDate && !startDate) {
      handleStartDateChange(endDate);
    }
  }, [startDate, endDate, currentDate, handleEndDateChange, handleStartDateChange]);

  return (
    <Grid container spacing={7}>
      <Grid item xs={dateRangeRequire ? 6 : 12} data-testid="start-date-picker">
        <DatePicker
          open={isStartDateDropdownOpen}
          value={startDate || endDate}
          onChange={handleStartDateChange}
          orientation="landscape"
          minDate={new Date(1980, 1, 1)}
          maxDate={endDate || currentDate}
          format="MM/dd/yyyy"
          label={DateRangePickerPlaceholders.startDate}
          inputRef={startRef}
          slots={{ textField: TextField }}
          slotProps={{
            textField: {
              setOpen: setIsStartDateDropdownOpen,
              isOpen: isStartDateDropdownOpen,
              testId: testId && `${testId}-start`,
            } as any,
            popper: { anchorEl: startRef.current, placement: "bottom" },
          }}
          onOpen={() => setIsStartDateDropdownOpen(true)}
          onClose={() => setIsStartDateDropdownOpen(false)}
        />
      </Grid>
      {dateRangeRequire && (
        <Grid item xs={6} data-testid="end-date-picker">
          <DatePicker
            open={isEndDateDropdownOpen}
            value={endDate || (startDate && currentDate)}
            onChange={handleEndDateChange}
            orientation="landscape"
            minDate={startDate || new Date(1980, 1, 1)}
            maxDate={currentDate}
            format="MM/dd/yyyy"
            label={DateRangePickerPlaceholders.endDate}
            inputRef={endRef}
            slots={{ textField: TextField }}
            slotProps={{
              textField: {
                setOpen: setIsEndDateDropdownOpen,
                isOpen: isEndDateDropdownOpen,
                testId: testId && `${testId}-end`,
              } as any,
              popper: {
                anchorEl: endRef.current,
                placement: "bottom",
              },
            }}
            onOpen={() => setIsEndDateDropdownOpen(true)}
            onClose={() => setIsEndDateDropdownOpen(false)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memo<DateRangePickerProps>(DateRangePicker);
