import { Box, Grid } from "@mui/material";
import React, { memo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import { ListOptionBase, MultiselectOption } from "@shared/components/List";
import { Chip } from "@shared/components/Chip";

export const useStyles = makeStyles((theme) => ({
  chips: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(5),
  },
  actionText: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    transition: "all ease-in .2s",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.warning.main,
    },
  },
  actionTextDisabled: {
    cursor: "default",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  clearAll: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(4.5),
    paddingTop: theme.spacing(4),
  },
}));

interface MultiselectedValuesProps<ListOption extends ListOptionBase> {
  selectedOptions: ListOption[] | string[];
  previewExpandable: boolean | undefined;
  entity: string;
  onClearAll(): void;
  optionsLength: number;
  onSelect(option: ListOption | string): void;
  testId?: string;
}

const MULTISELECT_PREVIEW = 5;

const SelectedOptionsList = <ListOption extends MultiselectOption>({
  selectedOptions,
  previewExpandable,
  entity,
  onClearAll,
  optionsLength,
  onSelect,
  testId,
}: MultiselectedValuesProps<ListOption>) => {
  const classes = useStyles();
  const [previewCount, setPreviewCount] = useState(MULTISELECT_PREVIEW);

  return (
    <Box className={classes.chips}>
      <Grid container spacing={2} data-testid={testId ? `${testId}-multiselect-selected` : "multiselect-selected"}>
        {selectedOptions.slice(0, previewCount).map((option) => (
          <Grid item xs={6} key={typeof option === "string" ? option : option.id}>
            <Chip
              fullWidth
              label={typeof option === "string" ? option : option.label}
              onDelete={() => onSelect(typeof option === "string" ? option : { ...option, selected: false })}
            />
          </Grid>
        ))}
        {selectedOptions.length > MULTISELECT_PREVIEW && previewCount === MULTISELECT_PREVIEW && (
          <Grid
            item
            className={cn(classes.actionText, !previewExpandable && classes.actionTextDisabled)}
            onClick={() => setPreviewCount(optionsLength)}
            data-testid={testId ? `${testId}-multiselect-many-options` : "multiselect-many-option"}
          >
            +{selectedOptions.length - MULTISELECT_PREVIEW} more selected
          </Grid>
        )}
      </Grid>
      <Box className={classes.clearAll}>
        <Box
          className={classes.actionText}
          onClick={onClearAll}
          data-testid={testId ? `${testId}-multiselect-clear-all` : "multiselect-clear-all"}
        >
          Clear all selected {entity}s
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SelectedOptionsList) as typeof SelectedOptionsList;
