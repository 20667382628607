import React, { memo, useMemo } from "react";
import { Box, Slide, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cn from "classnames";
import { useAppSelector, useGetNotificationSnackbarTopOffset } from "@app/hooks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { NOTIFICATION_BAR_HEIGHT } from "@app/constants/header";

const useStyles = makeStyles<Theme, { topOffset: number }>((theme) => ({
  root: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: ({ topOffset }) => topOffset,
    left: 0,
    width: "100vw",
    height: NOTIFICATION_BAR_HEIGHT,
    fontSize: 15,
    zIndex: theme.zIndex.snackbar,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
}));

const NotificationSnackbar = () => {
  const { isNotificationOpen, variant, text, exclamation, isImpersonating } = useAppSelector((state) => {
    const { isImpersonating } = impersonationStateSelector(state);

    return {
      isImpersonating,
      isNotificationOpen: state.notification.open,
      variant: state.notification.variant,
      text: state.notification.text,
      exclamation: state.notification.exclamation,
    };
  });

  const topOffset = useGetNotificationSnackbarTopOffset({
    isNotificationOpen,
    isImpersonating,
  });

  const classes = useStyles({ topOffset });

  const className = useMemo(
    () =>
      cn({
        [classes.root]: true,
        [classes.info]: variant === "info",
        [classes.warning]: variant === "warning",
      }),
    [classes, variant]
  );

  return (
    <Slide in={isNotificationOpen} direction="down">
      <div className={className}>
        <Box fontWeight={700} mr={1}>
          {exclamation}
        </Box>
        {text}
      </div>
    </Slide>
  );
};

export default memo(NotificationSnackbar);
