export interface NoDataTileProps {
  noDataMessage: NoDataMessage;
  onContactUsClick?: () => void;
}

export enum NoDataMessage {
  General = "No data available or access not provisioned.",
  Documents = "No documents data available or access not provisioned.",
  Reporting = "No reporting data available or access not provisioned.",
  Description = "If you believe you have received this message in error, please",
  MyCalendarFilter = "No events meet these filter parameters. Please adjust your filters and search again.",
  ContactUs = "contact us.",
  DataRoomsSegments = "No investment opportunities available for <segment>. Please contact your Relationship Manager to learn more about potential offerings.",
}
