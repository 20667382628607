import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { DonutTooltipProps } from "@shared/components/Donut/models";
import NumberUtils from "@shared/utils/NumberUtils";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 20,
    letterSpacing: 1,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 5,
    padding: theme.spacing(2.5, 5),
  },
}));

const CustomTooltip = ({ active, payload, numberFormat }: DonutTooltipProps) => {
  const classes = useStyles();

  if (!active) return null;

  return (
    <div className={classes.container}>
      <span>
        {payload![0].name} {NumberUtils.formatNumber(payload![0].value, numberFormat)} %
      </span>
    </div>
  );
};

export default CustomTooltip;
