import { AppStage, FeatureConfig, ToggledFeature } from "@shared/models/featureToggle";

class FeatureToggleService {
  private readonly [ToggledFeature.DefaultLandingPage]: FeatureConfig = {
    [AppStage.Dev]: false,
    [AppStage.Qa]: false,
    [AppStage.Sec]: false,
    [AppStage.Uat]: false,
    [AppStage.Prod]: false,
  };

  private readonly [ToggledFeature.MyCalendar]: FeatureConfig = {
    [AppStage.Dev]: true,
    [AppStage.Qa]: true,
    [AppStage.Sec]: true,
    [AppStage.Uat]: true,
    [AppStage.Prod]: true,
  };

  private readonly [ToggledFeature.GoogleAnalytics]: FeatureConfig = {
    [AppStage.Dev]: true,
    [AppStage.Qa]: true,
    [AppStage.Sec]: true,
    [AppStage.Uat]: false,
    [AppStage.Prod]: false,
  };

  public isFeatureEnabled(feature: ToggledFeature) {
    return this[feature][process.env.REACT_APP_STAGE as AppStage];
  }
}

export default new FeatureToggleService();
