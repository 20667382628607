import { createAsyncThunk } from "@reduxjs/toolkit";
import { InitiateTermsArgs, TermRuleConfig } from "@terms/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { termsSelector } from "@terms/store/selectors";
import { prepareOutstandingTerms } from "@terms/utils";
import { openTermsDialog, pushFinalRulesToExecute } from "@terms/store/ducks";
import { termsRulesMap } from "@terms/constants";

const initiateTermsPopup = createAsyncThunk<void, InitiateTermsArgs, ThunkApiConfig>(
  "terms/initiateTermsPopup",
  (config, { dispatch, getState }) => {
    const { data: termsData } = termsSelector(getState());

    const outstandingTerms = prepareOutstandingTerms(termsData!, config.associations || {});

    const rules: TermRuleConfig = termsRulesMap[config.type](config.associations, config.extraArgs);

    if (outstandingTerms.length) {
      dispatch(openTermsDialog({ outstandingTerms, rules }));
    } else {
      dispatch(pushFinalRulesToExecute(rules.afterAll));
    }
  }
);

export default initiateTermsPopup;
