import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataRoomState, EntityDocumentType } from "@dataRooms/models";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { ToggleDocumentActionItemFlagPayload } from "@documents/models/api.models";
import { DataGridAsyncSlice } from "@shared/models/redux";
import { updateDataRoomsDocumentReadFlag } from "@dataRooms/store/thunks/updateDataRoomsDocumentReadFlag";
import CommonUtils from "@shared/utils/CommonUtils";
import { SortDirection } from "@shared/models/general";
import { SegmentTab } from "@dataRooms/constants/SegmentTabs";
import { DocumentRow } from "@documents/models/dataGrid.models";
import { downloadDocument } from "@documents/store/thunks/downloadDocument";
import { downloadSelectedDataRoomsDocuments } from "@dataRooms/store/thunks/downloadSelectedDataRoomsDocuments";
import { fetchEntityDocumentTypes } from "./thunks/fetchEntityDocumentTypes";
import { fetchEntityDocuments } from "./thunks/fetchEntityDocuments";

const initialState: DataRoomState = {
  entityDocumentTypes: ReduxUtils.getAsyncSlice<EntityDocumentType[]>(),
  entityDocuments: ReduxUtils.getAsyncSlice<Record<string, DataGridAsyncSlice<DocumentRow[]>>>(),
  highlightedVideo: null,
  sort: {
    key: "title",
    direction: SortDirection.ASC,
  },
  searchKey: "",
  activeSegmentTab: SegmentTab.ViewAll,
};

export const dataRoomSlice = createSlice({
  name: "dataRooms",
  initialState,
  reducers: {
    resetState: (state) => {
      state.entityDocumentTypes = initialState.entityDocumentTypes;
      state.entityDocuments = initialState.entityDocuments;
    },
    setEntityDocumentAsRead: (state, action) => {
      const { id, documentTypeId } = action.payload;

      state.entityDocuments.data?.[documentTypeId]?.data?.forEach((document) => {
        if (document.id === id) {
          document.markedRead = true;
        }
      });
    },
    updateActionItemFlagState: (state, action: PayloadAction<ToggleDocumentActionItemFlagPayload>) => {
      const { documentType, id, actionItem } = action.payload;

      if (documentType) {
        const documents = state.entityDocuments.data![documentType]!.data!;

        state.entityDocuments.data![documentType]!.data = documents.map((document) => {
          if (document.id === id) {
            document.actionItem = actionItem;
          }

          return document;
        });
      }
    },
    setHighlightedVideo: (state, action) => {
      state.highlightedVideo = action.payload;
    },
    resetHighlightedVideo: (state) => {
      state.highlightedVideo = initialState.highlightedVideo;
    },
    updateSort: (state, action: PayloadAction<DataRoomState["sort"]>) => {
      state.sort = action.payload;
    },
    updateSearchKey: (state, action: PayloadAction<DataRoomState["searchKey"]>) => {
      state.searchKey = action.payload;
    },
    setActiveSegmentTab: (state, action: PayloadAction<DataRoomState["activeSegmentTab"]>) => {
      state.activeSegmentTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntityDocumentTypes.pending, (state) => {
      ReduxUtils.defaultPendingActionHandler(state.entityDocumentTypes);
    });

    builder.addCase(fetchEntityDocumentTypes.fulfilled, (state, action) => {
      ReduxUtils.defaultFulfilledActionHandler(state.entityDocumentTypes, action.payload);

      state.entityDocuments.loading = true;
      state.entityDocuments.data = action.payload.reduce((result, { documentTypeId }) => {
        return {
          ...result,
          [documentTypeId]: ReduxUtils.getDataGridAsyncSlice(),
        };
      }, {});
    });

    builder.addCase(fetchEntityDocumentTypes.rejected, (state, action) => {
      ReduxUtils.defaultRejectedActionHandler(state.entityDocumentTypes, action.payload as string);
    });

    builder.addCase(fetchEntityDocuments.pending, (state) => {
      ReduxUtils.defaultPendingActionHandler(state.entityDocuments);

      const entityDocuments = Object.values(state.entityDocuments.data ?? {});

      entityDocuments.forEach((dataGridSlice) => {
        dataGridSlice.pagination.start = 1;
      });
    });

    builder.addCase(fetchEntityDocuments.fulfilled, (state, action) => {
      const { sortDirection: direction, sortKey: key } = action.meta.arg;

      state.entityDocuments.loading = false;
      state.entityDocuments.data = Object.entries(action.payload).reduce(
        (result, [typeId, documents]) => ({
          ...result,
          [typeId]: {
            ...result?.[typeId],
            data: documents,
            loading: false,
            sort: {
              direction,
              key,
            },
          },
        }),
        state.entityDocuments.data as Record<string, DataGridAsyncSlice<DocumentRow[]>>
      );
    });

    builder.addCase(fetchEntityDocuments.rejected, (state, action) => {
      ReduxUtils.defaultRejectedActionHandler(state.entityDocuments, action.payload as string);
    });
    builder.addCase(updateDataRoomsDocumentReadFlag.pending, (state, action) => {
      const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg.documentIds);
      const documentType = String(action.meta.arg.documentType);
      const documents = state.entityDocuments.data![documentType]!.data!;

      state.entityDocuments.data![documentType]!.data =
        documents.map((document) => ({
          ...document,
          markedRead: idsToMarkReadMap[String(document.id)!] ?? document.markedRead,
        })) ?? [];
    });
    builder
      .addCase(updateDataRoomsDocumentReadFlag.rejected, (state, action) => {
        const idsToMarkReadMap = CommonUtils.convertArrayToMap(action.meta.arg.documentIds);

        const documentType = String(action.meta.arg.documentType);
        const documents = state.entityDocuments.data![documentType]!.data!;

        state.entityDocuments.data![documentType]!.data =
          documents.map((document) => ({
            ...document,
            markedRead: idsToMarkReadMap[document.id!] ? false : document.markedRead,
          })) ?? [];
      })
      .addCase(downloadDocument.pending, (state, action) => {
        if (action.meta.arg.documentTypeId) {
          const typeId = String(action.meta.arg.documentTypeId);
          const row = state.entityDocuments.data?.[typeId].data?.find((row) => row.id === action.meta.arg.id);

          if (row) {
            row.isLoading = true;
          }
        }
      })
      .addCase(downloadDocument.fulfilled, (state, action) => {
        if (action.meta.arg.documentTypeId) {
          const typeId = String(action.meta.arg.documentTypeId);
          const row = state.entityDocuments.data?.[typeId].data?.find((row) => row.id === action.meta.arg.id);

          if (row) {
            row.isLoading = false;
          }
        }
      })
      .addCase(downloadDocument.rejected, (state, action) => {
        if (action.meta.arg.documentTypeId) {
          const typeId = String(action.meta.arg.documentTypeId);
          const row = state.entityDocuments.data?.[typeId].data?.find((row) => row.id === action.meta.arg.id);

          if (row) {
            row.isLoading = false;
          }
        }
      })
      .addCase(downloadSelectedDataRoomsDocuments.pending, (state, action) => {
        const selectedRows = state.entityDocuments.data![String(action.meta.arg.documentType)].data?.filter((row) =>
          action.meta.arg.rows.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = true));
        }
      })
      .addCase(downloadSelectedDataRoomsDocuments.fulfilled, (state, action) => {
        const selectedRows = state.entityDocuments.data![String(action.meta.arg.documentType)].data?.filter((row) =>
          action.meta.arg.rows.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      })
      .addCase(downloadSelectedDataRoomsDocuments.rejected, (state, action) => {
        const selectedRows = state.entityDocuments.data![String(action.meta.arg.documentType)].data?.filter((row) =>
          action.meta.arg.rows.some((document) => document.id === row.id)
        );

        if (selectedRows) {
          selectedRows.forEach((row) => (row.isLoading = false));
        }
      });
  },
});

export const {
  resetState,
  setEntityDocumentAsRead,
  updateActionItemFlagState,
  setHighlightedVideo,
  resetHighlightedVideo,
  updateSort,
  updateSearchKey,
  setActiveSegmentTab,
} = dataRoomSlice.actions;

export default dataRoomSlice.reducer;
