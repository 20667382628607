import React, { FunctionComponent, memo, PropsWithChildren, useCallback, useState } from "react";
import { Box, ClickAwayListener, Fade, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { AppRoute } from "@app/models/AppRoute";
import ReportingDrawerRoutes from "@reporting/components/ReportingDrawerRoutes";
import { ReactComponent as SideBarArrow } from "@assets/svg/arrow_sidebar.svg";
import { APP_NAVIGATION_HEIGHT } from "@app/constants/header";
import { ReportingNavigationSection } from "@reporting/models";

interface Props {
  routes: Array<ReportingNavigationSection>;
  activeRoute: AppRoute;
}

const useStyles = makeStyles<Theme, { isDrawerOpen: boolean }>((theme) => ({
  drawer: {
    position: "absolute",
    top: 0,
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
    transition: "left ease-in .15s",
    [theme.breakpoints.up("lg")]: {
      left: ({ isDrawerOpen }) => (isDrawerOpen ? 0 : -380),
      height: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      top: ({ isDrawerOpen }) => (isDrawerOpen ? 84 : -750),
      transition: "top ease-in .15s",
      width: "100%",
      zIndex: 5,
    },
  },
  drawerContent: {
    position: "sticky",
    top: APP_NAVIGATION_HEIGHT,
    padding: theme.spacing(14, 20),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(12.5, 12.5),
    },
    maxHeight: ({ isDrawerOpen }) => (isDrawerOpen ? "100%" : 140),
    overflowY: ({ isDrawerOpen }) => (isDrawerOpen ? "auto" : "initial"),
  },
  drawerHeader: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    marginBottom: theme.spacing(6),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
  },
  drawerHeaderMobile: {
    position: "absolute",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    letterSpacing: 2.5,
    height: 84,
    width: "100%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(9, 7.5),
    zIndex: 5,
    borderBottom: ({ isDrawerOpen }) => (isDrawerOpen ? `1px solid` : "unset"),
  },
  toggleButton: {
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      left: ({ isDrawerOpen }) => (isDrawerOpen ? 0 : 60),
      transform: ({ isDrawerOpen }) => `rotateZ(${isDrawerOpen ? "180" : "0"}deg)`,
    },
    [theme.breakpoints.down("lg")]: {
      transform: ({ isDrawerOpen }) => `rotateZ(${isDrawerOpen ? "270" : "90"}deg)`,
    },
    borderRadius: "50%",
    width: 42,
    height: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer",
    transition: "all ease-in .15s",
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
  },
  toggleButtonArrow: {
    marginLeft: 4,
  },
  sectionTitle: {
    height: 56,
    paddingLeft: theme.spacing(3.5),
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
  },
  backdrop: {
    position: "absolute",
    zIndex: 2,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backdropFilter: "blur(4px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}));

const ReportingDrawer: FunctionComponent<Props> = ({ children, routes, activeRoute }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles({ isDrawerOpen });

  const handleToggleDrawer = useCallback(() => {
    setDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen, setDrawerOpen]);

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box position="relative">
      <ClickAwayListener onClickAway={handleCloseDrawer}>
        <>
          {isSmall && (
            <Box className={classes.drawerHeaderMobile}>
              REPORTING
              <Box className={classes.toggleButton} onClick={handleToggleDrawer}>
                <SideBarArrow className={classes.toggleButtonArrow} />
              </Box>
            </Box>
          )}
          <Box className={classes.drawer}>
            <Box className={classes.drawerContent}>
              {!isSmall && (
                <Box className={classes.drawerHeader}>
                  REPORTING
                  <Box className={classes.toggleButton} onClick={handleToggleDrawer}>
                    <SideBarArrow className={classes.toggleButtonArrow} />
                  </Box>
                </Box>
              )}
              {routes.map(({ label, id, options }) => (
                <div key={id}>
                  {routes.length > 1 && <div className={classes.sectionTitle}>{label}</div>}
                  <ReportingDrawerRoutes routes={options} activeRoute={activeRoute} onLinkClick={handleCloseDrawer} />
                </div>
              ))}
            </Box>
          </Box>
        </>
      </ClickAwayListener>

      <Fade in={isDrawerOpen}>
        <Box className={classes.backdrop} />
      </Fade>

      {children}
    </Box>
  );
};

export default memo<PropsWithChildren<Props>>(ReportingDrawer);
