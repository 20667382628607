export enum CurrencySymbol {
  AUD = "$",
  BRL = "R$",
  CAD = "$",
  CHF = "₣",
  CNY = "¥",
  DKK = "kr.",
  EUR = "€",
  GBP = "£",
  HKD = "$",
  IDR = "Rp",
  INR = "₹",
  JPY = "¥",
  KRW = "₩",
  NOK = "kr",
  NZD = "$",
  PLN = "zł",
  RON = "lei",
  SEK = "kr",
  SGD = "$",
  USD = "$",
}

export enum CurrencyName {
  AUD = "Australian Dollar",
  BRL = "Brazilian Real",
  CAD = "Canadian Dollar",
  CHF = "Swiss Franc",
  CNY = "Chinese Yuan",
  DKK = "Danish Krone",
  EUR = "Euro",
  GBP = "Pound Sterling",
  HKD = "Hong Kong Dollar",
  IDR = "Indonesian Rupiah",
  INR = "Indian Rupee",
  JPY = "Japanese Yen",
  KRW = "South Korean Won",
  NOK = "Norwegian Krone",
  NZD = "New Zealand Dollar",
  PLN = "Polish Zloty",
  RON = "Romanian Leu",
  SEK = "Swedish Krona",
  SGD = "Singapore Dollar",
  USD = "US Dollar",
}

export enum CurrencyAbbreviation {
  AUD = "AUD",
  BRL = "BRL",
  CAD = "CAD",
  CHF = "CHF",
  CNY = "CNY",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  IDR = "IDR",
  INR = "INR",
  JPY = "JPY",
  KRW = "KRW",
  NOK = "NOK",
  NZD = "NZD",
  PLN = "PLN",
  RON = "RON",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
}

export type Currency = keyof typeof CurrencyName;
