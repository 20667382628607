import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { callEmbedUrlAPI } from "@reporting/store/thunks/callEmbedUrlAPI";
import { ReportingEmbedUrlConfig } from "@reporting/models";
import { portfolioPerformanceEmbedUrlSelector } from "@portfolioPerformance/store/selectors";

export const fetchPortfolioPerformanceEmbedUrlThunk = createAsyncThunk<ReportingEmbedUrlConfig, void, ThunkApiConfig>(
  "portfolioPerformance/fetchPortfolioPerformanceEmbedUrlThunk",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callEmbedUrlAPI("PortfolioPerformance"));
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load report",
        })
      );

      return rejectWithValue("Failed to fetch Embed Url");
    }
  },
  {
    condition: (_, { getState }) => {
      const portfolioPerformanceEmbedUrl = portfolioPerformanceEmbedUrlSelector(getState());

      return !portfolioPerformanceEmbedUrl.data && !portfolioPerformanceEmbedUrl.loading;
    },
  }
);
