import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { CalendarEventApiResponseItem } from "@myCalendar/models";
import { selectEvents } from "@myCalendar/store/selectors";

export const fetchCalendarEvents = createAsyncThunk<CalendarEventApiResponseItem[], void, ThunkApiConfig>(
  "myCalendar/fetchCalendarEvents",
  async (_, { rejectWithValue, dispatch, extra }) => {
    try {
      const response = await extra<CalendarEventApiResponseItem[]>("cvent/api/Events");

      return response.data;
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load data",
        })
      );

      return rejectWithValue("Failed to load calendar events");
    }
  },
  {
    condition: (_arg, { getState }) => {
      const eventsSlice = selectEvents(getState());

      return !eventsSlice.data && !eventsSlice.loading;
    },
  }
);
