import { ProfileMenuOptionId } from "@app/models/Header";
import { profileMenuOptions } from "@app/constants/header";

export const getProfileMenuOptions = (canImpersonate: boolean, isImpersonating: boolean) => {
  if (!canImpersonate) {
    return [profileMenuOptions[ProfileMenuOptionId.Preferences]];
  }

  return [
    profileMenuOptions[ProfileMenuOptionId.Preferences],
    isImpersonating
      ? profileMenuOptions[ProfileMenuOptionId.StopImpersonation]
      : profileMenuOptions[ProfileMenuOptionId.StartImpersonation],
  ];
};
