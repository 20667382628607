import { sub } from "date-fns";
import { TimeRangeFilterOptions2, TimeRangeIDs } from "@documents/models/filter.models";
import DateUtils from "@shared/utils/DateUtils";

export const timeRangeConverter2 = (timeRange: TimeRangeFilterOptions2) => {
  switch (timeRange.timePeriod2.id) {
    case TimeRangeIDs.LAST30: {
      const endDate = new Date();

      const year = endDate.getFullYear();
      const month = endDate.getMonth();
      const day = endDate.getDate();
      const endDateUTC = new Date(Date.UTC(year, month, day + 1));
      const startDate = sub(endDateUTC, { days: 32 });

      return { start2: startDate, end2: endDateUTC };
    }

    case TimeRangeIDs.LAST90: {
      const endDate = new Date();

      const year = endDate.getFullYear();
      const month = endDate.getMonth();
      const day = endDate.getDate();
      const endDateUTC = new Date(Date.UTC(year, month, day + 1));
      const startDate = sub(endDateUTC, { days: 92 });

      return { start2: startDate, end2: endDateUTC };
    }

    case TimeRangeIDs.YEARBEGINNING: {
      const currentYear = new Date().getFullYear();
      const startOfTheYear = new Date(Date.UTC(currentYear, 0, 1));
      const currentDayDate = new Date();
      const currentMonth = currentDayDate.getMonth();
      const currentDay = currentDayDate.getDate();

      const endDate = new Date(Date.UTC(currentYear, currentMonth, currentDay + 1));

      return { start2: startOfTheYear, end2: endDate };
    }

    case TimeRangeIDs.EXACTRANGE: {
      if (!timeRange.start2 || !timeRange.end2) {
        return { start2: null, end2: null };
      }

      return {
        start2: DateUtils.setUtcTimeZone(DateUtils.setStartOfDay(timeRange.start2)),
        end2: DateUtils.setUtcTimeZone(DateUtils.setEndOfDay(timeRange.end2)),
      };
    }

    default:
      return { start2: null, end2: null };
  }
};
