import { createSelector } from "@reduxjs/toolkit";
import { intersectionBy } from "lodash";
import { RootState } from "@store/index";
import { TermsState, TermToDisplay } from "@terms/models";

export const termsSelector = (state: RootState): TermsState => state.terms;

export const termsDialogDataSelector = createSelector(
  termsSelector,
  ({ data: termsData, outstandingTerms, dialogOpen }) => {
    const termsToDisplay = outstandingTerms.map((outstandingTermData): TermToDisplay => {
      const termData = termsData.find((term) => term.id === outstandingTermData.id)!;

      return {
        ...outstandingTermData,
        name: termData.name,
        text: termData.text,
      };
    });

    return {
      dialogOpen,
      termsToDisplay,
    };
  }
);

export const termRulesHandlersDataSelector = createSelector(
  termsSelector,
  ({ data: termsData, outstandingTerms, rules, dialogOpen }) => {
    const activeTermId = outstandingTerms.find((term) => term.active)?.id;

    const activeTermData = termsData.find((term) => term.id === activeTermId)!;
    const outstandingTermsData = intersectionBy(termsData, outstandingTerms, "id");

    return { activeTermData, outstandingTermsData, rules, dialogOpen };
  }
);
