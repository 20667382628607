import { useEffect, useRef, useState } from "react";
import { IMPERSONATION_BAR_HEIGHT } from "@app/constants/header";

interface Props {
  shouldObserve: boolean;
  isImpersonating: boolean;
}

const useIsAppNavigationPinned = ({ shouldObserve, isImpersonating }: Props) => {
  const appBarRef = useRef<HTMLDivElement>(null);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    const appBarNode = appBarRef.current;
    const topOffset = isImpersonating ? IMPERSONATION_BAR_HEIGHT + 1 : 1;

    const observer = new IntersectionObserver(
      ([observerEntry]) => {
        setIsPinned(observerEntry.intersectionRatio < 1);
      },
      {
        threshold: [1],
        rootMargin: `-${topOffset}px 0px 0px 0px`,
      }
    );

    if (shouldObserve && appBarNode) {
      observer.observe(appBarNode);
    }

    return () => observer.unobserve(appBarNode!);
  }, [shouldObserve, isImpersonating]);

  return {
    appBarRef,
    isPinned,
  };
};

export default useIsAppNavigationPinned;
