import { useEffect } from "react";
import { useAppSelector } from "@app/hooks";
import { userSliceSelector } from "@user/store/selectors";
import FeatureToggleService from "@services/FeatureToggleService";
import { ToggledFeature } from "@shared/models/featureToggle";
import gtag from "../../../util/gtag";

const useGTagRegister = () => {
  const { data: userData } = useAppSelector(userSliceSelector);

  useEffect(() => {
    if (!userData || !FeatureToggleService.isFeatureEnabled(ToggledFeature.GoogleAnalytics)) {
      return;
    }

    gtag("js", new Date());

    gtag("set", "user_properties", {
      external: userData.external,
      user_id: userData.id,
      user_type: userData.userType,
    });

    gtag("config", process.env.REACT_APP_GA_TRACKING_ID);
  }, [userData]);
};

export default useGTagRegister;
