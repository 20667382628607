import { createSelector } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { RootState } from "@store/index";
import { DataRoomState, EntityDocumentGridRow, EntityDocumentType } from "@dataRooms/models";
import { Nullable } from "@shared/models/general";
import { entitledFundsSelector } from "@store/Entities/selectors";
import { FundraisingStatus, ProductLine } from "@store/Entities/models";
import { RowType } from "@shared/components";
import DateUtils from "@shared/utils/DateUtils";
import { DocumentStatus } from "@documents/models/filter.models";
import { preferencesSelector } from "@app/store/selectors";
import { HomePageVideoDetails } from "@home/models";
import { SegmentTab, segmentTabValues } from "@dataRooms/constants/SegmentTabs";

export const entityDocumentTypesSelector = (state: RootState): DataRoomState["entityDocumentTypes"] =>
  state.dataRooms.entityDocumentTypes;

export const entityDocumentsSelector = (state: RootState): DataRoomState["entityDocuments"] =>
  state.dataRooms.entityDocuments;

export const highlightedVideoSelector = (state: RootState): DataRoomState["highlightedVideo"] =>
  state.dataRooms.highlightedVideo;

export const sortSelector = (state: RootState): DataRoomState["sort"] => state.dataRooms.sort;

export const searchKeySelector = (state: RootState): DataRoomState["searchKey"] => state.dataRooms.searchKey;

export const activeSegmentTabSelector = (state: RootState): DataRoomState["activeSegmentTab"] =>
  state.dataRooms.activeSegmentTab;

export const highlightedVideoSectionDetailsSelector = createSelector(
  highlightedVideoSelector,
  (highlightedVideo): Nullable<HomePageVideoDetails> => {
    if (
      !highlightedVideo ||
      highlightedVideo.videoId === null ||
      highlightedVideo.videoTokenExpiration === null ||
      highlightedVideo.videoToken === null
    ) {
      return null;
    }

    return {
      id: highlightedVideo.videoId,
      title: highlightedVideo.title,
      type: "New Opportunity Spotlight",
      tokenExpiration: highlightedVideo.videoTokenExpiration,
      token: highlightedVideo.videoToken,
      description: highlightedVideo.videoDescription,
    };
  }
);

export const dataRoomsFromEntitledFundsSelector = createSelector(
  entitledFundsSelector,
  ({
    data: entitledFunds,
  }): {
    id: number;
    globalId: string;
    title: string;
    subTitle: Nullable<string>;
    productSegment: string;
  }[] => {
    if (entitledFunds === null) {
      return [];
    }

    return Object.values(entitledFunds)
      .filter(
        (entitledFund) =>
          entitledFund.fundraisingStatus === FundraisingStatus.Fundraising &&
          entitledFund.productLine !== ProductLine.NonInvestment &&
          entitledFund.productFamily !== "Investor Conferences"
      )
      .map(({ id, globalId, entityDisplayName, entityName, productFamily, productSegment }) => ({
        id,
        globalId,
        title: entityDisplayName ?? entityName,
        subTitle: productFamily,
        productSegment,
      }));
  }
);

export const dataRoomsIncludeClosedStatusSelector = createSelector(
  entitledFundsSelector,
  ({
    data: entitledFunds,
  }): {
    id: number;
    globalId: string;
    title: string;
    subTitle: Nullable<string>;
    productSegment: string;
  }[] => {
    if (entitledFunds === null) {
      return [];
    }

    return Object.values(entitledFunds)
      .filter(
        (entitledFund) =>
          (entitledFund.fundraisingStatus === FundraisingStatus.Fundraising ||
            entitledFund.fundraisingStatus === FundraisingStatus.Closed) &&
          entitledFund.productLine !== ProductLine.NonInvestment &&
          entitledFund.productFamily !== "Investor Conferences"
      )
      .map(({ id, globalId, entityDisplayName, entityName, productFamily, productSegment }) => ({
        id,
        globalId,
        title: entityDisplayName ?? entityName,
        subTitle: productFamily,
        productSegment,
      }));
  }
);

export const dataRoomsBySegmentSelector = createSelector(
  dataRoomsFromEntitledFundsSelector,
  activeSegmentTabSelector,
  (dataRooms, activeSegmentTab) => {
    if (!dataRooms.length) {
      return [];
    }

    if (activeSegmentTab === SegmentTab.ViewAll) {
      return dataRooms;
    }

    return dataRooms.filter((dataRoom) => dataRoom.productSegment === segmentTabValues[activeSegmentTab]);
  }
);

export const dataRoomsSelector = createSelector(
  dataRoomsBySegmentSelector,
  sortSelector,
  searchKeySelector,
  (
    dataRoomsBySegment,
    sort,
    searchKey
  ): {
    id: number;
    globalId: string;
    title: string;
    subTitle: Nullable<string>;
  }[] => {
    if (!dataRoomsBySegment.length) {
      return [];
    }
    const dataRoomsBySearchKey = dataRoomsBySegment.filter((dataRoom) => {
      return dataRoom.title.toLowerCase().includes(searchKey.toLowerCase());
    });

    return orderBy(
      dataRoomsBySearchKey,
      [({ title }) => title.toLowerCase()],
      sort.direction.toLowerCase() as "asc" | "desc"
    );
  }
);

export const populatedEntityDocumentTypesSelector = createSelector(
  entityDocumentTypesSelector,
  entityDocumentsSelector,
  ({ data: documentTypes }, { data: documents }): Nullable<EntityDocumentType[]> => {
    if (documentTypes === null || documents === null) {
      return null;
    }

    return documentTypes.filter(({ documentTypeId }) => documents[documentTypeId]);
  }
);

export const entityDocumentsGridSelector = createSelector(
  [entityDocumentsSelector, (_state: RootState, typeId: number) => typeId, preferencesSelector],
  ({ data: documents }, typeId, preferences): EntityDocumentGridRow[] => {
    if (documents === null || documents[typeId].data === null) {
      return [];
    }

    return (
      documents[typeId].data?.map(
        ({
          actionItem,
          title,
          publishDate,
          videoId,
          videoToken,
          documentStatus,
          markedRead,
          fileType,
          id,
          documentSubtype,
          documentTypeId,
          fundId,
          otherAccounts,
          isLoading,
        }) => {
          const row = {
            actionItem,
            title,
            publishDate:
              documentStatus === DocumentStatus.Active && publishDate
                ? DateUtils.formatDate(new Date(publishDate), preferences.DateFormat)
                : "N/A",
            markedRead: markedRead,
            id,
            fileType,
            documentSubtype,
            fundId,
            documentTypeId,
            otherAccounts,
            isLoading,
          };

          return videoId === null
            ? {
                ...row,
                markedRead,
                rowMeta: {
                  nonClickableRow: !fileType,
                  type:
                    documentStatus === DocumentStatus.Pending || documentStatus === DocumentStatus.PendingApproval
                      ? RowType.Highlighted
                      : RowType.Regular,
                },
              }
            : {
                ...row,
                publishDate: publishDate ? DateUtils.formatDate(new Date(publishDate), preferences.DateFormat) : "N/A",
                videoId,
                videoToken,
                markedRead,
                rowMeta: {
                  type: RowType.Video,
                  cellRenderers: {
                    actionItem: () => null,
                    download: () => null,
                    markedRead: () => null,
                  },
                },
              };
        }
      ) ?? []
    );
  }
);
