import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { downloadAndArchiveDocuments } from "@documents/store/thunks/downloadAndArchiveDocuments";
import { openNotificationThunk } from "@store/Notification/thunks";
import { DownloadSelectedDataRoomsDocumentsArgs } from "@dataRooms/models";
import { updateDataRoomsDocumentReadFlag } from "@dataRooms/store/thunks/updateDataRoomsDocumentReadFlag";
import { getUnreadDocumentIds } from "@documents/utils/getUnreadDocumentIds";
import TermsNotAcceptedError from "@terms/utils/TermsNotAcceptedError";

export const downloadSelectedDataRoomsDocuments = createAsyncThunk<
  void,
  DownloadSelectedDataRoomsDocumentsArgs,
  ThunkApiConfig
>("dataRooms/downloadSelectedDataRoomsDocuments", async ({ rows, documentType }, { dispatch, rejectWithValue }) => {
  try {
    await dispatch(downloadAndArchiveDocuments(rows));

    const documentIds = rows.map((row) => row.id);
    const unreadDocumentsIds = getUnreadDocumentIds(rows, documentIds);

    dispatch(
      updateDataRoomsDocumentReadFlag({
        documentIds: unreadDocumentsIds,
        documentType,
      })
    );

    dispatch(
      openNotificationThunk({
        variant: "info",
        text: "Document download successful.",
      })
    );
  } catch (error) {
    if (error instanceof TermsNotAcceptedError) {
      return;
    }

    dispatch(
      openNotificationThunk({
        variant: "warning",
        text: "The document download failed",
      })
    );

    return rejectWithValue("Failed to download document");
  }
});
