import React, { memo, useCallback } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog } from "@shared/components";
import { VIDEO_PROVIDER_URL } from "@app/constants/globalUrls";
import { useAppSelector, useAppDispatch } from "@app/hooks";
import { videoSelector } from "@videos/store/selectors";
import { closeVideoDialog } from "@videos/store/ducks";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: 785,
    minHeight: 840,
    maxHeight: "none",
  },
  player: {
    width: 670,
    height: 400,
    paddingBottom: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  info: {
    overflowY: "scroll",
    width: 670,
    height: 190,
    fontSize: 13,

    "&>p": {
      marginBottom: "1rem",
    },
  },
}));

const VideoDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { videoName, videoId, videoToken, isOpenVideoDialog } = useAppSelector(videoSelector);

  const handleDialogClose = useCallback(() => {
    dispatch(closeVideoDialog());
  }, [dispatch]);

  return (
    <Dialog
      classes={{
        paperScrollPaper: classes.paper,
      }}
      maxWidth="md"
      title={videoName ?? ""}
      onClose={handleDialogClose}
      open={isOpenVideoDialog}
    >
      <iframe
        className={classes.player}
        title="vzaar video player"
        frameBorder="0"
        allowFullScreen
        src={`${VIDEO_PROVIDER_URL}/${videoId}/player?uss_token=${videoToken}`}
      />
      <div className={classes.info}>
        <p>
          <b>IMPORTANT INFORMATION</b>
        </p>
        <p>
          <b>THE MATERIALS ARE FOR INFORMATION PURPOSES ONLY AND SHOULD NOT BE RELIED UPON AS INVESTMENT ADVICE.</b> The
          Materials have been prepared by The Carlyle Group Inc. (together with its affiliates, &quot;Carlyle&quot;) and
          is not intended to be taken by, and should not be taken by, any individual recipient as investment advice, a
          recommendation to buy, hold or sell any security, or an offer to sell or a solicitation of offers to purchase
          any security. An offer or solicitation for an investment in an investment fund managed or sponsored by Carlyle
          or its affiliates (&quot;Fund&quot;) will occur only through a confidential private placement memorandum and
          related purchase documentation, and subject to the terms and conditions contained in such documents and in the
          Fund&apos;s operative agreements.
        </p>
        <p>
          In the event the Materials are included in any “Historical Diligence Materials” folder of a data room, such
          Materials are provided for historical informational purposes only and should not be relied upon for
          consideration of a potential investment in any Fund or security. Such Materials are subject to important
          additional information that appeared upon your login to the site. If you did not receive such information,
          please contact the LP Connect support team.
        </p>
        <p>
          <b>THE MATERIALS ARE CONFIDENTIAL.</b> The information in the Materials is <b>STRICTLY CONFIDENTIAL</b> and is
          subject to the confidentiality obligations in the partnership agreement and subscription agreement of each
          fund you are an investor in. The Materials may include material non-public information. It is illegal to buy
          or sell public securities while in the possession of material non-public information. You must not make or
          direct any trades in any securities on the basis of any of this information or in any related discussions.
        </p>
        <p>
          <b>THE MATERIALS ARE NOT A RECOMMENDATION FOR ANY SECURITY OR PORTFOLIO COMPANY.</b> References to portfolio
          companies are intended to illustrate the application of Carlyle&apos;s investment process only and should not
          be used as the basis for making any decision about purchasing, holding or selling any securities.
        </p>
        <p>
          <b>PAST PERFORMANCE IS NOT INDICATIVE OF FUTURE RESULTS.</b> The performance of those portfolio companies
          discussed in the Materials is not necessarily indicative of the performance of all of Carlyle&apos;s portfolio
          companies, and you should not assume that investments in the future will be profitable or will equal the
          performance of past portfolio companies. Investors should consider the content of this recording in
          conjunction with investment fund quarterly reports, financial statements and other disclosures regarding the
          valuations and performance of the specific investments discussed herein.
        </p>
        <p>
          <b>DO NOT RELY ON ANY OPINIONS, PREDICTIONS OR FORWARD-LOOKING STATEMENTS CONTAINED HEREIN.</b> Certain of the
          information contained in the Materials constitutes &quot;forward-looking statements&quot; that are inherently
          unreliable and actual events or results may differ materially from those reflected or contemplated herein.
          Except where otherwise indicated, the information contained herein is based on matters as they exist as of the
          date of this recording and not as of any future date, and may not be updated or otherwise revised to reflect
          information that subsequently becomes available, or circumstances existing or changes occurring after the date
          hereof. None of Carlyle or any of its representatives makes any assurance as to the accuracy of those
          predictions or forward-looking statements. Carlyle expressly disclaims any obligation or undertaking to update
          or revise any such forward-looking statements.
        </p>
        <p>
          <b>EXTERNAL SOURCES. </b>Certain information contained herein has been obtained from third-party sources.
          Although Carlyle believes such sources to be reliable, we make no representation as to its accuracy or
          completeness.{" "}
        </p>
        <p>
          <b>THE MATERIALS MAY NOT BE COPIED, QUOTED OR REFERENCED WITHOUT CARLYLE&apos;S PRIOR WRITTEN CONSENT.</b>
        </p>
      </div>
    </Dialog>
  );
};

export default memo(VideoDialog);
