import { DocumentRow } from "@documents/models/dataGrid.models";
import { DocumentResponse } from "@documents/models/api.models";

export const normalizeDocumentResponse = (document: DocumentResponse): DocumentRow => ({
  id: document.documentId,
  actionItem: document.actionItem,
  markedRead: document.markedRead,
  title: document.title,
  investorAccountName: document.investorAccountName ?? "All Investors",
  fundDisplayName: document.fundDisplayName ?? document.parentEntitledEntityName ?? document.fundName ?? "All Funds",
  fileType: document.fileType,
  publishDate: document.publishDateEST,
  documentTypeId: document.documentTypeId,
  otherAccounts: document.otherAccounts,
  documentSubtype: document.documentSubtype,
  fundId: document.fundId,
  documentStatus: document.documentStatus,
  isLoading: false,
  videoId: document.videoId,
  videoToken: document.videoToken,
  videoTokenExpiration: document.videoTokenExpiration,
  videoIsHighlighted: document.videoIsHighlighted,
  videoDescription: document.videoDescription,
});
