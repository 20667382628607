import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { AccountGroup } from "@store/Entities/models";
import { deleteAccountGroupLocally } from "@store/Entities/ducks";
import { openNotificationThunk } from "@store/Notification/thunks";
import { fetchAccountGroups } from "@store/Entities/thunks";

export const deleteAccountGroupThunk = createAsyncThunk<boolean, void, ThunkApiConfig>(
  "profile/deleteAccountGroupThunk",
  async (_, { extra, getState, dispatch, rejectWithValue }) => {
    const {
      profile: {
        myGroups: { selectedAccountGroupId },
      },
      entities: {
        accountGroups: { data },
      },
      impersonation: { isImpersonating },
    } = getState();

    const selectedGroup = data!.find((g: AccountGroup) => g.groupId === selectedAccountGroupId);

    if (isImpersonating) {
      dispatch(deleteAccountGroupLocally(<number>selectedAccountGroupId));

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: `${selectedGroup!.groupName} group has been deleted`,
        })
      );

      return true;
    }

    try {
      await Promise.all(
        selectedGroup!.investorAccountIds.map((accountId: number) =>
          extra("/entitlements/v1/user/InvestorAccountGroupsAssociation", {
            method: "delete",
            body: { accountId, groupId: selectedAccountGroupId },
          })
        )
      );

      await extra(`/entitlements/v1/user/InvestorAccountGroups/${selectedAccountGroupId}`, { method: "delete" });

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: `${selectedGroup!.groupName} group has been deleted`,
        })
      );

      dispatch(fetchAccountGroups(true));

      return true;
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: `Failed to delete ${selectedGroup!.groupName} group`,
        })
      );

      return rejectWithValue(false);
    }
  }
);
