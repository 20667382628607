import { models, Report } from "powerbi-client";
import { format } from "date-fns";
import { Param } from "@accountSummaryAlpInvest/models";

// Function to construct Export Parameters from Report Slicers and Filters
export async function getExportParams(report?: Report) {
  if (!report) {
    return {};
  }

  const activePage = await report.getActivePage();
  const visuals = await activePage.getVisuals();
  let filterList: models.IFilter[] = [];

  // Retrieve the slicer visuals and get filters
  const slicers = visuals.filter(function (visual) {
    return visual.type === "slicer";
  });

  slicers.forEach(async (slicer) => {
    const state = await slicer.getSlicerState();
    const filter = state.filters[0];

    filterList.push(filter);
  });

  // Add Portfolio Type filter (from page 0)
  const pages = await report.getPages();
  const page = pages[0];
  const pagefilters = await page.getFilters();

  filterList = filterList.concat(pagefilters);

  // Add Report Level filters
  const filters = await report.getFilters();

  filterList = filterList.concat(filters);

  // Remove Hidden filters
  const visibleFilters = filterList.filter(function (filter) {
    const isHidden = filter.displaySettings && filter.displaySettings.isHiddenInViewMode === true;

    return !isHidden;
  });

  let displayCurrencyValue = "";
  let reportDateValue = "";
  let portfolioTypeValues = ["All"];
  let investorValues = ["All"];
  let fundValues = ["All"];
  let mandateFundValues = ["All"];

  visibleFilters.forEach((filter: any) => {
    const parameter = filter.target.table + ":" + filter.target.column;

    switch (parameter) {
      case Param.Currency:
        displayCurrencyValue = filter.values[0];
        break;
      case Param.Date: {
        const reportDate = new Date(filter.conditions[0].value);
        // Subtract one day, because embed report date filter uses less than condition, paginated/export does not.
        const reportDateAdjusted = new Date(reportDate.getTime() - 86400000);

        // Correct Date for local timezone.
        reportDateValue = format(reportDateAdjusted, "yyyy-MM-dd") + "T00:00:00";
        break;
      }
      case Param.PortfolioType:
        if (filter.values != undefined && filter.values != null && filter.operator != "All")
          portfolioTypeValues = filter.values;
        break;
      case Param.Investor:
        if (filter.values != undefined && filter.values != null && filter.operator != "All")
          investorValues = filter.values;
        break;
      case Param.Fund:
        if (filter.values != undefined && filter.values != null && filter.operator != "All") fundValues = filter.values;
        break;
      case Param.MandateFund:
        if (filter.values != undefined && filter.values != null && filter.operator != "All")
          mandateFundValues = filter.values;
        break;
      default:
    }
  });

  const exportParams = {
    "Display Currency": displayCurrencyValue,
    "Report Date": reportDateValue,
    Investor: investorValues,
    Fund: fundValues,
    "Mandate Fund": mandateFundValues,
    "Portfolio Type": portfolioTypeValues,
  };

  return exportParams;
}
