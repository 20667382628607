import React, { FunctionComponent, useCallback } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { ReactComponent as Checkmark } from "@assets/svg/check.svg";

interface Props {
  checked?: boolean;

  onChange?(checked: boolean): void;
}

const useStyles = makeStyles<Theme, Pick<Props, "onChange">>((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 13,
    height: 13,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    cursor: ({ onChange }) => (onChange ? "pointer" : "inherit"),
  },
  checkmark: {
    color: theme.palette.info.main,
    width: 9,
  },
}));

const Checkbox: FunctionComponent<Props> = ({ checked, onChange }) => {
  const classes = useStyles({ onChange });

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange(!checked);
    }
  }, [onChange, checked]);

  return (
    <Box className={classes.root} onClick={handleChange} role="checkbox" data-testid="checkbox">
      {checked && <Checkmark data-testid="checkmark" className={classes.checkmark} />}
    </Box>
  );
};

export default Checkbox;
