import { GetAppListener } from "@shared/models/redux";
import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";
import RouterUtils from "@shared/utils/RouterUtils";
import { AppRoute } from "@app/models/AppRoute";
import { resetState } from "@capitalAccountStatement/store/ducks";
import { initCapitalAccountStatement } from "@capitalAccountStatement/store/thunks";

export const getIAGroupChangedCASListener: GetAppListener = (addAppListener, { history }) =>
  addAppListener({
    actionCreator: changeInvestorAccountsGroup.fulfilled,
    effect: (_action, { dispatch, cancelActiveListeners }) => {
      cancelActiveListeners();

      dispatch(resetState());

      if (RouterUtils.checkPathMatchExactRoute(history, AppRoute.CapitalAccountStatement)) {
        dispatch(initCapitalAccountStatement());
      }
    },
  });
