import { AppThunkAPI } from "@shared/models/redux";
import { ReportingExport } from "@reporting/models";

type CallExportStatusAPI = (exportId: string) => AppThunkAPI<Promise<ReportingExport>>;

export const callExportStatusAPI: CallExportStatusAPI = (exportId) => async (_dispatch, _getState, extra) => {
  const response = await extra<ReportingExport>(`/entitlements/v1/reports/exportStatus/${exportId}`);

  return response.data;
};
