import React, { memo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { userSliceSelector } from "@user/store/selectors";
import { useAppSelector } from "@app/hooks";
import { IMPERSONATION_BAR_HEIGHT, IMPERSONATION_BAR_HEIGHT_MOBILE } from "@app/constants/header";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    width: "100vw",
    height: IMPERSONATION_BAR_HEIGHT,
    fontSize: 15,
    zIndex: theme.zIndex.snackbar,
    color: theme.palette.common.white,
    background: theme.palette.info.main,
    [theme.breakpoints.down("lg")]: {
      height: IMPERSONATION_BAR_HEIGHT_MOBILE,
      letterSpacing: 0,
      display: "initial",
      textAlign: "center",
      lineHeight: 1,
      paddingTop: theme.spacing(5.5),
    },
  },
}));

const ImpersonateSnackbar = () => {
  const classes = useStyles();
  const { user: impersonatedUserData, isImpersonating } = useAppSelector(impersonationStateSelector);
  const { data: userData } = useAppSelector(userSliceSelector);

  if (!isImpersonating) {
    return null;
  }

  return (
    <div className={classes.snackbar}>
      You are logged in as &thinsp;
      <b>{userData!.name}</b>. You currently impersonate &thinsp;
      <b>{impersonatedUserData!.name}</b>
    </div>
  );
};

export default memo(ImpersonateSnackbar);
