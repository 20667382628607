import React, { FunctionComponent, memo, PropsWithChildren, useCallback } from "react";
import { Box, Dialog as MuiDialog, DialogActions, DialogContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { RoundedIcon } from "@shared/components/RoundedIcon";
import { ReactComponent as CloseIcon } from "@assets/svg/close.svg";
import { useHistoryListener } from "@shared/hooks/useHistoryListener";
import { useMobileLayout } from "@app/hooks";
import { DialogProps } from "./models";

const useStyles = makeStyles((theme) => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down("md")]: {
      "& .MuiTypography-root": {
        fontSize: 22,
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
      },
    },
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  title: {
    padding: 0,
    width: "fit-content",
    paddingRight: theme.spacing(6),
    flex: 1,
    "& .MuiTypography-root": {
      fontSize: 24,
      textTransform: "uppercase",
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
    },
  },
  content: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(6.5, 12.5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(7.5, 7.5),
    },
  },
  actions: {
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(7.5, 12),
    borderTop: `1px solid ${theme.palette.primary.main}`,
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(7.5, 10.5, 6, 10.5),
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const Dialog: FunctionComponent<DialogProps> = ({
  children,
  title,
  actions,
  closeOnBackdropClick,
  onKeyPress,
  ...props
}) => {
  const classes = useStyles();

  const handleDialogKeyPress = useCallback(
    (event) => {
      if (onKeyPress) {
        onKeyPress(event);
      }
    },
    [onKeyPress]
  );

  const handleCloseDialog = useCallback(
    (_event, reason: "backdropClick" | "escapeKeyDown") => {
      if (reason === "backdropClick" && !closeOnBackdropClick) return;

      props.onClose({ reason });
    },
    [closeOnBackdropClick, props]
  );

  const handleCloseClick = useCallback(() => {
    props.onClose({ reason: "closeClick" });
  }, [props]);

  useHistoryListener(
    useCallback(() => {
      if (props.open) {
        props.onClose({ reason: "historyChange" });
      }
    }, [props])
  );

  const isMobile = useMobileLayout();

  return (
    <MuiDialog {...props} onClose={handleCloseDialog} onKeyPress={handleDialogKeyPress} data-testid="dialog-container">
      <Box className={classes.heading} data-testid="dialog-heading">
        <Typography data-testid="dialog-title" variant="bodySubhead1" fontWeight="bold">
          {title}
        </Typography>
        <RoundedIcon interactive Icon={CloseIcon} onClick={handleCloseClick} sizeBig={isMobile} />
      </Box>
      <DialogContent className={classes.content} data-testid="dialog-content">
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.actions} data-testid="dialog-actions">
          {actions}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

Dialog.defaultProps = {
  closeOnBackdropClick: false,
};

export default memo<PropsWithChildren<DialogProps>>(Dialog);
