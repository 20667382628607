import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { FundDocumentsRow } from "@funds/models";
import { downloadAndArchiveDocuments } from "@documents/store/thunks/downloadAndArchiveDocuments";
import { openNotificationThunk } from "@store/Notification/thunks";
import { getDocumentFilesMap } from "@documents/utils/getDocumentFilesMap";
import TermsNotAcceptedError from "@terms/utils/TermsNotAcceptedError";
import { updateFundDocumentReadFlag } from "./updateFundDocumentReadFlag";

export const downloadSelectedFundDocuments = createAsyncThunk<void, FundDocumentsRow[], ThunkApiConfig>(
  "funds/downloadSelectedFundDocuments",
  async (rows, { dispatch, rejectWithValue }) => {
    try {
      const documentFiles = await dispatch(downloadAndArchiveDocuments(rows));
      const documentFilesMap = getDocumentFilesMap(documentFiles);

      const unreadDocumentIds = rows
        .filter(({ id, markedRead }) => documentFilesMap[id] && !markedRead)
        .map(({ id }) => id);

      dispatch(updateFundDocumentReadFlag(unreadDocumentIds));

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: "Document download successful.",
        })
      );
    } catch (error) {
      if (error instanceof TermsNotAcceptedError) {
        return;
      }

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "The document download failed",
        })
      );

      return rejectWithValue("Failed to download document");
    }
  }
);
