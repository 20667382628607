import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { RootState } from "@store/index";
import CommonUtils from "@shared/utils/CommonUtils";
import { MultiselectOption, ListOptionBase } from "@shared/components";
import DateUtils from "@shared/utils/DateUtils";
import { selectedIAGroupIdSelector } from "@app/store/selectors";
import { fundsSelector, investorsSelector } from "@store/Entities/selectors";
import { CashFlowActivityState } from "../models";

export const reportSelector = (state: RootState): CashFlowActivityState["report"] => state.cashFlowActivity.report;

export const filterOptionsSelector = (state: RootState): CashFlowActivityState["filterOptions"] =>
  state.cashFlowActivity.filterOptions;

export const filterValuesSelector = (state: RootState): CashFlowActivityState["filters"] =>
  state.cashFlowActivity.filters;

const selectedFundSelector = (state: RootState): CashFlowActivityState["filters"]["fund"] =>
  state.cashFlowActivity.filters.fund;

const selectedAccountsSelector = (state: RootState): CashFlowActivityState["filters"]["accounts"] =>
  state.cashFlowActivity.filters.accounts;

const selectedContributionsSelector = (state: RootState): CashFlowActivityState["filters"]["contributions"] =>
  state.cashFlowActivity.filters.contributions;

const selectedDistributionsSelector = (state: RootState): CashFlowActivityState["filters"]["distributions"] =>
  state.cashFlowActivity.filters.distributions;

const selectedTimeRangeSelector = (state: RootState): CashFlowActivityState["filters"]["timeRange"] =>
  state.cashFlowActivity.filters.timeRange;

export const cfaReportExportLoadingSelector = (state: RootState): CashFlowActivityState["isReportDownloading"] =>
  state.cashFlowActivity.isReportDownloading;

const fundsOptionsSelector = createSelector(filterOptionsSelector, (options): ListOptionBase[] => {
  return options.data!.fundToInvestorsMapping
    ? Object.entries(options.data!.fundToInvestorsMapping).map(([id, { fundName }]) => ({
        id,
        label: fundName,
      }))
    : [];
});

const accountsSelector = createSelector(
  filterOptionsSelector,
  selectedAccountsSelector,
  selectedFundSelector,
  (options, selectedAccounts, selectedFund) => {
    let accounts: MultiselectOption[] = [];

    if (selectedFund) {
      const selectedAccountsMap = CommonUtils.convertArrayToMap(selectedAccounts);

      accounts = options.data!.fundToInvestorsMapping![selectedFund].investors.map((a) => ({
        ...a,
        selected: selectedAccountsMap[a.id],
      }));
    }

    return accounts;
  }
);

const contributionsSelector = createSelector(
  filterOptionsSelector,
  selectedContributionsSelector,
  (options, selectedContributions) => {
    const selectedContributionsMap = CommonUtils.convertArrayToMap(selectedContributions);

    return options.data!.contributions.map((c) => ({
      ...c,
      selected: selectedContributionsMap[c.id],
    }));
  }
);

const distributionsSelector = createSelector(
  filterOptionsSelector,
  selectedDistributionsSelector,
  (options, selectedDistributions) => {
    const selectedContributionsMap = CommonUtils.convertArrayToMap(selectedDistributions);

    return options.data!.distributions.map((c) => ({
      ...c,
      selected: selectedContributionsMap[c.id],
    }));
  }
);

const timeRangeSelector = createSelector(filterOptionsSelector, selectedTimeRangeSelector, (options, timeRange) => {
  const selectedTimeRange = options.data!.timeRange.find((t) => t.id === timeRange.timePeriod);

  return {
    start: DateUtils.convertDateToYearQuarter(timeRange.start!),
    end: DateUtils.convertDateToYearQuarter(timeRange.end!),
    id: timeRange.timePeriod,
    label: selectedTimeRange!.label,
  };
});

const selectedFundOptionSelector = createSelector(filterOptionsSelector, selectedFundSelector, (options, fund) => {
  let selectedFundOption = null;

  if (fund && options.data!.fundToInvestorsMapping) {
    selectedFundOption = {
      id: fund,
      label: options.data!.fundToInvestorsMapping![fund].fundName,
    };
  }

  return selectedFundOption;
});

export const filterSelector = createSelector(
  filterOptionsSelector,
  filterValuesSelector,
  fundsOptionsSelector,
  accountsSelector,
  contributionsSelector,
  distributionsSelector,
  timeRangeSelector,
  selectedFundOptionSelector,
  (options, values, funds, accounts, contributions, distributions, selectedTimeRange, selectedFund) => ({
    funds,
    accounts,
    contributions,
    distributions,
    selectedTimeRange,
    timeRangeOptions: options.data!.timeRange,
    timeRangeStartOptions: options.data!.timeRangeStart,
    timeRangeEndOptions: options.data!.timeRangeEnd,
    selectedFund,
    loading: options.loading,
    rawValues: values,
  })
);

const selectedAccountNamesSelector = createSelector(accountsSelector, (accounts) => {
  const selectedOptions = accounts.filter((a) => a.selected);

  return (selectedOptions.length ? selectedOptions : accounts).map((a) => a.label);
});

export const reportDataSelector = createSelector(
  fundsSelector,
  investorsSelector,
  reportSelector,
  filterOptionsSelector,
  selectedAccountNamesSelector,
  selectedFundOptionSelector,
  selectedIAGroupIdSelector,
  (funds, investors, report, options, selectedAccounts, selectedFundOption, selectedAccountGroup) => {
    return {
      rows: report.data,
      selectedAccountGroupId: selectedAccountGroup,
      selectedAccounts,
      selectedFund: selectedFundOption?.label,
      loading: funds.loading || investors.loading || report.loading || options.loading,
      error: report.error || options.error,
    };
  }
);

export const isCFAReportDownloadableSelector = createSelector(
  reportSelector,
  filterOptionsSelector,
  (report, filterOptions) => {
    const loading = report.loading || filterOptions.loading;
    const error = report.error || filterOptions.error;
    const hasData = !isEmpty(report.data);

    return !loading && !error && hasData;
  }
);
