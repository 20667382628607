import { REPORTING_TERM_ID } from "@terms/constants";
import { Nullable } from "@shared/models/general";

export interface Term {
  id: typeof REPORTING_TERM_ID | number;
  accepted: boolean;
  activatedDate: Nullable<string>;
  associationType: TermAssociation;
  associations: Array<string>;
  name: string;
  status: TermStatus;
  text: string;
  whenAccepted: TermAcceptPeriod;
}

export type ServerTerm = Omit<Term, "accepted">;

export interface OutstandingTerm {
  id: Term["id"];
  status: OutstandingTermStatus;
  active: boolean;
}

export interface TermToDisplay extends OutstandingTerm {
  name: Term["name"];
  text: Term["text"];
}

export enum TermAssociation {
  SiteWide = "Sitewide",
  EntitledEntity = "EntitledEntity",
  DocumentType = "DocumentType",
  Reporting = "Reporting", // UI hardcoded
}

export enum TermAcceptPeriod {
  Once = "Once",
  Session = "Session",
}

export enum TermStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum OutstandingTermStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Declined = "Declined",
}
