import React, { memo, useMemo } from "react";
import { Box } from "@mui/material";
import { Dropdown } from "@shared/components/Dropdown";
import StringUtils from "@shared/utils/StringUtils";
import { List, MultiselectOption } from "@shared/components/List";
import SelectToggleButton from "@shared/components/Select/SelectToggleButton";
import { MultiselectProps } from "@shared/components/Select/models";
import SelectedOptionsList from "../SelectedOptionsList/SelectedOptionsList";

const Multiselect = <ListOption extends MultiselectOption>({
  loading,
  entity,
  onSelect,
  onClearAll,
  disabled,
  testId,
  variant,
  previewExpandable,
  dropdownWidth,
  onToggleButtonClick,
  ...listProps
}: MultiselectProps<ListOption>) => {
  const selectedOptions = useMemo(() => {
    return listProps.options.filter((option) => option.selected);
  }, [listProps.options]);

  let toggleButtonText = `All ${entity}s`;

  if (!listProps.options.length) {
    toggleButtonText = `No ${entity}s available`;
  }
  if (selectedOptions.length) {
    toggleButtonText = `${selectedOptions.length} ${StringUtils.pluralize(selectedOptions.length, entity)} selected`;
  }

  return (
    <Box data-testid={testId ? `multiselect-container-${testId}` : "multiselect-container"}>
      {!!selectedOptions.length && (
        <SelectedOptionsList
          selectedOptions={selectedOptions}
          previewExpandable={previewExpandable}
          entity={entity}
          onClearAll={onClearAll}
          optionsLength={listProps.options.length}
          onSelect={onSelect}
          testId={testId}
        />
      )}
      <Dropdown
        width={dropdownWidth}
        renderToggle={({ ref, toggleDropdown, open }) => (
          <SelectToggleButton
            open={open}
            ref={ref}
            onClick={() => {
              toggleDropdown();

              if (onToggleButtonClick) onToggleButtonClick();
            }}
            variant={variant}
            loading={loading}
            disabled={disabled}
            size={listProps.size}
            testId={testId}
          >
            {toggleButtonText}
          </SelectToggleButton>
        )}
      >
        <List<ListOption>
          {...listProps}
          variant="multiselect"
          selectedOptionIds={selectedOptions.map((option) => option.id)}
          onOptionClick={(option) => onSelect({ ...option, selected: !option.selected })}
        />
      </Dropdown>
    </Box>
  );
};

export default memo(Multiselect) as typeof Multiselect;
