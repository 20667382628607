import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { addListener } from "@reduxjs/toolkit";
import { useAppDispatch } from "@app/hooks";
import { AddAppListener } from "@shared/models/redux";
import { getFinalTermRuleListener, getImmediateTermRuleListener, getTermsLoadedListener } from "@terms/store/listeners";
import { getImpersonationListener } from "@impersonateUser/store/listeners";
import { getIAGroupChangedHomePageListener } from "@home/store/listeners";
import { getIAGroupChangedDocumentPageListener } from "@documents/store/listeners";
import { getIAGroupChangedFundProfilePageListener } from "@funds/store/listeners";
import { getIAGroupChangedDataRoomProfilePageListener } from "@dataRooms/store/listeners";
import { getIAGroupChangedCASListener } from "@capitalAccountStatement/store/listeners";
import { getIAGroupChangedCFAListener } from "@cashFlowActivity/store/listeners";
import { getIAGroupChangedSOIListener } from "@scheduleOfInvestments/store/listeners";

const addAppListener = addListener as AddAppListener;

const useStartAppListeners = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    [
      getTermsLoadedListener,
      getImmediateTermRuleListener,
      getFinalTermRuleListener,
      getImpersonationListener,
      getIAGroupChangedHomePageListener,
      getIAGroupChangedDocumentPageListener,
      getIAGroupChangedFundProfilePageListener,
      getIAGroupChangedDataRoomProfilePageListener,
      getIAGroupChangedCASListener,
      getIAGroupChangedSOIListener,
      getIAGroupChangedCFAListener,
    ].forEach((getListener) => {
      dispatch(getListener(addAppListener, { history }));
    });
  }, [dispatch, history]);
};

export default useStartAppListeners;
