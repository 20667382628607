import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdministrationReportsFilters, AdministrationReportsState } from "@administrationReports/models";
import { timeRangeOptions } from "@documents/constants";
import {
  generateDocumentReportThunk,
  generateDocumentsMissingEntitlementsReportThunk,
  generateDocumentsDigestDispatchReportThunk,
  generateBounceBackReportThunk,
  generateTermsAcceptanceReportThunk,
  generateWhoAccessedDataRoomReportThunk,
  generateWhoAccessedDocumentsReportThunk,
  generateWhoAccessedEventReplayReportThunk,
  generateWhoAccessedFeaturedInsightsReportThunk,
} from "@administrationReports/store/thunks";

const initialState: AdministrationReportsState = {
  isReportDownloading: false,
  filters: {
    timeRange: {
      timePeriod: timeRangeOptions[0],
      start: null,
      end: null,
    },
    timeRange2: {
      timePeriod2: timeRangeOptions[0],
      start2: null,
      end2: null,
    },
    selectedFunds: null,
    selectedAccounts: null,
    documentTypes: null,
    documentSubTypes: null,
    userEmail: "",
    batchNumber: "",
    batchNumbers: [],
    commonNames: [],
    selectedDataRooms: null,
    selectedEventReplays: null,
    includeDeletedDocuments: false,
    insightsHubContentTitles: null,
    insightsHubContentAuthors: null,
    emailSource: "",
    selectedDate: null,
  },
};

const administrationReportsSlice = createSlice({
  name: "administrationReportsSlice",
  initialState,
  reducers: {
    changeAdministrationReportFilterValue: (state, action: PayloadAction<Partial<AdministrationReportsFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateDocumentReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateDocumentReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateDocumentReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedDocumentsReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateWhoAccessedDocumentsReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedDocumentsReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateTermsAcceptanceReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateTermsAcceptanceReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateTermsAcceptanceReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedDataRoomReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateWhoAccessedDataRoomReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedDataRoomReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedEventReplayReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateWhoAccessedEventReplayReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedEventReplayReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedFeaturedInsightsReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateWhoAccessedFeaturedInsightsReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateWhoAccessedFeaturedInsightsReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateDocumentsMissingEntitlementsReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateDocumentsMissingEntitlementsReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateDocumentsMissingEntitlementsReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateDocumentsDigestDispatchReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateDocumentsDigestDispatchReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateDocumentsDigestDispatchReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateBounceBackReportThunk.pending, (state) => {
        state.isReportDownloading = true;
      })
      .addCase(generateBounceBackReportThunk.fulfilled, (state) => {
        state.isReportDownloading = false;
      })
      .addCase(generateBounceBackReportThunk.rejected, (state) => {
        state.isReportDownloading = false;
      });
  },
});

export const { changeAdministrationReportFilterValue } = administrationReportsSlice.actions;
export default administrationReportsSlice.reducer;
