import { Pagination } from "@shared/models/redux";
import { getDocumentReadFlag, getDocumentsActionFlag, timeRangeConverter } from "@documents/utils";
import { documentStatusesAdmin, documentStatusesNonAdmin } from "@documents/constants";
import { DocumentsState } from "@documents/models";
import { FetchDocumentsRequestBody } from "@documents/models/api.models";
import { Nullable } from "@shared/models/general";
import { DocumentType, EntitledAccount, EntitledFund } from "@store/Entities/models";
import { ListOptionBase } from "@shared/components";

interface RequestFilters {
  funds?: Nullable<Array<EntitledFund["globalId"]>>;
  fundIds?: Nullable<Array<EntitledFund["id"]>>;
  investorAccounts?: Nullable<Array<EntitledAccount["globalId"]>>;
  investorAccountIds?: Nullable<Array<EntitledAccount["id"]>>;
  documentTypes: Nullable<Array<DocumentType["documentTypeId"]>>;
  timeRange: {
    timePeriod: ListOptionBase;
    start: Nullable<Date>;
    end: Nullable<Date>;
  };
  status: Nullable<ListOptionBase>;
  fileName: string;
}

interface Props {
  isAdmin: boolean;
  filters: RequestFilters;
  sort: DocumentsState["sort"];
  pagination: Pagination;
  downloadAsFile?: boolean;
}

export const buildDocumentsRequestBody = ({
  isAdmin,
  filters,
  pagination,
  sort,
  downloadAsFile,
}: Props): FetchDocumentsRequestBody => {
  const convertedDates = timeRangeConverter(filters.timeRange);

  return {
    actionFlag: getDocumentsActionFlag(filters.status),
    readFlag: getDocumentReadFlag(filters.status),
    count: downloadAsFile ? null : pagination.count,
    documentLocation: "Document",
    documentStatuses: isAdmin ? documentStatusesAdmin : documentStatusesNonAdmin,
    entitledAccountGlobalIds: filters.investorAccounts,
    entitledEntityGlobalIds: filters.funds,
    entitledAccountIds: filters.investorAccountIds,
    entitledEntityIds: filters.fundIds,
    includeChildren: true,
    reportFormat: downloadAsFile ? "excel documentList" : undefined,
    sortDirection: sort.direction,
    sortKey: sort.key,
    start: downloadAsFile ? null : pagination.start,
    startDate: convertedDates.start,
    endDate: convertedDates.end,
    fileName: filters.fileName.length ? filters.fileName : undefined,
    documentTypes: filters.documentTypes || undefined,
    userId: null, // only for impersonation,
  };
};
