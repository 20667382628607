import { createAsyncThunk } from "@reduxjs/toolkit";
import { entitledFundsSelector } from "@store/Entities/selectors";
import { ThunkApiConfig } from "@shared/models/redux";
import { EntitledFundResponse } from "../models";

export const fetchEntitledFunds = createAsyncThunk<EntitledFundResponse[], boolean | undefined, ThunkApiConfig>(
  "entities/fetchEntitledFunds",
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra<EntitledFundResponse[]>("/entitlements/v1/user/EntitledEntities");

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to load entitled funds");
    }
  },
  {
    condition: (refresh, { getState }) => {
      if (refresh) return true;

      const entitledFunds = entitledFundsSelector(getState());

      return !entitledFunds.loading && !entitledFunds.data;
    },
  }
);
