import { createAsyncThunk } from "@reduxjs/toolkit";
import { without } from "lodash";
import { ThunkApiConfig } from "@shared/models/redux";
import { updateAccountGroupLocally } from "@store/Entities/ducks";
import { openNotificationThunk } from "@store/Notification/thunks";
import { AccountGroup } from "@store/Entities/models";
import { fetchAccountGroups } from "@store/Entities/thunks";

export const updateAccountGroupThunk = createAsyncThunk<boolean, void, ThunkApiConfig>(
  "profile/updateAccountGroupThunk",
  async (_, { extra, getState, dispatch, rejectWithValue }) => {
    const {
      profile: {
        myGroups: { selectedAccountGroupId, selectedAccountIds, groupNameInput },
      },
      entities: {
        accountGroups: { data },
      },
      user: { data: userData },
      impersonation: { isImpersonating },
    } = getState();

    if (isImpersonating) {
      dispatch(
        updateAccountGroupLocally({
          groupId: <number>selectedAccountGroupId,
          groupName: groupNameInput,
          entitledUserId: Number(userData!.id),
          investorAccountIds: selectedAccountIds,
        })
      );

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: `${groupNameInput} group has been updated`,
        })
      );

      return true;
    }

    const initialGroup = data!.find((g: AccountGroup) => g.groupId === selectedAccountGroupId);

    try {
      const accountsToRemove = without(initialGroup!.investorAccountIds, ...selectedAccountIds);
      const accountsToAdd = without(selectedAccountIds, ...initialGroup!.investorAccountIds);

      await Promise.all(
        accountsToRemove.map((accountId) =>
          extra("/entitlements/v1/user/InvestorAccountGroupsAssociation", {
            method: "delete",
            body: { accountId, groupId: selectedAccountGroupId },
          })
        )
      );
      await Promise.all(
        accountsToAdd.map((accountId) =>
          extra("/entitlements/v1/user/InvestorAccountGroupsAssociation", {
            method: "post",
            body: { accountId, groupId: selectedAccountGroupId },
          })
        )
      );

      if (initialGroup!.groupName !== groupNameInput) {
        await extra("/entitlements/v1/user/InvestorAccountGroups", {
          method: "put",
          body: {
            entitledUserId: userData!.id,
            groupId: selectedAccountGroupId,
            groupName: groupNameInput,
            createDate: null,
            updatedDate: new Date(),
          },
        });
      }

      dispatch(
        openNotificationThunk({
          variant: "info",
          text: `${groupNameInput} group has been updated`,
        })
      );

      dispatch(fetchAccountGroups(true));

      return true;
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: `Failed to update group ${initialGroup!.groupName}`,
        })
      );

      return rejectWithValue(false);
    }
  }
);
