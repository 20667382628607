import { useCallback } from "react";
import { openNotificationThunk } from "@store/Notification/thunks";
import { useAppDispatch } from "@app/hooks";

const allowedExtensions = ["png", "jpg", "jpeg", "bmp", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"];

const MAX_FILE_SIZE_MB = 5;

const useAttachmentValidator = () => {
  const dispatch = useAppDispatch();

  const validateAttachment = useCallback(
    (file: File) => {
      if (file.size > MAX_FILE_SIZE_MB * 1000000) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: `Attachment failed. File size exceeds ${MAX_FILE_SIZE_MB}MB.`,
          })
        );

        return false;
      }

      const fileExtension = file.name.split(".").pop();

      if (!allowedExtensions.includes(fileExtension!.toLowerCase())) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: "Attachment failed. File type is not allowed. Make sure your file ends with .png, .jpg, .jpeg, .bmp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, or .pdf.",
          })
        );

        return false;
      }

      return true;
    },
    [dispatch]
  );

  return {
    validateAttachment,
    allowedExtensions,
  };
};

export default useAttachmentValidator;
