import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { callExportStatusAPI } from "@reporting/store/thunks/callExportStatusAPI";
import { ReportingExport } from "@reporting/models";

export const fetchAccountSummaryExportStatusThunk = createAsyncThunk<
  ReportingExport,
  { exportId: string },
  ThunkApiConfig
>(
  "accountSummaryAlpInvest/fetchAccountSummaryExportStatusThunk",
  async ({ exportId }, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callExportStatusAPI(exportId));
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to call export status api",
        })
      );

      return rejectWithValue("Failed to fetch export status");
    }
  }
);
