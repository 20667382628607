export enum ProductSegment {
  // Carlyle AccountSummary
  CorporateAccountSummary = "Corporate:AccountSummary",
  GlobalCreditAccountSummary = "Global Credit:AccountSummary",
  GlobalPrivateEquityAccountSummary = "Global Private Equity:AccountSummary",

  // Carlyle Reports
  CorporateReports = "Corporate:Reports",
  GlobalCreditReports = "Global Credit:Reports",
  GlobalPrivateEquityReports = "Global Private Equity:Reports",

  // Funds
  CorporateFunds = "Corporate:Funds",
  GlobalCreditFunds = "Global Credit:Funds",
  GlobalPrivateEquityFunds = "Global Private Equity:Funds",

  // Data Rooms
  CorporateDataRooms = "Corporate:NewOpportunities",
  GlobalCreditDataRooms = "Global Credit:NewOpportunities",
  GlobalPrivateEquityDataRooms = "Global Private Equity:NewOpportunities",
  InvestmentSolutionsDataRooms = "Investment Solutions:NewOpportunities",

  // AlpInvest AccountSummary
  InvestmentSolutionsAccountSummary = "Investment Solutions:AccountSummary",
  InvestmentSolutionsAccountSummaryExport = "Investment Solutions:AccountSummaryExport",

  // AlpInvest PortfolioPerformance Report
  InvestmentSolutionsPortfolioPerformance = "Investment Solutions:PortfolioPerformance",
  InvestmentSolutionsPortfolioPerformanceExport = "Investment Solutions:PortfolioPerformanceExport",

  // AlpInvest DiversificationAnalysis Report
  InvestmentSolutionsDiversificationAnalysis = "Investment Solutions:DiversificationAnalysis",
  InvestmentSolutionsDiversificationAnalysisExport = "Investment Solutions:DiversificationAnalysisExport",

  // AlpInvest Demo User
  DemoUser = "Investment Solutions:Demo",

  // AlpInvest Admin
  AlpInvestAdmin = "Investment Solutions:Admin",
}
