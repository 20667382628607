import { AsyncThunk, AsyncThunkAction } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { AppDispatch } from "@store/index";

class RequestUtils {
  private readonly thunksPromiseMap = new Map<string, ReturnType<AsyncThunkAction<unknown, unknown, ThunkApiConfig>>>();

  public getAbortThunk<ThunkArg, Returned>(
    thunk: AsyncThunk<Returned, ThunkArg, ThunkApiConfig>,
    dispatch: AppDispatch
  ) {
    return (arg?: ThunkArg) => {
      const currentPromise = this.getThunkPromise(thunk.typePrefix);

      if (currentPromise) currentPromise.abort();

      const newPromise = dispatch(thunk(arg as ThunkArg));

      this.setThunkPromise(thunk.typePrefix, newPromise);

      return newPromise;
    };
  }

  private getThunkPromise(typePrefix: string) {
    return this.thunksPromiseMap.get(typePrefix);
  }

  private setThunkPromise(typePrefix: string, promise: ReturnType<AsyncThunkAction<unknown, unknown, ThunkApiConfig>>) {
    this.thunksPromiseMap.set(typePrefix, promise);
  }
}

export default new RequestUtils();
