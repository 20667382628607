import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { FileResponse } from "@shared/models/request";
import { dateConverter } from "@documents/utils";

export const generateDocumentsMissingEntitlementsReportThunk = createAsyncThunk<void, void, ThunkApiConfig>(
  "administrationReports/generateDocumentsMissingEntitlementsReportThunk",
  async (_, { dispatch, rejectWithValue, getState, extra }) => {
    const {
      administrationReports: { filters },
      entities: { entitledAccounts, entitledFunds },
    } = getState();

    const { start, end } = dateConverter(filters.selectedDate);

    const body = {
      startDate: start,
      endDate: end,
      entitledEntityIds: (filters.selectedFunds ?? []).map((fundGlobalId) => entitledFunds.data![fundGlobalId].id),
      entitledAccountIds: (filters.selectedAccounts ?? []).map(
        (accountGlobalId) => entitledAccounts.data![accountGlobalId].id
      ),
      documentTypeIds: filters.documentTypes,
      batchId: filters.batchNumber || null,
      reportFormat: "excel",
    };

    try {
      const response = await extra<FileResponse>("/documents/v1/reports/missingIFC", {
        method: "post",
        responseType: "blob",
        body,
      });

      if (response.status == 204) {
        dispatch(
          openNotificationThunk({
            variant: "warning",
            text: "Report has no data for the parameters given",
          })
        );

        return;
      }

      const today = new Date();
      const fileName = `DocumentsMissingEntitlementsReport${
        today.getMonth() + 1
      }-${today.getDate()}-${today.getFullYear()}.xlsx`;

      if (response.data.blob?.size) {
        saveAs(response.data.blob, fileName);

        dispatch(
          openNotificationThunk({
            variant: "info",
            text: "Report generation successful.",
          })
        );
      }
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Report download failed.",
        })
      );

      return rejectWithValue("The document download failed");
    }
  }
);
