import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AccountSummaryFilterOption,
  AccountSummaryFilterValues,
  AccountSummaryFundsFilter,
} from "@accountSummaryCarlyle/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { filterOptionsSelector, filterValuesSelector } from "@accountSummaryCarlyle/store/selectors";
import { changeAsOfDateFilter, changeFilterValue } from "@accountSummaryCarlyle/store/ducks";
import { fetchAccountSummaryReport } from "@accountSummaryCarlyle/store/thunks/fetchAccountSummaryReport";
import getLast3QuartersFunds from "@accountSummaryCarlyle/utils/getLast3QuartersFunds";
import RequestUtils from "@shared/utils/RequestUtils";

export const changeAsOfDateControl = createAsyncThunk<void, Partial<AccountSummaryFilterValues>, ThunkApiConfig>(
  "accountSummary/changeAsOfDateControl",
  async (filterValues, { getState, dispatch }) => {
    const filters = filterValuesSelector(getState());
    const filterOptions = filterOptionsSelector(getState());
    const fetchAccountSummaryReportCancelable = RequestUtils.getAbortThunk(fetchAccountSummaryReport, dispatch);

    if (!filterValues.asOfDate) {
      return;
    }

    if (filters.asOfDate?.current !== filterValues.asOfDate.current) {
      dispatch(changeAsOfDateFilter({ current: filterValues.asOfDate.current }));

      let fundsToPopulate: AccountSummaryFilterValues["funds"] | undefined;

      if (filterValues.asOfDate.current) {
        fundsToPopulate = getLast3QuartersFunds(filterOptions.data);
      } else {
        fundsToPopulate = filterOptions.data!.find((option: AccountSummaryFilterOption) => {
          return option.year === filterValues?.asOfDate?.year && option.quarter === filterValues?.asOfDate?.quarter;
        })?.associatedFunds;
      }

      const fundOpts = fundsToPopulate
        ? Object.values(fundsToPopulate).reduce((acc: Record<string, AccountSummaryFundsFilter>, curr) => {
            acc[curr.globalId] = {
              label: curr.label,
              globalId: curr.globalId,
              selected: filters.funds && filters.funds[curr.globalId] ? filters.funds[curr.globalId].selected : false,
            };

            return acc;
          }, {})
        : {};

      dispatch(
        changeFilterValue({
          funds: fundOpts,
        })
      );

      fetchAccountSummaryReportCancelable();

      return;
    }

    if (filters?.asOfDate?.year === filterValues.asOfDate.year) {
      if (filters.asOfDate.quarter !== filterValues.asOfDate.quarter) {
        dispatch(
          changeAsOfDateFilter({
            year: filterValues.asOfDate.year,
            quarter: filterValues?.asOfDate?.quarter,
          })
        );

        if (filters.funds) {
          if (!filterOptions || filterOptions.data === null) {
            return;
          }

          const associatedFunds = filterOptions.data.find((option: AccountSummaryFilterOption) => {
            return option.year === filterValues?.asOfDate?.year && option.quarter === filterValues?.asOfDate?.quarter;
          })?.associatedFunds;

          if (!associatedFunds) {
            return;
          }

          const fundOpts = Object.values(associatedFunds).reduce(
            (acc: Record<string, AccountSummaryFundsFilter>, curr) => {
              acc[curr.globalId] = {
                label: curr.label,
                globalId: curr.globalId,
                selected: filters.funds && filters.funds[curr.globalId] ? filters.funds[curr.globalId].selected : false,
              };

              return acc;
            },
            {}
          );

          dispatch(
            changeFilterValue({
              funds: fundOpts,
            })
          );
        }

        fetchAccountSummaryReportCancelable();
      }
    } else {
      const quarters: string[] = [];

      filterOptions?.data?.forEach((period) => {
        if (period.year === filterValues?.asOfDate?.year && period.associatedFunds) {
          quarters.push(period.quarter);
        }
      });

      dispatch(
        changeAsOfDateFilter({
          year: filterValues.asOfDate.year,
          quarter:
            quarters.indexOf(filterValues.asOfDate.quarter) !== -1
              ? filterValues.asOfDate.quarter
              : quarters.sort()[quarters.length - 1],
        })
      );

      if (filters.funds) {
        if (!filterOptions || filterOptions.data === null) {
          return;
        }

        const associatedFunds = filterOptions.data.find((option: AccountSummaryFilterOption) => {
          return option.year === filterValues?.asOfDate?.year && option.quarter === filterValues?.asOfDate?.quarter;
        })?.associatedFunds;

        if (!associatedFunds) {
          return;
        }

        const fundOpts = Object.values(associatedFunds).reduce(
          (acc: Record<string, AccountSummaryFundsFilter>, curr) => {
            acc[curr.globalId] = {
              label: curr.label,
              globalId: curr.globalId,
              selected: filters.funds && filters.funds[curr.globalId] ? filters.funds[curr.globalId].selected : false,
            };

            return acc;
          },
          {}
        );

        dispatch(
          changeFilterValue({
            funds: fundOpts,
          })
        );
      }

      fetchAccountSummaryReportCancelable();
    }
  }
);
