import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppRoute } from "@app/models/AppRoute";

const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== AppRoute.Help) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
};

export default useScrollToTop;
