import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  children: string;
  testId?: string;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: 30,
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.main,
    fontSize: 26,
    textTransform: "uppercase",
    letterSpacing: 2.6,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Heading = ({ children, testId }: Props) => {
  const classes = useStyles();

  return (
    <Box data-testid={testId} className={classes.heading}>
      {children}
    </Box>
  );
};

export default Heading;
