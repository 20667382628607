import React, { FunctionComponent, memo, useCallback, useMemo } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { AppBar, Tab, Tabs, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AppRoute } from "@app/models/AppRoute";
import { GLOBAL_INSIGHTS_URL } from "@app/constants/globalUrls";
import { APP_NAVIGATION_HEIGHT } from "@app/constants/header";
import { useAppSelector, useGetAppNavigationActiveTab, useIsAppNavigationPinned } from "@app/hooks";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { appNavigationFundsSelector } from "@app/store/selectors";
import { HeaderTabLabel } from "@app/models/Header";
import FeatureToggleService from "@services/FeatureToggleService";
import { ToggledFeature } from "@shared/models/featureToggle";
import { useGetUserPermissions } from "@user/hooks";
import AppNavigationFundsDropdown from "@app/components/AppNavigationFundsDropdown";
import AppNavigationReportingDropdown from "@app/components/AppNavigationReportingDropdown";
import { getReportingNavigationLinks } from "@reporting/utils/getReportingNavigationLinks";
import { fundsSelector } from "@store/Entities/selectors";

interface Props {
  isTransparent?: boolean;
}

const useAppBarStyles = makeStyles<Theme, { isImpersonating: boolean; isPinned: boolean } & Props>((theme) => ({
  positionSticky: {
    transition: "background .15s",
    background: ({ isTransparent, isPinned }) =>
      isTransparent && !isPinned ? "transparent" : theme.palette.primary.main,
    top: ({ isImpersonating }) => (isImpersonating ? 42 : 0),
  },
}));

const useTabsStyles = makeStyles<Theme, Props & { isPinned: boolean }>((theme) => ({
  indicator: {
    height: 10,
    bottom: -9,
  },
  scroller: {
    overflow: "visible !important",
  },
  flexContainer: {
    position: "relative",
    margin: "0 auto",
    justifyContent: "flex-end",
    maxWidth: `calc(1700px + ${theme.spacing(15)})`,
    padding: theme.spacing(0, 7.5),
    [theme.breakpoints.up("md")]: {
      maxWidth: `calc(1700px + ${theme.spacing(50)})`,
      padding: theme.spacing(0, 25),
    },
    "&::before": {
      content: "''",
      display: ({ isTransparent, isPinned }) => (isTransparent && !isPinned ? "block" : "none"),
      position: "absolute",
      bottom: 0,
      width: `calc(100% - calc(${theme.spacing(7.5)} * 2))`,
      height: 1,
      background: theme.palette.common.white,
      opacity: 0.75,
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - calc(${theme.spacing(25)} * 2))`,
      },
    },
  },
}));

const useTabStyles = makeStyles((theme) => ({
  root: {
    height: APP_NAVIGATION_HEIGHT,
    minWidth: 160,
    textTransform: "none",
    transition: "opacity .15s",
    color: `${theme.palette.common.white} !important`,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  selected: {
    opacity: 1,
    "& button": {
      opacity: 1,
    },
  },
  disabled: {
    display: "none",
  },
}));

const AppNavigation: FunctionComponent<Props> = ({ isTransparent }) => {
  const { isImpersonating } = useAppSelector(impersonationStateSelector);
  const funds = useAppSelector(appNavigationFundsSelector);
  const { loading: fundsLoading } = useAppSelector(fundsSelector);

  const history = useHistory();
  const activeTab = useGetAppNavigationActiveTab();
  const { appBarRef, isPinned } = useIsAppNavigationPinned({
    isImpersonating,
    shouldObserve: !!isTransparent,
  });

  const appBarClasses = useAppBarStyles({
    isImpersonating,
    isTransparent,
    isPinned,
  });
  const tabsClasses = useTabsStyles({ isTransparent, isPinned });
  const tabClasses = useTabStyles();

  const permissions = useGetUserPermissions();

  const reportingDropdownSections = useMemo(() => getReportingNavigationLinks(permissions), [permissions]);

  const handleTabChange = useCallback(
    (_event, value) => {
      if (value === AppRoute.InsightsHub) {
        window.open(GLOBAL_INSIGHTS_URL, "_blank")?.focus();

        return;
      }

      history.push(value);
    },
    [history]
  );

  const shouldRenderAdministrationNavigation = useMemo(
    () =>
      matchPath(history.location.pathname, {
        path: AppRoute.AdministrationBase,
      }),
    [history.location.pathname]
  );

  const RegularTabs = useMemo(
    () => [
      <Tab
        classes={tabClasses}
        label={HeaderTabLabel.InsightsHub}
        value={AppRoute.InsightsHub}
        key={AppRoute.InsightsHub}
        data-testid="insights-hub-tab"
      />,
      <Tab
        classes={tabClasses}
        label={HeaderTabLabel.DataRooms}
        value={AppRoute.DataRooms}
        key={AppRoute.DataRooms}
        disabled={!permissions.ViewDataRooms}
        data-testid="data-room-tab"
      />,
      <Tab
        classes={tabClasses}
        loading={fundsLoading}
        value={AppRoute.FundProfile}
        key={AppRoute.FundProfile}
        dropdownLabel={HeaderTabLabel.Funds}
        dropdownOptions={funds}
        onDropdownOptionClick={handleTabChange}
        component={AppNavigationFundsDropdown}
        disabled={!permissions.ViewFunds}
        data-testid="funds-tab"
      />,
      <Tab
        classes={tabClasses}
        value={AppRoute.ReportingBase}
        key={AppRoute.ReportingBase}
        dropdownLabel={HeaderTabLabel.Reporting}
        dropdownSections={reportingDropdownSections}
        onDropdownOptionClick={handleTabChange}
        component={AppNavigationReportingDropdown}
        disabled={!reportingDropdownSections.length}
        data-testid="reporting-tab"
      />,
      <Tab
        classes={tabClasses}
        label={HeaderTabLabel.Documents}
        value={AppRoute.Documents}
        key={AppRoute.Documents}
        data-testid="documents-tab"
      />,
      <Tab
        classes={tabClasses}
        disabled={!FeatureToggleService.isFeatureEnabled(ToggledFeature.MyCalendar)}
        label={HeaderTabLabel.MyCalendar}
        value={AppRoute.MyCalendar}
        key={AppRoute.MyCalendar}
        data-testid="calendar-tab"
      />,
    ],
    [funds, fundsLoading, handleTabChange, permissions, reportingDropdownSections, tabClasses]
  );

  const AdministrationTabs = useMemo(
    () => [
      <Tab
        classes={tabClasses}
        label="Reports"
        value={AppRoute.AdministrationReports}
        key={AppRoute.AdministrationReports}
      />,
      <Tab
        classes={tabClasses}
        label="Impersonate User"
        value={AppRoute.AdministrationImpersonate}
        key={AppRoute.AdministrationImpersonate}
      />,
    ],
    [tabClasses]
  );

  return (
    <AppBar position="sticky" elevation={0} classes={appBarClasses} ref={appBarRef}>
      <Tabs value={activeTab} onChange={handleTabChange} classes={tabsClasses} indicatorColor="primary">
        {shouldRenderAdministrationNavigation ? AdministrationTabs : RegularTabs}
      </Tabs>
    </AppBar>
  );
};

export default memo<Props>(AppNavigation);
