import { createAsyncThunk } from "@reduxjs/toolkit";
import { callDocumentReadStatusAPI } from "@documents/store/thunks/callDocumentReadStatusAPI";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ThunkApiConfig } from "@shared/models/redux";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";

export const updateReadFlag = createAsyncThunk<void, string[], ThunkApiConfig>(
  "documents/updateReadFlag",
  async (documentIds, { dispatch, getState, rejectWithValue }) => {
    const { isImpersonating } = impersonationStateSelector(getState());

    if (isImpersonating) return;

    try {
      await dispatch(callDocumentReadStatusAPI(documentIds));
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "There was a problem updating the document status. Please try again.",
        })
      );

      return rejectWithValue("Failed to update read status");
    }
  }
);
