import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { ThunkApiConfig } from "@shared/models/redux";
import { filterSelector } from "@accountSummaryCarlyle/store/selectors";
import DateUtils from "@shared/utils/DateUtils";
import { ReportingPeriodTypes } from "@shared/models/reporting";
import { FileResponse } from "@shared/models/request";
import { openNotificationThunk } from "@store/Notification/thunks";
import { reportMessages } from "@reporting/constants";

export const downloadAccountSummaryAsExcel = createAsyncThunk<void, void, ThunkApiConfig>(
  "accountSummary/downloadAccountSummaryAsExcel",
  async (_, { getState, extra, dispatch, rejectWithValue }) => {
    try {
      const { rawValues, accounts, selectedFunds } = filterSelector(getState());
      const date =
        rawValues.asOfDate &&
        DateUtils.convertYearQuarterToEndDate(rawValues.asOfDate.year, rawValues.asOfDate.quarter);
      const fundsArray = rawValues.funds && Object.keys(rawValues.funds);
      const accountsArray = rawValues.accounts && Object.keys(rawValues.accounts);

      const selectedAccounts = accounts
        ? Object.values(accounts)
            .filter((fund) => fund.selected)
            .map((item) => item.id)
        : null;

      const requestBody = {
        current: !!rawValues.asOfDate?.current,
        fundIds: selectedFunds?.length ? selectedFunds : fundsArray,
        investorIds: selectedAccounts?.length ? selectedAccounts : accountsArray,
        period: ReportingPeriodTypes.ITD,
        reportingDate: date,
      };

      const response = await extra<FileResponse>("reporting/v1/AccountSummary/ReportDownload", {
        body: requestBody,
        method: "post",
        responseType: "blob",
      });

      // save it and show notification
      if (response.data.blob?.size) {
        saveAs(response.data.blob, response.data.fileName);
        dispatch(
          openNotificationThunk({
            variant: "info",
            text: reportMessages.success,
          })
        );
      } else {
        throw new Error("downloadAccountSummaryAsExcel(): no data returned on request");
      }
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: reportMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch Account Summary Report");
    }
  }
);
