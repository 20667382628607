import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData, UserPreferenceName, UserState } from "@user/models";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { fetchUserProfile } from "@user/store/thunks/fetchUserProfile";
import { normalizeUserResponse } from "@user/utils/normalizeUserResponse";
import { updateUserPreference } from "@user/store/thunks/updateUserPreference";
import { convertIAGroupId } from "@user/utils/convertIAGroupId";
import { fetchUserSegmentFunction } from "@user/store/thunks/fetchUserSegmentFunction";

const initialState: UserState = ReduxUtils.getAsyncSlice<UserData>();

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeLoggedInInvestorAccountsGroup: (state, action: PayloadAction<UserData["selectedAccountGroup"]>) => {
      state.data!.selectedAccountGroup = action.payload;
    },
    resetUserSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        ReduxUtils.defaultPendingActionHandler(state);
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const normalizedUserData = normalizeUserResponse(action.payload);

        ReduxUtils.defaultFulfilledActionHandler(state, normalizedUserData);
      })
      .addCase(updateUserPreference.fulfilled, (state, action) => {
        const updatedPreferences = {
          ...state.data!.preferences,
          [action.payload.setting]:
            action.payload.setting === UserPreferenceName.UserDefaultInvestorsGroup
              ? // @ts-ignore
                convertIAGroupId(action.payload.value)
              : action.payload.value,
        };

        state.data!.preferences = updatedPreferences;
      })
      .addCase(fetchUserSegmentFunction.fulfilled, (state, action) => {
        if (!state.data) return;

        state.data.segmentFunction = action.payload;
      });
  },
});

export const { changeLoggedInInvestorAccountsGroup, resetUserSlice } = userSlice.actions;
export default userSlice.reducer;
