import { Column } from "react-table";
import { AsOfDate } from "@shared/models/filters";
import { AsyncSlice } from "@shared/models/redux";
import { Nullable } from "@shared/models/general";
import { BaseRow, ColumnMeta, ListOptionBase } from "@shared/components";
import { ReportingPeriod, ReportingPeriodFund } from "@reporting/models";

export interface SOIFundOption extends ListOptionBase {
  key: string;
}

export type ScheduleOfInvestmentsFilterOptions = ReportingPeriod[];

export type ScheduleOfInvestmentsFundsFilter = ReportingPeriodFund;

export interface ScheduleOfInvestmentsAccountsFilter {
  [key: string]: boolean;
}

export interface ScheduleOfInvestmentsFilterValues {
  asOfDate: Nullable<AsOfDate>;
  funds: Nullable<ScheduleOfInvestmentsFundsFilter>;
  accounts: Nullable<ScheduleOfInvestmentsAccountsFilter>;
}

export interface SoiDetailed {
  capitalContributed: number;
  companyId: string;
  companyName: string;
  dividentOtherIncome: number;
  fundId: string;
  gaapUnrealizedAppreciationPreAccruedCarry: number;
  initialInvestmentDate: string;
  investmentDistributionsGrossOfTaxWithholding: number;
  investmentIncome: number;
  investorId: string;
  lastUpdatedDatetime: Nullable<string>;
  realizedCapitalGainLoss: number;
  remainingInvestedCapitalAndUndistributedProceedsAtFairValue: number;
  remainingIvestedCapitalAndUndistributedProceeds: number;
  reportingCurrency: string;
  reportingDate: string;
  totalFundRemainingInvestedCapitalAndUndistributedProceedsAtFairValue: number;
  uncalledInvestmentsAtCost: number;
}

export interface SoiAggregated {
  accruedCarry: number;
  advancesToPortfolioCompanies: number;
  bankCreditFacility: number;
  capitalAccountAtFairValue: number;
  capitalContributed: number;
  cashAndCashEquivalents: number;
  deferredCredit: number;
  deferredFinancingCostsNet: number;
  deferredGain: number;
  deposits: number;
  dueFromAffiliates: number;
  dueFromPartners: number;
  dueFromToAffiliates: number;
  dueFromToPartners: number;
  dueToAffiliates: number;
  dueToPartners: number;
  fairValueOfEscrow: number;
  fairValueOfGuaranteeObligations: number;
  fairValueOfHedges: number;
  fundId: string;
  gaapUnrealizedAppreciationPreAccruedCarry: number;
  guaranteeObligations: number;
  hedgeInstrumentsAtFairValue: number;
  interestReceivable: number;
  investmentDistributionsGrossOfTaxWithholding: number;
  investmentIncome: number;
  investmentsAtFairValueGaap: number;
  investor: string;
  lessCarryInvestmentDistributions: number;
  lessCarryInvestmentIncome: number;
  netOfCarryInvestmentDistributions: number;
  netOfCarryInvestmentIncome: number;
  nonControllingInterestInSubsidiary: number;
  notePayable: number;
  otherAssets: number;
  otherLiabilities: number;
  prepaidManagementFees: number;
  proceedsAvailableFromInvestments: number;
  remainingInvestedCapitalAndUndistributedProceedsAtCost: number;
  remainingInvestedCapitalAndUndistributedProceedsAtFairValue: number;
  reportingCurrency: string;
  reportingDate: string;
  restrictedCash: number;
  uncalledInvestmentsAtCost: number;
  undistributedProceeds: number;
}

export interface ScheduleOfInvestmentsReport {
  soiDetailed: SoiDetailed[];
  soiAggregated: SoiAggregated[];
}

export interface SoiReportRequestBody {
  fundId: string;
  currency: string;
  reportingDate: Date;
}

export interface ScheduleOfInvestments {
  filterOptions: AsyncSlice<ScheduleOfInvestmentsFilterOptions>;
  filters: ScheduleOfInvestmentsFilterValues;
  report: AsyncSlice<ScheduleOfInvestmentsReport>;
  isReportDownloading: boolean;
}

export type SoiDataGridColumn = ColumnMeta<SoiDataGridRow> & Column<SoiDataGridRow>;

export interface SoiReportInvestors {
  [key: string]: SoiAggregated;
}

export interface ReportCompany {
  companyName: string;
  currency: string;
  initialInvestmentDate: Date;
}

export type ReportCompanies = [string, ReportCompany][];

export enum SoiDataGridColumnsNames {
  FundInvestments = "Fund Investments",
  InitialInvestmentDate = "Initial Investment Date",
  CapitalContributed = "Capital Contributed",
  UncalledInvestmentsAtCost = "Uncalled Investments at Cost",
  InvestmentIncome = "Investment Income*",
  InvestmentDistributions = "Investment Distributions (Gross of Tax Withholding)",
  RemainingCapitalAndUndistributedProceedsAtCost = "Remaining Capital and Undistributed Proceeds at Cost",
  GAAPUnrealizedAppreciation = "GAAP Unrealized Appreciation (Pre-Accrued Carry)**",
  RemainingInvestedCapitalAndUndistributedProceedsAtFairValue = "Remaining Invested Capital and Undistributed Proceeds at Fair Value",
  InvestmentsAtFairValue = "Investments at Fair Value",
}

export interface SoiDataGridRow extends BaseRow {
  fundInvestments: string;
  initialInvestmentDate?: string;
  capitalContributed?: number;
  uncalledInvestmentsAtCost?: number;
  investmentIncome?: number;
  remainingInvestedCapitalAndUndistributedProceedsAtCost?: number;
  investmentDistributionsGrossOfTaxWithholding?: number;
  gaapUnrealizedAppreciationPreAccruedCarry?: number;
  remainingInvestedCapitalAndUndistributedProceedsAtFairValue?: number;
}

export enum SoiDataGridFundInvestmentsValues {
  TotalFundInvestment = "Total Fund Investment",
  LessRealizedCarry = "Less Realized Carry",
  NetOfCarry = "Net of Carry",
  FairValueOfEscrow = "Fair Value of Escrow",
  FairValueOfGuaranteeObligations = "Fair Value of Guarantee Obligations",
  FairValueOfHedges = "Fair Value of Hedges",
  NonControllingInterestInSubsidiary = "Non-Controlling Interest in Subsidiary",
  InterestReceivable = "Interest Receivable",
  UncalledInvestmentsAtCost = "Uncalled Investments, at Cost",
  UndistributedProceeds = "Undistributed Proceeds",
  InvestmentsAtFairValueGAAP = "Investments at Fair Value (GAAP)",
  TieInToCapitalAccountAtFairValue = "Tie-In to Capital Account at Fair Value",
  AccruedCarry = "Accrued Carry",
  AdvancesToPortfolioCompanies = "Advances to Portfolio Companies",
  CashAndCashEquivalents = "Cash and Cash Equivalents",
  RestrictedCash = "Restricted Cash",
  ProceedsAvailableFromInvestments = "Proceeds Available from Investments",
  DueFromToPartners = `Due from/(to) Partners`,
  DueFromToAffiliates = `Due from/(to) Affiliates`,
  PrepaidManagementFees = "Prepaid Management Fees",
  Deposits = "Deposits",
  DeferredFinancingCostsNet = "Deferred Financing Costs, Net",
  OtherAssets = "Other Assets",
  DerivativeInstrumentsAtFairValue = "Derivative Instruments at Fair Value***",
  BankCreditFacility = "Bank Credit Facility",
  NotePayable = "Note Payable",
  DeferredCredit = "Deferred Credit",

  DeferredGain = "Deferred Gain",
  GuaranteeObligations = "Guarantee Obligations",
  OtherLiabilities = "Other Liabilities",
  CapitalAccountAtFairValue = "Capital Account at Fair Value",
}

export interface DownloadSOIReportRequestBody {
  fundId: string;
  investorIds: Nullable<string[]>;
  currency: Nullable<string>;
  reportingDate: string;
}
