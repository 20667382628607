import { createAsyncThunk, Dictionary } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";
import { keyBy } from "lodash";
import DateUtils from "@shared/utils/DateUtils";
import { RootState } from "@store/index";
import { filterOptionsSelector, filterValuesSelector, fundsSelector } from "@scheduleOfInvestments/store/selectors";
import { changeFilterValue } from "@scheduleOfInvestments/store/ducks";
import { investorsSelector } from "@store/Entities/selectors";
import { Investors } from "@store/Entities/models";
import {
  ScheduleOfInvestmentsAccountsFilter,
  ScheduleOfInvestmentsFilterOptions,
  ScheduleOfInvestmentsFilterValues,
  ScheduleOfInvestmentsFundsFilter,
  ScheduleOfInvestmentsReport,
  SoiAggregated,
  SoiReportRequestBody,
} from "@scheduleOfInvestments/models";
import { SpecialAccountKeys } from "@shared/models/reporting";
import { reportMessages } from "@reporting/constants";
import { openNotificationThunk } from "@store/Notification/thunks";
import { getInvestorAccountsIds } from "@scheduleOfInvestments/utils";
import { ThunkApiConfig } from "@shared/models/redux";
import { FileResponse } from "@shared/models/request";
import { normalizeReportingPeriodsResponse } from "@reporting/utils";
import { ReportingPeriodsResponse } from "@reporting/models";
import RequestUtils from "@shared/utils/RequestUtils";

export const fetchScheduleOfInvestmentsFilters = createAsyncThunk<
  ScheduleOfInvestmentsFilterOptions,
  void,
  ThunkApiConfig
>("scheduleOfInvestments/fetchScheduleOfInvestmentsFilterOptions", async (_, { rejectWithValue, extra, signal }) => {
  try {
    const response = await extra<ReportingPeriodsResponse[]>("/reporting/v1/SOI/Periods", {
      signal,
      method: "post",
    });

    return normalizeReportingPeriodsResponse(response.data);
  } catch (e) {
    return rejectWithValue("could not fetch fund options");
  }
});

const extractInvestorOptionsFromReport = (
  report: Dictionary<SoiAggregated>,
  investors: Investors
): ScheduleOfInvestmentsAccountsFilter => {
  return Object.keys(report).reduce((result: ScheduleOfInvestmentsAccountsFilter, investor: string) => {
    if (
      investors[investor] &&
      investor !== SpecialAccountKeys.CarlyleGroupAccount &&
      investor !== SpecialAccountKeys.TotalFundAccount
    ) {
      result[investor] = false;
    }

    return result;
  }, {});
};

export const fetchScheduleOfInvestmentsReport = createAsyncThunk<ScheduleOfInvestmentsReport, void, ThunkApiConfig>(
  "scheduleOfInvestments/fetchScheduleOfInvestmentsReports",
  async (_, { rejectWithValue, getState, extra, signal, dispatch }) => {
    try {
      const filters = filterValuesSelector(getState());

      if (filters.funds && filters.asOfDate) {
        const fundId = filters.funds.value.split("-")[0];
        const currency = filters.funds.currency;
        const year = filters.asOfDate.year.toString();
        const quarter = filters.asOfDate.quarter;
        const reportingDate = DateUtils.convertYearQuarterToEndDate(year, quarter);

        const body = {
          fundId,
          currency,
          reportingDate,
          // investorIds: filters.accounts,
        };

        const response = await extra<ScheduleOfInvestmentsReport, SoiReportRequestBody>("/reporting/v1/SOI/Report", {
          method: "post",
          body,
          signal,
        });

        const rootState: RootState = getState();

        const { data: investors } = await investorsSelector(rootState);

        const report = keyBy(response.data.soiAggregated, "investor");
        const options = investors ? extractInvestorOptionsFromReport(report, investors) : {};

        Object.keys(options).forEach((key) => {
          // @ts-ignore
          if (filters.accounts && filters.accounts[key]) {
            options[key] = true;
          }
        });

        await dispatch(changeFilterValue({ accounts: options }));

        return {
          soiAggregated: response.data.soiAggregated,
          soiDetailed: response.data.soiDetailed,
        };
      }

      return { soiAggregated: [], soiDetailed: [] };
    } catch (e) {
      return rejectWithValue("Failed to fetch Schedule of investments Reports");
    }
  }
);

export const changeSoiFilterValues = createAsyncThunk<void, Partial<ScheduleOfInvestmentsFilterValues>, ThunkApiConfig>(
  "scheduleOfInvestments/changeSoiFilterValues",
  async (filterValues, { getState, dispatch }) => {
    const filters = filterValuesSelector(getState());
    const newFilterValues: Partial<ScheduleOfInvestmentsFilterValues> = {};
    const filterOptions = filterOptionsSelector(getState());
    const fetchScheduleOfInvestmentsReportCancelable = RequestUtils.getAbortThunk(
      fetchScheduleOfInvestmentsReport,
      dispatch
    );

    if (filterValues.asOfDate) {
      if (filters?.asOfDate?.year === filterValues.asOfDate.year) {
        if (filters.asOfDate.quarter !== filterValues.asOfDate.quarter) {
          const newAsOfDate = {
            year: filterValues.asOfDate.year,
            quarter: filterValues?.asOfDate?.quarter,
          };

          newFilterValues["asOfDate"] = newAsOfDate;
          await dispatch(changeFilterValue(newFilterValues));
          const fundList = await fundsSelector(getState());

          if (fundList && fundList.length) {
            if (filters.funds) {
              const currentSelectedFund = fundList.find((fund: any) => {
                return fund.id === filters.funds!.value;
              });

              if (!currentSelectedFund) {
                const latestFund = {
                  value: fundList[0].id as string,
                  label: fundList[0].label as string,
                  currency: fundList[0].key && fundList[0].key.split("-")[1],
                };

                await dispatch(
                  changeFilterValue({
                    funds: latestFund as ScheduleOfInvestmentsFundsFilter,
                  })
                );
              }
            }
          }

          await fetchScheduleOfInvestmentsReportCancelable();
        }
      } else if (filters?.asOfDate?.year !== filterValues.asOfDate.year) {
        const quarters: string[] = [];

        filterOptions?.data?.map((period) => {
          if (period.year === filterValues?.asOfDate?.year) {
            if (period.associatedFunds) {
              quarters.push(period.quarter);
            }
          }
        });
        const newAsOfDate = {
          year: filterValues.asOfDate.year,
          quarter:
            quarters.indexOf(filterValues.asOfDate.quarter) !== -1
              ? filterValues.asOfDate.quarter
              : quarters.sort()[quarters.length - 1],
        };

        newFilterValues.asOfDate = newAsOfDate;
        await dispatch(changeFilterValue(newFilterValues));
        const fundList = fundsSelector(getState());

        if (fundList && fundList.length) {
          if (filters.funds) {
            const currentSelectedFund = fundList.find((fund) => {
              return fund.id === filters.funds!.value;
            });

            if (!currentSelectedFund) {
              const latestFund = {
                value: fundList[0].id as string,
                label: fundList[0].label as string,
                currency: fundList[0].key && fundList[0].key.split("-")[1],
              };

              await dispatch(
                changeFilterValue({
                  funds: latestFund as ScheduleOfInvestmentsFundsFilter,
                })
              );
            }
          }
        }

        await fetchScheduleOfInvestmentsReportCancelable();
      }
    } else {
      dispatch(changeFilterValue(filterValues));
      await fetchScheduleOfInvestmentsReportCancelable();
    }
  }
);

export const initScheduleOfInvestments = createAsyncThunk<void, void, ThunkApiConfig>(
  "scheduleOfInvestments/initScheduleOfInvestments",
  async (_, { dispatch }) => {
    const fetchScheduleOfInvestmentsFiltersCancelable = RequestUtils.getAbortThunk(
      fetchScheduleOfInvestmentsFilters,
      dispatch
    );

    const fetchScheduleOfInvestmentsReportCancelable = RequestUtils.getAbortThunk(
      fetchScheduleOfInvestmentsReport,
      dispatch
    );

    await fetchScheduleOfInvestmentsFiltersCancelable();

    await fetchScheduleOfInvestmentsReportCancelable();
  }
);

export const downloadScheduleOfInvestmentsReport = createAsyncThunk<void, void, ThunkApiConfig>(
  "scheduleOfInvestments/downloadReport",
  async (_, { getState, extra, dispatch, rejectWithValue }) => {
    try {
      const filterValues = filterValuesSelector(getState());

      if (!filterValues.funds || !filterValues.asOfDate) {
        throw new Error("No required filter data provided");
      }

      const currency = filterValues.funds.currency;
      const fundId = filterValues.funds.value.split("-")[0];
      const investorIds = getInvestorAccountsIds(filterValues.accounts);
      const reportingDate = DateUtils.convertYearQuarterToEndDate(
        filterValues.asOfDate.year,
        filterValues.asOfDate.quarter
      ).toISOString();

      const requestBody = {
        currency,
        fundId,
        investorIds,
        reportingDate,
      };

      const response = await extra<FileResponse>("reporting/v1/SOI/reportdownload", {
        method: "post",
        body: requestBody,
        responseType: "arraybuffer",
      });

      if (response.data.blob?.size) {
        saveAs(response.data.blob, response.data.fileName);
        dispatch(
          openNotificationThunk({
            variant: "info",
            text: reportMessages.success,
          })
        );
      } else {
        throw new Error("downloadSOIReport(): no data returned on request");
      }
    } catch (e) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: reportMessages.warning,
        })
      );

      return rejectWithValue("Failed to fetch Schedule Of Investments Report");
    }
  }
);
