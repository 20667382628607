import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { fetchUserProfile } from "@user/store/thunks/fetchUserProfile";
import { fetchUserTerms } from "@terms/store/thunks/fetchUserTerms";
import { fetchUserSegmentFunction } from "@user/store/thunks/fetchUserSegmentFunction";

export const initializeApp = createAsyncThunk<void, void, ThunkApiConfig>(
  "app/initializeApp",
  async (_, { dispatch }) => {
    try {
      await dispatch(fetchUserProfile()).unwrap();

      await Promise.all([dispatch(fetchUserTerms()), dispatch(fetchUserSegmentFunction())]);
    } catch (error) {
      console.error(error);
    }
  }
);
