import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Tooltip } from "@mui/material";
import { ReactComponent as InfoIcon } from "@assets/svg/info_circle.svg";

interface TileHeaderProps {
  title: string;
  tooltip?: string;
}

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    letterSpacing: 2.4,
    "@media (max-width : 414px )": {
      width: "70%",
    },
  },
  icon: {
    color: theme.palette.info.main,
    "&:hover > path": {
      fill: theme.palette.info.main,
    },
    width: 18,
    height: 18,
    marginLeft: theme.spacing(3),
  },
}));

const ToolTipInfoIcon: FunctionComponent<{ tooltip: string }> = ({ tooltip }) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip} placement="right">
      <InfoIcon className={classes.icon} />
    </Tooltip>
  );
};

const TileHeader: FunctionComponent<TileHeaderProps> = ({ title, tooltip = null }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Typography variant="subhead2" className={classes.title}>
        {title}
      </Typography>
      {tooltip && <ToolTipInfoIcon tooltip={tooltip} />}
    </div>
  );
};

export default TileHeader;
