import React, { forwardRef, PropsWithChildren, useCallback, useMemo } from "react";
import { Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import cn from "classnames";
import { SelectToggleButtonProps } from "@shared/components/Select/models";
import { ReactComponent as ArrowDropdown } from "@assets/svg/arrow_dropdown.svg";

const useStyles = makeStyles<Theme, Pick<SelectToggleButtonProps, "open" | "variant" | "loading">>((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightMedium,
    cursor: ({ loading }) => (loading ? "default" : "pointer"),
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  small: {
    fontSize: 16,
    height: 35,
  },
  medium: {
    fontSize: 18,
    height: 45,
  },
  large: {
    fontSize: 20,
    height: 55,
  },
  contained: {
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 5),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}0a`,
    },
  },
  transparent: {
    backgroundColor: "transparent",
    color: theme.palette.common.white,
    padding: theme.spacing(0, 5),
    "& span": {
      fontSize: theme.typography.fontSize,
      fontFamily: theme.typography.fontFamily,
      "&::before": {
        display: "block",
        content: "attr(data-title)",
        fontWeight: "bold",
        height: "0",
        overflow: "hidden",
        visibility: "hidden",
      },
    },
    "&:active span, &:focus span": {
      position: "relative",
      top: "0px",
      left: "0px",
    },
    "&:hover": {
      cursor: ({ loading }) => (loading ? "default" : "pointer"),
      boxShadow: "unset",
      backgroundColor: "transparent",
      opacity: 1,
      "& span": {
        color: theme.palette.common.white,
      },
    },
  },
  underlined: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  loader: {
    marginLeft: theme.spacing(2.5),
    color: "inherit",
  },
  arrow: {
    transition: "transform ease-in .25s",
    transform: ({ open }) => (open ? "rotate(180deg)" : "none"),
    marginLeft: theme.spacing(2.5),
  },
}));

const SelectToggleButton = forwardRef<HTMLButtonElement, PropsWithChildren<SelectToggleButtonProps>>(
  ({ children, size = "medium", variant = "underlined", open, disabled, loading, testId, onClick }, ref) => {
    const classes = useStyles({ open, variant, loading });

    const handleClick = useCallback(() => {
      if (!disabled && !loading && onClick) {
        onClick();
      }
    }, [loading, disabled, onClick]);

    const className = useMemo(
      () =>
        cn({
          [classes.root]: true,
          [classes.small]: size === "small",
          [classes.medium]: size === "medium",
          [classes.large]: size === "large",
          [classes.contained]: variant === "contained",
          [classes.transparent]: variant === "transparent",
          [classes.underlined]: variant === "underlined",
        }),
      [classes, size, variant]
    );

    return (
      <Button
        fullWidth
        ref={ref}
        disabled={disabled}
        onClick={handleClick}
        className={className}
        data-testid={testId ? `toggle-button-${testId}` : "toggle-button"}
      >
        <span
          data-title={children as string}
          className={classes.text}
          data-testid={testId ? `${testId}-toggle-button` : "toggle-button-content"}
        >
          {children}
        </span>
        {loading ? (
          <CircularProgress className={classes.loader} size={15} />
        ) : (
          <ArrowDropdown
            className={classes.arrow}
            data-testid={testId ? `arrow-dropdown-${testId}` : "arrow-dropdown"}
          />
        )}
      </Button>
    );
  }
);

SelectToggleButton.displayName = "forwardRef(SelectToggleButton)";

export default SelectToggleButton;
