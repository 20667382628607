import { AccountSummaryDataGridColumn, AccountSummaryDataGridColumnNames } from "../models";

export const accountSummaryDataGridColumns: ReadonlyArray<AccountSummaryDataGridColumn> = [
  {
    accessor: "fundType",
    Header: AccountSummaryDataGridColumnNames.FundType,
    flex: 1,
    letterSpacing: 0,
    withTooltip: true,
    minWidth: 150,
  },
  {
    accessor: "currency",
    Header: AccountSummaryDataGridColumnNames.Currency,
    flex: 1,
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "reportingDate",
    Header: AccountSummaryDataGridColumnNames.AsOf,
    flex: 0.6,
    letterSpacing: 0,
    minWidth: 90,
  },
  {
    accessor: "commitment",
    Header: AccountSummaryDataGridColumnNames.Commitment,
    flex: 1.2,
    type: "currency",
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "remainingCommitments",
    Header: AccountSummaryDataGridColumnNames.RemainingCommitment,
    flex: 1.2,
    type: "currency",
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "totalContributedCapital",
    Header: AccountSummaryDataGridColumnNames.TotalContributedCapital,
    flex: 1.2,
    type: "currency",
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "totalDistributions",
    Header: AccountSummaryDataGridColumnNames.TotalDistributions,
    flex: 1.2,
    type: "currency",
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "capitalAccountAtFairValue",
    Header: AccountSummaryDataGridColumnNames.CapitalAccountAtFairValue,
    flex: 1.2,
    type: "currency",
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "netMoic",
    Header: AccountSummaryDataGridColumnNames.NetMoic,
    flex: 0.7,
    letterSpacing: 0,
    minWidth: 100,
  },
];

export enum AccountSummaryTitles {
  AccountSummary = "Account Summary",
  RemainingCommitment = "Remaining Commitment By Fund Type",
  CapitalAccount = "Capital Account at Fair Value By Fund Type",
}

export const FootnoteMessage =
  "* For purposes of aggregation, all currencies have been converted to USD as of the reporting period spot rate.";
