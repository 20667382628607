import { createAsyncThunk } from "@reduxjs/toolkit";
import { callActionItemFlagAPI } from "@documents/store/thunks/callActionItemFlagAPI";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { openNotificationThunk } from "@store/Notification/thunks";
import { ThunkApiConfig } from "@shared/models/redux";
import { ToggleActionItemFlagArgs } from "@documents/models/api.models";

export const toggleActionItemFlag = createAsyncThunk<void, ToggleActionItemFlagArgs, ThunkApiConfig>(
  "documents/toggleActionItemFlag",
  async ({ actionItem, id, otherAccounts, onSendRequest, documentType }, { dispatch, getState, rejectWithValue }) => {
    dispatch(onSendRequest({ id, actionItem: !actionItem, documentType }));

    const { isImpersonating } = impersonationStateSelector(getState());

    if (isImpersonating) return;

    try {
      if (otherAccounts?.length) {
        await Promise.all(
          otherAccounts.map((account) =>
            dispatch(
              callActionItemFlagAPI({
                actionItem,
                id: account.documentId,
              })
            )
          )
        );
      }

      await dispatch(
        callActionItemFlagAPI({
          actionItem,
          id: id!,
        })
      );
    } catch (e) {
      dispatch(onSendRequest({ id, actionItem, documentType }));

      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Something went wrong. Please try again",
        })
      );

      return rejectWithValue("Failed to toggle action item flag");
    }
  }
);
