import { createSlice } from "@reduxjs/toolkit";
import { ReportingState } from "@reporting/models";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { fetchEmbedTokenThunk } from "@reporting/store/thunks/fetchEmbedTokenThunk";

const initialState: ReportingState = {
  embedTokenConfig: ReduxUtils.getAsyncSlice(),
};

const reportingSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmbedTokenThunk.pending, (state) => ReduxUtils.defaultPendingActionHandler(state.embedTokenConfig))
      .addCase(fetchEmbedTokenThunk.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.embedTokenConfig, {
          token: action.payload.token,
          expiration: action.payload.expiration,
        });
      })
      .addCase(fetchEmbedTokenThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.embedTokenConfig, action.payload as string)
      );
  },
});

export default reportingSlice.reducer;
