import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationPayload } from "@store/Notification/models";
import { closeNotification } from "@store/Notification/actions";

export const openNotificationThunk = createAsyncThunk<NotificationPayload, NotificationPayload>(
  "notification/openNotificationThunk",
  (notification, { dispatch }) => {
    setTimeout(() => dispatch(closeNotification()), 5000);

    return notification;
  }
);
