import React, { ChangeEventHandler, FunctionComponent, useCallback, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { noop } from "lodash";
import { useTheme } from "@mui/styles";
import { Button, Chip, RoundedIcon } from "@shared/components";
import { investorRequestSelector } from "@investorRequest/store/selectors";
import { updateCommonRequestData } from "@investorRequest/store/ducks";
import FileUtils from "@shared/utils/FileUtils";
import useAttachmentValidator from "@investorRequest/hooks/useAttacmentValidator";
import { MimeType } from "@shared/models/mimeType";
import { useAppDispatch, useAppSelector, useMobileLayout } from "@app/hooks";
import { ReactComponent as Clip } from "@assets/svg/clip.svg";
import { ReactComponent as ExcelIcon } from "@assets/svg/file_excel.svg";
import { ReactComponent as ImageIcon } from "@assets/svg/file_image.svg";
import { ReactComponent as PdfIcon } from "@assets/svg/file_pdf.svg";
import { ReactComponent as PowerpointIcon } from "@assets/svg/file_powerpoint.svg";
import { ReactComponent as WordIcon } from "@assets/svg/file_word.svg";
import { ReactComponent as DocumentIcon } from "@assets/svg/file_document.svg";
import { ReactComponent as ExclamationIcon } from "@assets/svg/exclamation.svg";

interface Props {
  loading: boolean;
  onSubmit(): void;
}

const InvestorRequestControls: FunctionComponent<Props> = ({ onSubmit, loading }) => {
  const {
    requestType,
    validationWarningShown,
    commonFields: { attachment },
  } = useAppSelector(investorRequestSelector);

  const dispatch = useAppDispatch();

  const { validateAttachment, allowedExtensions } = useAttachmentValidator();

  const accept = useMemo(
    () => `image/png, ${allowedExtensions.map((ext) => `.${ext}`).join(", ")}`,
    [allowedExtensions]
  );

  const AttachmentIcon = useMemo(() => {
    switch (attachment?.type) {
      case MimeType.JPEG:
      case MimeType.PNG:
        return ImageIcon;
      case MimeType.PDF:
        return PdfIcon;
      case MimeType.DOCX:
        return WordIcon;
      case MimeType.PPTX:
        return PowerpointIcon;
      case MimeType.XLSX:
        return ExcelIcon;
      default:
        return DocumentIcon;
    }
  }, [attachment]);

  const handleAttachFile = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      if (!event.target.files) return;

      const file = event.target.files[0];

      if (!validateAttachment(file)) return;

      const base64 = await FileUtils.toBase64String(file);

      dispatch(
        updateCommonRequestData({
          attachment: {
            base64,
            name: file.name,
            type: file.type as MimeType,
          },
        })
      );
    },
    [dispatch, validateAttachment]
  );

  const handleRemoveAttachment = useCallback(() => {
    dispatch(updateCommonRequestData({ attachment: null }));
  }, [dispatch]);

  const theme = useTheme();
  const isMobile = useMobileLayout();

  return (
    <>
      {attachment && (
        <Box pl={5} pb={isMobile ? 4 : undefined}>
          <Chip Icon={AttachmentIcon} label={attachment.name} onDelete={handleRemoveAttachment} />
          <Box fontSize={14} mt={2.5}>
            Up to one document may be attached; max file size of 5MB
          </Box>
        </Box>
      )}
      <Grid
        item
        container
        sx={{
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "center" : "space-between",
          width: isMobile ? "auto" : "100%",
        }}
        data-testid="investor-request-controls"
      >
        <Grid
          item
          container
          xs={isMobile ? undefined : 9}
          sx={{
            flexDirection: isMobile ? "column-reverse" : "row",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Button
              upload
              fullWidth
              size={isMobile ? "small" : "medium"}
              onClick={noop}
              shape="rounded"
              startIcon={<Clip />}
              disabled={loading || !requestType || !!attachment}
            >
              Attach a document
              <input
                type="file"
                id="attachment"
                name="attachment"
                hidden
                accept={accept}
                value=""
                onChange={handleAttachFile}
              />
            </Button>
          </Grid>
          {validationWarningShown && (
            <Grid
              item
              sx={{
                paddingBottom: isMobile ? 4 : "unset",
                alignItems: "center",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                height="100%"
                color="warning.main"
              >
                <Box fontSize={15} fontWeight="500" display="flex" alignItems="center">
                  <RoundedIcon Icon={ExclamationIcon} variant="warning" />
                  <Box ml={2}>ERROR</Box>
                </Box>
                <Box fontSize={18}>Please finish filling out information</Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item xs={isMobile ? undefined : 2} sx={{ paddingTop: isMobile ? theme.spacing(4) : "unset" }}>
          <Button
            fullWidth
            size={isMobile ? "small" : "medium"}
            onClick={onSubmit}
            shape="rounded"
            disabled={loading || !requestType}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default InvestorRequestControls;
