import { AppThunkAPI } from "@shared/models/redux";
import { ReportingExport } from "@reporting/models";

type CallExportAPI = (report: string, type: string, exportParams: string) => AppThunkAPI<Promise<ReportingExport>>;

export const callExportAPI: CallExportAPI =
  (report: string, type: string, exportParams: string) => async (_dispatch, _getState, extra) => {
    const response = await extra<ReportingExport>(`/entitlements/v1/reports/${report}/export`, {
      queryParams: { type: type },
      method: "POST",
      body: exportParams,
    });

    return response.data;
  };
