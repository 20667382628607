import { createAsyncThunk } from "@reduxjs/toolkit";
import { FundFPSIS } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchFPSISData = createAsyncThunk<
  FundFPSIS[],
  {
    entityID: string;
    date: string;
  },
  ThunkApiConfig
>("funds/fetchFPSISData", async ({ entityID, date }, { rejectWithValue, extra, signal }) => {
  const formatDate = date.split("T")[0];

  try {
    const response = await extra<FundFPSIS[]>(`/reporting/v1/FPSIS/${entityID}:${formatDate}`, { signal });

    return response.data;
  } catch (e) {
    return rejectWithValue("Failed to fetch FPSIS data");
  }
});
