import { History } from "history";
import { matchPath } from "react-router-dom";
import { AppRoute } from "@app/models/AppRoute";
import { Nullable } from "@shared/models/general";

class RouterUtils {
  static getMatchPath<P extends { [K: string]: string }>(history: History, path: AppRoute) {
    return matchPath<P>(history.location.pathname, {
      path,
    });
  }

  static checkPathMatchExactRoute(history: History, path: AppRoute): boolean {
    const match = this.getMatchPath(history, path);

    return match ? match.isExact : false;
  }

  static checkPathMatchExactRouteAndReturnRouteParams<P extends { [K: string]: string }>(
    history: History,
    path: AppRoute
  ): Nullable<P> {
    const match = this.getMatchPath<P>(history, path);

    return match?.isExact ? match.params : null;
  }
}

export default RouterUtils;
