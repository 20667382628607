import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ProfileMenuOption, ProfileMenuOptionId } from "@app/models/Header";
import { AppRoute } from "@app/models/AppRoute";
import { stopImpersonationSession } from "@impersonateUser/store/actions";
import useAppDispatch from "@app/hooks/useAppDispatch";
import { useAppSelector } from "@app/hooks/index";
import { impersonationStateSelector } from "@impersonateUser/store/selectors";
import { appHeaderBarSelector } from "@app/store/selectors";

const useAppProfile = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { canImpersonate, isImpersonating } = useAppSelector(impersonationStateSelector);
  const { userName } = useAppSelector(appHeaderBarSelector);

  const handleProfileMenuClick = useCallback(
    (option: ProfileMenuOption) => {
      switch (option.id) {
        case ProfileMenuOptionId.Preferences:
          history.push(AppRoute.UserProfile);
          break;
        case ProfileMenuOptionId.StartImpersonation:
          history.push(AppRoute.AdministrationImpersonate);
          break;
        case ProfileMenuOptionId.StopImpersonation:
          dispatch(stopImpersonationSession());
          history.push(AppRoute.Home);
          break;
        default:
          break;
      }
    },
    [dispatch, history]
  );

  const handleSignOut = useCallback(() => {
    history.push(AppRoute.SignOut);
  }, [history]);

  return {
    userName,
    canImpersonate,
    isImpersonating,
    handleProfileMenuClick,
    handleSignOut,
  };
};

export default useAppProfile;
