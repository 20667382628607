import React, { useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import cn from "classnames";
import { ExternalLegend, LegendPayload } from "@shared/components/Donut/models";
import NumberUtils from "@shared/utils/NumberUtils";
import { donutWidth } from "./constants";

const useStyles = makeStyles<Theme, { legendWidth?: number }>((theme) => ({
  legend: {
    display: "flex",
    width: ({ legendWidth }) => legendWidth ?? donutWidth,
    listStyleType: "none",
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  container: {
    listStyleType: "none",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    columnGap: 30,
    [theme.breakpoints.down("md")]: { paddingInlineStart: 0 },
  },
  element: {
    display: "flex",
    width: 285,
    [theme.breakpoints.down("md")]: {
      width: 245,
    },
    height: 20,
    "& div": {
      display: "flex",
    },
  },
  colorBox: {
    display: "flex",
    marginRight: 10,
  },
  percent: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: "auto", // flex trick - to push element to the end
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  fundName: {
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "inline",
      maxWidth: 185,
    },
  },
}));

const CustomLegend = ({ payload, external, legendWidth, numberFormat }: ExternalLegend) => {
  const classes = useStyles({ legendWidth });

  const extractLegends = useCallback(() => {
    return (
      payload &&
      payload.map((value: LegendPayload, index: number) => {
        return (
          <li
            className={classes.element}
            key={`item-${index}`}
            onMouseEnter={() => {
              external.setActiveIndex(index);
            }}
            onMouseLeave={() => {
              external.setActiveIndex(null);
            }}
          >
            <svg className={classes.colorBox} width="14" height="18" viewBox="0 5 32 35" version="1.1">
              <path fill={value.color} d="M0,4h32v32h-32z" />
            </svg>

            <span className={cn(external.activeIndex === index && classes.bold, classes.fundName)}>{value.value}</span>

            <div className={classes.percent}>{NumberUtils.formatNumber(value?.payload?.value, numberFormat)} %</div>
          </li>
        );
      })
    );
  }, [classes, external, payload, numberFormat]);

  return (
    <div className={classes.legend}>
      <ul className={classes.container}>{extractLegends()}</ul>
    </div>
  );
};

export default CustomLegend;
