import { TermAppearanceType, TermRuleConfig, TermsRulesAssociationsConfig } from "@terms/models";
import {
  fetchApplicationWideEntitiesIfAllSiteWideTermsAccepted,
  fetchApplicationWideEntitiesIfThereIsNoSiteWideTerms,
  redirectToHomePageIfFundTermDeclinedOnAppInitialize,
  redirectToHomePageIfReportingTermDeclinedOnAppInitialize,
  signOutIfSiteWideTermDeclined,
} from "@terms/constants/rules/initializeApplication.const";
import {
  preventViewDocumentIfAnyTermIsDeclined,
  viewDocumentIfAllEntitledEntitiesAndDocumentTypesTermsAccepted,
} from "@terms/constants/rules/viewDocument.const";
import { redirectToPreviousPageIfAnyTermDeclined } from "@terms/constants/rules/openTermedPage";

type TermsRulesMap = {
  [key in TermAppearanceType]: (
    associations?: TermsRulesAssociationsConfig,
    extraArgs?: Record<string, any>
  ) => TermRuleConfig;
};

export const termsRulesMap: TermsRulesMap = {
  [TermAppearanceType.InitializeApplication]: () => ({
    afterEach: [signOutIfSiteWideTermDeclined, fetchApplicationWideEntitiesIfAllSiteWideTermsAccepted],
    afterAll: [
      fetchApplicationWideEntitiesIfThereIsNoSiteWideTerms,
      redirectToHomePageIfReportingTermDeclinedOnAppInitialize,
      redirectToHomePageIfFundTermDeclinedOnAppInitialize,
    ],
  }),
  [TermAppearanceType.OpenReportingPage]: () => ({
    afterEach: [],
    afterAll: [redirectToPreviousPageIfAnyTermDeclined],
  }),
  [TermAppearanceType.OpenFundProfilePage]: () => ({
    afterEach: [],
    afterAll: [redirectToPreviousPageIfAnyTermDeclined],
  }),
  [TermAppearanceType.ViewDocument]: (associations, extraArgs) => ({
    afterEach: [],
    afterAll: [
      viewDocumentIfAllEntitledEntitiesAndDocumentTypesTermsAccepted(associations, extraArgs),
      preventViewDocumentIfAnyTermIsDeclined(associations, extraArgs),
    ],
  }),
};
