import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import AdministrationHome from "@administration/components/AdministrationHome";
import { AppRoute } from "@app/models/AppRoute";
import { useResetSelectedIAGroup } from "@administration/hooks";

const ImpersonateUserPage = lazy(() => import("@impersonateUser/containers/ImpersonateUserPage"));
const AdministrationReportsNewPage = lazy(() => import("@administrationReports/containers/AdministrationReportsPage"));

const AdministrationRouter = () => {
  useResetSelectedIAGroup();

  return (
    <Switch>
      <Route exact path={AppRoute.AdministrationBase} component={AdministrationHome} />
      <Route path={AppRoute.AdministrationImpersonate} component={ImpersonateUserPage} />
      <Route path={AppRoute.AdministrationReports} component={AdministrationReportsNewPage} />
    </Switch>
  );
};

export default AdministrationRouter;
