import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { TermAppearanceType } from "@terms/models";
import { AppRoute } from "@app/models/AppRoute";
import { LocalStorageKeys } from "@app/constants/localStorage";
import TermedRoute from "@terms/containers/TermedRoute";
import AppLoadingIndicator from "@app/components/AppLoadingIndicator";
import ReportingRouter from "@reporting/containers/ReportingRouter";
import AdministrationRouter from "@administration/containers/AdministrationRouter";
import ErrorBoundary from "@app/containers/ErrorBoundary";
import FeatureToggleService from "@services/FeatureToggleService";
import { ToggledFeature } from "@shared/models/featureToggle";
import { useGetUserPermissions } from "@user/hooks";
import { Permission } from "@user/models";

const HomePage = lazy(() => import("@home/containers/HomePage"));
const DocumentsPage = lazy(() => import("@documents/containers/DocumentsPage"));
const DataRoomsPage = lazy(() => import("@dataRooms/containers/DataRoomsPage"));
const DataRoomProfilePage = lazy(() => import("@dataRooms/containers/DataRoomProfilePage"));
const ProfilePage = lazy(() => import("@profile/containers/ProfilePage"));
const FrequentlyAskedQuestionsPage = lazy(
  () => import("@frequentlyAskedQuestions/containers/FrequentlyAskedQuestionsPage")
);
const FundProfilePage = lazy(() => import("@funds/containers/FundProfilePage"));
const MyCalendarPage = lazy(() => import("@myCalendar/containers/MyCalendarPage"));
const MyCalendarEventPage = lazy(() => import("@myCalendar/containers/MyCalendarEventPage"));
const InsightsHubArticlePage = lazy(() => import("@insightsHub/containers/InsightsHubArticlePage"));

const AppRouter = () => {
  const permissions = useGetUserPermissions();

  return (
    <ErrorBoundary>
      <Suspense fallback={<AppLoadingIndicator />}>
        <Switch>
          <Route exact path={AppRoute.Home} component={HomePage} />
          <Route path={AppRoute.Documents} component={DocumentsPage} />
          <Route path={AppRoute.UserProfile} component={ProfilePage} />
          {permissions[Permission.ViewDataRooms] && [
            <Route key={AppRoute.DataRoom} path={AppRoute.DataRoom} component={DataRoomProfilePage} />,
            <Route key={AppRoute.DataRooms} path={AppRoute.DataRooms} component={DataRoomsPage} />,
          ]}
          <TermedRoute
            type={TermAppearanceType.OpenReportingPage}
            path={AppRoute.ReportingBase}
            component={ReportingRouter}
          />
          {permissions[Permission.ViewFunds] && (
            <TermedRoute
              type={TermAppearanceType.OpenFundProfilePage}
              path={AppRoute.FundProfile}
              component={FundProfilePage}
            />
          )}
          {FeatureToggleService.isFeatureEnabled(ToggledFeature.MyCalendar) && [
            <Route key={AppRoute.MyCalendarEvent} path={AppRoute.MyCalendarEvent} component={MyCalendarEventPage} />,
            <Route key={AppRoute.MyCalendar} path={AppRoute.MyCalendar} component={MyCalendarPage} />,
          ]}
          <Route path={AppRoute.InsightsHubArticle} component={InsightsHubArticlePage} />
          <Route path={AppRoute.AdministrationBase} component={AdministrationRouter} />
          <Route path={AppRoute.Help} component={FrequentlyAskedQuestionsPage} />

          <Route
            path={AppRoute.Login}
            component={() => {
              window.location.assign(process.env.REACT_APP_AUTH_LOGIN_URI!);

              return <AppLoadingIndicator />;
            }}
          />
          <Route
            path={AppRoute.SignOut}
            component={() => {
              localStorage.removeItem(LocalStorageKeys.LpcActiveSession);
              window.location.assign(process.env.REACT_APP_AUTH_SIGNOUT_URI!);

              return <AppLoadingIndicator />;
            }}
          />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppRouter;
