class StringUtils {
  static pluralize = (count: number, noun: string, suffix = "s"): string => ` ${noun}${count !== 1 ? suffix : ""} `;

  static divideByCommaAndSemicolon = (value: string): string[] => {
    const values = value.split(";").map((val) => {
      return val.split(",").map((item) => item.trim());
    });

    const valuesArray = values.reduce((acc, cur) => acc.concat(cur));

    return valuesArray;
  };
}

export default StringUtils;
