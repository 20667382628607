import React, { memo } from "react";
import { Dropdown } from "@shared/components/Dropdown";
import { List, ListOptionBase } from "@shared/components/List";
import { SingleselectProps } from "@shared/components/Select/models";
import SelectToggleButton from "@shared/components/Select/SelectToggleButton";

const Singleselect = <ListOption extends ListOptionBase>({
  dropdownWidth,
  onSelect,
  variant,
  loading,
  disabled,
  testId,
  selectedOption,
  label,
  onToggleButtonClick,
  ...listProps
}: SingleselectProps<ListOption>) => (
  <Dropdown
    width={dropdownWidth}
    renderToggle={({ ref, toggleDropdown, open }) => (
      <SelectToggleButton
        open={open}
        ref={ref}
        onClick={() => {
          toggleDropdown();

          if (onToggleButtonClick) onToggleButtonClick();
        }}
        variant={variant}
        loading={loading}
        disabled={disabled}
        size={listProps.size}
        testId={testId}
      >
        {label ?? selectedOption?.label}
      </SelectToggleButton>
    )}
  >
    {({ closeDropdown }) => (
      <List<ListOption>
        {...listProps}
        variant="singleselect"
        selectedOptionId={selectedOption?.id}
        onOptionClick={(option) => {
          onSelect(option);

          closeDropdown();
        }}
      />
    )}
  </Dropdown>
);

export default memo(Singleselect) as typeof Singleselect;
