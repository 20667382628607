import { createAsyncThunk } from "@reduxjs/toolkit";
import { FSISDate } from "@funds/models";
import { ThunkApiConfig } from "@shared/models/redux";

export const fetchFSISDates = createAsyncThunk<
  FSISDate[],
  {
    fundId: string;
  },
  ThunkApiConfig
>("funds/fetchFSISDates", async ({ fundId }, { rejectWithValue, extra, signal }) => {
  try {
    const response = await extra<FSISDate[]>(`/reporting/v1/FPSIS/${encodeURIComponent(fundId)}/periods`, { signal });

    return response.data;
  } catch (e) {
    return rejectWithValue("Failed to fetch FSIS dates");
  }
});
