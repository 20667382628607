import React, { FunctionComponent, memo } from "react";
import { Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/styles";
import { NoDataMessage, NoDataTileProps } from "./models";

const useStyles = makeStyles((theme) => ({
  contactUs: {
    textTransform: "lowercase",
    textDecoration: "underline",
    padding: 0,
    fontSize: 19,
    fontWeight: 300,
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.info.main,
      backgroundColor: "transparent",
    },
  },
}));

const NoDataTile: FunctionComponent<NoDataTileProps> = ({ noDataMessage, onContactUsClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height={200}
      lineHeight={1.5}
      textAlign="center"
      data-testid="no-data-message-container"
      sx={{
        [theme.breakpoints.down("md")]: {
          fontSize: 20,
          letterSpacing: 0.4,
          lineHeight: "24px",
        },
      }}
    >
      <Grid item>
        <span data-testid="no-data-message">{noDataMessage}</span>{" "}
        {onContactUsClick ? (
          <>
            {NoDataMessage.Description}&nbsp;
            <Button className={classes.contactUs} onClick={onContactUsClick}>
              {NoDataMessage.ContactUs}
            </Button>
          </>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default memo<NoDataTileProps>(NoDataTile);
