import { useCallback } from "react";
import DateUtils from "@shared/utils/DateUtils";
import NumberUtils from "@shared/utils/NumberUtils";
import { useAppSelector } from "@app/hooks";
import { preferencesSelector } from "@app/store/selectors";
import { DateFormat, NumberFormat, UserPreferenceName } from "@user/models";

export const usePreferencesFormats = () => {
  const preferences = useAppSelector(preferencesSelector);

  const formatDate = useCallback(
    (date: Date, dateFormat: DateFormat = preferences[UserPreferenceName.DateFormat]) =>
      DateUtils.formatDate(date, dateFormat),
    [preferences]
  );

  const formatNumber = useCallback(
    (number: number, format: NumberFormat = preferences[UserPreferenceName.NumberFormat], fractionDigits?: number) =>
      NumberUtils.formatNumber(number, format, fractionDigits),
    [preferences]
  );

  return { formatDate, formatNumber };
};
