import React, { FunctionComponent, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import InvestorRequestEntitiesDropdowns from "@investorRequest/components/InvestorRequestEntitiesDropdowns";
import InvestorRequestFormTitle from "@investorRequest/components/InvestorRequestFormTitle";
import { investorRequestPreparedDataSelector, investorRequestSelector } from "@investorRequest/store/selectors";
import InvestorRequestTextarea from "@investorRequest/components/InvestorRequestTextarea";
import InvestorRequestDatepicker from "@investorRequest/components/InvestorRequestDatepicker";
import { updateCommonRequestData, updateCustomRequestData } from "@investorRequest/store/ducks";
import { useAppDispatch, useAppSelector, useMobileLayout } from "@app/hooks";
import { Nullable } from "@shared/models/general";
import { Input } from "@shared/components";

const WireInstructionsRequest: FunctionComponent = () => {
  const { funds, accounts, message, customFields } = useAppSelector(investorRequestPreparedDataSelector);
  const { validationWarningShown } = useAppSelector(investorRequestSelector);
  const dispatch = useAppDispatch();

  const handleChangeFieldValue = useCallback(
    (fieldName: string) => (value: string) => dispatch(updateCustomRequestData({ [fieldName]: value })),
    [dispatch]
  );

  const handleDatepickerChange = useCallback(
    (value: Nullable<Date>) => handleChangeFieldValue("effectiveDate")(value!.toISOString()),
    [handleChangeFieldValue]
  );

  const handleMessageChange = useCallback(
    (message: string) => dispatch(updateCommonRequestData({ message })),
    [dispatch]
  );

  const isMobile = useMobileLayout();
  const inputXS = isMobile ? 12 : 6;

  return (
    <>
      <div data-testid="wire-instructions-request" />
      <InvestorRequestFormTitle text="Related to:" />
      <InvestorRequestEntitiesDropdowns required accounts={accounts} funds={funds} />
      <InvestorRequestFormTitle text="Wire Instructions:" />
      <Grid item container xs={12} columnSpacing={isMobile ? undefined : 7} rowSpacing={isMobile ? 4 : 3}>
        <Grid item xs={inputXS}>
          <Input
            dense={false}
            placeholder="Bank Name*"
            variant="light"
            size="large"
            value={customFields.bankName}
            onChange={handleChangeFieldValue("bankName")}
            error={validationWarningShown && !customFields.bankName}
          />
        </Grid>
        <Grid item xs={inputXS}>
          <Input
            dense={false}
            placeholder="Beneficiary Account Name*"
            variant="light"
            size="large"
            value={customFields.beneficiaryAccountName}
            onChange={handleChangeFieldValue("beneficiaryAccountName")}
            error={validationWarningShown && !customFields.beneficiaryAccountName}
          />
        </Grid>
        <Grid item xs={inputXS}>
          <Input
            dense={false}
            placeholder="Bank Country*"
            variant="light"
            size="large"
            value={customFields.bankCountry}
            onChange={handleChangeFieldValue("bankCountry")}
            error={validationWarningShown && !customFields.bankCountry}
          />
        </Grid>
        <Grid item xs={inputXS}>
          <Input
            dense={false}
            placeholder="Bank Account Number or IBAN*"
            variant="light"
            size="large"
            value={customFields.bankAccountNumber}
            onChange={handleChangeFieldValue("bankAccountNumber")}
            error={validationWarningShown && !customFields.bankAccountNumber}
          />
        </Grid>
        <Grid item xs={inputXS}>
          <Input
            dense={false}
            placeholder="ABA/Routing Number or SWIFT ID*"
            variant="light"
            size="large"
            value={customFields.routingNumber}
            onChange={handleChangeFieldValue("routingNumber")}
            error={validationWarningShown && !customFields.routingNumber}
          />
        </Grid>
        <Grid item xs={inputXS} data-testid="investor-request-datepicker">
          <InvestorRequestDatepicker value={customFields.effectiveDate} onChange={handleDatepickerChange} />
        </Grid>
        <Grid item xs={12}>
          <Box pt={isMobile ? undefined : 7}>
            <InvestorRequestTextarea
              placeholder="Please include applicable intermediary and/or further credit details here*"
              value={message}
              onChange={handleMessageChange}
              error={validationWarningShown && !message}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default WireInstructionsRequest;
