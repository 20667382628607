import React, { memo, useMemo } from "react";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { noop } from "lodash";
import { Box } from "@mui/material";
import CommonUtils from "@shared/utils/CommonUtils";
import { Checkbox } from "@shared/components/Checkbox";
import { ListOptionBase, ListProps } from "@shared/components/List/models";
import { listOptionHeightMap, noOptions } from "@shared/components/List/constants";
import ListItem from "@shared/components/List/ListItem";
import { useListSearch } from "@shared/components/List/useListSearch";
import Input from "@shared/components/Input/Input";

const List = <ListOption extends ListOptionBase>(props: ListProps<ListOption>) => {
  const { options, withSearch, displayCount = 10, size = "medium", onOptionClick } = props;

  const { searchValue, displayOptions, handleSearch } = useListSearch(options);

  const optionHeight = listOptionHeightMap[size];

  const listHeight = useMemo(() => {
    const actualDisplayCount = displayOptions.length >= displayCount ? displayCount : displayOptions.length;

    return optionHeight * actualDisplayCount;
  }, [displayCount, optionHeight, displayOptions.length]);

  const selectedOptionsMap = useMemo(() => {
    if (props.variant === "multiselect") {
      return CommonUtils.convertArrayToMap(props.selectedOptionIds);
    }
  }, [props]);

  return (
    <>
      {withSearch && !!options.length && (
        <Box pt={5} pb={3} px={5}>
          <Input search autoFocus value={searchValue} onChange={handleSearch} size="small" placeholder="Search" />
        </Box>
      )}
      {displayOptions.length ? (
        <Box height={listHeight} data-testid="list-container">
          <AutoSizer>
            {({ height, width }: Size) => (
              <FixedSizeList
                width={width || 0}
                height={height || 0}
                itemCount={displayOptions.length}
                itemSize={optionHeight}
              >
                {({ index, style }) => {
                  const option = displayOptions[index];
                  let selected: boolean;

                  if (props.variant === "multiselect") {
                    selected = selectedOptionsMap![option.id as ListOption["id"]];

                    option.startAdornment = <Checkbox checked={selected} />;
                  } else {
                    selected = props.selectedOptionId === option.id;
                  }

                  return (
                    <ListItem
                      selected={selected}
                      size={size}
                      style={style}
                      option={option}
                      onOptionClick={onOptionClick}
                    />
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      ) : (
        <Box data-testid="list-w-no-options">
          <ListItem option={noOptions} size={size} onOptionClick={noop} />
        </Box>
      )}
    </>
  );
};

export default memo(List) as typeof List;
