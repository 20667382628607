export enum AppRoute {
  Home = "/",
  Documents = "/documents",
  UserProfile = "/profile",
  DataRooms = "/data-rooms",
  DataRoom = "/data-rooms/:globalId",
  FundProfile = "/fund-profile/:entityID",
  AccountSummaryCarlyle = "/reporting/account-summary/carlyle",
  AccountSummaryAlpInvest = "/reporting/account-summary/alpinvest",
  ReportingBase = "/reporting",
  CapitalAccountStatement = "/reporting/capital-account-statement",
  ScheduleOfInvestments = "/reporting/schedule-of-investments",
  CashFlowActivity = "/reporting/cash-flow-activity",
  PortfolioPerformance = "/reporting/portfolio-performance",
  DiversificationAnalysis = "/reporting/diversification-analysis",
  AdministrationBase = "/administration",
  AdministrationReports = "/administration/reports",
  AdministrationImpersonate = "/administration/impersonate",
  InsightsHub = "/insights-hub",
  InsightsHubArticle = "/insights-hub/:articleId",
  MyCalendar = "/my-calendar",
  MyCalendarEvent = "/my-calendar/:eventId",
  Help = "/help",
  Login = "/login",
  SignOut = "/signout",
}
