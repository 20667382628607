import { createAsyncThunk } from "@reduxjs/toolkit";
import { entitledAccountsSelector, entitledFundsSelector } from "@store/Entities/selectors";
import { ThunkApiConfig } from "@shared/models/redux";
import { EntitledAccount } from "../models";

export const fetchEntitledAccountsByEntity = createAsyncThunk<EntitledAccount[], string, ThunkApiConfig>(
  "entities/fetchEntitledAccountsByEntity",
  async (entitledEntityId, { rejectWithValue, extra, getState }) => {
    const entitledFunds = entitledFundsSelector(getState());
    const { id } = entitledFunds.data![entitledEntityId]!;

    try {
      const response = await extra<EntitledAccount[]>("/entitlements/v1/user/EntitledAccounts", {
        queryParams: { entitledEntities: id },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to load entitled accounts");
    }
  },
  {
    condition: (entitledEntityId, { getState }) => {
      const rootState = getState();
      const entitledAccounts = entitledAccountsSelector(rootState);
      const entitledFunds = entitledFundsSelector(rootState);

      return !entitledAccounts.loading && !entitledFunds.data![entitledEntityId]!.entitledAccountIds;
    },
  }
);
