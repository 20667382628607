import { GetAppListener } from "@shared/models/redux";
import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";
import RouterUtils from "@shared/utils/RouterUtils";
import { AppRoute } from "@app/models/AppRoute";
import { dataRoomsSelector } from "@dataRooms/store/selectors";

export const getIAGroupChangedDataRoomProfilePageListener: GetAppListener = (addAppListener, { history }) =>
  addAppListener({
    actionCreator: changeInvestorAccountsGroup.fulfilled,
    effect: async (_action, { getState, cancelActiveListeners }) => {
      cancelActiveListeners();

      const routeParams = RouterUtils.checkPathMatchExactRouteAndReturnRouteParams<{
        globalId: string;
      }>(history, AppRoute.DataRoom);

      if (!routeParams) return;

      const dataRooms = dataRoomsSelector(getState());

      if (dataRooms.every((dataRoom) => dataRoom.globalId !== routeParams.globalId)) {
        history.replace(AppRoute.DataRooms);
      }
    },
  });
