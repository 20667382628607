import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { selectedIAGroupIdSelector } from "@app/store/selectors";
import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";

export const useResetSelectedIAGroup = () => {
  const dispatch = useAppDispatch();
  const selectedIAGroup = useAppSelector(selectedIAGroupIdSelector);

  useEffect(() => {
    if (selectedIAGroup) {
      dispatch(changeInvestorAccountsGroup(null));
    }
  }, [dispatch, selectedIAGroup]);
};
