import { FetchDocumentsRequestBody } from "@documents/models/api.models";
import { FileResponse } from "@shared/models/request";
import { AppThunkAPI } from "@shared/models/redux";

type CallDownloadDocumentsAPI = (body: FetchDocumentsRequestBody) => AppThunkAPI<Promise<FileResponse>>;

export const callDownloadDocumentsAPI: CallDownloadDocumentsAPI = (body) => async (_dispatch, _state, extra) => {
  const response = await extra<FileResponse>("/documents/v1/Users/documents/search", {
    method: "post",
    responseType: "blob",
    body,
  });

  return response.data;
};
