import React, { forwardRef, FunctionComponent, SVGProps, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { get } from "lodash";

interface RoundedIconProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  variant?: "primary" | "secondary" | "warning" | "info" | "light" | "disabled";
  interactive?: boolean;
  onClick?(): void;
  testId?: string;
  sizeBig?: boolean;
}

const colorPathMap = {
  bg: {
    primary: "primary.main",
    secondary: "secondary.main",
    warning: "warning.main",
    info: "info.main",
    light: "common.white",
    disabled: "grey[600]",
  },
  icon: {
    primary: "common.white",
    secondary: "common.white",
    warning: "common.white",
    info: "common.white",
    light: "primary.main",
    disabled: "common.white",
  },
};

const useStyles = makeStyles<Theme, Pick<RoundedIconProps, "variant" | "interactive" | "sizeBig">>((theme) => ({
  root: {
    borderRadius: "50%",
    backgroundColor: ({ variant }) => get(theme.palette, colorPathMap.bg[variant!]),
    width: ({ sizeBig }) => (sizeBig ? 35 : 25),
    height: ({ sizeBig }) => (sizeBig ? 35 : 25),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all ease-in .15s",
    cursor: ({ interactive }) => (interactive ? "pointer" : "default"),
    "&:hover": {
      backgroundColor: ({ variant, interactive }) =>
        interactive ? theme.palette.info.main : get(theme.palette, colorPathMap.bg[variant!]),
    },
  },
  icon: {
    width: ({ sizeBig }) => (sizeBig ? 22 : 15),
    height: ({ sizeBig }) => (sizeBig ? 22 : 15),
    transition: "color ease-in .15s",
    color: ({ variant }) => get(theme.palette, colorPathMap.icon[variant!]),
  },
}));

const RoundedIcon = forwardRef<HTMLDivElement, RoundedIconProps>(
  ({ Icon, variant, interactive, onClick, testId, sizeBig, ...props }, ref) => {
    const classes = useStyles({ variant, interactive, sizeBig });

    const handleClick = useCallback(() => {
      onClick && onClick();
    }, [onClick]);

    return (
      <div
        {...props}
        className={classes.root}
        onClick={handleClick}
        ref={ref}
        data-testid={testId ? `rounded-icon-container-${testId}` : "rounded-icon-container"}
      >
        <Icon data-testid={testId ? `rounded-icon-${testId}` : "rounded-icon"} className={classes.icon} />
      </div>
    );
  }
);

RoundedIcon.defaultProps = {
  variant: "primary",
  interactive: false,
};

RoundedIcon.displayName = "RoundedIcon";

export default RoundedIcon;
