import { AppThunkAPI } from "@shared/models/redux";
import { ImpersonateUserResponse } from "@impersonateUser/models";

type CallImpersonatedUserDataAPI = (email: string) => AppThunkAPI<Promise<[ImpersonateUserResponse]>>;

export const callFetchImpersonatedUserAPI: CallImpersonatedUserDataAPI =
  (email) => async (_dispatch, _state, extra) => {
    const response = await extra<[ImpersonateUserResponse]>(`entitlements/v1/system/EntitledUsers?email=${email}`);

    return response.data;
  };
