import { createSlice } from "@reduxjs/toolkit";
import ReduxUtils from "@shared/utils/ReduxUtils";
import { DiversificationAnalysisState } from "@diversificationAnalysis/models";
import { fetchDiversificationAnalysisEmbedUrlThunk } from "@diversificationAnalysis/store/thunks/fetchDiversificationAnalysisEmbedUrlThunk";
import { postDiversificationAnalysisExportThunk } from "@diversificationAnalysis/store/thunks/postDiversificationAnalysisExportThunk";

const initialState: DiversificationAnalysisState = {
  embedUrlConfig: ReduxUtils.getAsyncSlice(),
  export: ReduxUtils.getAsyncSlice(),
  isReportDownloading: false,
};

const diversificationAnalysisSlice = createSlice({
  name: "diversificationAnalysis",
  initialState,
  reducers: {
    resetState: (state) => {
      state.embedUrlConfig = initialState.embedUrlConfig;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiversificationAnalysisEmbedUrlThunk.pending, (state) =>
        ReduxUtils.defaultPendingActionHandler(state.embedUrlConfig)
      )
      .addCase(fetchDiversificationAnalysisEmbedUrlThunk.fulfilled, (state, action) =>
        ReduxUtils.defaultFulfilledActionHandler(state.embedUrlConfig, {
          embedUrl: action.payload.embedUrl,
          reportingDate: action.payload.reportingDate,
        })
      )
      .addCase(fetchDiversificationAnalysisEmbedUrlThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.embedUrlConfig, action.payload as string)
      )
      .addCase(postDiversificationAnalysisExportThunk.pending, (state) => {
        state.isReportDownloading = true;
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          status: "Initialized",
        });
      })
      .addCase(postDiversificationAnalysisExportThunk.fulfilled, (state, action) => {
        ReduxUtils.defaultFulfilledActionHandler(state.export, {
          exportId: action.payload.exportId,
          status: "Initialized",
          percentComplete: action.payload.percentComplete,
          resourceFileExtension: action.payload.resourceFileExtension,
        });
      })
      .addCase(postDiversificationAnalysisExportThunk.rejected, (state, action) =>
        ReduxUtils.defaultRejectedActionHandler(state.export, action.payload as string)
      );
  },
});

export const { resetState } = diversificationAnalysisSlice.actions;
export default diversificationAnalysisSlice.reducer;
