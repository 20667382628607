import { GetAppListener } from "@shared/models/redux";
import { AppRoute } from "@app/models/AppRoute";
import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";
import { fetchNumberOfActionRequiredItems } from "@documents/store/thunks/fetchNumberOfActionRequiredItems";
import RouterUtils from "@shared/utils/RouterUtils";

export const getIAGroupChangedHomePageListener: GetAppListener = (addAppListener, { history }) =>
  addAppListener({
    actionCreator: changeInvestorAccountsGroup.fulfilled,
    effect: async (_action, { dispatch, cancelActiveListeners }) => {
      cancelActiveListeners();

      if (!RouterUtils.checkPathMatchExactRoute(history, AppRoute.Home)) {
        return;
      }

      dispatch(fetchNumberOfActionRequiredItems());
    },
  });
