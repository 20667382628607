import React, { FunctionComponent } from "react";
import { Button as MuiButton, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabButtonProps } from "./models";

const useStyles = makeStyles<Theme, Pick<TabButtonProps, "shape">>((theme) => ({
  root: {
    borderRadius: 0,
    height: 56,
    fontSize: 18,
    textTransform: "capitalize",
    boxShadow: "none",
    padding: theme.spacing(6),
    lineHeight: 1.2,
    "&:hover": {
      boxShadow: "none",
    },
    borderBottomStyle: "none",
    borderBottomWidth: "0px",
    [theme.breakpoints.up("xl")]: {
      minWidth: 200,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 14,
      width: 231,
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 14,
      width: 185,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 22,
      height: 70,
    },
  },
}));

const TabButton: FunctionComponent<TabButtonProps> = ({ children, variant, shape, onClick, testId }) => {
  const classes = useStyles({ shape });

  return (
    <MuiButton
      classes={{
        root: classes.root,
      }}
      variant={variant}
      color="primary"
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </MuiButton>
  );
};

TabButton.defaultProps = {
  variant: "contained",
  shape: "sharp",
};

export default TabButton;
