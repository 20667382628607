import {
  ReportCompany,
  ScheduleOfInvestmentsAccountsFilter,
  SoiAggregated,
  SoiDataGridColumn,
  SoiDataGridColumnsNames,
  SoiDataGridFundInvestmentsValues,
  SoiDetailed,
} from "@scheduleOfInvestments/models";
import { AsOfDate } from "@shared/models/filters";
import { onlyFairValueRowsConfig } from "@scheduleOfInvestments/constants";
import { Nullable } from "@shared/models/general";
import { NumberFormat } from "@user/models";

export const getSoiDataGridColumns = (
  numberFormat: NumberFormat,
  isMobile: boolean
): ReadonlyArray<SoiDataGridColumn> => [
  {
    accessor: "fundInvestments",
    Header: SoiDataGridColumnsNames.FundInvestments,
    width: 265,
    align: "left",
    headerAlign: "left",
    headerContentAlign: "flex-start",
    letterSpacing: 0,
    pinned: !isMobile,
    withTooltip: true,
    minWidth: 300,
  },
  {
    accessor: "initialInvestmentDate",
    Header: SoiDataGridColumnsNames.InitialInvestmentDate,
    flex: 0.5,
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "capitalContributed",
    Header: SoiDataGridColumnsNames.CapitalContributed,
    flex: 0.5,
    type: "currency",
    numberFormat,
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "uncalledInvestmentsAtCost",
    Header: SoiDataGridColumnsNames.UncalledInvestmentsAtCost,
    flex: 0.5,
    type: "currency",
    numberFormat,
    letterSpacing: 0,
    minWidth: 130,
  },
  {
    accessor: "investmentIncome",
    Header: SoiDataGridColumnsNames.InvestmentIncome,
    flex: 0.6,
    type: "currency",
    numberFormat,
    letterSpacing: 0,
    minWidth: 140,
  },
  {
    accessor: "investmentDistributionsGrossOfTaxWithholding",
    Header: SoiDataGridColumnsNames.InvestmentDistributions,
    flex: 0.6,
    type: "currency",
    numberFormat,
    letterSpacing: 0,
    minWidth: 140,
  },
  {
    accessor: "gaapUnrealizedAppreciationPreAccruedCarry",
    Header: SoiDataGridColumnsNames.GAAPUnrealizedAppreciation,
    flex: 0.8,
    type: "currency",
    numberFormat,
    letterSpacing: 0,
    minWidth: 150,
  },
  {
    accessor: "remainingInvestedCapitalAndUndistributedProceedsAtFairValue",
    Header: SoiDataGridColumnsNames.InvestmentsAtFairValue,
    flex: 0.8,
    type: "currency",
    numberFormat,
    letterSpacing: 0,
    minWidth: 150,
  },
];

export const getSoiDataGridColumnsWithAsOfDateBeforeQ42017 = (
  numberFormat: NumberFormat,
  isMobile: boolean
): ReadonlyArray<SoiDataGridColumn> => {
  let insertIndex = 0;
  const columns = getSoiDataGridColumns(numberFormat, isMobile)
    .filter((column: SoiDataGridColumn) => column.Header !== SoiDataGridColumnsNames.UncalledInvestmentsAtCost)
    .map((column: SoiDataGridColumn, index: number) => {
      if (column.Header === SoiDataGridColumnsNames.InvestmentDistributions) {
        insertIndex = index + 1;
      }

      if (column.Header === SoiDataGridColumnsNames.InvestmentsAtFairValue)
        return {
          ...column,
          flex: 1,
          Header: SoiDataGridColumnsNames.RemainingInvestedCapitalAndUndistributedProceedsAtFairValue,
        };

      return column;
    });

  columns.splice(insertIndex, 0, {
    accessor: "remainingInvestedCapitalAndUndistributedProceedsAtCost",
    Header: SoiDataGridColumnsNames.RemainingCapitalAndUndistributedProceedsAtCost,
    flex: 0.6,
    type: "currency",
    numberFormat,
    letterSpacing: 0,
    minWidth: 140,
  });

  return columns;
};

export const getOnlyFairValueRowsConfig = (isBeforeQ12017: boolean | null) => {
  if (!isBeforeQ12017) return onlyFairValueRowsConfig;

  return onlyFairValueRowsConfig.filter(
    (row) => row.fundInvestments !== SoiDataGridFundInvestmentsValues.InterestReceivable
  );
};

export const getReportCompanies = (report: SoiDetailed[] | undefined) => {
  if (!report) return [];

  const reportCompanies = report.reduce((companies: { [key: string]: ReportCompany }, company) => {
    companies[company.companyId] = {
      companyName: company.companyName,
      initialInvestmentDate: new Date(company.initialInvestmentDate),
      currency: company.reportingCurrency,
    };

    return companies;
  }, {});

  return Object.entries(reportCompanies).sort((a, b) =>
    a[1].companyName.toUpperCase() > b[1].companyName.toUpperCase() ? 1 : -1
  );
};

export const getCompanySummationField = (
  report: SoiDetailed[] | undefined,
  fieldName: string,
  companyId: string,
  investors: (string | number)[]
): number => {
  if (!report) return 0;

  return report.reduce((sum: number, item: SoiDetailed) => {
    sum +=
      !companyId || (item.companyId === companyId && investors && investors.includes(item.investorId))
        ? Number(item[fieldName as keyof SoiDetailed])
        : 0;

    return sum;
  }, 0);
};

export const getAggregatedSummationField = (
  report: SoiAggregated[] | undefined,
  fieldName: string,
  investors: (string | number)[]
): number | undefined => {
  if (!report) return 0;

  if (!fieldName) {
    return undefined;
  }
  if (!report.find((item) => fieldName in item)) {
    return undefined;
  }

  return report.reduce((sum, item) => {
    sum += investors?.includes(item.investor) ? Number(item[fieldName as keyof SoiAggregated]) : 0;

    return sum;
  }, 0);
};

export const getIsDateBeforeSpecifiedDate = (
  { year, quarter }: AsOfDate,
  specifiedYear: string,
  specifiedQuarter: string
): boolean => {
  return year < specifiedYear || (year === specifiedYear && quarter < specifiedQuarter);
};

export const getInvestorAccountsIds = (accounts: Nullable<ScheduleOfInvestmentsAccountsFilter>) => {
  if (!accounts) return null;

  const selectedAccounts = Object.keys(accounts).filter((accountId) => accounts[accountId]);

  return selectedAccounts.length ? selectedAccounts : null;
};
