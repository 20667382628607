import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { callEmbedUrlAPI } from "@reporting/store/thunks/callEmbedUrlAPI";
import { ReportingEmbedUrlConfig } from "@reporting/models";
import { diversificationAnalysisEmbedUrlSelector } from "@diversificationAnalysis/store/selectors";

export const fetchDiversificationAnalysisEmbedUrlThunk = createAsyncThunk<
  ReportingEmbedUrlConfig,
  void,
  ThunkApiConfig
>(
  "diversificationAnalysis/fetchDiversificationAnalysisEmbedUrlThunk",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callEmbedUrlAPI("DiversificationAnalysis"));
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: "Failed to load report",
        })
      );

      return rejectWithValue("Failed to fetch Embed Url");
    }
  },
  {
    condition: (_, { getState }) => {
      const diversificationAnalysisEmbedUrl = diversificationAnalysisEmbedUrlSelector(getState());

      return !diversificationAnalysisEmbedUrl.data && !diversificationAnalysisEmbedUrl.loading;
    },
  }
);
