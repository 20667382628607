import React from "react";
import ReactDOM from "react-dom";
import AppProviders from "@app/containers/AppProviders";
import App from "@app/containers/App";
import { unregister } from "./registerServiceWorker";

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
);

unregister();
