import { createAsyncThunk } from "@reduxjs/toolkit";
import { HomePageVideoDetailsResponse } from "@home/models";
import { ThunkApiConfig } from "@shared/models/redux";
import { openNotificationThunk } from "@store/Notification/thunks";
import { homeNotificationMessage } from "@home/constants";
import { callHomePageVideoDetailsAPI } from "@home/store/thunks/callHomePageVideoDetailsAPI";

export const fetchHomePageVideoDetails = createAsyncThunk<HomePageVideoDetailsResponse, void, ThunkApiConfig>(
  "home/fetchHomePageVideoDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      return await dispatch(callHomePageVideoDetailsAPI());
    } catch (error) {
      dispatch(
        openNotificationThunk({
          variant: "warning",
          text: homeNotificationMessage.warning,
        })
      );

      return rejectWithValue("Failed to fetch home page video details");
    }
  }
);
