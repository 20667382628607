import { changeInvestorAccountsGroup } from "@user/store/thunks/changeInvestorAccountsGroup";
import { entitledAccountsSelector, entitledFundsSelector } from "@store/Entities/selectors";
import { selectDocumentsFilterValues } from "@documents/store/selectors";
import { changeDocumentsFilters } from "@documents/store/thunks";
import { AppRoute } from "@app/models/AppRoute";
import { GetAppListener } from "@shared/models/redux";
import RouterUtils from "@shared/utils/RouterUtils";

export const getIAGroupChangedDocumentPageListener: GetAppListener = (addAppListener, { history }) =>
  addAppListener({
    actionCreator: changeInvestorAccountsGroup.fulfilled,
    effect: async (action, { getState, dispatch, cancelActiveListeners, condition }) => {
      cancelActiveListeners();

      await condition(() => RouterUtils.checkPathMatchExactRoute(history, AppRoute.Documents));

      const state = getState();

      const { data: accountsMap } = entitledAccountsSelector(state);
      const { data: fundsMap } = entitledFundsSelector(state);
      const filters = selectDocumentsFilterValues(state);

      const selectedAccounts = (filters.investorAccounts || []).filter((id) => accountsMap?.[id]);
      const selectedFunds = (filters.funds || []).filter((id) => fundsMap?.[id]);

      const investorAccounts = selectedAccounts.length ? selectedAccounts : null;
      const funds = selectedFunds.length ? selectedFunds : null;

      dispatch(changeDocumentsFilters({ investorAccounts, funds }));
    },
  });
