import React, { memo } from "react";
import { Box, CircularProgress } from "@mui/material";

const InvestorRequestLoadingPlaceholder = () => (
  <Box
    height={350}
    width="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    data-testid="investor-request-loading-placeholder"
  >
    <CircularProgress />
  </Box>
);

export default memo(InvestorRequestLoadingPlaceholder);
