import React, { FunctionComponent, memo } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { Button } from "@shared/components/Button";
import { RoundedIcon } from "@shared/components/RoundedIcon";
import { ReactComponent as MinusIcon } from "@assets/svg/minus.svg";
import { ReactComponent as PlusIcon } from "@assets/svg/plus.svg";

interface Props {
  text?: string;
  isFilterPanelOpen: boolean;
  onClick(): void;
  testId?: string;
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    "&:hover svg": {
      color: theme.palette.info.main,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const FilterButton: FunctionComponent<Props> = ({ text, onClick, isFilterPanelOpen, testId }) => {
  const classes = useStyles();

  const FilterIcon = isFilterPanelOpen ? MinusIcon : PlusIcon;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.buttonContainer}>
      <Button
        fullWidth
        size={isSmall ? "medium" : "large"}
        onClick={onClick}
        testId={testId}
        endIcon={<RoundedIcon Icon={FilterIcon} variant="light" testId={testId} />}
      >
        <span data-testid={testId ? `filter-button-${testId}` : "filter-button"}>{text}</span>
      </Button>
    </Box>
  );
};

FilterButton.defaultProps = {
  text: "Filter",
};

export default memo<Props>(FilterButton);
