import { createAsyncThunk } from "@reduxjs/toolkit";
import { investorsSelector } from "@store/Entities/selectors";
import { ThunkApiConfig } from "@shared/models/redux";
import { Investor } from "../models";

export const fetchInvestors = createAsyncThunk<Investor[], boolean | undefined, ThunkApiConfig>(
  "entities/fetchInvestors",
  async (_, { rejectWithValue, extra, signal }) => {
    try {
      const response = await extra<Investor[]>("/reporting/v1/Investors?pageNumber=1&pageSize=1000000", { signal });

      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to load Investors");
    }
  },
  {
    condition: (refresh, { getState }) => {
      if (refresh) return true;

      const investors = investorsSelector(getState());

      return !investors.loading && !investors.data;
    },
  }
);
