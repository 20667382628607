import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImpersonationState } from "@impersonateUser/models";
import { startImpersonationSession, stopImpersonationSession } from "@impersonateUser/store/actions";
import { fetchUserProfile } from "@user/store/thunks/fetchUserProfile";
import { initializeImpersonationSession } from "@impersonateUser/store/thunks/initializeImpersonationSession";
import { PreferenceShortenObject, UserData, UserPreferenceName } from "@user/models";

const initialState: ImpersonationState = {
  canImpersonate: false,
  isImpersonating: false,
  errorImpersonating: false,
  user: null,
};

const impersonationSlice = createSlice({
  name: "impersonation",
  initialState,
  reducers: {
    changeImpersonatedUserInvestorAccountsGroup: (state, action: PayloadAction<UserData["selectedAccountGroup"]>) => {
      state.user!.selectedAccountGroup = action.payload;
    },
    resetImpersonationError: (state) => {
      state.errorImpersonating = false;
    },
    updateImpersonatedUserPreference(state, action: PayloadAction<PreferenceShortenObject<UserPreferenceName>>) {
      const updatedPreferences = {
        ...state.user!.preferences,
        [action.payload.setting]: action.payload.value,
      };

      state.user!.preferences = updatedPreferences;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.canImpersonate = action.payload.permissions.some((permission) => permission === "impersonate:User");
      })
      .addCase(startImpersonationSession, (state, action) => {
        state.isImpersonating = true;
        state.user = action.payload;
      })
      .addCase(stopImpersonationSession, (state) => {
        state.isImpersonating = false;
        state.user = initialState.user;
      })
      .addCase(initializeImpersonationSession.rejected, (state) => {
        state.errorImpersonating = true;
      });
  },
});

export const {
  resetImpersonationError,
  updateImpersonatedUserPreference,
  changeImpersonatedUserInvestorAccountsGroup,
} = impersonationSlice.actions;
export default impersonationSlice.reducer;
