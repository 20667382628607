import React, { FunctionComponent, memo } from "react";
import { Grid, Typography } from "@mui/material";
import { useMobileLayout } from "@app/hooks";

interface Props {
  text: string;
}

const InvestorRequestFormTitle: FunctionComponent<Props> = ({ text }) => {
  const isMobile = useMobileLayout();

  return (
    <Grid item xs={12} data-testid="investor-request-form-title">
      <Typography variant="bodySubhead2" sx={{ letterSpacing: 0, fontSize: isMobile ? 18 : "inherit" }}>
        {text}
      </Typography>
    </Grid>
  );
};

export default memo<Props>(InvestorRequestFormTitle);
